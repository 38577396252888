import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import NavBar from '../navigation/nav-bar'
import ActivityBox from './activity-box'

class RecurrentActivities extends React.Component {
    
    constructor(){
        super()

        this.state={
            activities: {},
            uploadedActivities: {}
        }
    }
    
    componentDidMount(){
        firebase.firestore().collection("lms/recurrent-activities/beginner").get()
        .then((querySnapshot) => {
            let activities = {}
            querySnapshot.forEach((doc) => {
                activities[doc.id]=doc.data()
            });
            this.setState({activities})
            console.log(activities)
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });


        firebase.firestore().collection("student-portfolios/"+this.props.uid+"/recurrent-activities").onSnapshot((snap)=>{

            let uploadedActivities = {}
            snap.docs.forEach(doc=>{
                uploadedActivities[doc.id]=doc.data()
            })

            this.setState({uploadedActivities})


        })
    }

    addActivity = (activity)=>{
       
        const act = this.state.activities[activity]

        const timestamp = +new Date()

        const task = {
            name: act.nombre, 
            status: "undone", 
            timestamp: timestamp
        }

        const date = new Date()
        var day = date.getDate()
        var month = date.getMonth() + 1
        var year = date.getFullYear()


        const dateFormat = day+"-"+month+"-"+year
        
        
        firebase.firestore().collection("students/"+this.props.uid+"/agenda").doc(dateFormat).get().then(doc=>{
            
            var agenda = doc.data()
           
            agenda[activity] = task

            firebase.firestore().collection("students/"+this.props.uid+"/agenda").doc(dateFormat).update(agenda)
        }).catch(err=>{

            var agenda = {}
            agenda[activity] = task
            firebase.firestore().collection("students/"+this.props.uid+"/agenda").doc(dateFormat).set(agenda)
        })
        
    }

    render(){
        const activities = this.state.activities
        console.log(this.state.topic)
        const topic = this.state.topic
       
        return(
            <div>
                <NavBar
                    handleBack={this.props.handleBack}
                    backView="language"
                    title="Actividades Recurrentes"
                />
                
                <div className="curricular-activities-view">
                    
                    {Object.keys(activities).map(key=>{

                        const activity = activities[key]
                        return(
                            <div>
                                
                                <ActivityBox
                                    name={activity.nombre}
                                    uploadedActivity={this.state.uploadedActivities[key]}
                                    addActivity={this.props.addActivity}
                                    activity_key={key}
                                    activity={activity}
                                    backView="recurrent-activities"
                                    viewCurricularActivity={this.props.viewCurricularActivity}
                                />
                            </div>
                        )
                    })}

                </div>
            </div>
        )
    }
}

export default RecurrentActivities