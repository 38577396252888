import React from 'react';
import {Mixpanel} from '../Mixpanel'
import GameButton from '../components/game-button'
import './game.css'
import Left from '../assets/images/left.png';
import axios from 'axios';


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;


class Game extends React.Component {
    constructor(){
        super()
        this.state = {
            button: "",
            counter: 0
        }
        this.handleGameClick = this.handleGameClick.bind(this)
    }

    componentWillMount(){
        firebase.database().ref("users/"+this.props.userID+"/clicker").once("value", (snap)=>{
            const played = snap.val()

            var timestamp = +new Date()
  
            timestamp = timestamp-86400000
    
            if(timestamp<(played)){
            //Send the selected quest level to quest component (plus one cause it's an array)
            alert("Ya completaste un reto del día de hoy. Vuelve mañana para realizar otro :)")

            this.props.handleViewChange("app")
            }
            
        })

        
    }

    handleGameClick(side) {

        if (this.state.button != side){
            const newCounter = this.state.counter + 1

            this.setState({
                button: side,
                counter: newCounter
            })

            firebase.database().ref("clicks/"+this.props.userID).update({
                clicks: newCounter
            })

            if (newCounter == 20){

                Mixpanel.track('Clicker Finish');

                alert("¡Ganaste 1 Morita! Vuelve mañana para poder ganar más")
            
                const that = this
                firebase.database().ref("users/"+this.props.userID+"/berries").once("value", function(snap){
                    const newBal = 1 + snap.val()

                    firebase.database().ref("users/"+that.props.userID).update({
                        berries: newBal
                    })
                })
                

                // axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
                //     var timestamp = data.data.timestamp

                //     firebase.database().ref("transactions/" + this.props.userID).push().set({
                //         desc: "Juego en App",
                //         cant: 10,
                //         type: "add",
                //         date: timestamp
                //     })

                //     firebase.database().ref("users/"+that.props.userID+"/clicker").set(timestamp)

                //     firebase.database().ref("analytics/clicks/"+timestamp).push(this.props.userID)
                // })
            }
        }

            
        
    }

    render(){

        return(
            <div className="game-container">
                <div className="nav-bar">

                    <div className="nav-left">
                        <div
                            className="nav-icon" 
                            onClick={()=>this.props.handleViewChange("app")}
                        >
                            <img className="back"src={Left}></img>
                        </div>
                        <p className="heading">Clicker</p>

                    </div>

                </div>
                <p className="game-text">
                    Haz click en el circulo gris 20 veces para ganar Moritas
                </p>
            
                <p>
                    {this.state.counter}
                </p>
                <div className="game-buttons">
                    <GameButton
                        status = {this.state.button}
                        side = "left"
                        color = "red"
                        handleGameClick = {this.handleGameClick}
                    />

                    <GameButton
                        status = {this.state.button}
                        side = "right"
                        color = "blue"
                        handleGameClick = {this.handleGameClick}
                    />
                </div>
                

            </div>
        )
        
    }
}

export default Game;