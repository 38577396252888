import React from 'react';
import './dialogue.css'
import Arrow from '../../assets/images/triangle.png'

const ManagerDialogue = (props) => {
    return(
        <div className="dialogue-container">
            <img className="left-arrow" src={Arrow}></img>
            <div
                className="dialogue-text"
            >
                <p>
                    {props.text}
                </p>
            </div>
            
        </div>
    )
}

export default ManagerDialogue;