import React from 'react'
import './berry-store-item.css'
import Berries from '../treehouse/berries';


const StoreItem = (props) => {

    return(
        <div className="store-item">
            <p className="product-name">{props.item.name}</p>
            <img 
                className="product-img"
                src={props.item.img}
            ></img>
            <Berries
                berries={props.item.price}
            />
            <button className="buy-btn" onClick={()=>props.handlePurchase(props.item.price, props.item)}>Comprar</button>
        </div>
    )

}

export default StoreItem;