import React from 'react';
import './wallstreet-nav.css';
import Quit from '../../assets/images/left.png'
import MarketIcon from '../../assets/images/market.png'
import PortfolioIcon from '../../assets/images/portfolio.png'
import NewsIcon from '../../assets/images/news.png'

import Cash from '../../assets/images/cash.png'

const WallStreetNav = (props) => {
    console.log(props.roundValues.lenght)
    const mins = Math.floor(props.timer / 60000)
    const secs = Math.floor(props.timer / 1000) - mins*60

    const cities = {
        mex: "🇲🇽 Ciudad de México", 
        nyc: "🇺🇸 Nueva York",
        sha: "🇨🇳 Shanghai",
        ldn: "🇬🇧 Londres"
    }
    return(
        <div className="street-nav">

        <div className="wallstreet-nav-left">
            {/* <div 
                className="back-left"
                onClick={props.cashOutModal}
            >
                <img className="back"src={Quit}></img>
                
            </div> */}

            <p class="nav-city-title">{cities[props.city]}</p>

        </div>

        <div class="wallstreet-nav-center">
        
                <div className="street-round">
                    Ronda: {props.currentRound+1} de {props.roundValues.length}
                </div>
                <p class="hud-subtitle">Tiempo Restante</p>
                <p className="street-time-counter">{mins}:{secs}</p>
        
        </div>

        <div className="wallstreet-nav-right">
            
            
            <div className="wallstreet-balance-container">
            
                <img className="wallstreet-balance-img" src={Cash}></img>
                <p className="wallstreet-balance">${props.balance}</p>
            
            </div>

            {/* <button 
                className="withdraw-btn"
                onClick={props.cashOutModal}
            
            >Retirar Fondos</button> */}
            
            <div className="street-tabs">
                <div onClick={()=>props.handleTabChange("market")}>
                    <img src={MarketIcon} className="street-tab"></img>
                </div>
                
                <div onClick={()=>props.handleTabChange("portfolio")}>
                    <img src={PortfolioIcon} className="street-tab"></img>
                </div>

                <div onClick={()=>props.handleTabChange("news")}>
                    <img src={NewsIcon} className="street-tab"></img>
                </div>
                
            </div>
        </div>

    </div>
    )
    
}

export default WallStreetNav;