import React from 'react';
import Recipe from './recipe';


const MenuContainer = (props) => {
    document.body.style.backgroundColor = "#00CCFF";
    window.scrollTo(0, 0);
    
    return(
        <div className="menu">
            {/* Recipe */}

            {Object.keys(props.menu).map((dish)=>{
                const ingredients = dish.split("-")
                const recipe = props.menu[dish]

                var imageArray = []

                for(var i =0; i<3; i++){
                    imageArray[i]=props.ingredientCatalogue[ingredients[i]]
                }

                return(
                    <div>
                        <Recipe
                            name={recipe.name}
                            price={recipe.price}
                            img={recipe.img}
                            ingredients={imageArray}
                        />
                    </div>
                )
                
            })}
            {/* <Recipe
                name="Burger"
                price={120}
                ingredients={["https://image.flaticon.com/icons/svg/135/135628.svg", "", ""]}
            />

            <Recipe
                name="Burger"
                price={120}
                ingredients={["https://image.flaticon.com/icons/svg/135/135628.svg", "", ""]}
            />

            <Recipe
                name="Burger"
                price={120}
                ingredients={["https://image.flaticon.com/icons/svg/135/135628.svg", "", ""]}
            />

            <Recipe
                name="Burger"
                price={120}
                ingredients={["https://image.flaticon.com/icons/svg/135/135628.svg", "", ""]}
            /> */}
        </div>

    )
}

export default MenuContainer;