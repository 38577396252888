import React from "react";
import "./button.css";

const Button = props => {
  return (
    <button
      onClick={e => props.handleClick(e)}
      className={[props.color, props.width].join(" ")}
      value={props.view}
    >
      {props.text}
    </button>
  );
};

export default Button;
