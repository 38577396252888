import React from 'react';
import Coin from '../../assets/images/coin.png'

import './prize-modal.css'
import Berries from '../treehouse/berries';

const PrizeModal = (props) => {
    if(props.type=="berries"){
        return(
            <div className="prize-modal-background">
                <div className="prize-modal">
                    <p className="prize-text">{props.text}</p>
                    <Berries
                        berries = {props.amount}
                    />
                    
                    <button onClick={props.handleClick} className="prize-modal-button">Ok</button>
                    
                </div>
            </div>
        )
    }
    else{
        return(
            <div className="prize-modal-background">
                <div className="prize-modal">
                    <p className="prize-text">{props.text}</p>
                    <div className="prize-coin-container">
                        <img src={Coin} className="prize-modal-coin"></img>
                        <p>{props.amount}</p>
                    </div>
                    <button onClick={props.handleClick} className="prize-modal-button">Ok</button>
                    
                </div>
            </div>
        )
    }
    
}

export default PrizeModal;

