import React, { Component, createRef } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;


import mergeImages from 'merge-images';


import Card from "../layout/card/card";
import Avatar from '../avatar/avatar';
import ChooseAvatar from '../avatar/choose-avatar';
import '../profile/profile-v2.css';
import Button from "../layout/button/button";
import AvatarPick from "../avatar/avatar-pick";

import AvatarEdit from "../avatar/avatar-edit";
import ButtonCard from './button-card';
import ManagerImg from '../../assets/images/manager.png'
import ClickerImg from '../../assets/images/clicker.png';
import StreetImg from '../../assets/images/street.png';
import StoreImg from '../../assets/images/tienda.png';
import SubscriptionImg from '../../assets/images/subscription.png';
// import SchoolImg from '../../assets/images/school-box.png';
import PricetagImg from '../../assets/images/pricetag.png'; 

import Pricetag from '../../assets/images/supermarket-building.png'
import Quest from '../../assets/images/quest-building.png'
import Street from '../../assets/images/bank.png'
import Store from '../../assets/images/store-building.png'
import BerryStore from '../../assets/images/berry-store-building.png'
import Clicker from '../../assets/images/clicker-building.png'
import Settings from '../../assets/images/settings.png'
import Arcade from '../../assets/images/arcade.png'
import Gourmet from '../../assets/images/gourmet.png'

import Oxxo from '../../assets/images/oxxo.png'
import Music from '../../assets/images/edificio-musica.gif'
import Lab from '../../assets/images/lab.png'

import Plaza from '../../assets/images/plaza.png'
import Model from '../../assets/images/model.png'

import Ani from '../../assets/images/ani.png'


import ArcadeVIP from '../../assets/images/arcade-vip.png'
import Robotica from '../../assets/images/taller-de-robots.gif'
import Web from '../../assets/images/cafe-internet.gif'


import Mail from '../../assets/images/mail.png'
import MailYellow from '../../assets/images/mail-notification.png'

import Grass from '../../assets/images/tile-grass.png'
import NavBar from '../navigation/nav-bar';
import RobinLogo from '../../assets/images/robin-logo.png'
import School from '../../assets/images/school.png'

import Diseno from '../../assets/images/diseno.png'
import Youtube from '../../assets/images/youtube-building-roger.gif'
import Dibujo from '../../assets/images/dibujo.png'
import Circuit from '../../assets/images/circuits.png'



import Monos from '../../assets/images/monos.png'
import Leones from '../../assets/images/leones.png'
import Hormigas from '../../assets/images/hormigas.png'
import Tortugas from '../../assets/images/tortugas.png'
import Buhos from '../../assets/images/buhos.png'
import Dojo from '../../assets/images/doji.png'
import Yoga from '../../assets/images/yoga.png'
import Baile from '../../assets/images/baile.png'
import Arte from '../../assets/images/artes.png'
import CienciasOhana from '../../assets/images/ohanaciencias.png'
import Leyendas from '../../assets/images/leyendas.png'
import Salud from '../../assets/images/salud.png'
import LSM from '../../assets/images/lsm.png'
import Mexico from '../../assets/images/mexico.png'
import Ohana from '../../assets/images/ohana.png'
import Agenda from '../LMS/agenda-v4';
import SubjectSelector from '../LMS/subject-selector';
import Robins from '../treehouse/robins';
import Berries from '../treehouse/berries';






class Profile extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = this.initialState();
    this.goToChangeImage = this.goToChangeImage.bind(this);
    this.goToHome = this.goToHome.bind(this);

    this.mainContainerRef = createRef();
  }

  componentWillMount(){
    document.body.style.backgroundColor = "#F0F0F4";
    document.body.style.backgroundImage = `url(${Grass})`

    this.setState({
      profile: this.props.image
    })

  }

  componentDidMount(){

    mergeImages([Lab, Buhos, Arcade])
    .then(b64 => console.log("b64"));

    if (this.mainContainerRef.current) {
        this.mainContainerRef.current.addEventListener('wheel', this.handleScroll);
      }
  }

  componentWillUnmount() {
    if (this.mainContainerRef.current) {
      this.mainContainerRef.current.removeEventListener('wheel', this.handleScroll);
    }
  }


  // componentWillUnmount(){
  //   // document.body.style.backgroundImage = "none"
  // }

  initialState() {
    return {
      changeImage: false
    };
  }

  goToChangeImage() {
    this.setState({
      changeImage: true
    });
  }

  goToHome() {
    this.setState(this.initialState())
  }

  pickAvatar =()=>{
    this.setState({
      profile: undefined
    })
    this.props.handleViewChange("avatar")
    
  }

  setAvatar = (url)=>{
    const uid = this.props.user.uid;

    firebase.database().ref("users/" + uid).update({avatar: url})

    this.setState({
      profile: url
    })
  }

  handleScroll = (event) => {
    if (this.mainContainerRef.current) {
      // Check if the scroll event originated from the agenda
      if (event.target.closest('.agenda-modal-view')) {
        // Allow vertical scrolling for the agenda
        return;
      }

      // For other elements, maintain horizontal scrolling
      if (event.deltaY !== 0) {
        this.mainContainerRef.current.scrollLeft += event.deltaY;
        event.preventDefault(); // Prevent vertical scroll
      }
    }
  }


  render() {

    console.log(this.props)
    var mailImg = MailYellow

    if(!this.props.child.mail){
      mailImg = Mail
    }

    if (!this.props.child.profile) {
      this.pickAvatar()
      return(
        <div>
          <p className="avatar-text">Elije una imagen de perfil</p>
          {/* <AvatarEdit
            goToHome={this.goToHome}
            changeAvatar={this.setAvatar}

            handleViewChange = {this.handleViewChange}
              child = {this.props.child}
              uid = {this.props.user.uid}
        /> */}
        </div>
        
      );
    } else {
      return(
        <div className='profile-container'>

          <div className="top-bar">
            <img src={RobinLogo} className="logo-nav"></img>
            <div className='profile-counters'>
                <Berries berries={this.props.child.berries}></Berries>
                <Robins robins={this.props.child.balance}></Robins>
            </div>
            
            <div className='city-map' onClick={()=>{this.props.handleViewChange("city-map")}}>
                <img className='city-map-img' src="https://polychatstorage.blob.core.windows.net/robin-buildings/diagonal-resize.png"></img>
            </div>
          </div>
          {/* <div onClick={()=>this.props.handleViewChange("subscription")}>
            <img src={Settings} className="settings-btn"></img>
          </div> */}
          
          <div className="town-container" ref={this.mainContainerRef}>
          <div className='top-container'></div>
            <div className='avatar-fixed' onClick={this.pickAvatar}>
              <Avatar
                image={this.props.child.profile}
              />
            </div>
           
            <Agenda
              addTask={this.props.addTask}
              handleViewChange={this.props.handleViewChange}
              viewCurricularActivity={this.props.viewCurricularActivity}
              uid = {this.props.uid}
              child={this.props.child}
              receiveAgendaDate={this.props.receiveAgendaDate}
              date={this.props.agendaDate}
              addCustomTask={this.props.addCustomTask}

              startDate={this.props.startDate}
              addActivity={this.props.addActivity}
            />
            
            

            {/* Treehouse */}
            <div className="building" onClick={()=>this.props.handleViewChange("treehouse")}>
              {/* <img className="profile-mini-img" src={this.props.child.profile}></img> */}
              <img className="building-img" src="https://polychatstorage.blob.core.windows.net/robin-buildings/treehouse.png"></img>
            </div>

            
            {/* School */}
            {this.props.child.school &&
            <SubjectSelector
                handleViewChange={this.props.handleViewChange}
                viewLMS={this.props.viewLMS}
                viewSubject={this.props.viewSubject}
                child={this.props.child}
            />
            }
            {/* {this.props.child.school &&
            <div className="building" onClick={()=>window.open("https://zep.us/play/8gr1vE")}>
                <img className="building-img" src={School}></img>
            </div>
            } */}

            {/* Polly */}
            {/* <div className="building" onClick={()=>window.open("https://rovaga.notion.site/Polly-chat-254f026253514e2fb55231cd2a241c42")}>
              <img className="building-img" src={"https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/files%2FaTJYFKUvCBX4nsBCL6kT6ttsIVr1%2F1680197952637rogelio_16-bit_pixel_art_of_big_ben_building_in_the_style_of_mo_8dd26f66-1886-4082-8da3-b14c3a59105b.png?alt=media&token=6723816b-983b-4d0c-b9f5-b41e3eacd0e5"}></img>
            </div> */}

            {/* Oxxo */}
            {this.props.child.school && 
                <div className="building" onClick={()=>this.props.handleViewChange("join-wallstreet")}>
                    <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/wallstreet.png"}></img>
                </div>
            } 

            {!this.props.child.school &&
              <div className='subject-selector-view'>
                {/* Web */}
                <div className="building" onClick={()=>this.props.handleViewChange("web")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/web.png"}></img>
                </div>
              
                {/* Robo */}
                <div className="building" onClick={()=>this.props.handleViewChange("robot")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/robot.png"}></img>
                </div>

                <div className="building" onClick={()=>this.props.handleViewChange("yt")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/yt.png"}></img>
                </div>

                

                {/* Music */}
                <div className="building" onClick={()=>this.props.handleViewChange("music")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/music.png"}></img>
                </div>
                
                {/* Lab */}
                <div className="building" onClick={()=>this.props.handleViewChange("sci")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/experiments.png"}></img>
                </div>

                {/* Gourmet */}
                <div className="building" onClick={()=>this.props.handleViewChange("cocina")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/cocina.png"}></img>
                </div>

                {/* Modelos 3D */}
                <div className="building" onClick={()=>this.props.handleViewChange("model")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/model.png"}></img>
                </div>

                {/* Arcade */}
                <div className="building" onClick={()=>this.props.handleViewChange("vg1")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/vg.png"}></img>
                </div>


                

                

                {/* Dibujo */}
                <div className="building" onClick={()=>this.props.handleViewChange("illustration")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/illustration.png"}></img>
                </div>


                {/* Arcade VIP */}
                {/* <div className="building" onClick={()=>this.props.handleViewChange("vg2")}>
                  <img className="building-img" src={ArcadeVIP}></img>
                </div> */}

                {/* Animación */}
                <div className="building" onClick={()=>this.props.handleViewChange("ani")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/ani.png"}></img>
                </div>

                {/* Diseño */}
                <div className="building" onClick={()=>this.props.handleViewChange("tech101")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/tech101.png"}></img>
                </div>

                

                

                {/* Modelos 3D */}
                <div className="building" onClick={()=>this.props.handleViewChange("circuits")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/circuits.png"}></img>
                </div>

                {/* Excel */}
                <div className="building" onClick={()=>this.props.handleViewChange("excel")}>
                  <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/excel.png"}></img>
                </div>


              </div>
            }


            
            
            

            

            

            {/* Supermarket */}
            {/* <div className="building" onClick={()=>this.props.handleViewChange("pricetag-levels", "pricetag")}>
              <img className="building-img" src={Pricetag}></img>
            </div> */}


            


            

            
            


            {/* Plaza */}
            {/* {this.props.child.group && */}
            {/* <div className="building" onClick={()=>this.props.handleViewChange("friend-list")}>
                <img className="building-img" src={Plaza}></img>
              </div> */}
            {/* } */}
            

            

            {/* Mail */}
            {/* <div className="building" onClick={()=>this.props.handleViewChange("refer")}>
              <img className="building-img" src={mailImg}></img>
            </div> */}
            
            {/* Street */}
            <div className="building" onClick={()=>this.props.handleViewChange("finanzas")}>
              <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/finanzas.png"}></img>
            </div>


            {/* Tienda */}
            <div className="building" onClick={()=>this.props.handleViewChange("store")}>
              <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/amazon.png"}></img>
            </div>

            {/* Tienda Morita */}
            <div className="building" onClick={()=>this.props.handleViewChange("berry-store")}>
              <img className="building-img" src={"https://polychatstorage.blob.core.windows.net/robin-buildings/ikea.png"}></img>
            </div>

            {/* School */}
            {/* <div className="building" onClick={()=>this.props.handleViewChange("tutoring")}>
              <img className="building-img" src={School}></img>
            </div> */}

            {/* Clicker */}
            {/* <div className="building" onClick={()=>this.props.handleViewChange("code")}>
              <img className="building-img" src={Clicker}></img>
            </div> */}

            
            



            
          </div>
          

          
        </div>
        
      ); 
    }
  }
}

export default Profile;