import React from 'react'
import CourseTopButton from './course-top-button'
import Robins from '../treehouse/robins'
import Quiz from '../../assets/images/quiz.png'

const QuizIntro = (props) => {
    console.log(props)

    const videos = {
        "pricetag": "https://www.youtube.com/embed/RSj16J5CDTs",
        "gourmet": "https://www.youtube.com/embed/vS1LjRPfxgY",
        "street": "https://www.youtube.com/embed/3vJRJ4TOziA",
        "quest":"https://www.youtube.com/embed/QskHGZOrIlo"

    }

    return (
        // <div className="quiz-intro">
        //     <iframe className="quiz-intro-video" src={props.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            
        //     <div>
        //         <CourseTopButton
            
        //         />

        //         <Robins
        //             robins={15}
        //         />
        //         <p></p>
        //     </div>
            

        // </div>

        <div className="session-view">

                <div className="session-view-container">
                    <div className="session-view-left">
                        <iframe className="session-video" src={videos[props.game]} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        {/* <p className="session-title">{this.props.session.title}</p> */}
                        {/* {<p id="texto" className="session-description">{this.props.session.text}</p>} */}
                        
                    </div>
        
                    <div className="session-view-right">
                        <CourseTopButton
                            text="Quiz"
                            color="#00CCFF"
                            fill="#00CCFF"
                            img={Quiz}
                            handleClick = {()=> props.handleViewChange("quiz", props.game)}
                        />

    
    
                        <div className="robin-session-container">
                            <Robins
                                robins={15}
                            />
                            <p className="robin-session-text">Recuerden que para ganar los Robins deberán responder correctamente 80% del quiz.</p>
                        </div>
                        

                        
    
                        
        
                    </div>
                    
                </div>
                </div>
    )
}

export default QuizIntro