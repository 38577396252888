import React from 'react';
import Left from '../../assets/images/left.png'
import MainImage from '../../assets/images/boy1.png'
import Diagolgue from './dialogue'
import QuestOptions from './quest-options'
import './quest.css'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import Cross from '../../assets/images/cross.png'
import PrizeModal from '../prize/prize-modal';
import {Mixpanel} from '../../Mixpanel'
import axios from 'axios';
import Loading from '../../assets/images/loading.gif'





import Character from  '../../assets/images/businessman.png'


class Quest extends React.Component {

    constructor(){

        super()

        this.state = {
            quest: 0, 
            scene: 0, 
            pause: false, 
            prev_scene: 0, 
            history: ["scene1"], 
            prize: -1
        }

        this.handleSceneChange = this.handleSceneChange.bind(this);
        this.handleBackScene = this.handleBackScene.bind(this);
        

        this.handlePause = this.handlePause.bind(this);

        // this.handleAddRobins = this.handleAddRobins.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
    }

    componentDidMount(){
        this.props.toggleLoading(false)
    }

    componentWillMount(){
        alert("QUEST")
        const that = this
        firebase.database().ref("quests/quest"+this.props.questLevel).on("value", function(snap){
            const quest = snap.val()

            that.setState({
                quest: quest,
                scene: "scene1"
            })
        })
        
    }

    handlePause(){
        this.setState({
            pause: !this.state.pause
        })
    }

    handleFinish(grade, skill){

        var amount = 0

        axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
            var timestamp = data.data.timestamp

            switch(grade){
                    case 0:
                        amount = 0
                        break;
                    case 1: 
                        amount = 5
                        break;
                    case 2: 
                        amount = 10
                        break;
                    case 3:
                        amount = 15
                        break;
            }
            

            // Register level progress
            firebase.database().ref("q-progress/"+this.props.userID+"/"+(this.props.questLevel-1)).set(grade)
            

            // Analytics
            firebase.database().ref("analytics/quests/"+timestamp).push(this.props.userID)
            Mixpanel.track('Quest Finish');

            //Calificación en skills
            firebase.database().ref("grades/"+this.props.userID+"/"+skill).once("value", (snap) => {
                var skillgrades = []

                if(snap.val()){
                    skillgrades = snap.val()
                }

                skillgrades.push(grade)
            
                firebase.database().ref("grades/"+this.props.userID+"/"+skill).set(skillgrades)
                
            })

            this.setState({
                prize: amount
            })

        })

        // firebase.database().ref("users/"+this.props.userID+"/balance").once("value", function(snap){
        //     var amount = 0

        //     var timestamp = +new Date();

        //     switch(grade){
        //         case 0:
        //             amount = 0
        //             break;
        //         case 1: 
        //             amount = 5
        //             break;
        //         case 2: 
        //             amount = 10
        //             break;
        //         case 3:
        //             amount = 15
        //             break;
        //     }

        //     this.setState({
        //         prize: amount
        //     })

        //     const newBal = amount + snap.val()

        //     firebase.database().ref("users/"+that.props.userID).update({
        //         balance: newBal
        //     })

        //     firebase.database().ref("transactions/" + that.props.userID).push().set({
        //         desc: "Quest",
        //         cant: amount,
        //         type: "add",
        //         date: timestamp
        //     })

        //     // firebase.database().ref("users/"+that.props.userID+"/quest-mini").set(timestamp)
    
            
    
        // })
        
    }

    handleTransaction(){
        const stars = this.state.stars
        const amount = this.state.amount

        var timestamp = +new Date();

        
        //Actualizar Balance y generar transacción
        firebase.database().ref("users/"+this.props.userID+"/balance").once("value", (snap) => {
            var oldBalance = snap.val()

            // firebase.database().ref("users/"+ this.props.userID).update({
            //     balance: oldBalance + amount, 
            //     // pricetag: timestamp
            // })
            
            firebase.database().ref("transactions/" + this.props.userID).push().set({
                desc: "Old Quest",
                cant: amount,
                type: "add",
                date: timestamp
            })

            if(oldBalance) {
                firebase.database().ref("users/"+ this.props.userID).update({
                    balance: oldBalance + amount, 
                    gourmet: timestamp
                })
            }

            
        })
    }

    // handleAddRobins(amount){
    //     const that = this
    //     firebase.database().ref("users/"+this.props.userID+"/balance").once("value", function(snap){
    //         const newBal = amount + snap.val()

    //         firebase.database().ref("users/"+that.props.userID).update({
    //             balance: newBal
    //         })
    //     })

    //     const those = that
    //     firebase.database().ref("quest-progress/"+this.props.userID+"/level").once("value", function(snap){
    //         const level = snap.val()
    //         console.log(that.props.userID)
            
    //         firebase.database().ref("quest-progress/"+those.props.userID).update({
    //             playedToday: true,
    //             level: level+1
    //         })
    //     })
    //     var timestamp = +new Date();

    //     firebase.database().ref("users/"+this.props.userID+"/played").set(timestamp)

        
    //     firebase.database().ref("transactions/" + this.props.userID).push().set({
    //         desc: "Quest en App",
    //         cant: amount,
    //         type: "add",
    //         date: timestamp
    //     })

    //     firebase.database().ref("analytics/quests/"+timestamp).push(this.props.userID)

    // }

    handleSceneChange(scene){
        var history = this.state.history

        history.push(scene)

        this.setState({
            scene: scene, 
            history: history
        })
    }

    handleBackScene(){
        var history = this.state.history
        history.pop()
        this.setState({
            scene: history[history.length - 1]
        })
    }

    render(){
        const currentScene = this.state.scene

        if(this.state.quest == 0){
            return(
                <div>
                    <div className="super-modal">
                            <div className="loading">
                                <img className="loading-img" src={Loading}></img>
                            </div>
                        </div>
                </div>
            )
        }

        else{

            return(
                <div>
                    <div
                        className="quest-quit" 
                        onClick={()=>this.props.handleViewChange("app")}
                    >
                        <img className="back"src={Cross}></img>
                    </div>

                    {this.state.prize==0 &&
                        <PrizeModal
                            amount={this.state.prize}
                            text="No ganaste 😰"
                            handleClick={this.handleTransaction}

                        />
                    }

                    {this.state.prize>=1 &&
                        <PrizeModal
                            amount={this.state.prize}
                            text="¡Ganaste! 😱"
                            handleClick={this.handleTransaction}
                        />
                    }
        
                    <div className="quest">
                        <div className="img-container">
                            <img className="quest-img"src={this.state.quest[currentScene].img}></img>
                        </div>
                        <div>
                            <Diagolgue
                                text={this.state.quest[currentScene].text}
                            />
                            <div className="bottom-collection">

                            <img className="character-img"src={Character}></img>
                                {this.state.quest[currentScene].back &&
                                        <div
                                            className="quest-back" 
                                            // onClick={()=>this.props.handleViewChange("app")}
                                            onClick={this.handleBackScene}
                                        >
                        
                        
                                            <img className="back"src={Left}></img>
                                        </div>
                                }

                                
                                <QuestOptions
                                    scene={this.state.quest[currentScene]}
                                    handleSceneChange={this.handleSceneChange}

                                    handleAddRobins={this.handleAddRobins}
                                    handleFinish={this.handleFinish}
                                />
                            </div>
                        </div>
                    </div>
                    
                    {/* <PauseModal
                        handlePause = {this.handlePause}
                        handleViewChange = {this.props.handleViewChange}
                    /> */}
                </div>
            
            )
        }
    }
    
}

export default Quest;
