import React from 'react';

import './selected-ingredient.css'

import Return from '../../assets/images/return.png'

const SelectedIngredient = (props) => {
    
    return(
        <div className="selected-ingredient-container" onClick={()=>props.handleCancelIngredient(props.index)}>
            {props.img &&
                <img className="selected-ingredient-img" src={props.img}></img>
            }

            {props.img &&
                <img className="selected-ingredient-return" src={Return}></img>
            }

            {/* {props.index>0 &&
                <p className="plus">+</p>
            }  */}
            
        </div>
    )
}

export default SelectedIngredient;