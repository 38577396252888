import React from 'react'
import SummaryCityBox from './summary-city-box'

import './summary.css'

const Summary = (props) =>{
    return(
        <div className="summary-view">
            <SummaryCityBox
                market={props.market}
                city='mex'
                name='Ciudad de México'
                portfolioSummary={props.portfolioSummary}
                playerQueue = {props.playerQueue}
                players = {props.players}
                team = {props.team}
            />
            <SummaryCityBox
                market={props.market}
                city='sha'
                name='Shangai'
                portfolioSummary={props.portfolioSummary}
                playerQueue = {props.playerQueue}
                players = {props.players}
                team = {props.team}
            />
            <SummaryCityBox
                market={props.market}
                city='nyc'
                name='Nueva York'
                portfolioSummary={props.portfolioSummary}
                playerQueue = {props.playerQueue}
                players = {props.players}
                team = {props.team}
            />
            <SummaryCityBox
                market={props.market}
                city='ldn'
                name='Londres'
                portfolioSummary={props.portfolioSummary}
                playerQueue = {props.playerQueue}
                players={props.players}
                team = {props.team}
            />
        </div>
    )
}

export default Summary