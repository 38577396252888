import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import axios from 'axios';


import './pricetag.css';

import Close from '../../assets/images/exit.png'
import Clock from '../../assets/images/clock.png'
import Heart3 from '../../assets/images/hearts-3.png'
import Heart2 from '../../assets/images/hearts-2.png'
import Heart1 from '../../assets/images/hearts-1.png'
import Smile from '../../assets/images/smile.png'
import Frown from '../../assets/images/frown.png'
import Receipt from '../../assets/images/receipt.png'
import PrizeModal from '../prize/prize-modal';
import Loading from '../../assets/images/loading.gif'

import {Mixpanel} from '../../Mixpanel'

class PricetagNew extends React.Component {
    constructor(){
        super()
        this.state = {
            products: undefined, 
            product: undefined, 
            counter: 0,
            receipt: 0, 
            result: "none", 
            lives: 3, 
            timer: 100, 
            options: [], 
            prize: -1, 
            amount: 0, 
            stars: 0, 
            level:"", 
            seedReward: 0
        }

        this.checkAnswer = this.checkAnswer.bind(this)
        this.showNext = this.showNext.bind(this)
        this.handleTick = this.handleTick.bind(this)
        this.handleFinish = this.handleFinish.bind(this)
        this.handleTransaction = this.handleTransaction.bind(this)

        this.renderLevel = this.renderLevel.bind(this)
        this.shuffle = this.shuffle.bind(this)
    }

    componentWillMount(){

        if(this.props.level > 1){ 
            if(!this.props.child.finanzas && !this.props.child.course){
                alert("Debes inscribirte al curso de Finanzas para seguir jugando.")
                this.props.handleViewChange("app")
            }
        }

        


        document.body.style.backgroundColor = "#F0F0F4";
        window.scrollTo(0, 0);
        firebase.database().ref("pricetag/level"+this.props.level).once("value", (snap)=>{
            const level = snap.val()
            //Render Game
            this.renderLevel(level)
            this.setState({
                level: level
            })

            // //Substract seeds from player
            // if(level.cost<=this.props.child.seeds){
            //     //I neet to do this at the end!!

            //     // firebase.database().ref("users/"+this.props.userID).update({
            //     //     seeds: this.props.child.seeds - level.cost
            //     // })

            //     //Render Game
            //     this.renderLevel(level)
            //     this.setState({
            //         level: level
            //     })
            // }

            // else{
                
            //     alert("No tienes semillas suficientes para jugar este juego :/")
            //     this.props.handleViewChange("pricetag-levels")
            // }
        })






        // firebase.database().ref("pricetag/pricetag"+this.props.level).once("value", (snap) => {
        //     const productArray = snap.val()
        //     this.setState({
        //         products: productArray,
        //         product: productArray[0]
        //     })

        //     var options = productArray[0].options;

        //     var optionArray = []

        //     Object.keys(options).map((option)=>{
        //         optionArray.push(options[option])
        //     })

        //     const rnd = Math.floor(Math.random() * 2)

        //     if(rnd==0){
        //             optionArray.reverse()
        //     }

        //     this.setState({
        //         options: optionArray
        //     })
        // })
    }

    componentDidMount(){
        this.intervalID = setInterval(this.handleTick, 1000)

        // if(this.state.product){
            this.props.toggleLoading(false)
        // }
         

    }

    renderLevel(level){
        //Get all products
        firebase.database().ref("pricetag/pricetagMX").once("value", (snap)=>{
            var productArray = snap.val()

            console.log(productArray)

            //Shuffle products
            productArray = this.shuffle(productArray)

            //Split array
            productArray = productArray.slice(0, level.products)

            //Set first product

            //Get them on state


            this.setState({
                products: productArray,
                product: productArray[0]
            })

            var options = productArray[0].options;

            var optionArray = []

            Object.keys(options).map((option)=>{
                optionArray.push(options[option])
            })

            const rnd = Math.floor(Math.random() * 2)

            if(rnd==0){
                    optionArray.reverse()
            }

            this.setState({
                options: optionArray
            })

        })

    }

    shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
          [array[i], array[j]] = [array[j], array[i]]; // swap elements
        }
        return array
        
    }

    handleTick(){
        var timer = this.state.timer

        if(this.state.result == "none"){

            if(timer>0){
                timer -= 1;
    
                this.setState({
                        timer: timer
                })
            }

            else{
                this.setState({
                    result: "wrong"
                })
            }
        }

    }

    checkAnswer(answer){
        if(this.state.product.options.answer == answer){
            this.setState({
                result: "right", 
                receipt: this.state.receipt + 1
            })
        }

        else{
            if(this.state.lives == 1){
                this.handleFinish()
            }
           
            this.setState({
                result: "wrong",
                lives: this.state.lives - 1
            })

        }

        if(this.state.counter == this.state.products.length-1){
            this.setState({
                finish:true
            })
        }
    }


    showNext(){
        var count = this.state.counter + 1
        
        this.setState({
            counter: count,
            result: "none", 
            product: this.state.products[count], 
            timer: 30
        })


        // Scramble options order for next product


        console.log(this.state.products)
        console.log(this.state.products)
        var options = this.state.products[count].options;

        var optionArray = []

        Object.keys(options).map((option)=>{
            optionArray.push(options[option])
        })

        const rnd = Math.floor(Math.random() * 2)

        if(rnd==0){
                optionArray.reverse()
        }

        this.setState({
            options: optionArray
        })

        
    }

    // handleFinish(){

    //     const receipt = this.state.receipt
    //     var amount = 0
    //     var stars = 0


    //     console.log("RECEIPT "+receipt)
    //     console.log("LENGHT "+this.state.products.length)

    //     const result = receipt/(this.state.products.length-1)


    //     var timestamp = +new Date();

    //     switch(true){
    //         case (result<=0.3):
    //             stars = 0
    //             break;
    //         case (result<=0.6): 
    //             stars = 1
    //             break;
    //         case (result<1): 
    //             stars = 2
    //             break;
    //         case (result==1):
    //             stars = 3
    //             break;
    //     }
        
    //     //Get old grade
    //     firebase.database().ref("pricetag-progress/"+this.props.userID+"/"+(this.props.level-1)).once("value", (snap)=>{
    //         const oldGrade = snap.val()



    //         //Substract old grade from current grade
    //         const gradeDifference = stars - oldGrade


    //         console.log("OldGrade"+gradeDifference)
    //         //If Substraction is bigger than 0
    //         if(gradeDifference>0){

    //             //Add amount (substraction * 5)
    //             const amount = gradeDifference*5

    //             //Update grade
    //             firebase.database().ref("pricetag-progress/"+this.props.userID+"/"+(this.props.level-1)).set(stars)
                

    //             this.setState({
    //                 prize: amount,
    //                 seedReward: this.state.level.seedsPerStar*stars
    //             })



    //         }
    //         else{
    //             alert("No conseguiste más estrellas. Recuerda que cada estrella nueva que consigas te dará $5 Robins :)")
    //             if (this.props.child.seeds>=this.state.level.cost){
    //                 firebase.database().ref("users/"+this.props.userID).update({
    //                     seeds: this.props.child.seeds - this.state.level.cost
    //                 })
    //             }
    //             this.props.handleViewChange("app")
    //         }

    //     })

        

    //     // Analytics
    //     firebase.database().ref("analytics/pricetag/"+timestamp).set(this.props.userID)
    //     Mixpanel.track('Price Tag Finish');


    //     //Calificación en skills
    //     firebase.database().ref("grades/"+this.props.userID+"/valor").once("value", (snap) =>{
    //         var skillgrades = []

    //         if(snap.val()){
                
    //             skillgrades = snap.val()
    //         }
    
    //         skillgrades.push(stars)
            
    //         firebase.database().ref("grades/"+this.props.userID+"/valor").set(skillgrades)
            
    //     })
        

    //     // this.setState({
    //     //     prize: amount, 
    //     //     stars: stars,
    //     //     amount: amount
    //     // })

    // }

    handleFinish(){

        const receipt = this.state.receipt
        var amount = 0
        var stars = 0

        console.log(this.state.products.length)

        this.setState({
            finish:false
        })
        


        const result = receipt/(this.state.products.length)

        axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
            var timestamp = data.data.timestamp

            switch(true){
                case (result<=0.3):
                    stars = 0
                    break;
                case (result<=0.6): 
                    stars = 1
                    break;
                case (result<1): 
                    stars = 2
                    break;
                case (result==1):
                    stars = 3
                    break;
            }

            console.log("STARS")
            console.log(stars)

            // Analytics
            firebase.database().ref("analytics/pricetag/"+timestamp).set(this.props.userID)
            Mixpanel.track('Price Tag Finish');


            

            //Calificación en skills
            firebase.database().ref("grades/"+this.props.userID+"/valor").once("value", (snap) =>{
                var skillgrades = []

                if(snap.val()){
                     
                    skillgrades = snap.val()
                }
        
                skillgrades.push(stars)
                
                firebase.database().ref("grades/"+this.props.userID+"/valor").set(skillgrades)

            })

            firebase.database().ref("pricetag-progress/"+this.props.userID+"/"+(this.props.level-1)).once("value",(snap)=>{
              
                const oldGrade = snap.val()
                
                console.log(oldGrade)
                if(stars>oldGrade){
                    // Register level progress
                    firebase.database().ref("pricetag-progress/"+this.props.userID+"/"+(this.props.level-1)).set(stars)
                    
                    this.setState({
                        prize: (stars-oldGrade)*5, 
                        stars: stars
                    })
                }

                else{
                    this.setState({
                        prize: 0, 
                        stars: stars
                    })
                }
            })
            

        })

    }

    handleTransaction(){
        const stars = this.state.stars
        const amount = this.state.prize
        
        var timestamp = +new Date();

        
        //Actualizar Balance y generar transacción
        firebase.database().ref("users/"+this.props.userID+"/balance").once("value", (snap) => {
            var oldBalance = snap.val()

            // firebase.database().ref("users/"+ this.props.userID).update({
            //     balance: oldBalance + amount, 
            //     pricetag: timestamp
            // })
            
            firebase.database().ref("transactions/" + this.props.userID).push().set({
                desc: "Pricetag", 
                cant: amount, 
                type: "add", 
                date: timestamp, 
                oldBalance: oldBalance,
            })

            if(oldBalance) {
                firebase.database().ref("users/"+ this.props.userID).update({
                    balance: oldBalance + amount, 
                    pricetag: timestamp
                })
            }
            else{
                this.props.handleViewChange("app")
            }
        })
    }

    // handleTransaction(){
    //     const stars = this.state.stars
    //     const amount = this.state.prize

    //     var timestamp = +new Date();

        
    //     //Actualizar Balance y generar transacción
    //     // firebase.database().ref("users/"+this.props.userID).once("value", (snap) => {
    //     //     var oldBalance = snap.val()


    //     //     firebase.database().ref("users/"+ this.props.userID).update({
    //     //         balance: this.props.child.balance + amount, 
    //     //         seeds: this.props.child.seeds + this.state.seedReward
    //     //         // pricetag: timestamp
    //     //     })
            
    //     //     firebase.database().ref("transactions/" + this.props.userID).push().set({
    //     //         desc: "Pricetag",
    //     //         cant: amount,
    //     //         type: "add",
    //     //         date: timestamp
    //     //     }).then(()=>{
    //     //         ()=>{this.props.handleViewChange("app")}
    //     //     })
    //     // })
    //     alert("¡Felicidades! Ganaste "+this.state.seedReward+" Semillas")

    //     firebase.database().ref("users/"+ this.props.userID).update({
    //         balance: this.props.child.balance + amount, 
    //         seeds: this.props.child.seeds + this.state.seedReward - this.state.level.cost
    //     })
        
    //     firebase.database().ref("transactions/" + this.props.userID).push().set({
    //         desc: "Pricetag",
    //         cant: amount,
    //         type: "add",
    //         date: timestamp
    //     }).then(()=>{
    //         ()=>{this.props.handleViewChange("app")}
    //     })
    // }
    

    

    render(){

        console.log(this.state.prize)

        var heartImg = Heart3

        switch(this.state.lives){
            case 3:
                heartImg=Heart3
                break;
            case 2:
                heartImg=Heart2
                break;
            case 1:
                heartImg=Heart1
                break;
            default:
                heartImg=Heart1
                break;

        }


        if(this.state.product){

            
            var displayImg = 'nil'

            switch(this.state.result){
                case "right":
                    displayImg=Smile
                    break;
                case "wrong":
                    displayImg=Frown
                    break;
                default:
                    displayImg=this.state.product.img
                    break;

            }

            


            return(
                <div>

                    {this.state.finish &&
                        <div onClick={this.handleFinish()}></div>
                            
                    }

                    {this.state.prize==0 &&
                        <PrizeModal
                            amount={this.state.prize}
                            text="No ganaste 😰"
                            handleClick={this.handleTransaction}

                        />
                    }

                    {this.state.prize>=1 &&
                        <PrizeModal
                            amount={this.state.prize}
                            text="¡Ganaste! 😱"
                            handleClick={this.handleTransaction}
                        />
                    }

                    <div onClick={()=>this.handleFinish()}>
                        <img src={Close} className="close"></img>
                    </div>
                    
                    <div className="pricetag-container">
                        <div className="pricetag-top-container">
                            <div className="timer">
                                <img src={Clock} className="clock-img"></img>
                                <p>{this.state.timer}s</p>
                            </div>
                            
                            <img src={heartImg} className="heart"></img>
                           
                        </div>
                        <div className="receipt">
                            <img src={Receipt} className="receipt-img"></img>
                            <p>{this.state.receipt}/{this.state.products.length}</p>
                        </div>
                        <p>Cual es el precio de…</p>
                        <p>{this.state.product.name}</p>
                        <div className="product">
                            <img src={displayImg} className="pricetag-product-img"></img>
                        </div>
                        <div>
                            
                            
                            {/* Check if there's a result, if none display options, else display next */}
                            {this.state.result == "none" ? 
                                this.state.options.map((option)=>{
                                    console.log(option)
                                    return(<button onClick={()=> this.checkAnswer(option)} className="pricetag-btn">${option}</button>)
                                }) :

                                <button onClick={()=> this.showNext()} className="next-btn">Siguiente</button>
                                
                            }
                        </div>
    
                    </div>
                    
                </div>
            )
        }

        else{
            
            return(
                <div>
                    <div className="super-modal">
                        <div className="loading">
                            <img className="loading-img" src={Loading}></img>
                        </div>
                    </div>
                </div>
            )
        }

    }
}

export default PricetagNew;