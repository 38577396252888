import React from 'react';

const ChatInput = props => {
    return (
      <input
        onChange={e => props.onChange(e)}
        className="chat-input"
        value={props.view || props.text}
        placeholder={props.placeholder}
        type={props.type}
        onKeyDown={(e) => props.onKeyDown(e) }
      />
    );
  };
  
  export default ChatInput;