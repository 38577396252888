import React from 'react';
import Bar from './bar'
import './skill.css'


const Skill = (props) => {
    //Get average grade
    var sum = 0;
    props.grades.map(grade => {
        sum = sum + grade
    })
    var average = sum/(props.grades.length)

    


    //Get name
    var name = ""
    switch(props.name){
        case "credito":
            name="Préstamo"
            break;
        case "inversion":
            name="Inversión"
            break;
        case "presupuesto":
            name="Presupuesto"
            break;
        case "ahorro":
            name="Ahorro"
            break;
        case "valor":
            name="Valor del Dinero"
            break;
    }
    return(
        <div className="skill-bar">
            <p className="skill-name" >{name} </p>
            <div>
                
            </div>
            <Bar
                max={3}
                progress={average}
                color={props.color}
            />
        </div>
    )
}

export default Skill;