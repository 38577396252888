import React from 'react'
import Article from './article'
import './news.css'
import MarketRanges from '../../assets/images/ranges.png'

const News = (props) => {
    document.body.style.backgroundColor = "#F42F54";

    var flip = false

    if(props.timer==40){
        flip = true
    }
    else{
        flip = false
    }
    
    const news = props.news[props.round] 

    if(news){
        
        return(
            <div className="news-view">
                            <Article
                                title={news[1].title}
                                img={news[1].img}
                                text={news[1].text}
                                flip={flip}
                            />

                            <Article
                                title={news[2].title}
                                img={news[2].img}
                                text={news[2].text}
                                flip={flip}
                            />

                            {/* <Article
                                title={news[3].title}
                                img={news[3].img}
                                text={news[3].text}
                            /> */}
            </div>
         )
    }
    else{
        return(<div></div>)
    }
}

export default News