import React from 'react';
import StockMarket from './stock-market';
import StockPortfolio from './stock-portfolio';
import News from './news'


const StreetView = (props) => {
    
    switch(props.tab){
        case "portfolio":
            return <StockPortfolio
                portfolio = {props.portfolio}
                handleStockSell={props.handleStockSell}
                round={props.round}
                timer={props.timer}
                stockArray={props.stockArray}
                market={props.market}
            />
        case "news":
            return <News
                news={props.news}
                round={props.round}
                timer={props.timer}
            />
        case "market":
            return <StockMarket
                market = {props.market}
                handleStockPurchase = {props.handleStockPurchase}
                round={props.round}
                timer={props.timer}
            />
    }
}

export default StreetView;