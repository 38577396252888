import React from 'react';
import './comment-message.css'

const CommentMessage = (props) => {
    // console.log(props.msg)
    // console.log(props.previous)

    var prevFrom = ''

    if(props.previous){
        prevFrom= props.previous.from
    }

    var link = false

    if(props.value.includes("http")){
        link = true
    }

    var timeString =""
    if(props.msg.timestamp){


        var date= new Date(props.msg.timestamp)
        // console.log(date.toLocaleDateString())
        // console.log(date.toLocaleTimeString())
        var dateString = date.toString()

        dateString = dateString.split(" ")

        var day = dateString[1]+" "+dateString[2]

        var time = date.toLocaleTimeString()
        var hours = time.split(" ")[0]
        var miday = time.split(" ")[1]

        console.log(hours,miday)

        hours = hours.slice(0,-3)

        timeString = day+" "+hours+" "+miday
    }

    var name = props.name

    name = name.split(" ")

    name = name[0]

    return(
        <div>

            {/* {prevFrom != props.from && */}
                <div className={"comment-top"}>
                    <p className={"comment-name"}>{name}</p>

                    <p className={"comment-timestamp"}>{timeString}</p>
                </div>
            {/* } */}
            
            <div className={"comment-message-container " + props.kind}>
            <div className="comment-container">

                {link ? (
                    <a target="_blank" href={props.value} className="comment-text">{props.value}</a>
                ) : (
                    <p className="comment-text">{props.value}</p>
                )
                }
                
            </div>

            {/* {prevFrom == props.msg.from && */}
                {/* <div onClick={()=>{props.reportMessage(props.msg)}} className="comment-sender">
                    <img className="comment-img"src={props.img}></img>
                </div> */}
            {/* } */}
            
            
        </div>
        </div>
        
    )
}

export default CommentMessage