import React from 'react'

const CityBox = (props) =>{

    var players = props.players

    return(
        <div className="city-box" onClick={()=>{props.pickCity(props.city)}}>
            <p className="city-name">{props.name}</p>


            <div className="city-visitors" style={{backgroundImage: "url('"+props.img+"')"}}>
                {Object.keys(players).map(id=>{
                    console.log(players[id])
                    console.log(props.team)
                    if(players[id].currentCity===props.city && players[id].currentTeam === props.team){
                        return(
                            
                            <div className="visitor-tag">
                                <img className="visitor-img" src={players[id].img}></img>
                                <p className="visitor-name">{players[id].name}</p>
                            </div>

                        )
                    }
                    
                })}
            </div>
                
            

            
        </div>
    )

}

export default CityBox