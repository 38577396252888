import React from 'react';
import './stock.css'

const Stock = (props) => {

    var classname = ""
    if(props.flip){
        classname = "stock swing-in-top-fwd"
    }
    else{
        classname = "stock"
    }
    

    return(
        <div className={classname}>
            <img className="stock-logo" src={props.img}></img>
            <div>
                <p>Valor: </p>
                <p className="stock-value">${props.price}</p>
            </div>
            
            
            <button 
                className="stock-sell-btn"
                onClick={()=>props.handleStockSell(props.stock, props.price)}
                
            >Vender</button>
        </div>
    )
}

export default Stock;