import React from 'react';
import './counter.css'

// import Plus from 'https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/plus-green.png?alt=media&token=c368d277-e1f2-427a-95bc-0465e73e5444'
import Minus from '../../assets/images/minus.png'


const Counter = (props) => {
    return(
        <div className="counter">
            <div onClick={props.minus} className="counter-minus">
                <img className="counter-img" src={Minus}></img>
            </div>
            
            <div className="amount">{props.amount}</div>

            <div onClick={props.plus} className="counter-plus">
                <img className="counter-img" src={"https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/plus-green.png?alt=media&token=c368d277-e1f2-427a-95bc-0465e73e5444"}></img>
            </div>
            
        </div>
    )
}

export default Counter;