import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;
import React from 'react'
import ChatInput from '../chat/chat-input'
import CommentMessage from './comment-message'
import "./activity-comment-section.css"
import CommentInput from './comment-input'

class ActivityCommentSection extends React.Component{

    constructor(){
        super()

        this.state={
            input:""
        }
    }

    handleChange=(event)=>{
        this.setState({
            input: event.target.value
        });
    }

   

    handleSend=()=>{
        
        if(this.state.input != ""){

            var timestamp = +new Date()

            const newEntry = {
                sender: this.props.uid,
                value: this.state.input,
                type: "text", 
                img: "", 
                name: this.props.child.name, 
                timestamp: timestamp
            }


            var activity = this.props.upload

           

            if(!activity.comments){
                activity.comments={}
            }
            activity.comments[timestamp] = newEntry
            activity.status = "waiting-approval"
            
            firebase.firestore().collection("students/"+this.props.uid+"/uploads").doc(this.props.activity.activity_key).update({comments: activity.comments, status: "waiting-approval"})


            this.setState({
                input: ""
            })
            this.scrollToBottom();
       
        }

    }

    onKeyDown=(event)=> {
        if (event.keyCode === 13) {
            console.log('enter')
            this.handleSend()
        }
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      }


    render(){
        
        const conversation = this.props.comments
        console.log(this.props)
        return(
            <div style={{width:"100%", height: "100%"}}>
                {/* <p className="activity-view-box-title">Comentarios</p> */}

                <div className="activity-comment-box-aux">

                

                <div className="activity-comment-section">
                    {Object.keys(conversation).map(entry=>{

                        var kind = "incoming"
                        if(this.props.uid == conversation[entry].from){
                            kind = "outgoing"
                        }

                        return(
                            <CommentMessage
                                value={conversation[entry].value}
                                img={conversation[entry].img}
                                kind={kind}
                                name={conversation[entry].name}
                                msg={conversation[entry]}
                                id={entry}
                                key={entry}
                            />
                        )
                    })}

                    <div style={{ float:"left", clear: "both"}}
                        ref={(el) => { this.messagesEnd = el; }}>
                            
                    </div>

                </div>
                

                <div className="activity-comment-section-bottom">
                    <img className="comment-avatar" src={this.props.avatar}></img>
                    <CommentInput
                        placeholder="Escribe tu mensaje..."
                        onChange={this.handleChange}
                        text={this.state.input}
                        type="text"
                        onKeyDown={this.onKeyDown}
                    />

                   

                    <button onClick={()=>{this.handleSend()}} className="comment-send">Enviar</button>
                </div>
                
                
            </div>
            </div>
        )
    }
}

export default ActivityCommentSection