import React, { useState, useEffect } from 'react'
import axios from 'axios'
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { NotionRenderer } from 'react-notion-x'
import { Collection } from 'react-notion-x/build/third-party/collection'
import { Code } from 'react-notion-x/build/third-party/code'
import { Equation } from 'react-notion-x/build/third-party/equation'
import { Modal } from 'react-notion-x/build/third-party/modal'
import { Pdf } from 'react-notion-x/build/third-party/pdf'
// import { NotionAPI } from 'notion-client'

import 'react-notion-x/src/styles.css'
import 'prismjs/themes/prism-tomorrow.css'
import 'katex/dist/katex.min.css'


const NotionPage = (props) => {
  const [recordMap, setRecordMap] = useState(null)
  console.log(props)

  const pageId = props.notionLink || removeHyphens(props.activityID)

  function removeHyphens(str) {
    return str.replace(/-/g, '')
  }

  useEffect(() => {
    console.log(pageId)


    axios.post("https://us-central1-robin-polychat.cloudfunctions.net/getNotionXBlocks", {
      pageId: pageId
    }).then((res) => {
      console.log(res.data)
      setRecordMap(res.data)
    })

  }, [pageId])

  if (!recordMap) {
    return <div>Loading...</div>
  }

  return (
    <div style={{ maxWidth: 768, width: '100%' }}>
      <NotionRenderer
        recordMap={recordMap}
        components={{
          Collection,
          Code,
          Equation,
          Modal,
          Pdf
        }}
        fullPage={true}
        darkMode={false}
      />
    </div>
  )
}

export default NotionPage