import React from 'react';
import './chat-message.css'

const ChatMessage = (props) => {
    // console.log(props.msg)
    // console.log(props.previous)

    var prevFrom = ''

    if(props.previous){
        prevFrom= props.previous.from
    }

    var link = false

    if(props.value.includes("http")){
        link = true
    }

    var timeString =""
    if(props.msg.timestamp){


        var date= new Date(props.msg.timestamp)
        // console.log(date.toLocaleDateString())
        // console.log(date.toLocaleTimeString())
        var dateString = date.toString()

        dateString = dateString.split(" ")

        var day = dateString[1]+" "+dateString[2]

        var time = date.toLocaleTimeString()
        var hours = time.split(" ")[0]
        var miday = time.split(" ")[1]

        hours = hours.slice(0,-3)

        timeString = day+" "+hours+" "+miday
    }

    return(
        <div>

            {prevFrom != props.from &&
                <div className={"message-first-"+props.kind}>
                    <p className={"message-name-"+props.kind}>{props.name}</p>

                    <p className={"message-timestamp"}>{timeString}</p>
                </div>
            }
            
            <div className={"chat-message-container " + props.kind}>
            <div className="message-container">

                {link ? (
                    <a target="_blank" href={props.value} className="message-text">{props.value}</a>
                ) : (
                    <p className="message-text">{props.value}</p>
                )
                }

                
            </div>


            {/* {prevFrom == props.msg.from && */}
                <div onClick={()=>{props.reportMessage(props.msg)}} className="message-sender">
                    <img className="sender-img"src={props.img}></img>
                </div>
            {/* } */}
            
            
        </div>
        </div>
        
    )
}

export default ChatMessage