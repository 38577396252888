
import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import TopicBox from './topic-box'
import GradeSelector from './grade-selector'
import NavBar from '../../components/navigation/nav-bar'
import Mate from '../../assets/images/calculator.png'
import Esp from '../../assets/images/espanol.png'
import Sci from '../../assets/images/science.png'
import Eng from '../../assets/images/english.png'

class LMS extends React.Component {
    constructor(){
        super()

        this.state={
            temas: {}, 
            gradeToggle: false, 
            grade: "7mo",
            points: {},
            topics: {}
        }
    }

    componentDidMount(){
       

        document.body.style.backgroundColor = '#F0F0F4'

        firebase.firestore().collection("curricular-progress").doc(this.props.uid).onSnapshot((snap)=>{

            const progress = snap.data()

            if(progress){
                var subjectProgress = progress[this.props.subject]

                if(!subjectProgress){
                    subjectProgress = "7mo"
                }

                this.setState({
                    grade: subjectProgress
                })
            }
        
          
          
            firebase.firestore().collection("subjects/"+this.props.subject+"/"+this.state.grade).onSnapshot((snap)=>{

     
                 let temas = {}
                 snap.docs.forEach(doc=>{
                     temas[doc.id]=doc.data()
                 })

                 console.log(temas)
     
                 this.setState({temas})
     
                
             })

        })

        // firebase.firestore().collection("student-points").doc(this.props.uid).onSnapshot(snap=>{
        //     const points = snap.data()

        //     this.setState({
        //         points
        //     })
        // })

        firebase.firestore().collection("students/"+this.props.uid+"/objectives/"+this.props.child.currentObjective+"/activities").get().then(snap=>{
            
            let topics={}
            snap.docs.forEach(doc=>{
                console.log(doc.data())
                topics[doc.data().topic_key]=true
            })

            this.setState({topics})

        })

        

    }

    toggleExtra=()=>{
        const currentState = this.state.extraToggle

        this.setState({
            extraToggle: !currentState
        })
    }

    handleToggle=(grade)=>{
        const currentState = this.state.gradeToggle

        this.setState({
            gradeToggle: !currentState
        })

        if(grade){

            firebase.firestore().collection("curricular-progress").doc(this.props.uid).get().then(doc=>{

                var progress = doc.data()

                if(!progress){
                    progress = {}
                }

                progress[this.props.subject] = grade

                firebase.firestore().collection("curricular-progress").doc(this.props.uid).set(progress)
            })

            
        }
    }
    

    render(){
        const temas = this.state.temas
        const topics = this.state.topics

        var topicAmount = 0

        const subjects = {
            "matematicas": "Matemáticas",
            "espanol": "Español", 
            "ciencias": "Ciencias", 
            "sociales": "Ciencias Sociales",
            "language": "Inglés"
        }

        const images = {
            "matematicas": Mate,
            "espanol": Esp, 
            "ciencias": Sci, 
            "sociales": Esp,
            "language": Eng
        }

        var bgColor = "#F42F54"

        if(this.state.extraToggle){
            bgColor = "#111E6B"
        }

        return(
            <div className="topics-view">
                <NavBar
                
                    handleBack={this.props.handleViewChange}
                    backView="subject-selector"
                    title={subjects[this.props.subject]}
                
                />

                <div style={{backgroundColor: bgColor}} onClick={()=>{this.toggleExtra()}} className="extra-toggle-btn">
                    <p>Ver Todos</p>
                </div>

                <GradeSelector
                    grade={this.state.grade}
                    toggle={this.state.gradeToggle}
                    handleToggle={this.handleToggle}
                />

                
                <p className="agenda-view-title">Temas</p>
                <div className="topics-container">

                    

                
                    {Object.keys(temas).map(key=>{
                        const topic = temas[key]

                        if(this.state.extraToggle){
                            topicAmount=1
                            return(
                                <div onClick={()=>{this.props.viewCurricularActivities(key, this.state.grade)}}>
                                    
                                    <TopicBox
                                        topic={topic}
                                        points={this.state.points}
                                        key={key}
                                        topic_key={key}
                                        img={images[this.props.subject]}
                                    />
                                </div>
                            )
                        }

                        else if(topics[key]){
                            topicAmount+=1
                            return(
                                <div onClick={()=>{this.props.viewCurricularActivities(key, this.state.grade)}}>
                                    
                                    <TopicBox
                                        topic={topic}
                                        points={this.state.points}
                                        key={key}
                                        topic_key={key}
                                        img={images[this.props.subject]}
                                    />
                                </div>
                            )
                        }
                        
                    })}

                    {topicAmount === 0 &&
                        <p className="agenda-view-title">No tienes temas de esta materia asignados a tus objetivos.</p>
                    }
                </div>

            </div>
        )
    }
}

export default LMS