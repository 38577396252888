import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import Level from './level';
// import './levels.css'
import Quit from '../../assets/images/left.png'
import ManagerImg from '../../assets/images/manager.png'
import StreetImg from '../../assets/images/street.png'
import ExtraLevel from './extra-level';

class Levels extends React.Component {

    constructor(){
        super()
        this.state = {
            levels: [0,0,0,0,0,0,0,0,0], 
            grades: "", 
            seedCosts: ""
        }
    }

    componentWillMount(){
        document.body.style.backgroundImage = "none"
        document.body.style.backgroundColor = "white"
        console.log("KEVEKKSS")
        window.scrollTo(0, 0);
        
        firebase.database().ref("extra-progress/"+this.props.userID).on("value", (snap) => {
            console.log(snap.val())
            var grades = snap.val()

            
            if(grades){
                const length = grades.length
                grades[length] = 0
                
                this.setState({
                    grades: grades
                })
            }
            else{
                this.setState({
                    grades: [0]
                })
            }
        })

        
    }

    render(){
        const grades = this.state.grades
        const levels = this.state.levels
        // const costs = this.state.seedCosts
        const costs = 1

        var video=""

        // var gameImg = ManagerImg;

        // if(this.props.game=="street"){
        //     gameImg = StreetImg
        // }


        // switch(this.props.game){
        //     case "street":
        //         video="nilo"
        //     case "pricetag":
        //         video="nulo"
        //     case "gourmet":
        //         video="nilo"
        //     case "old-quest":
        //         video="nilo"
        // }

        


        if (grades && costs) {

            var key = 1

            return(
                
                <div>
                    {/* <div className="nav-bar">
                        <div
                            className="nav-icon" 
                            onClick={()=>this.props.handleViewChange("app")}
                        >
                            <img className="back"src={Quit}></img>
                        </div>
                        
                        <p className="view-title">{this.props.game}</p>
                    </div> */}

                    <div className="game-intro">
                        <p className="extra-description">{this.props.description}</p>
                    </div>

                    <div className="levels">
                        {levels.map((element, index) => {
                            
                            if(grades[index] == undefined){
                                return(<div className="locked-level-container"><div className="locked-level"> <img className="lock-img" src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUyIDUyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MiA1MjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+CjxwYXRoIHN0eWxlPSJmaWxsOiNDN0NBQzc7IiBkPSJNNDAuNzcxLDUySDExLjIyOUM3Ljc4OSw1Miw1LDQ5LjIxMSw1LDQ1Ljc3MVYyNi4yMjlDNSwyMi43ODksNy43ODksMjAsMTEuMjI5LDIwaDI5LjU0MiAgQzQ0LjIxMSwyMCw0NywyMi43ODksNDcsMjYuMjI5djE5LjU0MkM0Nyw0OS4yMTEsNDQuMjExLDUyLDQwLjc3MSw1MnoiLz4KPHBhdGggc3R5bGU9ImZpbGw6I0U3RUNFRDsiIGQ9Ik00MC43NzEsMjBIMjZ2MzJoMTQuNzcxQzQ0LjIxMSw1Miw0Nyw0OS4yMTEsNDcsNDUuNzcxVjI2LjIyOUM0NywyMi43ODksNDQuMjExLDIwLDQwLjc3MSwyMHoiLz4KPHBhdGggc3R5bGU9ImZpbGw6IzRDNEM0RjsiIGQ9Ik0yNiw0MEwyNiw0MGMtMS42NSwwLTMtMS4zNS0zLTN2LTZjMC0xLjY1LDEuMzUtMywzLTNoMGMxLjY1LDAsMywxLjM1LDMsM3Y2ICBDMjksMzguNjUsMjcuNjUsNDAsMjYsNDB6Ii8+CjxwYXRoIHN0eWxlPSJmaWxsOiM1NDZBNzk7IiBkPSJNMTEuMjI5LDIwSDE1di02LjVDMTUsOC4yNjIsMTkuOTM1LDQsMjYsNHMxMSw0LjI2MiwxMSw5LjVWMjBoMy43NzEgIGMwLjA3NywwLDAuMTUyLDAuMDA5LDAuMjI5LDAuMDExVjEzLjVDNDEsNi4wNzUsMzQuMjUsMCwyNiwwUzExLDYuMDc1LDExLDEzLjV2Ni41MTFDMTEuMDc3LDIwLjAwOSwxMS4xNTIsMjAsMTEuMjI5LDIweiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K" /></div></div>)
                            }
                            else{

                                // console.log(this.state.seedCosts)
                                // var index = 1
                                // console.log(index.toString())
                                // console.log(this.state.seedCosts["level"+index.toString()])

                                // const level = this.state.seedCosts["level"+index.toString()]

                                
                                return(<ExtraLevel
                                    renderExtra={this.props.renderExtra}
                                    index={index}
                                    grades={grades[index]}
                                    course={this.props.course}
                                    handleViewChange={this.props.handleViewChange}
                                    // cost={level.cost}
                                    
                                />)

                            }
                        })}
                    </div>
                </div>
            )
        }

        else{
            return(
                <div>
                    
                </div>
            )
        }
        
        
    }
}

export default Levels;