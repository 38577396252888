import React from 'react'
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

// import icon from './images/robin-icon.png'
// import css from './css/charge.css'

import STRIPE_PUBLISHABLE from './constants/stripe';
import PAYMENT_SERVER_URL from './constants/server';

const CURRENCY = 'MXN';

const fromPesoToCent = amount => amount * 100;

const successPayment = (data,user) => {
  console.log(data)
  firebase.database().ref("users/"+user+"/subscription").set(data.data.subscription.id)
};

const errorPayment = data => {
  alert(data);
  console.log(data)
};

const onToken = (amount, description, user) => token =>
  axios.post(PAYMENT_SERVER_URL,
    {
      description: description,
      source: token.id,
      currency: CURRENCY,
      amount: fromPesoToCent(amount),
      email: token.email
    })
    .then((data)=>{
      firebase.database().ref("users/"+user+"/subscription").set(data.data.subscription.id)
    })
    .catch(errorPayment);

const Checkout = (props) =>
  <StripeCheckout
    name={props.name}
    description={props.description}
    amount={fromPesoToCent(props.amount)}
    token={onToken(props.amount, props.description, props.user)}
    currency={CURRENCY}
    stripeKey={STRIPE_PUBLISHABLE}
    locale="auto"
    // image={icon}
    label="Pagar con Tarjeta"
    
  > 
    <button className="green">Suscribir</button>
  </StripeCheckout>

export default Checkout;