import React from 'react';
import Berries from './berries'
import Robins from './robins'
import SkillGrades from './skillgrades';

import './kid-stats.css'


const KidStats = (props) => {

    console.log(props.user)

    return(
        <div className="kid-stats">

            <div className="profile">
                <img className="profile-img" src={props.user.avatar}></img>
                <p className="profile-name">{props.user.name}</p>
                <div className="currencies">

                    <Berries
                        berries={props.user.berries}
                    />

                    <Robins
                        robins={props.user.balance}
                    />
                </div>
            </div>

            <SkillGrades
                skillgrades = {props.skillgrades}
            />

        </div>
    )
}

export default KidStats