import React, {useState, useEffect} from 'react'

import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

const NotionDB = () => {

    const [notionData, setNotionData] = useState([]);
    





    // Subject view - read Materias the database

    // const data = {
    //     database:"f3bd8c7771604938bb7238ceb5b8d197"
        
    // }



    // Topics view - read topics database

    // const data = {
    //     database:"1adac672a1464d60aff992a1d1c000b4",
    //     filter: {
    //         and: [
    //             {
    //                 property: 'Subject',
    //                 select: {
    //                     equals: 'Science'
    //                 }
    //             },
    //             {
    //                 property: 'Grade',
    //                 select: {
    //                     equals: '7'
    //                 }
    //             }
    //         ]
    //     },
    //     sorts:[
    //         {
    //             property: 'Sequence',
    //             direction: 'ascending'
    //         }
    //     ]
    // }




    // Activities view - read paginas de instrucciones database
    
    const data = {
        database:"1aa9be33bef24c02986c85207fe99d06",
        filter: {
            and: [
                {
                    property: 'Topic',
                    relation: {
                        contains: '4d22d433-130a-46d0-befa-c350fea87ef1'
                    }
                }
            ]
        },
        sorts: [
            {
                property: 'secuencia',
                direction: 'ascending'
            }
        ]
    }

    useEffect(()=>{

        const notionQuery = firebase.functions().httpsCallable("notionQuery")
        notionQuery(data)
            .then(response=>{
                setNotionData(response.data);
                console.log(response.data)
            })
        
    }, [])


    return(
        <p> asdfadsf </p>
    )
  
}

export default NotionDB