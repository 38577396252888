import React from 'react'
import Next from '../../assets/images/green-right.png'
import Back from '../../assets/images/gray-left.png'
import './stepper.css'

class Stepper extends React.Component {
    
    constructor(){
        super()
        this.state = {
            currentStep: 0
        }
    }

    handleBack=()=>{
        if(this.state.currentStep>0){
            this.setState({
                currentStep: this.state.currentStep - 1
            })
        } 
    }

    handleNext=()=>{
        console.log(this.props.stepArray.length)
        if(this.state.currentStep<this.props.stepArray.length-1){
            this.setState({
                currentStep: this.state.currentStep + 1
            })
        } 
        else{
            this.props.finish()
        }
    }

    render(){
        const totalSteps = this.props.stepArray.length
        const currentStep = this.state.currentStep+1

        let lenght = currentStep*300/totalSteps

        let style ={
            width: lenght+'px'
        }

        const step = this.state.currentStep
        const stepArray = this.props.stepArray

        return(
            <div className="stepper-container">
                <div className="form-progress-bar">
                    <div style={style} className="form-progress-indicator"></div>
                </div>

                <div className="stepper-content">
                    <p className="stepper-text">{this.props.stepArray[step].text}</p>
                    <img className="stepper-img" src={this.props.stepArray[step].img}></img>
                </div>

                <div className="stepper-controls">
                    <img onClick={()=>this.handleBack()} className="back-arrow-img" src={Back}></img>
                    <img onClick={()=>this.handleNext()} className="back-arrow-img" src={Next}></img>
                </div>
                    
                
            </div>
            
        )
    }

    
}

export default Stepper