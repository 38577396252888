import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import FileUploader from 'react-firebase-file-uploader'
import axios from 'axios';

import {Mixpanel} from '../../Mixpanel'


import './session-view.css'
import CourseTopButton from './course-top-button'
import EvidenceUpload from './evidence-upload';
import Drive from '../../assets/images/drive-icon.png'

import Quiz from '../../assets/images/quiz.png'
import Doubt from '../../assets/images/doubt.png'
import UploadImg from '../../assets/images/upload-icon.png'
import Robins from '../treehouse/robins';
import Berries from '../treehouse/berries';

class ExtraActivity extends React.Component  {
    
    constructor(){
        super()

        this.state = {
            file: "",
            fileURL: "",
            progress: 0, 
            extraURL: ""
        }
    }

    componentWillMount() {

        Mixpanel.track('View Session', {
            session: "session"+this.props.sessionIndex, 
            course: "vg1"
        });

        firebase.database().ref("extra-activities/"+this.props.sessionIndex).once("value", (snap)=>{
            this.setState({
                extraURL: snap.val()
            })
        })
    }

    handleUploadStart = () => {
        this.setState({
            progress: 1
        })
    }

    handleUploadSuccess = filename => {
        this.setState({
            file: filename, 
            progress: 100
        })

        firebase.storage().ref("extra-evidence").child(this.state.file).getDownloadURL()
            .then(url=>{
                this.setState({
                    fileURL: url
                })
            })
        
    }

    handleProgress = progress => {
        this.setState({
            progress:progress
        })
        console.log(progress)
    }

    handleCancelUpload = () => {
        this.setState({
            file: ""
        })
    }

    handlePushToFirebase = () =>{

        Mixpanel.track('Extra Upload', {
            session: "extra"+this.props.sessionIndex, 
            course: "vg1"
        });

        Mixpanel.track('Extra '+this.props.sessionIndex);
        
        axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{

            var timestamp = data.data.timestamp

            const homework = {
                title: "Extra "+this.props.sessionIndex,
                correct: false,
                img: this.state.fileURL, 
                name: this.props.child.name,
                email: this.props.child.email,
                user: this.props.userID,
                berries: 3,
                date: timestamp,
                session: "extra"+this.props.sessionIndex
                
            }

            firebase.database().ref("extra-progress/"+this.props.userID+"/"+this.props.sessionIndex).set(homework)
            

            firebase.database().ref("users/"+this.props.userID+"/berries").once("value", (snap)=>{
                const oldBerries = snap.val()

                firebase.database().ref("users/"+this.props.userID+"/berries").set(oldBerries+3)
            })

            firebase.database().ref("users/"+this.props.userID+"/extra").set(timestamp)
            

        })
             

            

            
    }


    render(){
        console.log(this.state)

            return(
                <div className="session-view">
                    
                <div className="session-view-container">
                    
        
                    <div className="session-view-right">
                        <CourseTopButton
                            text="Empezar"
                            color="#3DD869"
                            fill="#3DD869"
                            img={Quiz}
                            handleClick = {()=> window.open(this.state.extraURL, "_blank")}
                        />
                        
    
                        {!this.state.homework ?
                            <div>
                                {this.state.file ? 
                                    
                                    <EvidenceUpload
                                        img={this.state.fileURL}
                                        handleCancelUpload={this.handleCancelUpload}
                                        handlePushToFirebase={this.handlePushToFirebase}
    
                                    />
                                    : <div>

                                        <label className="file-uploader">

                                            <CourseTopButton
                                                text="Compartir Foto"
                                                color="#00CCFF"
                                                img={UploadImg}
                                                className="hahaha"
                                                
                                            />

                                            <FileUploader
                                                hidden
                                                accept="image/*"
                                                name="image"
                                                storageRef={firebase.storage().ref("course-evidence")}
                                                onUploadStart={this.handleUploadStart}
                                                onUploadSuccess={this.handleUploadSuccess}
                                                onProgress={this.handleProgress}
                                                style={{opacity:'0'}}
                                            />

                                            

                                        </label>
                                    
                                        
    
                                        {this.state.progress >0 &&
                                            <div>
                                                <label>Progreso: </label>
                                                <p>{this.state.progress}%</p>
                                            </div>
                                        }
    
                                    
                                    </div>
                                }
                            </div>
    
                            : <img src={this.state.homework.img} className="homework-img"></img>
                        
                        }
    
                        <div className="berry-session-container">
                            
                            <Berries 
                                berries={3}
                            />
                            <p className="robin-session-text">Recuerden que para ganar las Moritas deberás subir una foto o video mostrándome su trabajo de esta actividad extra.</p>
                        </div>

                        

                        
    
                        
        
                    </div>
                    
                </div>
                </div>
            )
        

        
    }
    
}
export default ExtraActivity;