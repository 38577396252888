import React from 'react';
import './modal.css'
import Arrow from '../../assets/images/triangle.png'


const Modal = (props) => {


    var imgArray = []
    
    console.log(props)

    if(props.modal.images){
        imgArray=props.modal.images
    }
    
    return(
        <div className="modal-background">
            <div className="modal-box">
                {props.modal.title &&
                    <p className="modal-title">
                        {props.modal.title}
                    </p>
                }

                {imgArray.map(img=>{
                    return(
                        <img className="modal-img" src={img}></img>
                    )
                    
                })}

                {/* {props.modal.img &&
                    
                    <img className="modal-img" src={props.modal.img}></img>
                } */}
               
                <p className="modal-text">
                    {props.modal.dialogue}
                </p>

                <div className="modal-options">
                    {Object.keys(props.modal.options).map(function(key){
                        return(
                            <button 
                                onClick={props.modal.options[key]}
                                className="modal-option-btn"
                            >{key}</button>
                        )
                        
                    })}
                </div>
            </div>
            

            {/* <div className="bottom-dialogue">
                <img className="character" src="https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/boy%20(6).png?alt=media&token=0dddb8ae-33fe-4bd1-bd0b-19a5fe35b4ed"></img>
                <div className="options">
                    {Object.keys(props.modal.options).map(function(key){
                        return(
                            <button 
                                onClick={props.modal.options[key]}
                                className="option-btn"
                            >{key}</button>
                        )
                        
                    })}
                </div>
            </div> */}
        </div>
    )
}

export default Modal;