import React from 'react'
import Star0 from '../../assets/images/star-0.png'
import Star1 from '../../assets/images/star-1.png'
import Star2 from '../../assets/images/star-2.png'
import Star3 from '../../assets/images/star-3.png'
// import './level.css'

const OldLevel = (props) => {
    var startImg = ""
    switch(props.grades){
        case 0:
            startImg = Star0;
            break;
        case 1:
            startImg = Star1;
            break;
        case 2:
            startImg = Star2;
            break;
        case 3:
            startImg = Star3;
            break;
        default:
            break;
    }

    // var renderGame = props.renderGame

    if(props.index == 4){
        // renderGame = props.renderQuiz

        return(
            <div onClick={()=>props.handleViewChange("quiz-intro", props.game)} className="quest-container">
                <div className="quest-level">{props.index+1}</div>
                <img src={startImg} className="quest-grade"></img>
            </div>
        )
    }
    else{
        return(
            <div onClick={()=>props.renderGame(props.index)} className="quest-container">
                <div className="quest-level">{props.index+1}</div>
                <img src={startImg} className="quest-grade"></img>
            </div>
        )
    }
    
}

export default OldLevel;