import React from 'react'
import CityBox from './city-box'
import Mex from '../../assets/images/mex.jpg'
import Nyc from '../../assets/images/nyc.jpg'
import Sha from '../../assets/images/sha.jpg'
import Ldn from '../../assets/images/ldn.jpg'
import './city-picker.css'

const CityPicker = (props) =>{

    const team = props.players[props.uid].currentTeam

    // const players = props.players

    return(
        <div className="city-picker-view">
            <p className="city-picker-text">¿A que ciudad quieres ir?</p>
            <div className="city-boxes-container">
                <CityBox
                    players={props.players}
                    name={"Ciudad de Mexico"}
                    pickCity={props.pickCity}
                    city="mex"
                    team={team}
                    img={Mex}
                />
                <CityBox
                    players={props.players}
                    name={"Shanghai"}
                    pickCity={props.pickCity}
                    city="sha"
                    team={team}
                    img={Sha}
                />
                <CityBox
                    players={props.players}
                    name={"Nueva York"}
                    pickCity={props.pickCity}
                    city="nyc"
                    team={team}
                    img={Nyc}
                />
                <CityBox
                    players={props.players}
                    name={"Londres"}
                    pickCity={props.pickCity}
                    city="ldn"
                    team={team}
                    img={Ldn}
                />
            </div>
        </div>
        
    )

}

export default CityPicker