import React from 'react'
import { NotionRenderer } from "react-notion";
import ProjectCard from './project-card.js'
import './projects.css'
// import block from './projects.json'

import axios from 'axios'

class Projects extends React.Component {

    constructor(){
        super()
        this.state = {

        }
    }

    componentDidMount(){
    //     fetch("https://notion-api.splitbee.io/v1/table/62fbd3be-b74e-4bf2-87a3-6d7767ec8c0d")
    //   .then((res)=>{
    //       this.setState({
    //           items:res.json()
    //       })
    //   })
        document.body.style.backgroundImage = "none"
        document.body.style.backgroundColor = "#F0F0F4";

        axios.get("https://notion-api.splitbee.io/v1/table/62fbd3be-b74e-4bf2-87a3-6d7767ec8c0d").then(res=>{
            this.setState({
                items:res.data
            })
        })
      
      
    }

    render(){
        console.log(this.state)
        // const blockMap = block.recordMap.block;
        // console.log(blockMap)
        const projects = this.state.items
        return(
            <div>
                {this.state.items &&
                    <div className="projects-collection">
                        
                        {projects.map(id=>{
                            console.log(id)
                            return(
                                <div onClick={()=>{this.props.handleProjectView(id)}}>
                                    
                                    <ProjectCard
                                        project={id}
                                    />
                                </div>
                            )
                        })}
                    </div>
                }
                
            </div>
        )
    }
}
export default Projects