import React from 'react'
import TeamBox from './team-box'
import './team-picker.css'

const TeamPicker = (props) =>{

    return(
        <div className="teams">
            
            <div className="team-picker-view">
                <TeamBox
                    players={props.players}
                    name={"Azul"}
                    color="#00CCFF"
                    pickTeam={props.pickTeam}
                    team="azul"
                    balance={props.game.azul.balance}
                />
                <TeamBox
                    players={props.players}
                    name={"Rojo"}
                    color="#F42F54"
                    pickTeam={props.pickTeam}
                    team="rojo"
                    balance={props.game.rojo.balance}
                />
                <TeamBox
                    players={props.players}
                    name={"Verde"}
                    color="#3DD869"
                    pickTeam={props.pickTeam}
                    team="verde"
                    balance={props.game.verde.balance}
                />
                <TeamBox
                    players={props.players}
                    name={"Morado"}
                    color="#984EFF"
                    pickTeam={props.pickTeam}
                    team="morado"
                    balance={props.game.morado.balance}
                />
            </div>
        </div>
    )

}

export default TeamPicker