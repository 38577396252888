import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';
import {Mixpanel} from '../../Mixpanel'
import ChatInput from './chat-input';
import Quit from '../../assets/images/left.png'
import axios from 'axios';

import Send from '../../assets/images/send.png'


import File from '../../assets/images/happy-file.png'


import FileUploader from 'react-firebase-file-uploader'



import './chat.css'
import ChatMessage from './chat-message';
import ChatSentImg from './chat-sent-img';


class GroupChat extends React.Component {
    constructor(){
        super()
    
        this.state = {
            conversation: "", 
            input: "",
            type: "message", 
            selectedFile: null, 
            uploadValue: 0,
            chatmodal: "hidden"
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSend = this.handleSend.bind(this)
    }

    componentDidMount(){
        
    }


    componentWillMount(){

        if(this.props.child.ban){
            alert("Tu cuenta ha sido inhabilitada del chat grupal por "+this.props.child.ban)
            // this.props.handleViewChange("app")
        }
        else if (this.props.child.adult){
            alert("Tu cuenta no tiene habilitado el chat grupal")
            // this.props.handleViewChange("app")
        }
        else{
            console.log(this.props)
            firebase.firestore().collection(this.props.reference+"/messages").orderBy("timestamp", "desc").limit(50).onSnapshot((snap)=>{

                let list = []
                snap.docs.forEach(doc=>{
                    // console.log(doc.data())
                    list.push(doc.data())
                })
    
                list = list.reverse()
                console.log(list)
                // console.log(list)
                this.setState({
                    conversation:list
                })
                this.scrollToBottom();
            })
        }
        
        document.body.style.backgroundColor = "#F0F0F4";
        document.body.style.backgroundImage = `none`
        
        Mixpanel.track('Check Conversation');



        
    }

    handleChange(event){
        this.setState({
            input: event.target.value
        });
    }

    handleSend(){


        const input = this.state.input

        this.setState({
            input: ""
        })

        this.scrollToBottom();

        if(input != ""){
            const newEntry = {
                from: this.props.uid,
                to: "Roger",
                value: input,
                type: "text",
                img: this.props.child.profile,
                name: this.props.child.name
            }

            // console.log(this.props.type)
            axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
                var timestamp = data.data.timestamp
                newEntry.timestamp = timestamp
                console.log(this.props)
                firebase.firestore().collection(this.props.reference+"/messages").doc(timestamp.toString()).set(newEntry)
                // firebase.firestore().collection("chat-previews").doc(this.props.uid).set({
                //     id: this.props.uid, 
                //     name: this.props.child.name,
                //     img: this.props.child.profile, 
                //     seen: false,
                //     preview: newEntry.value, 
                //     timestamp: timestamp, 
                //     course: this.props.course,
                //     session: this.props.sessionIndex+1
                // })


                // firebase.database().ref(this.props.type+this.props.chatID+"/"+timestamp).set(newEntry)
        
                // if(this.props.type == "chats/"){
                //     firebase.database().ref("chat-manager/"+this.props.uid).set(timestamp)
                // }
                
            })

       
        }

    }

    addFriend = (event) =>{
        var friendID = this.state.selectedMessage.from

        var user = this.props.child

        console.log(user)
        const timestamp = +new Date()

        const friendlist = firebase.firestore().collection("friend-lists").doc(this.props.uid)
        
        friendlist.get().then(doc =>{
            var friends = doc.data()


            if(friends){

                if(!friends[friendID]){

                    friends[friendID] = {
                        channel: "channel_"+timestamp,
                        name: this.state.selectedMessage.name,
                        img: this.state.selectedMessage.img
                    }
                    
                    friendlist.set(friends)
    
    
    
                    const secondFriendList = firebase.firestore().collection("friend-lists").doc(friendID)
    
                    secondFriendList.get().then(doc =>{
                        var friends2 = doc.data()
    
                        console.log(friends2)

                        console.log(user)

                        if(friends2){
                            if(!friends2[this.props.uid]){
                

                                friends2[this.props.uid] = {
                                    channel: "channel_"+timestamp,
                                    name: user.name,
                                    img: user.avatar
                                }
                                secondFriendList.set(friends2)
                
                            }
                        }   

                        else{
                            friends2 = {}
                            friends2[this.props.uid] = {
                                channel: "channel_"+timestamp,
                                name: user.name,
                                img: user.avatar
                            }
                            secondFriendList.set(friends2)

                        }
            
                        
                    })
    
    
                    alert("Se ha agregado como amigo, ahora pueden enviar mensajes directos en el edificio de School")
                    
                    this.props.handleViewChange("friend-list")
                }
            }

            else{
                friends = {}
                friends[friendID] = {
                    channel: "channel_"+timestamp,
                    name: this.state.selectedMessage.name,
                    img: this.state.selectedMessage.img
                }
                
                friendlist.set(friends)



                const secondFriendList = firebase.firestore().collection("friend-lists").doc(friendID)
    
                secondFriendList.get().then(doc =>{
                    var friends2 = doc.data()


                    if(friends2){
                        if(!friends2[this.props.uid]){
            
                            friends2[this.props.uid] = {
                                channel: "channel_"+timestamp,
                                name: user.name,
                                img: user.avatar
                            }
                            secondFriendList.set(friends2)

                        }
                    }   

                    else{
                        friends2 = {}
                        friends2[this.props.uid] = {
                            channel: "channel_"+timestamp,
                            name: user.name,
                            img: user.avatar
                        }
                        secondFriendList.set(friends2)

                    }
        
                    
                })


                alert("Se ha agregado como amigo, ahora pueden enviar mensajes directos en el edificio de School")
                
                this.props.handleViewChange("friend-list")
            }

            
        })

    }

    blockUser=(event)=>{
        var userID = this.state.selectedMessage.from

        var user = this.props.child

        console.log(user)
        const timestamp = +new Date()

        const friendlist = firebase.firestore().collection("friend-lists").doc(this.props.uid)
        
        friendlist.get().then(doc =>{
            var friends = doc.data()

            if(friends){
                if(friends[userID]){
                    friends[userID].block = true
                }

                else{
                    friends[userID]={
                        block:true
                    }
                }

                friendlist.set(friends)
            }

            else{

                friends = {}
                friends[userID]={
                    block:true
                }
                friendlist.set(friends)

            }

            alert("Se ha bloqueado el usuario. Ya no podrás recibir mensajes personales de su parte. Si tienes más problemas con este usuario puedes comonicarte con algún maestro.")

            this.props.handleViewChange("friend-list")
        })
    }

    fileSelectHandler = (event) =>{
        // console.log(event.target.files[0])
        this.setState({
            selectedFile: event.target.files[0]
        })
        let time = Date.now()
        const file = event.target.files[0]
        const storageRef = firebase.storage().ref('files/'+this.props.uid+"/"+time+file.name)
        const task = storageRef.put(file)

        task.on('state_changed', snapshot =>{
            // console.log("CACA")
            // console.log(snapshot)
            const bytesTransferred= snapshot.bytesTransferred
            const totalBytes= snapshot.totalBytes

            let percentage = (bytesTransferred/totalBytes)*100

            this.setState({
                uploadValue: percentage
            })
        }, (error)=>{
            alert("Error al subir archivo")
        }, ()=>{
            this.setState({
                uploadedFile: task.snapshot.downloadURL
            })

            const fileType = task.snapshot.metadata.contentType
            const downloadURL = task.snapshot.downloadURL
            console.log(fileType)
            
            task.snapshot.ref.getDownloadURL().then((downloadURL)=> {
                console.log('File available at', downloadURL);
                console.log(this.props)
                const newEntry = {
                    from: this.props.uid,
                    to: "tutor",
                    value: downloadURL,
                    type: fileType, 
                    img: this.props.child.profile, 
                    name: this.props.child.name
                }
    
                console.log(this.props.type)
                axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
                    var timestamp = data.data.timestamp
    
                    newEntry.timestamp = timestamp

                    firebase.firestore().collection(this.props.reference+"/messages").doc(timestamp.toString()).set(newEntry)
    

                    // firebase.firestore().collection("chat-previews").doc(this.props.uid).set({
                    //     id: this.props.uid, 
                    //     name: this.props.child.name,
                    //     img: this.props.child.profile, 
                    //     seen: false,
                    //     preview: newEntry.value, 
                    //     timestamp: timestamp, 
                    //     course: this.props.course,
                    //     session: this.props.sessionIndex+1
                    // })
                    // firebase.database().ref(this.props.type+this.props.chatID+"/"+timestamp).set(newEntry)
            
                    // if(this.props.type == "chats/"){
                    //     firebase.database().ref("chat-manager/"+this.props.uid).set(timestamp)
                    // }
                    this.setState({
                        input: ""
                    })
                    this.scrollToBottom();
                })
            });
            
        })

    }

    onKeyDown=(event)=> {
        if (event.keyCode === 13) {
            console.log('enter')
            this.handleSend()
        }
    }

    fileUploadHandler=()=>{
        axios.post('')
    }

    // scrollToBottom() {
    //     someElement.scrollTop = someElement.scrollHeight;
    //   }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      }
      
    componentDidMount() {
        this.scrollToBottom();
      }
      
    componentDidUpdate() {
        // this.scrollToBottom();
    }

    componentWillUnmount(){
        this.cancelNotification();
    }

    cancelNotification(){
        firebase.database().ref("users/"+this.props.uid+"/mail").set(false)
    }

    reportMessage = () =>{

        var timestamp = this.state.selectedMessage.timestamp
        console.log(timestamp.toString())
        var reportedMessage = this.state.selectedMessage

        reportedMessage.reference = this.props.reference+"/messages/"+timestamp

        firebase.firestore().collection("reports").doc(timestamp.toString()).set(reportedMessage)
        alert("Un tutor de Robin evaluará el mensaje, gracias por tu reporte")
        this.chatModalToggle()
    }

    chatModalToggle = (message) =>{

        if(this.state.chatmodal == "hidden"){
            this.setState({
                chatmodal: "visible",
                selectedMessage: message
            })
        }
        else{
            this.setState({
                chatmodal: "hidden"
            }) 
        }
    }

    render(){

        if(this.props.child.ban || this.props.child.adult){
            return(
                <div>
                    <p>No tienes acceso al chat</p>
                    {this.props.child.ban &&
                        <p>por {this.props.child.ban}</p>
                    }
                    <div style={{ float:"left", clear: "both"}}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                </div>
            )
            
        }
        else if(this.state.conversation){
            
            const conversation = this.state.conversation
            return(
                <div>
                    {/* <button onClick={()=>{this.props.handleViewChange("chat")}} className="btn-chat-tutor">Chat con Tutor</button> */}
                    
                    <div className="conversation" id="your_div">

                        <div style={{visibility: this.state.chatmodal}} className="chat-modal-container">
                            <div onClick={()=>{this.chatModalToggle("as")}} className="chat-modal-bg"></div>
                            <div className="chat-modal">
                                <p className="text-align-center">¿Quieres reportar el mensaje como ofensivo, inapropiado o spam?</p>
                                <button onClick={()=>{this.addFriend()}} className="button-green">Agregar Amig@</button>
                                <button onClick={()=>{this.blockUser()}} className="button-red">Bloquear</button>
                                <button onClick={()=>{this.reportMessage()}} className="button-purple">Reportar</button>
                                <button onClick={()=>{this.chatModalToggle("as")}} className="button-blue">Cancelar</button>
                            </div>
                        </div>

                        {Object.keys(conversation).map((entry)=>{
                            // console.log(conversation[entry])
                            var shouldBeHidden = false

                            if(conversation[entry].hidden){
                                shouldBeHidden = conversation[entry].hidden
                            }

                            if(conversation[entry].from == this.props.uid){
                                // return(<div>{conversation[entry].value}</div>)

                                if(conversation[entry].type.includes("image")){
                                    return(
                                        <ChatSentImg
                                                value={conversation[entry].value}
                                                img={this.props.child.profile}
                                                kind="outgoing"
                                                name={this.props.child.name}
                                                shouldBeHidden={shouldBeHidden}

                                                previous={conversation[entry-1]}
                                                from={conversation[entry].from}
                                                msg={conversation[entry]}

                                                reportMessage={this.chatModalToggle}
                                                id={entry}
                                                key={entry}
                                        />   
                                    )
                                }
                                else{
                                    return(
                                        <ChatMessage
                                                value={conversation[entry].value}
                                                img={this.props.child.profile}
                                                kind="outgoing"
                                                name={this.props.child.name}
                                                shouldBeHidden={shouldBeHidden}
                                                
                                                previous={conversation[entry-1]}
                                                from={conversation[entry].from}
                                                msg={conversation[entry]}

                                                reportMessage={this.chatModalToggle}
                                                id={entry}
                                                key={entry}
                                            /> 
                                    )
                                }
                                
                            }
                            else {
                                if(conversation[entry].type.includes("image")){
                                    return(
                                        <ChatSentImg
                                                value={conversation[entry].value}
                                                img={conversation[entry].img}
                                                kind="robin"
                                                name={conversation[entry].name}
                                                shouldBeHidden={shouldBeHidden}
                                                
                                                previous={conversation[entry-1]}
                                                from={conversation[entry].from}
                                                msg={conversation[entry]}

                                                reportMessage={this.chatModalToggle}
                                                id={entry}
                                                key={entry}
                                        />   
                                    )
                                }
                                else{
                                    return(
                                        <ChatMessage
                                                value={conversation[entry].value}
                                                img={conversation[entry].img}
                                                kind="robin"
                                                name={conversation[entry].name}
                                                shouldBeHidden={shouldBeHidden}

                                                previous={conversation[entry-1]}
                                                from={conversation[entry].from}
                                                msg={conversation[entry]}

                                                reportMessage={this.chatModalToggle}
                                                id={entry}
                                                key={entry}
                                            /> 
                                    )
                                }

                            }

                            // else if(conversation[entry].sender == "teacher"){
                            //     return(
                            //         <ChatMessage
                            //             value={conversation[entry].value}
                            //             img={conversation[entry].img}
                            //             type="teacher"
                            //             name={conversation[entry].name}
                            //             shouldBeHidden={shouldBeHidden}

                            //             id={entry}
                            //             key={entry}
                            //         />
                            //     )
                            // }

                            // else{
                            //     return(
                            //         <ChatMessage
                            //             value={conversation[entry].value}
                            //             img={conversation[entry].img}
                            //             type="incoming"
                            //             name={conversation[entry].name}
                            //         />
                            //     )
                            // }
                        })
                        }
                        <p className="chat-bottom-disclaimer">Recuerda respetar a tus compañeros. Si necesitas reportar a alguien haz click en su foto de perfil.</p>

                        <div style={{ float:"left", clear: "both"}}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                    </div>
                    
                    <div className="chat-bottom">
                        <ChatInput
                            placeholder="Escribe tu mensaje..."
                            onChange={this.handleChange}
                            text={this.state.input}
                            type="text"
                            onKeyDown={this.onKeyDown}
                        />
                        
                        <input class="inputfile" name="file" id="file" type="file" onChange={this.fileSelectHandler} />
                        <label for="file">
                            <img className="upload-btn-img" src={File}></img>
                        </label>
                        <div onClick={this.handleSend} className="chat-send-small">
                            <img src={Send} className="upload-btn-img"></img>
                        </div>
                
                        <button onClick={this.handleSend} className="chat-send">Enviar</button>
                    </div>
                </div>
            )
        }

        else{
            return(
                <div>
                    
                    <div className="conversation" id="your_div">

                        {/* {this.props.type == "chats/" && */}

                            <div>

                                {/* <ChatMessage
                                    value={"¡Hola! En este grupo pueden hablar con sus compañeros, compartir dudas y sus trabajos🤓"}
                                    img={"https://scontent.fntr6-2.fna.fbcdn.net/v/t31.0-8/24273691_704923873047253_121943099010675091_o.png?_nc_cat=111&_nc_ht=scontent.fntr6-2.fna&oh=01d9ff9c6bf52ace0657543da841d818&oe=5D0B937D"}
                                    type="incoming"
                                /> */}
                                <ChatMessage
                                                value={"¡Hola! En este grupo pueden hablar con sus compañeros, compartir dudas y sus trabajos🤓"}
                                                img={"https://scontent.fntr6-2.fna.fbcdn.net/v/t31.0-8/24273691_704923873047253_121943099010675091_o.png?_nc_cat=111&_nc_ht=scontent.fntr6-2.fna&oh=01d9ff9c6bf52ace0657543da841d818&oe=5D0B937D"}
                                                kind="robin"
                                                name="Roger"
                                                shouldBeHidden={false}
                                                msg={[]}
                                                // previous={conversation[entry-1]}
                                                // from={conversation[entry].from}

                                                reportMessage={this.chatModalToggle}
                                                id={1}
                                                key={1}
                                            /> 
                            </div>
                            
                        {/* } */}

                        {/* {this.props.type == "group-chats/" &&

                            <div>

                            <ChatMessage
                                value={"¡Hola! Soy Robin. En este grupo pueden platicar con los compañeros de su grupo 😜"}
                                img={"https://scontent.fntr6-2.fna.fbcdn.net/v/t31.0-8/24273691_704923873047253_121943099010675091_o.png?_nc_cat=111&_nc_ht=scontent.fntr6-2.fna&oh=01d9ff9c6bf52ace0657543da841d818&oe=5D0B937D"}
                                type="robin"
                                name={"Robin"}
                            />

                            <ChatMessage
                                value="También pueden hablar con su maestro o maestra. Si me necesitan ya saben que pueden hablar conmigo en la oficina de correos 😁✌"
                                img={"https://scontent.fntr6-2.fna.fbcdn.net/v/t31.0-8/24273691_704923873047253_121943099010675091_o.png?_nc_cat=111&_nc_ht=scontent.fntr6-2.fna&oh=01d9ff9c6bf52ace0657543da841d818&oe=5D0B937D"}
                                type="robin"
                                name="Robin"
                            />

                            
                            </div>
                        } */}


                        <div style={{ float:"left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                        
                        
                    </div>
                    
                    <div className="chat-bottom">
                        <ChatInput
                            placeholder="Escribe tu mensaje..."
                            onChange={this.handleChange}
                            text={this.state.input}
                            type="text"
                            onKeyDown={this.onKeyDown}
                        />
                        
                        <input class="inputfile" name="file" id="file" type="file" onChange={this.fileSelectHandler} />
                        <label for="file">
                            <img className="upload-btn-img" src={File}></img>
                        </label>
                        <div onClick={this.handleSend} className="chat-send-small">
                            <img src={Send} className="upload-btn-img"></img>
                        </div>
                
                        <button onClick={this.handleSend} className="chat-send">Enviar</button>
                    </div>
                </div>
            )
        }
        
    }
}

export default GroupChat;
