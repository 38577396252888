import React from 'react';
import IngredientContainer from './ingredient-container';
import SelectedIngredient from './selected-ingredient';

import './kitchen.css'

const Kitchen = (props) => {

    document.body.style.backgroundColor = "#984EFF";
    window.scrollTo(0, 0);
    
    const dummyArray = [0,1,2]

    if(props.selectedIngredients){
        return(
            <div className="kitchen-view">   
                <p className="kitchen-instructions">Agrega ingredientes de tu inventario para preparar un platillo.</p>
                <div className="kitchen">
    
                                
                    <SelectedIngredient
                        key={0}
                        index={0}
                        img={props.ingredientCatalogue[props.selectedIngredients[0]]}
                        handleCancelIngredient={props.handleCancelIngredient}
                    />
                    +
                    <SelectedIngredient
                        key={1}
                        index={1}
                        img={props.ingredientCatalogue[props.selectedIngredients[1]]}
                        handleCancelIngredient={props.handleCancelIngredient}
                    />
                    +
                    <SelectedIngredient
                        key={2}
                        index={2}
                        img={props.ingredientCatalogue[props.selectedIngredients[2]]}
                        handleCancelIngredient={props.handleCancelIngredient}
                    />

                    

                </div>

                <button className="buy-item" onClick={()=>props.handleCook()}>Cocinar</button>

            </div>
            
    
        )
    }
    else{
        return(
            <div>
                <SelectedIngredient
                    img={""}
                />
                <SelectedIngredient
                    img={""}
                />
                <SelectedIngredient
                            img={""}
                />

                <button onClick={props.handleCook}>coo</button>
            </div>
        )
    }
}

export default Kitchen;