// import React from 'react'
// import firebase from 'firebase/compat/app';

// import DatePicker from 'react-datepicker'
// import './agenda.css'

// import './activity-builder.css'

// class ActivityBuilder extends React.Component {
//     constructor(){
//         super()

//         const date = new Date()

//         var day = date.getDate()
//         var month = date.getMonth() + 1
//         var year = date.getFullYear()

//         const dateFormat = day+"-"+month+"-"+year

//         this.state ={
//             nombre: "",
//             tiempo: "",
//             instrucciones: "",
//             subject: "", 
//             selectDate: dateFormat,
//             calendar: date, 
//         }
//     }

//     changeDate = calendarDate =>{

//         var date = new Date(calendarDate)
//         var day = date.getDate()
//         var month = date.getMonth() + 1
//         var year = date.getFullYear()

//         const timestamp = +new Date(calendarDate)

//         const dateFormat = day+"-"+month+"-"+year

//         this.setState({
//             selectDate: dateFormat, 
//             calendar: date
//         })


//     }

//     handleChange(event) {
//         switch (event.target.placeholder) {
//           case "Nombre de la actividad":
//             this.setState({
//                 nombre: event.target.value
//             });
//             break;
//           case "Tiempo para realizarla":
//             this.setState({
//                 tiempo: event.target.value
//             });
//             break;
//           case "Descripción":
//             this.setState({
//                 instrucciones: event.target.value
//             });
//             break;
//         case "Liga de acceso":
//                 this.setState({
//                     acceso: event.target.value
//                 });
//                 break;
        
//         }
//       } 

//     addActivity =()=>{

//         if(!this.state.nombre || !this.state.tiempo || !this.state.subject){
//             alert("Debes llenar todos los campos para poder agregar una actividad")
//         }

//         else{
//             var activity = this.state
//             var dateFormat = this.state.selectDate
//             var calendarDate = this.state.calendar
//             // var timestamp = +new Date()
//             const timestamp = +new Date(calendarDate)

//             if(activity.acceso){
//                 activity.acceso.toLowerCase()
//                 if(!activity.acceso.startsWith("http")){
//                     activity.acceso = "https://"+activity.acceso
//                 }
//             }
//             activity.name = this.state.nombre
//             activity.topic_key = "custom_topic"
//             activity.activity_key = "custom_activity_"+timestamp
            
//             activity.status = "undone"
//             console.log(calendarDate)
//             console.log(timestamp)
//             this.props.addTask(activity, dateFormat, timestamp)

//             this.props.cancelActivityBuilder()
//             // this.props.addTask(activity, activity.activity_key)


//             // firebase.firestore().collection("students/"+this.props.uid+"/agenda").doc(this.props.date).get()
//             // .then(doc=>{

//             //     var newTasks = {}

//             //     if(doc.data()){
//             //         newTasks = doc.data()
//             //     }

//             //     newTasks[activity.activity_key] = activity

//             //     firebase.firestore().collection("students/"+this.props.uid+"/agenda").doc(this.props.date).set(newTasks)
            
//             //     this.props.cancelActivityBuilder()
                
//             // })

//         }

        
//     }

//     setSubject=(subject)=>{
//         this.setState({
//             subject
//         })
//     }

//     render(){

//         var math, esl, esp, sci, hist, other = ""
        

//         switch (this.state.subject) {
//             case "matematicas":
//                     math="selected-subject"
//                 break;
//             case "language":
//                     esl="selected-subject"
//                 break;
//             case "espanol":
//                     esp="selected-subject"
//                 break;
//             case "ciencias":
//                     sci="selected-subject"
//                 break;
//             case "historia":
//                     hist="selected-subject"
//                 break;
//             case "otra":
//                     other="selected-subject"
//                 break;
//             default:
//                 break;
//         }

//         return(
//             <div className="activity-builder-modal">
//                 <div onClick={()=>{this.props.cancelActivityBuilder()}} className="activity-modal-bg"></div>
//                 <div className="activity-builder-form">
//                     <p className="activity-form-title">Crear Actividad Nueva</p>
//                     <input
//                         onChange={e => this.handleChange(e)}
//                         className="activity-builder-input"
//                         value={this.state.nombre}
//                         placeholder='Nombre de la actividad'
//                         type="text"
//                     />
                    
//                     <input
//                         onChange={e => this.handleChange(e)}
//                         className="activity-builder-input"
//                         value={this.state.instrucciones}
//                         placeholder='Descripción'
//                         type="text"
//                     />

//                     <input
//                         onChange={e => this.handleChange(e)}
//                         className="activity-builder-input"
//                         value={this.state.tiempo}
//                         placeholder='Tiempo para realizarla'
//                         type="number"
//                     />

//                     <input
//                         onChange={e => this.handleChange(e)}
//                         className="activity-builder-input"
//                         value={this.state.acceso}
//                         placeholder='Liga de acceso'
//                         type="text"
//                     />

//                     <DatePicker
//                       selected={this.state.calendar}
//                       onChange={date => this.changeDate(date)}
//                     />


//                     {/* <p className="subjects-input">Materia</p> */}
//                     <div className="subjects-radio-container">
//                         <button onClick={()=>{this.setSubject("matematicas")}} className={"subject-radio-btn "+math}>Matemáticas</button>
//                         <button onClick={()=>{this.setSubject("language")}} className={"subject-radio-btn "+esl}>Inglés</button>
//                         <button onClick={()=>{this.setSubject("ciencias")}} className={"subject-radio-btn "+sci}>Ciencias</button>
//                         <button onClick={()=>{this.setSubject("espanol")}} className={"subject-radio-btn "+esp}>Español</button>
//                         <button onClick={()=>{this.setSubject("historia")}} className={"subject-radio-btn "+hist}>Historia</button>
//                         <button onClick={()=>{this.setSubject("otra")}} className={"subject-radio-btn "+other}>Otra</button>
//                     </div>

                    
                    
//                     <div className="activity-form-row">
//                         <button onClick={()=>{this.props.cancelActivityBuilder()}} className="activity-builder-cancel">Cancelar</button>
//                         <button onClick={()=>{this.addActivity()}} className="activity-builder-add">Agregar</button>
//                     </div>

//                 </div>
                
//             </div>
//         )
//     }
// }

// export default ActivityBuilder



import React from 'react'
import firebase from 'firebase/compat/app';

import DatePicker from 'react-datepicker'

import ActivityBox from './activity-box';
import './agenda.css'

import './activity-builder.css'

class ActivityBuilder extends React.Component {
    constructor(){
        super()

        this.state = {
            nombre: "",
            tiempo: "",
            instrucciones: "",
            subject: "", 
            selectDate: "",
            calendar: new Date(), 
            view: 'list', // 'list' or 'create'
            activities: [], // This will store the list of activities
        }
    }

    componentDidMount() {
        this.setState({
            selectDate: this.props.date
        })

        this.fetchActivities();
    }

    fetchActivities() {
        // Fetch activities from Firebase or any other data source
        
        const query = {
            database:"396c609207ef4df3a3ade19f60f37646",
            filter: {
                and: [
                    {
                        property: 'Topic',
                        relation: {
                            contains: "0a7ccce0-a944-476c-9624-b2e27ffcff6a"
                        }
                    }
                ]
            },
            sorts: [
                {
                    property: 'secuencia',
                    direction: 'ascending'
                }
            ]
        }

        const notionQuery = firebase.functions().httpsCallable("notionQuery")
        notionQuery(query)
            .then(response=>{
                console.log(response.data)
                this.setState({
                    activities: response.data?.results
                })
            })

        
    }

    changeDate = calendarDate =>{

        var date = new Date(calendarDate)
        var day = date.getDate()
        var month = date.getMonth() + 1
        var year = date.getFullYear()

        const timestamp = +new Date(calendarDate)

        const dateFormat = day+"-"+month+"-"+year

        this.setState({
            selectDate: dateFormat, 
            calendar: date
        })


    }

    handleChange(event) {
        switch (event.target.placeholder) {
          case "Nombre de la actividad":
            this.setState({
                nombre: event.target.value
            });
            break;
          case "Tiempo para realizarla":
            this.setState({
                tiempo: event.target.value
            });
            break;
          case "Descripción":
            this.setState({
                instrucciones: event.target.value
            });
            break;
        case "Liga de acceso":
                this.setState({
                    acceso: event.target.value
                });
                break;
        
        }
      } 

    changeView = (view) => {
        this.setState({ view });
        
        if (view === 'list' && !this.state.activities.length) {
            // Fetch activities here (e.g., from Firebase)
            // For now, we'll use dummy data
            this.setState({
                activities: [
                    { name: "Activity 1", subject: "matematicas" },
                    { name: "Activity 2", subject: "language" },
                    // Add more dummy activities as needed
                ]
            });
        }
    }

    renderActivityList() {

        const timestamp = +new Date()
                    
        return (
            <div className="activity-list">
                <h3>Actividades Recurrentes</h3>
                    {this.state.activities.map((activity, index) => (
                            <ActivityBox
                            name={activity.properties.nombre.title[0].plain_text}
                            addActivity={this.props.addActivity}
                            activity_key={"recurrent_activity_"+timestamp}
                            activity={activity}
                            backView="student-profile"
                            // viewCurricularActivity={this.props.viewCurricularActivity}
                    />
                    ))}
            </div>
        );
    }

    addActivity =()=>{

        if(!this.state.nombre || !this.state.tiempo || !this.state.subject){
            alert("Debes llenar todos los campos para poder agregar una actividad")
        }

        else{
            var activity = this.state
            var dateFormat = this.state.selectDate
            var calendarDate = this.state.calendar
            var timestamp = +new Date(calendarDate)

            if(activity.acceso){
                activity.acceso.toLowerCase()
                if(!activity.acceso.startsWith("http")){
                    activity.acceso = "https://"+activity.acceso
                }
            }
            activity.name = this.state.nombre
            activity.topic_key = "custom_topic"
            activity.activity_key = "custom_activity_"+timestamp
            
            activity.status = "undone"
            console.log(calendarDate)
            console.log(timestamp)
            this.props.addTask(activity, dateFormat, timestamp)

            this.props.cancelActivityBuilder()

            console.log(activity)
            this.props.addTask(activity, activity.activity_key)


            // firebase.firestore().collection("students/"+this.props.uid+"/agenda").doc(this.props.date).get()
            // .then(doc=>{

            //     var newTasks = {}

            //     if(doc.data()){
            //         newTasks = doc.data()
            //     }

            //     newTasks[activity.activity_key] = activity

            //     firebase.firestore().collection("students/"+this.props.uid+"/agenda").doc(this.props.date).set(newTasks)
            
            //     this.props.cancelActivityBuilder()
                
            // })

        }

        
    }

    setSubject=(subject)=>{
        this.setState({
            subject
        })
    }

    renderActivityBuilder(){

        var math, esl, esp, sci, hist, other = ""
        

        switch (this.state.subject) {
            case "matematicas":
                    math="selected-subject"
                break;
            case "language":
                    esl="selected-subject"
                break;
            case "espanol":
                    esp="selected-subject"
                break;
            case "ciencias":
                    sci="selected-subject"
                break;
            case "historia":
                    hist="selected-subject"
                break;
            case "otra":
                    other="selected-subject"
                break;
            default:
                break;
        }
        return(
            <div className="activity-builder-form">

                <div>
                    <input
                        onChange={e => this.handleChange(e)}
                        className="activity-builder-input"
                        value={this.state.nombre}
                        placeholder='Nombre de la actividad'
                        type="text"
                    />
                    <input
                        onChange={e => this.handleChange(e)}
                        className="activity-builder-input"
                        value={this.state.instrucciones}
                        placeholder='Descripción'
                        type="text"
                    />

                </div>

                <div>
                    <input
                        onChange={e => this.handleChange(e)}
                        className="activity-builder-input"
                        value={this.state.tiempo}
                        placeholder='Tiempo para realizarla'
                        type="number"
                    />

                    <input
                        onChange={e => this.handleChange(e)}
                        className="activity-builder-input"
                        value={this.state.acceso}
                        placeholder='Liga de acceso'
                        type="text"
                    />
                </div>
                    
                    
                    
                    
                    

                    <DatePicker
                      selected={this.state.calendar}
                      onChange={date => this.changeDate(date)}
                    />


                    {/* <p className="subjects-input">Materia</p> */}
                    <div className="subjects-radio-container">
                        <button onClick={()=>{this.setSubject("matematicas")}} className={"subject-radio-btn "+math}>Matemáticas</button>
                        <button onClick={()=>{this.setSubject("language")}} className={"subject-radio-btn "+esl}>Inglés</button>
                        <button onClick={()=>{this.setSubject("ciencias")}} className={"subject-radio-btn "+sci}>Ciencias</button>
                        <button onClick={()=>{this.setSubject("espanol")}} className={"subject-radio-btn "+esp}>Español</button>
                        <button onClick={()=>{this.setSubject("otra")}} className={"subject-radio-btn "+other}>Otra</button>
                    </div>

                    
                    
                    <div className="activity-form-row">
                        {/* <button onClick={()=>{this.props.cancelActivityBuilder()}} className="activity-builder-cancel">Cancelar</button> */}
                        <button onClick={()=>{this.addActivity()}} className="activity-builder-add">Agregar</button>
                    </div>

                </div>
        )
    }

    render(){
        var math, esl, esp, sci, hist, other = ""
        
        console.log(this.props)
        switch (this.state.subject) {
            case "matematicas":
                    math="selected-subject"
                break;
            case "language":
                    esl="selected-subject"
                break;
            case "espanol":
                    esp="selected-subject"
                break;
            case "ciencias":
                    sci="selected-subject"
                break;
            case "historia":
                    hist="selected-subject"
                break;
            case "otra":
                    other="selected-subject"
                break;
            default:
                break;
        }

        return(
            <div className="activity-builder-modal">
                <div onClick={()=>{this.props.cancelActivityBuilder()}} className="activity-modal-bg"></div>
                <div className="activity-builder">
                    <div className="view-toggle">
                        <input 
                            id="show-activities"
                            type="radio" 
                            name="view"
                            value="list" 
                            checked={this.state.view === 'list'} 
                            onChange={() => this.changeView('list')}
                        />
                        <label htmlFor="show-activities">Actividades Recurrentes</label>
                        <input 
                            id="create-activity"
                            type="radio" 
                            name="view"
                            value="create" 
                            checked={this.state.view === 'create'} 
                            onChange={() => this.changeView('create')}
                        />
                        <label htmlFor="create-activity">Crear Actividad</label>
                    </div>
                    
                    {this.state.view === 'list' ? (
                        this.renderActivityList()
                    ) : (
                        this.renderActivityBuilder()
                    )}
                </div>
            </div>
        )
    }
}

export default ActivityBuilder