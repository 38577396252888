import React from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;



class IFA extends React.Component {
    
    constructor(){
        super()

        this.state={
            users: {}
        }
    }

    componentDidMount(){
        firebase.database().ref("ifa").once("value",(snap)=>{

            this.setState({
                users: snap.val()
            })

        })
    }

    send = (kid, key)=>{
        
        const sendWelcome = firebase.functions().httpsCallable("sayHello")
 
        const msg = {
                //   text: "",
            subject: "Acceso a Robin para "+kid.name,
                //   template: "",
            html:"<div>Hola, <br/><br/>Ya puede ingresar "+kid.name+" a <a href='robinacademy.com/app'>robinacademy.com/app </a> <br/><br/><b>usuario: "+kid.username+"<br/>contraseña: "+kid.password+"</b><br/><br/>En este video les cuento como funciona el curso y como pueden entrar a la primera clase, empezamos el lunes.<br/><a href='https://youtu.be/js9_JbPxtTI'>https://youtu.be/js9_JbPxtTI</a><br/><br/>Si tienen alguna duda me pueden escribir por whatsapp al: 81 1904 1378 <br/><br/><br/>Saludos,<br/>Roger</div>",
            to:kid.parent
        }

        var newUsers = this.state.users
        newUsers[key]={}

        this.setState({
            users: newUsers
        })

        console.log(kid)

        sendWelcome(msg).then((result)=>{
            console.log(msg)
        })
    }
    
    render(){
        return(
            <div>
                {
                    Object.keys(this.state.users).map(key=>{

                        const user = this.state.users[key]

                        return(
                            <div onClick={()=>{this.send(user,key)}}>
                                {user.parent}
                            </div>
                        )

                    })
                }
            </div>
        )
    }


}

export default IFA