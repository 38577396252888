import React from "react";
import Button from "../layout/button/button";

const EmailPrompt = props => {
  return (
    <div className="form">
      <p className="welcome-text">¿Tienes cuenta de email?</p>

      <div>
        <Button
          text="No"
          color="button-green"
          handleClick={props.handleChangeWelcomeView}
          view="create-username"
        />
        <Button
          text="Si"
          color="button-green"
          handleClick={props.handleChangeWelcomeView}
          view="create-email"
        />
      </div>
    </div>
  );
};

export default EmailPrompt;
