import React from 'react'
import '../../components/gourmet/ingredient-container.css'

const IngredientContainer = (props) => {

    return(
        <div className="item-container" onClick={()=>props.selectItem(props.img, props.type)}>
            {props.img &&
                <img className="avatar-item-img" src={props.img}></img>
            }
            
        </div>
    )

}

export default IngredientContainer

