import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./course-checkout-form.js";
import "./course-checkout.css";


const promise = loadStripe("pk_test_Zjzv9cv8T8mqd4I2BGMHXE31");


class CourseCheckout extends React.Component {

    render(){
        return (
            <div className="App">
              <Elements stripe={promise}>
                <CheckoutForm />
              </Elements>
            </div>
          );
    }
    
  }

  export default CourseCheckout