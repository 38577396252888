import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import Discord from '../../assets/images/discord.png'

import "./friend-list.css"
import CourseTopButton from '../course/course-top-button'


class FriendList extends React.Component {
    constructor(){
        super()

        this.state={
            friends:{}
        }
    }

    componentDidMount(){


        window.scrollTo(0, 0);
        document.body.style.backgroundColor = "#F0F0F4";
        document.body.style.backgroundImage = `none`

        const friendQuery = firebase.firestore().collection("friend-lists").doc(this.props.uid)
        friendQuery.get().then(doc=>{
            const friends = doc.data()

            this.setState({
                friends
            })
        })
    }

    render(){
        return(
            <div >
                <div className="discord-btn">
                <CourseTopButton
                            text="Discord"
                            color="#984EFF"
                            fill="#984EFF"
                            img={Discord}
                            handleClick = {()=> {window.open("https://discord.gg/Tsr9dSH", "_blank")}}
                        />
                </div>
                
                
                {this.state.friends &&
                    <div className="friend-list">
                        
                        
                        {Object.keys(this.state.friends).map(id=>{

                            const friend = this.state.friends[id]
                            if(!friend.block){
                                return(
                                    <div className="friend-card" onClick={()=>{this.props.selectFriendChat(friend.channel)}}>
                                        <img className="friend-img" src={friend.img}></img>
                                        <p className="friend-name">{friend.name}</p>
                                    </div>
                                )
                            }
                        })}

                    </div>
                }

            {!this.state.friends &&
                <div className="friend-list">

                    <p className="friend-list-message">Ahora puedes agregar a tus amigos y hablar solo con ellos. Para agregarlos solo entra a un chat grupal y haz click sobre su foto de perfil.</p>
                    {/* <br/><br/><br/>
                    <p className="friend-list-message">Para agregarlos solo entra a un chat grupal y haz click sobre su foto de perfil.</p> */}
                    
                </div>
            }
            </div>
        )
    }
}

export default FriendList