// import React from 'react'
// import './grade-selector.css'

// const GradeSelector = (props) =>{
//     return(
//         <div className="grade-selector" onClick={()=>{props.handleToggle()}}>

//             {props.toggle &&
//                 <div>
                    
//                     <div className="grade" onClick={()=>{props.handleToggle(props.grade)}}>Grado</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("K1")}}>K1</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("K2")}}>K2</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("K3")}}>K3</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("1ro")}}>1</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("2do")}}>2</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("3ro")}}>3</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("4to")}}>4</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("5to")}}>5</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("6to")}}>6</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("7mo")}}>7</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("8vo")}}>8</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("9no")}}>9</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("10mo")}}>10</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("10mo")}}>11</div>
//                     <div className="grade" onClick={()=>{props.handleToggle("10mo")}}>12</div>
//                 </div>
                
//             }

//             {!props.toggle &&
//                 <div className="selected-grade" onClick={()=>{props.handleToggle()}}>
//                     <p>{props.grade}</p>
//                 </div>
                
//             }
//         </div>
//     )
// }

// export default GradeSelector


import React, {useState} from 'react'
import './grade-selector.css'

const GradeSelector = (props) =>{

    const [isActive, setIsActive] = useState(false)

    const handleToggle = () =>{
        setIsActive(!isActive)
    }

    return(
        <div className="grade-selector" onClick={()=>{handleToggle()}}>

            {isActive &&
                <div>
                    
                    <div className="grade" onClick={()=>{handleToggle()}}>Grado</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("K1")}}>K1</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("K2")}}>K2</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("K3")}}>K3</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("1")}}>1</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("2")}}>2</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("3")}}>3</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("4")}}>4</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("5")}}>5</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("6")}}>6</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("7")}}>7</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("8")}}>8</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("9")}}>9</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("10")}}>10</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("11")}}>11</div>
                    <div className="grade" onClick={()=>{handleToggle(); props.setGrade("12")}}>12</div>

                </div>
                
            }

            {!isActive &&
                <div className="selected-grade" onClick={()=>{handleToggle()}}>
                    <p>{props.grade}</p>
                </div>
                
            }
        </div>
    )
}

export default GradeSelector