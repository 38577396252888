import React from 'react'
import IngredientContainer from './ingredient-container';
import './recipe.css'

const Recipe = (props) => {
    const dummyFunc = (argument)=>{

        //I'm passing a dummy function for select ingredient to reuse the component. Buts since it's not clickable.
        console.log("")
    }
    return(
        <div className="recipe">
            <div className="top-recipe">
                <img className="recipe-img" src={props.img}></img>
                <p className="recipe-price">Venta: ${props.price}</p>
            </div>

            <div className="bottom-recipe">
                <IngredientContainer
                    img={props.ingredients[0]}
                    selectIngredient={dummyFunc}
                />
                +
                <IngredientContainer
                    img={props.ingredients[1]}
                    selectIngredient={dummyFunc}
                />
                +
                <IngredientContainer
                    img={props.ingredients[2]}
                    selectIngredient={dummyFunc}
                />
                
            </div>

        </div>
    )
}

export default Recipe