import React from 'react';

const QuestOptions = (props) => {

    const options = props.scene.options
    const reward = props.scene.reward
    const skill = props.scene.skill
    console.log(options)
    

    if(options){
        return(
            
                <div className="btn-collection">
                    {Object.keys(options).map(function(key){
                        console.log(key)
                        if(key == "finish"){
                            return(
                                <button
                                    key={key}
                                    className="choice-btn"
                                    onClick={()=>props.handleFinish(reward, skill)}
                                >
                                {options[key]}
                                </button>
                            )
                            
                        }
                        else{
                            return(
                                <button 
                                    key={key} 
                                    className="choice-btn"
                                    onClick={()=>props.handleSceneChange(key)}
                                > 
                                    {options[key]}
                                </button>
                            )
                        }
                        
                    })}
                </div>
            
        )
    }
    else{
        return(
            <div>
                
            </div>
        )
    }
    
}

export default QuestOptions;