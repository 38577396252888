import React from 'react';
import mergeImages from 'merge-images';

// import { Canvas, Image } from 'canvas';

import * as PIXI from 'pixi.js'
import {Mixpanel} from '../../Mixpanel'

import Inventory from '../../assets/images/inventario.png'
import Edit from '../../assets/images/editar.png'
import White from '../../assets/images/white.png'
import Store from '../../assets/images/treehouse-bg.png'
import Left from '../../assets/images/left.png'

import Wood from "../../assets/images/wood-texture.png"
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';

import AvatarInventory from './avatar-inventory';
import NavBar from '../navigation/nav-bar'

import basicState from './basicInventory.json'


import './avatar.css'

// import { Stage, Sprite } from '@inlet/react-pixi'
import HouseInventory from '../treehouse/house/house-inventory';

class AvatarEdit extends React.Component {
    constructor(){
        super()
        console.log(basicState)

        this.state = { 
            type: "layer1",
            downX: 0,
            editing: false,
            height: window.innerHeight, 
            width: window.innerWidth, 
            x: 0,
            y: 0, 
            positionRatio: 1930/window.innerHeight,

            // avatarItems: {
            //     feet: "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2Fcompare.png?alt=media&token=f2e278e4-a8d5-4dd4-b56e-77fc8f862e8b", 
            //     legs: "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2Fcompare.png?alt=media&token=f2e278e4-a8d5-4dd4-b56e-77fc8f862e8b",
            //     body: "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2Fcompare.png?alt=media&token=f2e278e4-a8d5-4dd4-b56e-77fc8f862e8b",
            //     face: "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2Fcompare.png?alt=media&token=f2e278e4-a8d5-4dd4-b56e-77fc8f862e8b", 
            //     hat: "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2Fcompare.png?alt=media&token=f2e278e4-a8d5-4dd4-b56e-77fc8f862e8b"
            // },
            // avatarInventory:{
            //     hat: ["https://pngimg.com/uploads/cowboy_hat/cowboy_hat_PNG86.png", "http://pngimg.com/uploads/policeman/policeman_PNG89009.png", "http://pngimg.com/uploads/birthday_hat/birthday_hat_PNG86544.png", "http://pngimg.com/uploads/chef/chef_PNG71.png","http://pngimg.com/uploads/bowler_hat/bowler_hat_PNG14.png"],
            //     legs: ["https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2Fcolor-wheel.png?alt=media&token=a198a766-8bfd-45fa-a57e-961f68fc45eb"],
            //     body: [
                    

            //     ],
            //     face: [],
            //     feet: []
            // },
            // avatarItems: Object.assign([], basicState.avatarItems)
            // , 
            // avatarInventory: Object.assign([], basicState.avatarInventory)
            avatarItems: ''
            , 
            avatarInventory: ''
        };

    }

    
    selectItem = (item, type)=>{

        console.log(item, type)
        var avatarItems = this.state.avatarItems

        avatarItems[type] = item


        this.setState({
            avatarItems: avatarItems
        })
    }

    selectType=(type)=>{
        this.setState({
            type: type
        })

        

    
    }

    saveAvatar=()=>{
        console.log(this.state.avatarItems)
        const avatarItems = this.state.avatarItems

        firebase.database().ref("avatars/"+this.props.uid+"/avatarItems").set(avatarItems)

        var imgagesToMerge = []
        Object.keys(avatarItems).map((key)=>{
            const img = avatarItems[key]
            console.log(img)
            if(img){
                imgagesToMerge.push(img)
            }
        })
        this.setState({
            avatarInventory: basicState.avatarInventory
        })
        console.log(imgagesToMerge)
        mergeImages(imgagesToMerge)
            .then(b64 => {
                console.log(b64)
                firebase.database().ref("users/"+this.props.uid+"/profile").set(b64)

                this.props.changeAvatar(b64)
            });

            
    }

    componentDidMount(){

        console.log(basicState)


        document.body.style.backgroundImage = `url(${Wood})`
        
        firebase.database().ref("basicInventory").once("value", snap=>{
            this.setState({
                avatarInventory: snap.val().avatarInventory,
                avatarItems: snap.val().avatarItems
            })

            console.log(snap.val())

            
        firebase.database().ref("avatars/"+this.props.uid).once("value", (snap)=>{
            if(snap.val()){
                const avatarInventory = snap.val().avatarInventory
                const avatarItems = snap.val().avatarItems
                console.log(snap.val())

                var stateInventory = this.state.avatarInventory
                var currentItems = this.state.avatarItems

                if(avatarInventory){
                    Object.keys(avatarInventory).map(key=>{

                        console.log(key)
                        const layer = avatarInventory[key]
                        console.log(layer)
                        if(layer){
                            console.log(stateInventory)
                            console.log(basicState)
                            stateInventory[key] = stateInventory[key].concat(layer)
    
    
                            // console.log(basicInventory)
                            // basicInventory[key].push(layer)
                            console.log(basicState)
    
                            console.log(stateInventory)
                        }
                    })
                }
                





                if(avatarItems){
                    currentItems = avatarItems
                }
                console.log(stateInventory)

                this.setState({
                    avatarInventory: stateInventory, 
                    avatarItems: currentItems
                })
            }
            

        })

        })

    }

    




    render(){



        // New Avatar Rendering

        const avatarItems = this.state.avatarItems
        

       
        console.log(this.state)


        //

        const vh = this.state.height

        
        // console.log(this.state)

        const ratio = vh/1570

        var coinHeight = 152*ratio

        const objects = this.state.objects
        const inventory = this.state.inventory

        var backgroundHeight = 0

        if(this.state.viewInventory){
            backgroundHeight= -500/this.state.positionRatio
        }

        // console.log("height: "+vh)
        // console.log("width: "+4000*ratio)

        // var isDragging = "block"

        var bufferStyle={
            // pointerEvents: "all",
            height: vh,
            width: 4000*ratio
        }

        // if(this.state.dragging){
        //     bufferStyle={
        //         display: "none",
        //         pointerEvents: "none",
        //         height: `${vh}`,
        //         width:`${4000*ratio}`
        //     }
        // }

        var bgAlpha=1

        if(this.state.editing){
            bgAlpha=0.6
        }

        if(this.state.avatarInventory && this.state.avatarItems){
            console.log(this.state)

            
            return(
                <div  className="avatar-background">
                    <NavBar
                        handleBack={this.saveAvatar}
                        backView="app"
                        title="Armario"
                    />

                    {/* <p className="edit-avatar-text">Edita tu personaje...</p>                   */}
                    <div className="avatar-items">
                        <img src={this.state.avatarItems.layer1} className={"avatar-item avatar-layer1"}></img>
                        <img src={this.state.avatarItems.layer2} className={"avatar-item avatar-layer2"}></img>
                        <img src={this.state.avatarItems.layer3} className={"avatar-item avatar-layer3"}></img>
                        <img src={this.state.avatarItems.layer4} className={"avatar-item avatar-layer4"}></img>
                        <img src={this.state.avatarItems.layer5} className={"avatar-item avatar-layer5"}></img>
                    </div>
    
    
                    <AvatarInventory
                        inventory = {this.state.avatarInventory[this.state.type]}
                        selectItem = {this.selectItem}
                        label={"Zapatos"}
                        type={this.state.type}
                        selectType={this.selectType}
                    />
                    
                    
                </div>
            )
        }
        else{
            return(<div></div>)
        }
        
    }
}

export default AvatarEdit





//Algo está causando un re-render cuando hago click en el canvas. Se redibuja el background sobre la moneda que dibujé previamente.

//Las coordenadas del mouse no concuerdan con las del canvas