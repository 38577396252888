import React from 'react';
import './merchant.css'
import Counter from './counter';

import Tent from '../../assets/images/tent.png'

class Merchant extends React.Component {

    constructor(){
        super()

        this.state = {
            amount: 0
        }

        this.minus = this.minus.bind(this)
        this.plus = this.plus.bind(this)
    }

    minus(){
        if(this.state.amount > 0){
            this.setState({
                amount: this.state.amount - 1
            })
        }
        
    }

    plus(){
        this.setState({
            amount: this.state.amount + 1
        })
    }

    render(){
        
        const catalogue = this.props.ingredientCatalogue
        const item = this.props.item
        const price = this.props.price
        return(
            <div>
                <img className="tent-img" src={Tent}></img>
                <div className="merchant">
    
                    <div className="merchant-dialogue">
                        <img className="merchant-img" src="https://media.discordapp.net/attachments/1129827205522927647/1189311661945921566/cashier.png?ex=659db3cc&is=658b3ecc&hm=d1f998fea686f5e0b65d2759a9bf3f7dfc5997e0a04b3931fee337ada22e2a1c&=&format=webp&quality=lossless&width=1024&height=1024"></img>
                        <div className="item-offer">
                            <img className="item-img" src={catalogue[item]}></img>
                            <p className="item-price">= ${price}</p>
                        </div>
    
                        <div className="dialogue-bubble"></div>
                    </div>
        
                    <Counter
                        amount={this.state.amount}
                        minus={this.minus}
                        plus={this.plus}
                    />
    
                    <button onClick={()=>this.props.buyItem(item, price, this.state.amount)} className="buy-item">Comprar</button>
                </div>
    
            </div>
            
    
        )
    }
    
}

export default Merchant;