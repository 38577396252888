import React from 'react';
import Left from '../../assets/images/left.png';
import './nav-bar.css'


const NavBar = (props) =>{
    console.log(props.backView)
    return(
    <div className="nav-bar-container">
        <div className="nav-left">
            <div
                className="nav-icon" 
                onClick={()=>props.handleBack(props.backView, props.game)}
            >
            <img className="back"src={Left}></img>
            </div>
            <p className="heading">{props.title}</p>
        </div>
    </div>
    )
    
}
export default NavBar;