import React from 'react';
import GourmetNav from './gourmet-nav';
import GourmetInventory from './gourmet-inventory';
import GourmetMarket from './gourmet-market';
import MenuContainer from './menu-container';
import Kitchen from './kitchen';
import Modal from './modal';

import Star0 from '../../assets/images/star-0.png'
import Star1 from '../../assets/images/star-1.png'
import Star2 from '../../assets/images/star-2.png'
import Star3 from '../../assets/images/star-3.png'

import {Mixpanel} from '../../Mixpanel'
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;




class Gourmet extends React.Component {

    constructor(){
        super()

        this.state = {
            view: "menu", 
            cash: 100, 
            sales:0,
            costs:0,
            inventory: [],
            ingredientCatalogue: {
                "tomato": "https://image.flaticon.com/icons/svg/135/135702.svg",
                "meat": "https://image.flaticon.com/icons/svg/135/135628.svg",
                "shrimp": "https://image.flaticon.com/icons/svg/776/776493.svg", 
                "lettuce": "https://image.flaticon.com/icons/svg/1515/1515026.svg", 
                "flour": "https://image.flaticon.com/icons/svg/992/992767.svg"
            }, 
            selectedIngredients: [], 
            market:{ 
                "tomato": 20,
                "meat": 30,
                "shrimp": 40
            },
            menu:{
                "meat-shrimp-tomato": {
                    name: "burger",
                    price: 120, 
                    img: "https://image.flaticon.com/icons/svg/878/878052.svg"
                },
                "meat-tomato-tomato": {
                    name: "salad", 
                    price: 90, 
                    img: "https://image.flaticon.com/icons/svg/135/135637.svg"
                }
            }, 
            modal:{
                dialogue: "Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem ",
                img:"https://image.flaticon.com/icons/svg/878/878052.svg",
                options: {
                    ok: ()=>{this.closeModal()}
                }
            }, 
            modalView: false, 

        }

        this.renderView = this.renderView.bind(this)
        this.gourmetViewChange = this.gourmetViewChange.bind(this)
        this.selectIngredient = this.selectIngredient.bind(this)
        this.handleCancelIngredient = this.handleCancelIngredient.bind(this)
        this.buyItem = this.buyItem.bind(this)
        this.handleCook = this.handleCook.bind(this)
        this.showKitchen = this.showKitchen.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.showDishModal = this.showDishModal.bind(this)
        this.handleCashOut = this.handleCashOut.bind(this)
        this.handleQuit = this.handleQuit.bind(this)
        this.handleTransaction = this.handleTransaction.bind(this)
        this.recursiveTest = this.recursiveTest.bind(this)
    }

    recursiveTest(amountLeft, profit, dishes){
        console.log("RRRRRRR")
        
        Object.keys(this.state.menu).map((dish)=>{

            
            


            console.log("")
            console.log("")
            console.log("")
            console.log(dishes)
            console.log("Tengo $"+amountLeft)
            
            const ingredientArray = dish.split("-")

            var dishCost = 0

            ingredientArray.forEach(ingredient => {
                dishCost = dishCost + this.state.market[ingredient]
            });

            console.log("Preparo "+this.state.menu[dish].name+" por $"+dishCost)

            
            if(dishCost <= amountLeft){
                
                
                
                const newAmount = amountLeft - dishCost

                const newProfit = profit + (this.state.menu[dish].price - dishCost)

                

                
                
                if(newAmount>10){
                    
                    var arrayDummy = dishes.slice(0)
                    arrayDummy.push(this.state.menu[dish].name)
                    console.log("Z")
                    console.log("Z")
                    console.log("Me quedan $"+newAmount)
                    console.log(arrayDummy)
                    this.recursiveTest(newAmount, newProfit, arrayDummy)
                    // dishes.splice(-1,1)
                }
                else{
                    var arrayDummy = dishes.slice(0)
                    arrayDummy.push(this.state.menu[dish].name)
                    console.log("B")
                    console.log("B")
                    console.log("Terminé")
                    console.log(arrayDummy)
                    // console.log(dishArray)
                    console.log("Me sobra $"+newAmount)
                    // dishes.splice(-1,1)
                    
                }
                
            }

            else{
                console.log("A")
                console.log("A")
                console.log("Terminé")
                console.log(dishes)
                console.log("Me sobra $"+amountLeft)
                dishes.splice(-1,1)
              

            }
            
        })

    }

    componentDidMount(){
        this.props.toggleLoading(false)
        
    }

    componentWillMount(){
        
        if(this.props.level > 1){ 
            if(!this.props.child.finanzas && !this.props.child.course){
                alert("Debes inscribirte al curso de Finanzas para seguir jugando.")
                this.props.handleViewChange("app")
            }
        }

        firebase.database().ref("gourmet-levels/ingredients").once("value", (snap)=>{
            this.setState({
                ingredientCatalogue: snap.val()
            })
        })

        firebase.database().ref("gourmet-levels/gourmet"+this.props.level).on("value", (snap)=>{
            const gourmet = snap.val()
            // console.log(gourmet)
            this.setState({
                market: gourmet.market,
                menu: gourmet.menu, 
                max: gourmet.max,
                cash: gourmet.cash
            })

            // this.recursiveTest(100,0,[])
            // console.log("FINAL: ")
            // console.log(result)
        })
    }

    handleCashOut(){

        // this.closeModal()

        axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
            var timestamp = data.data.timestamp

            const balance = this.state.sales - this.state.costs
            var stars = 0
            var amount = 0
            var starImg = Star0


            switch(true){
                case (balance<this.state.max*0.33):
                    stars = 0
                    amount = 0
                    starImg = Star0
                    break;
                case (balance<this.state.max*0.66): 
                    stars = 1
                    amount = 10
                    starImg = Star1
                    break;
                case (balance<this.state.max): 
                    stars = 2
                    amount = 15
                    starImg = Star2
                    break;
                case (balance>=this.state.max):
                    stars = 3
                    amount = 20
                    starImg = Star3
                    break;
            }

        
            // // Register level progress
            // firebase.database().ref("gourmet-progress/"+this.props.userID+"/"+(this.props.level-1)).set(stars)
            

            console.log(this.props.userID)
            // Analytics
            firebase.database().ref("analytics/gourmet/"+timestamp).set(this.props.userID)
            Mixpanel.track('Gourmet Finish');

            // Calificación en skills
            firebase.database().ref("grades/"+this.props.userID+"/presupuesto").once("value", (snap) => {
                var skillgrades = []

                if(snap.val()){
                    
                    skillgrades = snap.val()
                }
        
                skillgrades.push(stars)
                
                firebase.database().ref("grades/"+this.props.userID+"/presupuesto").set(skillgrades)

            })


            firebase.database().ref("gourmet-progress/"+this.props.userID+"/"+(this.props.level-1)).once("value",(snap)=>{
              
                const oldGrade = snap.val()
                
                console.log(oldGrade)
                if(stars>oldGrade){
                    // Register level progress
                    firebase.database().ref("gourmet-progress/"+this.props.userID+"/"+(this.props.level-1)).set(stars)
                    
                    const resultModal = {
                        title: "¡Felicidades!",
                        dialogue: "Ganaste $"+stars*5+" Robins :)",
                        images: [starImg],
                        options: {
                            ok: ()=>{this.handleTransaction(timestamp, stars*5)}
                        }
                    }
            
                    this.setState({
                        modal: resultModal
                    })
                }

                else{
                    const resultModal = {
                        title: "Será la próxima",
                        dialogue: "No conseguiste más estrellas que antes",
                        images: [starImg],
                        options: {
                            ok: ()=>{this.props.handleViewChange("app")}
                        }
                    }
            
                    this.setState({
                        modal: resultModal
                    })
                }
            })
            
            

        })

    }

   

    handleTransaction(timestamp, amount){
        // const amount = this.state.prize

        
        //Actualizar Balance y generar transacción
        firebase.database().ref("users/"+this.props.userID+"/balance").once("value", (snap) => {
            var oldBalance = snap.val()
            console.log(oldBalance)

            
            firebase.database().ref("transactions/" + this.props.userID).push().set({
                desc: "Gourmet",
                cant: amount,
                type: "add",
                date: timestamp, 
                oldBalance: oldBalance,
            })

            if(oldBalance) {
                firebase.database().ref("users/"+ this.props.userID).update({
                    balance: oldBalance + amount, 
                    gourmet: timestamp
                })
            }
            else{
                this.props.handleViewChange("app")
            }

        })
    }

    summary(){

        const costs = this.state.costs
        const sales = this.state.sales
        const profit = sales - costs
        const summaryModal = {
            title: "Resumen",
            dialogue: "Tus gastos son: $"+costs+"\n\n Tus ventas son: $"+sales+"\n\n En total la ganancia fue de: $"+profit,
            img:"",
            options: {
                ok: ()=>{this.handleCashOut()}
            }
        }

        this.setState({
            modal: summaryModal, 
            modalView: true 
        })
    }

    handleQuit(){
        const quitModal = {
            title: "",
            dialogue: "Parece que todavía puedes hacer platillos ¿Seguro que quieres salir del juego?",
            img:"",
            options: {
                no: ()=>{this.closeModal()},
                si: ()=>{this.summary()}
            }
        }

        this.setState({
            modal: quitModal, 
            modalView: true 
        })
    }



    closeModal(){
        this.setState({
            modalView: false
        })

        const inventory = this.state.inventory
        const view = this.state.view

        const dishModal = {
            title: "",
            dialogue: "Parece que ya no tienes más ingredientes para preparar platillos. \n\n Veamos cuanto hemos ganado hoy...",
            img:"",
            options: {
                veamos: ()=>{this.summary()}
            }
        }

        

        if(inventory.length < 3 && view == "kitchen"){
            this.setState({
                modal: dishModal, 
                modalView: true 
            })
        }
    }

    showKitchen(){
        this.setState({
            view: "kitchen", 
            modalView: false
            
        })
    }

    showDishModal(dish, price, img){
        const dishModal = {
            title: dish,
            dialogue: "Se puede vender por $"+price,
            images: [img],
            options: {
                ok: ()=>{this.closeModal()}
            }
        }

        this.setState({
            modal: dishModal, 
            modalView: true
            
        })

    }

    buyItem(item, price, amount){
        const total = price*amount
        var cash = this.state.cash
        var inventory = this.state.inventory

        const inventorySpace = inventory.length + amount

        if(cash>= total){

            if(inventorySpace <= 20 ){
                cash = cash-total
                for(var i = 0; i < amount; i++){
                    inventory.push(item)
                }

                this.setState({
                    inventory: inventory,
                    cash: cash,
                    costs: this.state.costs + total
                })
            }

            else{
                alert("No tienes suficiente espacio en tu inventario.")
            }

        }
        else{
            alert("No tienes suficiente dinero :/")
        }
    }

    handleCook(){
        var selectedIngredients = this.state.selectedIngredients


        if(selectedIngredients.length == 3){

            selectedIngredients.sort()

            const dish = selectedIngredients.join("-")

            const array = dish.split("-")

            var result = this.state.menu[dish]

            if(!result){
                result = {
                    name: "Revoltura Extraña",
                    price: 0,
                    img: "https://media.discordapp.net/attachments/1129827205522927647/1189312901014626437/green-slime.png?ex=659db4f3&is=658b3ff3&hm=20726d012e3156bb45ce33c1d5178c5eff85113acab58b398eb8e4d045dabd33&=&format=webp&quality=lossless&width=1024&height=1024"
                }
            }

            this.setState({
                selectedIngredients: [], 
                sales: this.state.sales + result.price, 
                cash: this.state.cash + result.price
            })

            console.log(this.state)

            // alert(result)

            this.showDishModal(result.name,result.price, result.img)
        }

        else{
            alert("Necesitas combinar al menos 3 ingredientes!")
        }

    }

    handleCancelIngredient(index){

        //Todos los ingredientes seleccionados
        var selectedIngredients = this.state.selectedIngredients

        //Conseguir solo el que se va a regresar
        const ingredient = selectedIngredients[index]

        //El inventario a donde lo voy a mover
        var inventory = this.state.inventory

        if(ingredient){

            

            if(inventory.length<20){

                //Borrarlo de los seleccionados
                selectedIngredients.splice(index, 1)

                inventory.push(ingredient)

                this.setState({
                    inventory: inventory, 
                    selectedIngredients: selectedIngredients
                })

            }
            else{
                alert("Ya no tienes espacio en tu inventario!")
            }
        }



    }

    selectIngredient(index){

        var inventory = this.state.inventory

        const ingredient = inventory[index]

        var selectedIngredients = this.state.selectedIngredients

        if(ingredient && this.state.view == "kitchen"){
            
        
            if(selectedIngredients.length<3){
                inventory.splice(index, 1)

                selectedIngredients.push(ingredient)

                this.setState({
                    selectIngredients: selectedIngredients, 
                    inventory: inventory
                })
            }

            // else{
            //     alert("no seas gordo")
            // }
        }

    

    }

    renderView(){
        
        switch (this.state.view) {
            
            case "menu":
              return(
                <MenuContainer
                    menu={this.state.menu}
                    ingredientCatalogue = {this.state.ingredientCatalogue}
                />
              )
            
            case "market":
              return(
                <GourmetMarket
                    market={this.state.market}
                    ingredientCatalogue = {this.state.ingredientCatalogue}
                    buyItem={this.buyItem}
                />
              )

            case "kitchen":
              return(
                <Kitchen
                    selectedIngredients={this.state.selectedIngredients}
                    ingredientCatalogue = {this.state.ingredientCatalogue}
                    handleCancelIngredient = {this.handleCancelIngredient}
                    handleCook = {this.handleCook}
                />
              )
        }
    }

    gourmetViewChange(view){

        if(view=="kitchen"){
            
            const shouldKitchenModal = {
                dialogue: "Asegurate de tener todos los ingredientes porque una vez que entres a la cocina no podrás regresar al Menú o Mercado. ¿Quieres empezar a cocinar?",
                images: ["https://media.discordapp.net/attachments/1129827205522927647/1189312147000414278/kitchen.png?ex=659db440&is=658b3f40&hm=476dded3860d7d64c2ebc36aa5aaa5597026c2fcae4fce336a81418793731a25&=&format=webp&quality=lossless&width=1024&height=1024"],
                options: {
                    no: ()=>{this.closeModal()},
                    si: ()=>{this.showKitchen()}
                }
            }

            this.setState({
                modal: shouldKitchenModal,
                modalView: true
            })

        }

        //If you're not in kitchen mode, change view
        else if(this.state.view != "kitchen"){
            this.setState({
                view: view
            })
        }   
        else{
            alert("No puedes regresar al mercado o el menú mientras estás en la cocina!")
        }
        
    }

    render(){

        // const modal = {
        //     options: this.state.modal.options,
        //     dialogue: this.state.modal.
        // }
        return(
            <div>
                {this.state.modalView &&
                    <Modal
                        // options={this.state.modal.options}
                        // dialogue={this.state.modal.dialogue}
                        // imgages={this.state.modal.img}
                        // title={this.state.modal.title}

                        modal={this.state.modal}

                    />
                }
                <GourmetNav
                    gourmetViewChange = {this.gourmetViewChange}
                    cash = {this.state.cash}
                    handleQuit = {this.handleQuit}
                />

                {this.renderView()}

                <GourmetInventory
                    inventory = {this.state.inventory}
                    ingredientCatalogue = {this.state.ingredientCatalogue}
                    selectIngredient = {this.selectIngredient}
                />

                
            </div>
        )
    }
}

export default Gourmet;