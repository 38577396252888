import React from 'react';
import './article.css'

const Article = (props) => {

    var classname = ""
    if(props.flip){
        classname = "article swing-in-top-fwd"
    }
    else{
        classname = "article"
    }
    
    return(
        <div className={classname}>
            <p className="article-title">{props.title}</p>
            <img className="article-img" src={props.img}></img>
            <p className="article-text">{props.text}</p>
        </div>
    )
}

export default Article