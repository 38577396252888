import React from 'react';
import './course-top-button.css'

const CourseTopButton = (props) => {
    var style = {
        borderColor: props.color
    }

    if(props.fill){
        style.backgroundColor = props.fill
        style.color = "white"
    }
    return(
        <div onClick={props.handleClick} style={style} className="course-top-button-container">
            <img src={props.img} className="course-top-button-img"></img>
            <p style={{color:props.textColor}}>{props.text}</p>
        </div>


    )
}

export default CourseTopButton