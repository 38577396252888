import React from "react";
import Button from "../layout/button/button";
import Field from "../layout/field/field";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;


import Voucher from 'voucher-code-generator'


class CreateUsername extends React.Component {
  constructor() {
    super();

    this.state = {
      name: "",
      email: "",
      password: "",
      passwordConfirm: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);

    }

  componentDidMount(){
    // this.createBunch()
  }

  createOne=()=>{

    let fullname = this.state.name

    let name = fullname.toLowerCase()

    var username = ""

    if(name.includes(" ")){
        let array = name.split(" ")
        username = array[0]
        if (array[1]) {
            username = username + array[1]
        }
    }
    else{
        username=name
    }

    username = username.replace("ñ","n")

    username = username.replace("é","e")

    username = username.replace("á","a")

    username = username.replace("í","i")

    username = username.replace("ó","o")

    username = username.replace("ú","u")

    username = username.replace("ç","c")

    var code = Voucher.generate({
        length: 3,
        count: 1,
        charset: "0123456789"
    });

    let email = username + code + "@robin.com"

    let passwordArray = ["pokemon", "pikachu", "charmander", "togepi", "mewtwo", "valparaiso", "bogota", "colombia", "argentina", "rancagua", "biobio", "rosario", "california", "londres", "madrid", "barcelona", "telescopio", "oceano", "bosque", "morado", "chocolate", "ballena", "delfin", "cancun", "volcan", "mercurio", "jupiter", "saturno", "galaxia", "caracoles"]

    let password = passwordArray[Math.floor(Math.random() * passwordArray.length)];

    // password = this.state.parent

    console.log(password)
    firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((usu) => {
              // console.log(usu.usu.usu)
              
              let user = usu.user;
              user.updateProfile({
                  displayName: this.state.name,
                  photoURL: "https://example.com/jane-q-user/profile.jpg"
              })
              .then(
                  function() {},
                  function(error) {
                    // An error happened.
                  }
              );

              var profile = {
                email: email,
                name: fullname,
                password: password,
                parent: this.state.parent,
                balance: 0
              }

              const sendWelcome = firebase.functions().httpsCallable("sayHello")
 
                const msg = {
                //   text: "",
                  subject: "Acceso a Robin para "+fullname,
                //   template: "",
                  html:"<div>Hola, <br/><br/>Ya puede ingresar "+fullname+" a <a href='robinacademy.com/app'>robinacademy.com/app </a> <br/><br/><b>usuario: "+fullname+"<br/>contraseña: "+password+"</b><br/><br/>En este video les cuento como funciona el curso y como pueden entrar a la primera clase, empezamos el lunes.<br/><a href='https://youtu.be/js9_JbPxtTI'>https://youtu.be/js9_JbPxtTI</a><br/><br/>Si tienen alguna duda me pueden escribir por whatsapp al: 81 1904 1378 <br/><br/><br/>Saludos,<br/>Roger</div>",
                  to:this.state.parent
                }

                sendWelcome(email).then((result)=>{
                  console.log(result)
                  
                })

              
              firebase.database().ref("users/"+user.uid).set(profile)
              
              console.log("Listo")
              
              
            })
            .catch(function(error) {
              // var errorCode = error.code;
              var errorMessage = error.message;
              console.log(errorMessage);
            });
        
  }

  createBunch=()=>{
    
    firebase.database().ref("ifa").once("value",(snap)=>{
      

      let usersArray = snap.val()

      console.log(usersArray)

      Object.keys(usersArray).map((key, index)=>{

        // if(index>10 && index<30){

        const kid = usersArray[key]
      
        

        let fullname = kid.name

          let name = fullname.toLowerCase()

          var username = ""

          if(name.includes(" ")){
              let array = name.split(" ")
              username = array[0]
              if (array[1]) {
                  username = username + array[1]
              }
          }
          else{
              username=name
          }

          username = username.replace("ñ","n")

          username = username.replace("é","e")

          username = username.replace("á","a")

          username = username.replace("í","i")

          username = username.replace("ó","o")

          username = username.replace("ú","u")

          username = username.replace("ç","c")

          var code = Voucher.generate({
              length: 3,
              count: 1,
              charset: "0123456789"
          });

          console.log(username, code[0])

          let email = username + code[0] + "@robin.com"
          // let email = kid.username + "@robin.com"

          let passwordArray = ["pokemon", "pikachu", "charmander", "togepi", "mewtwo", "valparaiso", "bogota", "colombia", "argentina", "rancagua", "biobio", "rosario", "california", "londres", "madrid", "barcelona", "telescopio", "oceano", "bosque", "morado", "chocolate", "ballena", "delfin", "cancun", "volcan", "mercurio", "jupiter", "saturno", "galaxia", "caracoles"]

          // let password = passwordArray[Math.floor(Math.random() * passwordArray.length)];

          let password = kid.password

          console.log(kid.parent)

              const sendWelcome = firebase.functions().httpsCallable("sayHello")
 
                const msg = {
                //   text: "",
                  subject: "Acceso a Robin para "+kid.name,
                //   template: "",
                  html:"<div>Hola, <br/><br/>Ya puede ingresar "+kid.name+" a <a href='robinacademy.com/app'>robinacademy.com/app </a> <br/><br/><b>usuario: "+kid.username+"<br/>contraseña: "+kid.password+"</b><br/><br/>En este video les cuento como funciona el curso y como pueden entrar a la primera clase, empezamos el lunes.<br/><a href='https://youtu.be/js9_JbPxtTI'>https://youtu.be/js9_JbPxtTI</a><br/><br/>Si tienen alguna duda me pueden escribir por whatsapp al: 81 1904 1378 <br/><br/><br/>Saludos,<br/>Roger</div>",
                  to:kid.parent
                }

                sendWelcome(email).then((result)=>{
                  console.log(email)
                  
                })



          // const createUser = firebase.functions().httpsCallable("createUsers")
          //       console.log(kid.username)
          //       const datos = {
          //         kid:kid,
          //         username:kid.username,
          //         password:password,
          //         email:kid.username + "@robin.com"
          //       }

          //       console.log(datos)

          //       createUser(datos).then((result)=>{
          //         console.log(result)
                  
          //       })
          







          // var user = ""
          // firebase
          //   .auth()
          //   .createUserWithEmailAndPassword(email, password)
          //   .then((usu) => {
             
          //     console.log(usu.user.uid)
          //     // console.log(usu.usu.usu)
          //     // var user = ""
          //     // user = usu.user;
          //     // user.updateProfile({
          //     //     displayName: this.state.name,
          //     //     photoURL: "https://example.com/jane-q-user/profile.jpg"
          //     // })
          //     // .then(
          //     //     function() {},
          //     //     function(error) {
          //     //       // An error happened.
          //     //     }
          //     // );

          //     // var profile = {
          //     //   email: username + code[0] + "@robin.com",
          //     //   name: fullname,
          //     //   vg1: kid.vg1,
          //     //   yt: kid.yt, 
          //     //   music: kid.music, 
          //     //   balance: 0,
          //     //   parent: kid.parent, 
          //     // }

          //     var profile = kid

          //     profile.email = username+code[0]+"@robin.com"

          //     // if(kid.age < 8){
          //     //   profile = {
          //     //     email: email,
          //     //     name: fullname,
          //     //     cocina: "banregio",
          //     //     balance: 0
          //     //   }
          //     // }

          //     firebase.database().ref("users/"+usu.user.uid).set(profile)

          //     const sendWelcome = firebase.functions().httpsCallable("sayHello")
 
          //       const email = {
          //       //   text: "",
          //         subject: "Acceso a Robin para "+fullname,
          //       //   template: "",
          //         html:"<div>Hola, <br/><br/>Ya puede ingresar "+fullname+" a <a href='robinacademy.com/app'>robinacademy.com/app </a> <br/><br/><b>usuario: "+username+code+"<br/>contraseña: "+password+"</b><br/><br/>En este video les cuento como funciona el curso y como pueden entrar a la primera clase, empezamos el lunes.<br/><a href='https://youtu.be/js9_JbPxtTI'>https://youtu.be/js9_JbPxtTI</a><br/><br/>Si tienen alguna duda me pueden escribir por whatsapp al: 81 1904 1378 <br/><br/><br/>Saludos,<br/>Roger</div>",
          //         to:kid.parent
          //       }

          //       sendWelcome(email).then((result)=>{
          //         console.log(result)
                  
          //       })
              
          //   })
          //   .catch(function(error) {
          //     // var errorCode = error.code;
          //     var errorMessage = error.message;
          //     console.log(errorMessage);
          //   });
        // }
      })
    })
  }

  handleCreate() {



    if (this.state.password === this.state.password) {
      if (this.state.password.length > 5) {
        var username = this.state.email + "@robin.com";
        firebase
          .auth()
          .createUserWithEmailAndPassword(username, this.state.password)
          .then(() => {
            const user = firebase.auth().currentUser;

            user
              .updateProfile({
                displayName: this.state.name,
                photoURL: "https://example.com/jane-q-user/profile.jpg"
              })
              .then(
                function() {},
                function(error) {
                  // An error happened.
                }
              );

            this.writeUserData(user);
          })
          .catch(function(error) {
            // Handle Errors here.
            if (
              error.message ===
              "The email address is already in use by another account."
            ) {
              alert("Este usuario ya está registrado, utiliza otro por favor.");
            } else {
              alert(console.log(error.message));
            }
          });
      } else {
        alert("El password debe tener por lo menos 6 caracteres.");
      }
    } else {
      alert("Los passwords no son iguales.");
    }
  }

  writeUserData(user) {

    firebase
      .database()
      .ref("users/" + user.uid)
      .set({
        name: this.state.name,
        email: this.state.email,
        balance: 0, 
        ohana: "jul1",
        buhos: "jul1",
        monos: "jul1",
        leones: "jul1",
        tortugas: "jul1",
        buhos: "jul1",
        hormigas: "jul1",
        
        yoga: "jul1",
        baile: "jul1",
        arte: "jul1",
        ciencias: "jul1",
        leyendas: "jul1",
        salud: "jul1",
        lsm: "jul1",
        mexico: "jul1",
        ohana: "jul1",
        artesmarciales: "jul1"
      });
      alert(user.uid)
  

      firebase
      .database()
      .ref("clicks/" + user.uid)
      .set({
        clicks: 0
      });

    // firebase
    //   .database()
    //   .ref("quest-progress/" + user.uid)
    //   .set({
    //     level: 1,
    //     playedToday:false
    //   });

    // firebase
    //   .database()
    //   .ref("street-progress/" + user.uid)
    //   .set({
    //     balance:0, 
    //     active: false,
    //     playedToday: false
    // });


    this.props.setUser(user, 0, this.state.name);

    this.props.handleStartApp();
  }

  handleChange(event) {
    switch (event.target.placeholder) {
      case "Nombre del Alumno":
        this.setState({
          name: event.target.value
        });
        break;
      case "Usuario":
        this.setState({
          email: event.target.value
        });
        break;
      case "Contraseña":
        this.setState({
          password: event.target.value
        });
        break;
      case "Correo del papá o mamá":
        this.setState({
          parent: event.target.value
        });
        break;
    }
  }

  render() {
    return (
      <div className="form">
        
       
        
        <div>
          <Field
            placeholder="Nombre del Alumno"
            color="field-green"
            onChange={this.handleChange}
            text={this.state.name}
            type="text"
          />

          {/* <Field
            placeholder="Usuario"
            color="field-green"
            onChange={this.handleChange}
            text={this.state.email}
            type="text"
          />

          <Field
            placeholder="Contraseña"
            color="field-green"
            onChange={this.handleChange}
            text={this.state.password}
            type="password"
          /> */}

          <Field
            placeholder="Correo del papá o mamá"
            color="field-green"
            onChange={this.handleChange}
            text={this.state.parent}
            type="email"
          />
        </div>

        <Button
          text="Crear Cuenta"
          color="button-green"
          handleClick={this.createBunch}
          view="logged"
        />
      </div>
    );
  }
}

export default CreateUsername;
