import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;


import './course-view.css'
import CourseTopButton from './course-top-button';
import CourseSessionBox from './course-session-box';
import {Mixpanel} from '../../Mixpanel'

import Chat from '../../assets/images/speech-bubble.png'
import Teacher from '../../assets/images/teacher.png'
import VG2 from '../../assets/images/controll-white-bg.png'
import Robot2 from '../../assets/images/robot-white-bg.png'


class CourseView extends React.Component {

    constructor(){
        super()
        this.state = {
            progress: {
                session0:""
            }, 
            id: ""
        }
    }

    renderCourse = (course)=>{
        document.body.style.backgroundColor = "#F0F0F4";
        document.body.style.backgroundImage = 'none'
        window.scrollTo(0, 0);

        this.props.handleViewChange(course)
        console.log(this.props)
        
        firebase.database().ref(course).once("value", (snap)=> {
            this.setState({
                course: snap.val(), 
                id: course
            })
        })
        

        

        firebase.database().ref("homework-"+course+"/"+this.props.userID).on("value", (snap)=>{
            if(snap.val()) {
                this.setState({
                    progress: snap.val()
                })
                console.log(snap.val())
            }
            else{
                this.setState({
                    progress:{
                        session0:""
                    }
                })
            }
            
        })


        switch(course){
            case "sci":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/DgRaiiC_jLw",
                    courseDescription: "En este curso realizaremos experimentos desde casa mientras aprendemos sobre física y química durante 4 semanas. Regístrate para iniciar con el grupo del próximo lunes"
                })
            break

            case "vg1":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/KjRjlBYcb2s",
                    courseDescription: "En este curso aprendemos las bases de programación mientras creamos nuestro primer videojuego de plataformas."
                })
            break

            case "vg2":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/KjRjlBYcb2s",
                    courseDescription: "Ahora que tenemos las bases de programación vamos a crear 8 minijuegos con mecánicas totalmente diferentes para ver como funcionan nuestros videojuegos favoritos."
                })
            break

            case "finanzas":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/1-Tym5n1Y4g",
                    courseDescription: "En este curso podrás realizar un ejercicio diario que simula una situación de la vida real. Vamos a entender mejor el valor del dinero, practicar como hacer presupuestos y entender como funcionan las inversiones."
                })
            break

            case "cocina":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/gMBE_mZyypI",
                    courseDescription: "En este curso realizaremos diferentes platillos para desayunos, comidas, cenas y postres que podrás disfrutar con tu familia. "
                })
            break

            case "yt":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/Y4y46p-m66U",
                    courseDescription: "En este curso aprendemos con Dalia a crear un canal, crear contenido, desarrollar guiones y editar  videos para Youtube."
                    
                })
            break

            case "robot":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/Y4y46p-m66U",
                    courseDescription: "Los niños aprenden a programar un robot y utilizar sus diferentes sensores durante 4 semanas."
                    
                })
            break

            case "robot2":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/Y4y46p-m66U",
                    courseDescription: "En este módulo 2 aprendemos a utilizar un nuevo Robot de CoderZ con un mejor simulador de físicas"
                })
            break

            case "music":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/iA1dO0H4U6Q",
                    courseDescription: "En este curso aprendemos a crear nuestros propios ritmos y producir nuestras canciones durante 4 semanas"
                })
            break

        }
    }

    
    componentWillMount(){
        document.body.style.backgroundColor = "#F0F0F4";
        document.body.style.backgroundImage = 'none'
        window.scrollTo(0, 0);

        const oldCourses = ["vg1-nov5", "vg1-dic1", "vg1-dic2", "vg1-dic3", "vg1-dic4", "vg1-ene1", "vg1-ene2", "vg1-ene3", "vg1-ene4", "vg1-ene5", "vg1-feb1", "vg1-feb2", "vg1-feb3", "vg1-feb4", "vg1-feb5", "vg1-mara", "vg1-mar1", "vg1-mar2", "vg1-mar3"]

        console.log(this.props)
        if(oldCourses.includes(this.props.child.vg1)){
            firebase.database().ref(this.props.course+"old").once("value", (snap)=> {
                this.setState({
                    course: snap.val(), 
                    id: this.props.course
                })
            })
        }
        else{
            firebase.database().ref(this.props.course).once("value", (snap)=> {
                this.setState({
                    course: snap.val(),
                    id: this.props.course
                })
            })
        }

        

        firebase.database().ref("homework-"+this.props.course+"/"+this.props.userID).on("value", (snap)=>{
            if(snap.val()) {
                this.setState({
                    progress: snap.val()
                })
            }
            
        })

        switch(this.props.course){
            case "sci":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/DgRaiiC_jLw",
                    courseDescription: "En este curso realizaremos experimentos desde casa mientras aprendemos sobre física y química durante 4 semanas. Regístrate para iniciar con el grupo del próximo lunes"
                })
            break

            case "vg1":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/KjRjlBYcb2s",
                    courseDescription: "En este curso aprendemos las bases de programación mientras creamos nuestro primer videojuego de plataformas."
                })
            break

            case "vg2":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/KjRjlBYcb2s",
                    courseDescription: "Ahora que tenemos las bases de programación vamos a crear 8 minijuegos con mecánicas totalmente diferentes para ver como funcionan nuestros videojuegos favoritos."
                })
            break

            case "finanzas":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/1-Tym5n1Y4g",
                    courseDescription: "En este curso podrás realizar un ejercicio diario que simula una situación de la vida real. Vamos a entender mejor el valor del dinero, practicar como hacer presupuestos y entender como funcionan las inversiones."
                })
            break

            case "cocina":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/gMBE_mZyypI",
                    courseDescription: "En este curso realizaremos diferentes platillos para desayunos, comidas, cenas y postres que podrás disfrutar con tu familia. "
                })
            break

            case "yt":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/Y4y46p-m66U",
                    courseDescription: "En este curso aprendemos con Dalia a crear un canal, crear contenido, desarrollar guiones y editar  videos para Youtube."
                    
                })
            break

            case "robot":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/Y4y46p-m66U",
                    courseDescription: "Los niños aprenden a programar un robot y utilizar sus diferentes sensores durante 4 semanas."
                    
                })
            break

            case "robot2":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/Y4y46p-m66U",
                    courseDescription: "En este módulo 2 aprendemos a utilizar un nuevo Robot de CoderZ con un mejor simulador de físicas"
                })
            break

            case "music":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/iA1dO0H4U6Q",
                    courseDescription: "En este curso aprendemos a crear nuestros propios ritmos y producir nuestras canciones durante 4 semanas"
                })
            break
            case "web":
                this.setState({
                    courseVideo: "https://www.youtube.com/embed/4ngPj8cHKro",
                    courseDescription: "Los alumnos aprenden a programar mientras se divierten creando sus páginas web durante 4 semanas."
                })
            break
        }
    }

    render(){
        console.log(this.state)

        console.log(this.props)

        let course = this.state.id
        
        var isRegistered
        
        if(this.props.child[course]){
            isRegistered=true
        }
        else{
            isRegistered=false
        }
        

        if(this.state.course && this.state.id){

        var tutorchat = this.state.course.tutorchat
        var groupchat = this.state.course.groupchat

        if(this.props.child.adult){
            tutorchat = "https://wa.me/528119041378"
            groupchat = "https://wa.me/528119041378"
        }


        var courseArray = this.state.course.sessions

        let selectedCourse = this.state.id

        let child = this.props.child


        if(child.school){
            return(
                <div className="course-view-container">
                    {/* <p className="session-locked-title">{this.state.course.name}</p> */}
                    <div className="course-top-buttons">
                        {/* <CourseTopButton
                                text="Chat con Tutor"
                                color="#00CCFF"
                                fill="#00CCFF"
                                img={Chat}
                                handleClick = {()=> {Mixpanel.track('Help Chat'); this.props.handleViewChange("chat")}}
                        /> */}


                        {this.props.course=="vg1" ? 
                            (
                                <CourseTopButton
                                    text="Actividades Extra"
                                    onClick={()=>{alert("www.apple.com")}}
                                    color="#984EFF"
                                    fill="#984EFF"
                                    img={Teacher}
                                    handleClick = {()=> {Mixpanel.track('View Extra');this.props.handleViewChange("extra")}}
                                />
                            )
                            :(
                                <div></div>
                                
                            )
                        }
                    
                    
                    
                    </div>
                    <div className="course-sessions">
                        {courseArray.map((session, index)=>{
                            
                            const sessionNum = "session"+index

                            var complete = false

                            console.log(this.state)

                            if(this.state.progress[sessionNum]){
                                complete=true
                            }

                            return(
                                <CourseSessionBox
                                    complete={complete}
                                    progress={this.state.progress[sessionNum]}
                                    // text={session.title}
                                    session={session}
                                    sessionIndex={index}
                                    handleSessionView={this.props.handleSessionView}
                                    
                                    
                                />
                            )

                        })}
                        {this.state.id == "vg1" &&
                            <div className="new-module" onClick={()=>{this.props.handleCourseChange("vg2")}}>
                                <img className="new-module-img" src={VG2}></img>
                                <p className="new-module-name"> Módulo 2</p>
                            </div>
                        }

                        {this.state.id == "vg2" &&
                            <div className="new-module" onClick={()=>{this.props.handleCourseChange("vg3")}}>
                                <img className="new-module-img" src={VG2}></img>
                                <p className="new-module-name"> Módulo 3</p>
                            </div>
                        }

                        {this.state.id == "robot" &&
                            <div className="new-module" onClick={()=>{this.props.handleCourseChange("robot2")}}>
                                <img className="new-module-img" src={Robot2}></img>
                                <p className="new-module-name"> Módulo 2</p>
                            </div>
                        }

                    </div>
                    

                </div>
            )
        }

        if(!child[selectedCourse]){
            return(
                <div className="course-view-container">

                    <div className="course-description">
                        
                        <iframe className="video-course" src={this.state.courseVideo} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                        <div className="course-description-left">
                        <p className="course-description-text">{this.state.courseDescription}</p>
                            
                            <CourseTopButton
                                text="Regístrate"
                                color="#3DD869"
                                fill="#3DD869"
                                img={Teacher}
                                handleClick = {()=> {this.props.handleCourseBuy("buy-course")}}
                            />
                        </div>

                    </div>

                    <div className="course-sessions">
                        {courseArray.map((session, index)=>{
                            
                            const sessionNum = "session"+index

                            var complete = false

                            console.log(this.state)

                            if(this.state.progress[sessionNum]){
                                complete=true
                            }

                            return(
                                <CourseSessionBox
                                    complete={complete}
                                    progress={this.state.progress[sessionNum]}
                                    // text={session.title}
                                    session={session}
                                    sessionIndex={index}
                                    handleSessionView={()=>{alert("Regístrate al curso para poder acceder a esta clase")}}
                                    
                                    
                                />
                            )

                        })}
                    </div>
                </div>

            )
        }

        console.log(courseArray)
            return(
                <div className="course-view-container">
                    {/* <p className="session-locked-title">{this.state.course.name}</p> */}
                    <div className="course-top-buttons">
                        {/* <CourseTopButton
                                text="Chat con Tutor"
                                color="#00CCFF"
                                fill="#00CCFF"
                                img={Chat}
                                handleClick = {()=> {Mixpanel.track('Help Chat'); this.props.handleViewChange("chat")}}
                        /> */}


                        {this.props.course=="vg1" ? 
                            (
                                <CourseTopButton
                                    text="Actividades Extra"
                                    onClick={()=>{alert("www.apple.com")}}
                                    color="#984EFF"
                                    fill="#984EFF"
                                    img={Teacher}
                                    handleClick = {()=> {Mixpanel.track('View Extra');this.props.handleViewChange("extra")}}
                                />
                            )
                            :(
                                <div></div>
                                
                            )
                        }
                    
                    
                    
                    </div>
                    <div className="course-sessions">
                        {courseArray.map((session, index)=>{
                            
                            const sessionNum = "session"+index

                            var complete = false

                            console.log(this.state)

                            if(this.state.progress[sessionNum]){
                                complete=true
                            }

                            return(
                                <CourseSessionBox
                                    complete={complete}
                                    progress={this.state.progress[sessionNum]}
                                    // text={session.title}
                                    session={session}
                                    sessionIndex={index}
                                    handleSessionView={this.props.handleSessionView}
                                    
                                    
                                />
                            )

                        })}
                        {this.state.id == "vg1" &&
                            <div className="new-module" onClick={()=>{this.props.handleCourseChange("vg2")}}>
                                <img className="new-module-img" src={VG2}></img>
                                <p className="new-module-name"> Módulo 2</p>
                            </div>
                        }

                        {this.state.id == "vg2" &&
                            <div className="new-module" onClick={()=>{this.props.handleCourseChange("vg3")}}>
                                <img className="new-module-img" src={VG2}></img>
                                <p className="new-module-name"> Módulo 3</p>
                            </div>
                        }

                        {this.state.id == "robot" &&
                            <div className="new-module" onClick={()=>{this.props.handleCourseChange("robot2")}}>
                                <img className="new-module-img" src={Robot2}></img>
                                <p className="new-module-name"> Módulo 2</p>
                            </div>
                        }

                    </div>
                    

                </div>
            )
        }
        else{
            return(
                <div></div>
            )
        }
        
    }
    
}

export default CourseView