
import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database'; 
import ActivityBox from './activity-box'
import './activity-box.css'
import NavBar from '../../components/navigation/nav-bar'

class CurricularActivities extends React.Component {
    constructor(){
        super()

        this.state={
            activities: {},
            uploadedActivities: {},
            objectives: {},
            tests: {}
        }
    }

    componentDidMount(){
        
        
      
        firebase.firestore().collection("LMS/"+this.props.subject+"/"+this.props.grade).doc(this.props.topic).get().then(doc=>{
            
            this.setState({topic:doc.data()})
        })


        
        
        firebase.firestore().collection("LMS/"+this.props.subject+"/"+this.props.grade+"/"+this.props.topic+"/actividades").onSnapshot((snap)=>{
            

            let activities = {}
            snap.docs.forEach(doc=>{
                activities[doc.id]=doc.data()
            })

            this.setState({activities})

        })
        console.log(this.props.child.currentObjective)
        firebase.firestore().collection("students/"+this.props.uid+"/objectives/"+this.props.child.currentObjective+"/activities").get().then(snap=>{
            
            let objectives={}
            snap.docs.forEach(doc=>{
                objectives[doc.id]=doc.data()
            })
            console.log(objectives)
            this.setState({objectives})
        })



        firebase.firestore().collection("students/"+this.props.uid+"/uploads").onSnapshot((snap)=>{

            let uploadedActivities = {}
            snap.docs.forEach(doc=>{
                uploadedActivities[doc.id]=doc.data()
            })

            this.setState({uploadedActivities})


        })


        firebase.firestore().collection("tests/"+this.props.subject+"/"+this.props.topic).onSnapshot((snap)=>{
            

            let tests = {}
            snap.docs.forEach(doc=>{
                tests[doc.id]=doc.data()
            })

            this.setState({tests})

        })


    }

    // addActivity = (activity)=>{
       
    //     const act = this.state.activities[activity]

    //     const timestamp = +new Date()

    //     var task = act
    //     task.status="undone"
    //     task.timestamp=timestamp
    //     task.name=act.nombre


    //     const date = new Date()
    //     var day = date.getDate()
    //     var month = date.getMonth() + 1
    //     var year = date.getFullYear()


    //     const dateFormat = day+"-"+month+"-"+year
        
        
    //     firebase.firestore().collection("students/uid/agenda").doc(dateFormat).get().then(doc=>{
            
    //         var agenda = doc.data()
           
    //         agenda[activity] = task

    //         firebase.firestore().collection("students/uid/agenda").doc(dateFormat).update(agenda)
    //     }).catch(err=>{

    //         var agenda = {}
    //         agenda[activity] = task
    //         firebase.firestore().collection("students/uid/agenda").doc(dateFormat).set(agenda)
    //     })

        
    // }

    toggleExtra=()=>{
        const currentState = this.state.extraToggle

        this.setState({
            extraToggle: !currentState
        })
    }


    render(){
        const activities = this.state.activities
        console.log(activities)
        const topic = this.state.topic
        var bgColor = "#F42F54"
        var actAmount = 0
        var grades = 0
        var gradedActivities = 0

        if(this.state.extraToggle){
            bgColor = "#111E6B"
        }
        return(
            <div>
                <NavBar
                    handleBack={this.props.handleBack}
                    backView="lms"
                    title="Actividades del Tema"
                />

                {/* <div className="top-right-activities-btn" onClick={()=>{alert("change view")}}>
                    <button className="other-activities-btn" >Actividades Extra</button>
                </div> */}

                <div style={{backgroundColor: bgColor}} onClick={()=>{this.toggleExtra()}} className="extra-toggle-btn">
                    <p>Ver Todos</p>
                </div>

                {topic &&
                    <p className="curricular-activities-topic">{topic.name}</p>
                }
                
                <div className="curricular-activities-view">
                    
                    {Object.keys(activities).map(key=>{
                        console.log(key)
                        console.log(this.state.uploadedActivities[key])

                        if(this.state.extraToggle){
                            actAmount=1
                            const activity = activities[key]
                            if(this.state.uploadedActivities[key]){
                                if(this.state.uploadedActivities[key].score){
                                    grades += this.state.uploadedActivities[key].score
                                    gradedActivities += 1
                                }
                            }
                           
                            return(
                                <div>
                                    
                                    <ActivityBox
                                        name={activity.nombre}
                                        uploadedActivity={this.state.uploadedActivities[key]}
                                        addActivity={this.props.addActivity}
                                        activity_key={key}
                                        activity={activity}
                                        backView="curricular-activities"
                                        viewCurricularActivity={this.props.viewCurricularActivity}
                                    />
                                </div>
                            )
                        }
                        else if(this.state.objectives[key]){
                            const activity = activities[key]
                            actAmount+=1

                            if(this.state.uploadedActivities[key]){
                                if(this.state.uploadedActivities[key].score){
                                    grades += this.state.uploadedActivities[key].score
                                    gradedActivities += 1
                                }
                            }

                            return(
                                <div>
                                    
                                    <ActivityBox
                                        name={activity.nombre}
                                        uploadedActivity={this.state.uploadedActivities[key]}
                                        addActivity={this.props.addActivity}
                                        activity_key={key}
                                        activity={activity}
                                        backView="curricular-activities"
                                        viewCurricularActivity={this.props.viewCurricularActivity}
                                    />
                                </div>
                            )
                        }
                    })}
                    {actAmount === 0 &&
                        <p className="agenda-view-title">No tiene actividades de este tema asignados a sus objetivos.</p>
                    }

                    <p className="agenda-view-title">Grade: {grades/gradedActivities}%</p>

                    {Object.keys(this.state.tests).map(quiz_id=>{
                    
                        return(
                            <div className='quiz-box' onClick={''}>
                                
                                <ActivityBox
                                        name={this.state.tests[quiz_id].name}
                                        uploadedActivity={''}
                                        addActivity={this.props.addActivity}
                                        activity_key={quiz_id}
                                        activity={this.state.tests[quiz_id]}
                                        backView="curricular-activities"
                                        viewCurricularActivity={()=>{this.props.viewCurricularActivity}}
                                />
                            </div>
                        )
                    
                    })}

                    {/* <button className='create-quiz' onClick={()=>{this.props.viewQuizBuilder(this.props.subject, this.props.topic)}}>Crear Quiz</button> */}
                    
                </div>
            </div>
        )
    }
}

export default CurricularActivities