import React from 'react';
import './street-nav.css';
import Quit from '../../assets/images/left.png'
import MarketIcon from '../../assets/images/market.png'
import PortfolioIcon from '../../assets/images/portfolio.png'
import NewsIcon from '../../assets/images/news.png'

const StreetNav = (props) => {
    return(
        <div className="street-nav">

        <div className="street-nav-left">
            <div 
                className="back-left"
                onClick={props.cashOutModal}
            >
                <img className="back"src={Quit}></img>
                
            </div>

            <div className="hud-left">
                <div className="street-round">
                    Round: {props.round}
                </div>
                <div className="street-timer">
                    Time: {props.timer}
                </div>
            </div>

        </div>

        <div className="street-nav-right">
            
            <div className="street-balance">
                Balance: ${props.balance}
            </div>

            {/* <button 
                className="withdraw-btn"
                onClick={props.cashOutModal}
            
            >Retirar Fondos</button> */}
            
            <div className="street-tabs">
                <div onClick={()=>props.handleTabChange("market")}>
                    <img src={MarketIcon} className="street-tab"></img>
                </div>
                
                <div onClick={()=>props.handleTabChange("portfolio")}>
                    <img src={PortfolioIcon} className="street-tab"></img>
                </div>

                <div onClick={()=>props.handleTabChange("news")}>
                    <img src={NewsIcon} className="street-tab"></img>
                </div>
                
            </div>
        </div>

    </div>
    )
    
}

export default StreetNav;