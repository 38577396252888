import React from 'react';

import './gourmet-nav.css'
import GourmetTab from './gourmet-tab';

import Kitchen from '../../assets/images/food.png'
import Market from '../../assets/images/shopping-basket.png'
import Menu from '../../assets/images/cook-book.png'
import Cash from '../../assets/images/cash.png'
// import Quit from 'https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/quit-cross.png?alt=media&token=04ad50b8-1b1e-43c2-94df-396aca65202c'

const GourmetNav = (props) => {

    return(
        <div className="gourmet-nav">
            {/* Quit */}
            <div className="quit" onClick={()=>props.handleQuit()}>
                <img className="quit" src={"https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/quit-cross.png?alt=media&token=04ad50b8-1b1e-43c2-94df-396aca65202c"}></img>
            </div>

            

            <div className="gourmet-tabs">

                {/* menu */}
                <div onClick={(e)=>props.gourmetViewChange("menu")}>
                    
                    <GourmetTab
                        color="#00CCFF"
                        name="Menu"
                        img={Menu}

                    />
                </div>

                {/* market */}
                <div onClick={(e)=>props.gourmetViewChange("market")}>
                    
                    <GourmetTab
                        color="#3DD869"
                        name="Mercado"
                        img={Market}

                    />
                </div>

                {/* kitchen */}
                <div onClick={(e)=>props.gourmetViewChange("kitchen")}>
                    
                    <GourmetTab
                        color="#984EFF"
                        name="Cocina"
                        img={Kitchen}

                    />
                </div>

            </div>
            <div className="cash-container">
            
                <img className="cash-img" src={Cash}></img>
                <p className="cash">{props.cash}</p>
            
            </div>
            

        </div>
    )
}

export default GourmetNav;