import React from 'react';
import StreetView from './street-view';
import StreetNav from './street-nav';
import StreetTabBar from './street-tab-bar';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import DialogueModal from './dialogue-modal';
import PrizeModal from '../prize/prize-modal';
import {Mixpanel} from '../../Mixpanel'
import axios from 'axios';
import Instructions from './instructions';

import img1 from '../../assets/images/street-img1.png'
import img2 from '../../assets/images/street-img2.png'
import img3 from '../../assets/images/street-img3.png'




class Street extends React.Component {

    constructor(){
        super()

        this.state = {
            streetPlay: 0,
            tab: "market",
            balance: 100,
            market: "",
            news: "",
            portfolio: "", 
            timer: 40,
            round: 1, 
            active: false,
            modal: false,
            options: "", 
            dialogue:"", 
            prize: -1, 
            finish: false, 
            stockArray: [], 
            scene: 0,
            instructions: [
                {
                    text: "Tienes $100 Robins para comprar tarjetas de negocio en el mercado. Lo mejor es que las compres al precio más barato.",
                    img: img1, 
                    btn: "Siguiente"  
                }, 
                {
                    text: "Cada ronda los precios de las tarjetas cambian. Cuando tus tarjetas suban de precio puedes entrar a tu portafolio para venderlas.",
                    img: img2, 
                    btn: "Siguiente"  
                }, 
                {
                    text: "En la sección de noticias encontrarás pistas para saber que pasará en las próximas rondas. Si a la empresa le va mal probablemente su tarjeta baje de precio.",
                    img: img3, 
                    btn: "Empezar"  
                }

            ]
        }

        this.handleTabChange = this.handleTabChange.bind(this)
        this.handleStockPurchase = this.handleStockPurchase.bind(this)
        this.handleStockSell = this.handleStockSell.bind(this)
        this.handleTick = this.handleTick.bind(this)
        this.handleCashOut = this.handleCashOut.bind(this)
        this.activateNewGame = this.activateNewGame.bind(this)
        this.handleTimeOut = this.handleTimeOut.bind(this)
        this.startNewGame = this.startNewGame.bind(this)
        this.cashOutModal = this.cashOutModal.bind(this)
        this.rules = this.rules.bind(this)

        this.handleTransaction = this.handleTransaction.bind(this)

        var intervalID = 0
    }

    // componentWillMount(){

    //     const that = this

    //     firebase.database().ref("street-levels/street"+this.props.level+"/market").on("value", function(snap){
    //         const market = snap.val()
    //         that.setState({
    //             market: market
    //         })
    //     })

    //     const user = this.props.userID

    //     firebase.database().ref("street-progress/"+user+'/balance').set(0).then(()=>{
    //         firebase.database().ref("street-progress/"+ user).on("value", function(snap){
                
    //             const progress = snap.val()
    
    //             that.setState({
    //                 active: true
    //             })
    
    //             // const portfolio = progress.portfolio
    //             console.log(progress)
    //             if(progress){
    //                 Object.keys(progress.portfolio).map(function(key){
    //                     const stock = that.state.market[progress.portfolio[key]]
    //                     progress.portfolio[key] = stock
    //                 })
    //                 that.setState({
    //                     portfolio: progress.portfolio
    //                 })
    //             }

    //             else{
    //                 that.setState({
    //                     portfolio: ""
    //                 })
    //             }
    
    //         })
    //     })

        

    //     // this.activateNewGame(this.props.userID)

        

    //     firebase.database().ref("street-levels/street"+this.props.level+"/news").on("value", function(snap){
    //         const news = snap.val()
            
            
    //         that.setState({
    //             news: news
    //         })
    //     })
    // }

    componentWillMount(){

        if(this.props.level > 1){ 
            if(!this.props.child.finanzas && !this.props.child.course){
                alert("Debes inscribirte al curso de Finanzas para seguir jugando.")
                this.props.handleViewChange("app")
            }
        }

        this.setState({
            streetPlay: this.props.child.street
        })

        const that = this

        firebase.database().ref("street-levels/street"+this.props.level+"/market").on("value", function(snap){
            const market = snap.val()
            that.setState({
                market: market
            })
        })

        const user = this.props.userID

        firebase.database().ref("street-progress/"+user+'/balance').set(0).then(()=>{
            
            firebase.database().ref("street-progress/"+ user+"/portfolio").on("value", function(snap){
                const portfolio = snap.val()
    
                that.setState({
                    active: true
                })
    
                // const portfolio = progress.portfolio
    
                if(portfolio){
                    Object.keys(portfolio).map(function(key){
                        const stock = that.state.market[portfolio[key]]
                        portfolio[key] = stock
                    })
                    that.setState({
                        portfolio: portfolio
                    })
                }

                else{
                    that.setState({
                        portfolio: ""
                    })
                }
    
            })
        })


        // this.activateNewGame(this.props.userID)

        

        firebase.database().ref("street-levels/street"+this.props.level+"/news").on("value", function(snap){
            const news = snap.val()
            
            
            that.setState({
                news: news
            })
        })
    }

    componentDidMount(){
        this.intervalID = setInterval(this.handleTick, 1000)
        this.props.toggleLoading(false)
        
    }

    componentWillUnmount(){
        clearInterval(this.intervalID);
    }

    activateNewGame(user){
        
        // this.setState({
        //     modal: true, 
        //     options: {
        //         esperar: () => {this.notStartNewGame(user)},
        //         jugar: () => {this.rules(user)}
        //     }, 
        //     dialogue: "Para jugar este juego tienes que invertir $100 Robins. ¿Quieres esperar a juntar más Robins? Podrás recuperar tu dinero al final del juego."
        // })

        this.rules(user)
        
    }

    rules(user){
        this.setState({
            modal: true, 
            options: {
                ok: () => {this.startNewGame(user)}
            },
            dialogue:"En este juego tendrás que comprar tarjetas cuando esten baratas y venderlas caras. Cada Ronda el precio cambiará y habrá nuevas noticias."
        })
    }

    // notStartNewGame(user){
    //     this.setState({
    //         modal: false
    //     })

    //     const that = this

    //     firebase.database().ref("users/"+user+"/balance").once("value", function(snap){
    //         const oldBalance = snap.val()
            
    //         firebase.database().ref("users/"+user).update({
    //             balance: oldBalance + 100
    //         })
            
    //         that.props.handleViewChange("app")
    //     })
    // }

    startNewGame(user){

        this.setState({
            active: true, 
            modal: false
        })

        firebase.database().ref("street-progress/"+user).set({
            balance: 100, 
            active: true
        })
        
    }

    handleTick(){
        
        var timer = this.state.timer
        const round = this.state.round

        if(this.state.active){

            if(round>=10){

                if(!this.state.finish){
                    this.handleTimeOut()

                    this.setState({
                        finish: true
                    })
                }
                
            }
    
            else if(timer>0){
                timer -= 1;
    
                this.setState({
                    timer: timer
                })
            }
    
            
    
            else{
                this.setState({
                    timer: 40,
                    round: this.state.round + 1
                })
            }
        }
    }

    handleTimeOut(){
        
        // firebase.database().ref("street-progress/"+this.props.userID).update({
        //     "playedToday": true
        // })

        const options = {
            ok: () => {this.cashOutModal()}
        }
        this.setState({
            modal: true,
            options: options, 
            dialogue: "Ya pasaron 10 rondas y el mercado va a cerrar. Vuelve mañana para seguir invirtiendo."
        })
    }

    cashOutModal(){

        this.setState({
            modal: false
        })

        if(this.state.portfolio){
            alert("Tienes acciones en tu portafolio. Para poder salir y retirar tu dinero vende todas tus acciones primero.")
        }
        else{
            const options = {
                no: () => {this.handleCloseModal()},
                si: () => {this.handleCashOut()}
            }
    
            this.setState({
                modal: true, 
                options: options,
                dialogue: "¿Quieres salir del juego y retirar el balance que tienes hasta ahora?"
            })
        }
        
    }

    handleCloseModal(){
        this.setState({
            modal: false, 
            options: "", 
            dialogue: ""
        })
    }

    handleCashOut(){

        this.handleCloseModal()

        axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
            var timestamp = data.data.timestamp

            const balance = this.state.balance
            var amount = 0
            var stars = 0


            switch(true){
                case (balance<101):
                    stars = 0
                    amount = 0
                    break;
                case (balance<200): 
                    stars = 1
                    amount = 10
                    break;
                case (balance<350): 
                    stars = 2
                    amount = 15
                    break;
                case (balance>=350):
                    stars = 3
                    amount = 20
                    break;
            }

            // this.setState({
            //     stars: stars, 
            //     prize: amount
            // })
        
            // // Register level progress
            // firebase.database().ref("s-progress/"+this.props.userID+"/"+(this.props.level-1)).set(stars)
            

            // Analytics
            firebase.database().ref("analytics/street/"+timestamp).set(this.props.userID)
            Mixpanel.track('Street Finish');

            //Calificación en skills
            firebase.database().ref("grades/"+this.props.userID+"/inversion").once("value", (snap) => {
                var skillgrades = []

                if(snap.val()){
                    
                    skillgrades = snap.val()
                }
        
                skillgrades.push(stars)
                
                firebase.database().ref("grades/"+this.props.userID+"/inversion").set(skillgrades)

            })



            firebase.database().ref("s-progress/"+this.props.userID+"/"+(this.props.level-1)).once("value",(snap)=>{
              
                const oldGrade = snap.val()
                
                console.log(oldGrade)
                if(stars>oldGrade){
                    // Register level progress
                    firebase.database().ref("s-progress/"+this.props.userID+"/"+(this.props.level-1)).set(stars)
                    
                    this.setState({
                        prize: (stars-oldGrade)*5, 
                        stars: stars
                    })
                }

                else{
                    this.setState({
                        prize: 0, 
                        stars: stars
                    })
                }
            })



            
            this.handleCloseModal

        })

    }

    handleTransaction(){
        const amount = this.state.prize

        var timestamp = +new Date();
        
        //Actualizar Balance y generar transacción
        firebase.database().ref("users/"+this.props.userID+"/balance").once("value", (snap) => {
            var oldBalance = snap.val()
            
            
            firebase.database().ref("transactions/" + this.props.userID).push().set({
                desc: "Street",
                cant: amount,
                type: "add",
                date: timestamp, 
                oldBalance: oldBalance,
            })

            if(oldBalance) {
                firebase.database().ref("users/"+ this.props.userID).update({
                    balance: oldBalance + amount, 
                    street: timestamp
                })
            }
            else{
                this.props.handleViewChange("app")
            }
        })
    }


    handleTabChange(newTab){
        this.setState({
            tab: newTab
        })
    }

    handleStockPurchase(stock, value){
        const user = this.props.userID

        console.log(this.state.stockArray)

        var prevStocks = this.state.stockArray
        prevStocks.push(stock)


        if(this.state.balance>=value){
            firebase.database().ref("street-progress/"+user+'/portfolio').push(stock)


            this.setState({
                balance: this.state.balance - value, 
                stockArray: prevStocks
            })

        }

        
    }

    handleStockSell(stock, value, index){
        const user = this.props.userID


        firebase.database().ref("street-progress/"+user+'/portfolio/'+stock).remove()
        this.setState({
            balance: this.state.balance + value
        })
    }

    nextInstructions = () => {

        const newScene = this.state.scene + 1

        if(newScene >= this.state.instructions.length){
            this.setState({
                streetPlay: 1
            })
        }

        else{
            this.setState({
                scene: newScene
            })
        }
        

        
    }

    render(){

        if(!this.state.streetPlay){
            return(
                <Instructions
                    scene={this.state.instructions[this.state.scene]}
                    nextInstructions={this.nextInstructions}
                />
            )
        }

        if(this.state.active){
            return(
                <div>
                    
                    <StreetNav
                        balance={this.state.balance}
                        handleTabChange={this.handleTabChange}
                        handleViewChange = {this.props.handleViewChange}
                        timer = {this.state.timer}
                        round={this.state.round}
                        cashOutModal={this.cashOutModal}
                    />

                    {this.state.prize==0 &&
                        <PrizeModal
                            amount={this.state.prize}
                            text="No ganaste 😰"
                            handleClick={this.handleTransaction}

                        />
                    }

                    {this.state.prize>=1 &&
                        <PrizeModal
                            amount={this.state.prize}
                            text="¡Ganaste! 😱"
                            handleClick={this.handleTransaction}
                        />
                    }
    
                    <StreetView
                        tab={this.state.tab}  
                        market={this.state.market}
                        portfolio={this.state.portfolio}
                        news={this.state.news}
                        handleStockPurchase={this.handleStockPurchase}
                        handleStockSell={this.handleStockSell}
                        round={this.state.round}
                        timer={this.state.timer}
                        stockArray={this.state.stockArray}
                    />
    
                    <StreetTabBar
                        handleTabChange={this.handleTabChange}
                    />

                    { this.state.modal &&
                        <DialogueModal
                            options={this.state.options}
                            dialogue={this.state.dialogue}
                        />
                    }
                
    
                </div>
            )
        }

        else{
            return(
                <div>
                    { this.state.modal &&
                        <DialogueModal
                            options={this.state.options}
                            dialogue={this.state.dialogue}
                        />

                    }
                </div>
            )
        }
        
    }
}

export default Street;