import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';
import {Mixpanel} from '../../Mixpanel'
import ChatInput from './chat-input';
import Quit from '../../assets/images/left.png'
import axios from 'axios';

import Send from '../../assets/images/send.png'


import File from '../../assets/images/happy-file.png'


import FileUploader from 'react-firebase-file-uploader'



import './chat.css'
import ChatMessage from './chat-message';
import ChatSentImg from './chat-sent-img';


class Chat extends React.Component {
    constructor(){
        super()
    
        this.state = {
            conversation: "", 
            input: "",
            type: "message", 
            selectedFile: null, 
            uploadValue: 0
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSend = this.handleSend.bind(this)
    }

    componentDidMount(){
        
    }


    componentWillMount(){

        console.log(this.props)

        document.body.style.backgroundColor = "#F0F0F4";
        document.body.style.backgroundImage = `none`
        firebase.database().ref(this.props.type+this.props.chatID).on("value", (snap)=>{
            const conversation = snap.val()

            // this.setState({
            //     conversation: conversation
            // })
            console.log(conversation)
        })
        Mixpanel.track('Check Conversation');



        firebase.firestore().collection("chats").doc(this.props.uid).collection("messages").onSnapshot((snap)=>{

            let list = []
            snap.docs.forEach(doc=>{
                // console.log(doc.data())
                list.push(doc.data())
            })

            console.log(list)
            // console.log(list)
            this.setState({
                conversation:list
            })
        })
    }

    handleChange(event){
        this.setState({
            input: event.target.value
        });
    }

    handleSend(){

        const input = this.state.input

        this.setState({
            input: ""
        })

        if(input != ""){
            const newEntry = {
                from: this.props.uid,
                to: "Roger",
                value: input,
                type: "text", 
                img: this.props.child.profile, 
                name: this.props.child.name
            }

            // const response = openai.createCompletion({
            //     model: "text-ada-001",
            //     prompt: "Qué es el adn",
            //     max_tokens: 7,
            //     temperature: 0,
            // })
            

            // console.log(this.props.type)
            axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
                var timestamp = data.data.timestamp
                newEntry.timestamp = timestamp
                firebase.firestore().collection("chats").doc(this.props.uid).collection("messages").doc(timestamp.toString()).set(newEntry)
                firebase.firestore().collection("chat-previews").doc(this.props.uid).set({
                    id: this.props.uid, 
                    name: this.props.child.name,
                    img: this.props.child.profile, 
                    seen: false,
                    preview: newEntry.value, 
                    timestamp: timestamp, 
                    course: this.props.course,
                    session: this.props.sessionIndex+1
                })


                // firebase.database().ref(this.props.type+this.props.chatID+"/"+timestamp).set(newEntry)
        
                // if(this.props.type == "chats/"){
                //     firebase.database().ref("chat-manager/"+this.props.uid).set(timestamp)
                // }
                
            })

       
        }

    }

    fileSelectHandler = (event) =>{
        // console.log(event.target.files[0])
        this.setState({
            selectedFile: event.target.files[0]
        })

        let time = Date.now()

        const file = event.target.files[0]
        const storageRef = firebase.storage().ref('files/'+this.props.uid+"/"+time+file.name)
        const task = storageRef.put(file)

        task.on('state_changed', snapshot =>{
            // console.log("CACA")
            // console.log(snapshot)
            const bytesTransferred= snapshot.bytesTransferred
            const totalBytes= snapshot.totalBytes

            let percentage = (bytesTransferred/totalBytes)*100

            this.setState({
                uploadValue: percentage
            })
        }, (error)=>{
            alert("Error al subir archivo")
        }, ()=>{
            this.setState({
                uploadedFile: task.snapshot.downloadURL
            })

            const fileType = task.snapshot.metadata.contentType
            const downloadURL = task.snapshot.downloadURL
            console.log(fileType)
            
            task.snapshot.ref.getDownloadURL().then((downloadURL)=> {
                console.log('File available at', downloadURL);
                console.log(this.props)
                const newEntry = {
                    from: this.props.uid,
                    to: "tutor",
                    value: downloadURL,
                    type: fileType, 
                    img: this.props.child.profile, 
                    name: this.props.child.name
                }
    
                console.log(this.props.type)
                axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
                    var timestamp = data.data.timestamp
    
                    newEntry.timestamp = timestamp

                    firebase.firestore().collection("chats").doc(this.props.uid).collection("messages").doc(timestamp.toString()).set(newEntry)
    

                    firebase.firestore().collection("chat-previews").doc(this.props.uid).set({
                        id: this.props.uid, 
                        name: this.props.child.name,
                        img: this.props.child.profile, 
                        seen: false,
                        preview: newEntry.value, 
                        timestamp: timestamp, 
                        course: this.props.course,
                        session: this.props.sessionIndex+1,
                        user:this.props.child.email
                    })
                    // firebase.database().ref(this.props.type+this.props.chatID+"/"+timestamp).set(newEntry)
            
                    // if(this.props.type == "chats/"){
                    //     firebase.database().ref("chat-manager/"+this.props.uid).set(timestamp)
                    // }
                    this.setState({
                        input: ""
                    })
                })
            });
            
        })

    }

    onKeyDown=(event)=> {
        if (event.keyCode === 13) {
            console.log('enter')
            this.handleSend()
        }
    }

    fileUploadHandler=()=>{
        axios.post('')
    }

    // scrollToBottom() {
    //     someElement.scrollTop = someElement.scrollHeight;
    //   }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      }
      
    componentDidMount() {
        this.scrollToBottom();
      }
      
    componentDidUpdate() {
        this.scrollToBottom();
    }

    componentWillUnmount(){
        this.cancelNotification();
    }

    cancelNotification(){
        firebase.database().ref("users/"+this.props.uid+"/mail").set(false)
    }

    dummy=(dum)=>{
        console.log(dum)
    }

    render(){

        if(this.state.conversation){
            
            const conversation = this.state.conversation
            return(
                <div>
                    
                    <div className="conversation" id="your_div">

                        {/* <button onClick={()=>{this.props.handleViewChange("group-chat")}} className="btn-chat-grupal">Chat Grupal</button> */}
                    

                        {Object.keys(conversation).map((entry)=>{
                            // console.log(conversation[entry])

                            var shouldBeHidden = false

                            if(conversation[entry].hidden){
                                shouldBeHidden = conversation[entry].hidden
                            }

                            if(conversation[entry].from == this.props.uid){
                                // return(<div>{conversation[entry].value}</div>)

                                if(conversation[entry].type.includes("image")){
                                    return(
                                        <ChatSentImg
                                                value={conversation[entry].value}
                                                img={this.props.child.profile}
                                                kind="outgoing"
                                                name={this.props.child.name}
                                                shouldBeHidden={shouldBeHidden}
                                                
                                                previous={conversation[entry-1]}
                                                from={conversation[entry].from}
                                                msg={conversation[entry]}

                                                reportMessage={this.dummy}

                                                id={entry}
                                                key={entry}
                                        />   
                                    )
                                }
                                else{
                                    return(
                                        <ChatMessage
                                                value={conversation[entry].value}
                                                img={this.props.child.profile}
                                                kind="outgoing"
                                                name={this.props.child.name}
                                                shouldBeHidden={shouldBeHidden}
                                                
                                                previous={conversation[entry-1]}
                                                from={conversation[entry].from}
                                                
                                                msg={conversation[entry]}
                                                reportMessage={this.dummy}

                                                id={entry}
                                                key={entry}
                                            /> 
                                    )
                                }
                                
                            }
                            else {
                                if(conversation[entry].type.includes("image")){
                                    return(
                                        <ChatSentImg
                                                value={conversation[entry].value}
                                                img={"https://robinacademy.com/_next/static/images/roger-28e780e9222b90d056c9112b68f122c9.png"}
                                                kind="robin"
                                                name={conversation[entry].name}
                                                shouldBeHidden={shouldBeHidden}
                                                
                                                previous={conversation[entry-1]}
                                                from={conversation[entry].from}
                                                msg={conversation[entry]}

                                                reportMessage={this.dummy}

                                                id={entry}
                                                key={entry}
                                        />   
                                    )
                                }
                                else{
                                    return(
                                        <ChatMessage
                                                value={conversation[entry].value}
                                                img={"https://robinacademy.com/_next/static/images/roger-28e780e9222b90d056c9112b68f122c9.png"}
                                                kind="robin"
                                                name={conversation[entry].name}
                                                shouldBeHidden={shouldBeHidden}
                                                
                                                previous={conversation[entry-1]}
                                                from={conversation[entry].from}

                                                msg={conversation[entry]}

                                                reportMessage={this.dummy}

                                                id={entry}
                                                key={entry}
                                            /> 
                                    )
                                }

                            }

                        })

                        }

                        <p className="chat-bottom-disclaimer">Recuerda que el profesor se conecta de lunes a viernes de 5 a 6:30pm en hora de la Cd. de México</p>
                        <div style={{ float:"left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                    </div>
                    
                    <div className="chat-bottom">
                        <ChatInput
                            placeholder="Escribe tu mensaje..."
                            onChange={this.handleChange}
                            text={this.state.input}
                            type="text"
                            onKeyDown={this.onKeyDown}
                        />
                        
                        <input class="inputfile" name="file" id="file" type="file" onChange={this.fileSelectHandler} />
                        <label for="file">
                            <img className="upload-btn-img" src={File}></img>
                        </label>
                        <div onClick={this.handleSend} className="chat-send-small">
                            <img src={Send} className="upload-btn-img"></img>
                        </div>
                
                        <button onClick={this.handleSend} className="chat-send">Enviar</button>
                    </div>
                </div>
            )
        }

        else{
            return(
                <div>
                    
                    <div className="conversation" id="your_div">

                        {this.props.type == "chats/" &&

                            <div>

                                <ChatMessage
                                    value={"¡Hola! Soy Roger. Puedes escribirme cualquier duda que tengas, yo me conecto de de 5pm a 6:30pm de Lunes a Viernes para apoyarlos 🤓"}
                                    img={"https://scontent.fntr6-2.fna.fbcdn.net/v/t31.0-8/24273691_704923873047253_121943099010675091_o.png?_nc_cat=111&_nc_ht=scontent.fntr6-2.fna&oh=01d9ff9c6bf52ace0657543da841d818&oe=5D0B937D"}
                                    type="incoming"
                                    msg={[]}
                                />
                            </div>
                            
                        }

                        {this.props.type == "group-chats/" &&

                            <div>

                            <ChatMessage
                                value={"¡Hola! Soy Robin. En este grupo pueden platicar con los compañeros de su grupo 😜"}
                                img={"https://scontent.fntr6-2.fna.fbcdn.net/v/t31.0-8/24273691_704923873047253_121943099010675091_o.png?_nc_cat=111&_nc_ht=scontent.fntr6-2.fna&oh=01d9ff9c6bf52ace0657543da841d818&oe=5D0B937D"}
                                type="robin"
                                name={"Robin"}
                                msg={[]}

                            />

                            <ChatMessage
                                value="También pueden hablar con su maestro o maestra. Si me necesitan ya saben que pueden hablar conmigo en la oficina de correos 😁✌"
                                img={"https://scontent.fntr6-2.fna.fbcdn.net/v/t31.0-8/24273691_704923873047253_121943099010675091_o.png?_nc_cat=111&_nc_ht=scontent.fntr6-2.fna&oh=01d9ff9c6bf52ace0657543da841d818&oe=5D0B937D"}
                                type="robin"
                                name="Robin"
                                msg={[]}

                            />

                            
                            </div>
                        }


                        <div style={{ float:"left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                        
                        
                    </div>
                    
                    <div className="chat-bottom">
                        <ChatInput
                            placeholder="Escribe tu mensaje..."
                            onChange={this.handleChange}
                            text={this.state.input}
                            type="text"
                            onKeyDown={this.onKeyDown}
                        />
                        
                        <input class="inputfile" name="file" id="file" type="file" onChange={this.fileSelectHandler} />
                        <label for="file">
                            <img className="upload-btn-img" src={File}></img>
                        </label>
                        <div onClick={this.handleSend} className="chat-send-small">
                            <img src={Send} className="upload-btn-img"></img>
                        </div>
                
                        <button onClick={this.handleSend} className="chat-send">Enviar</button>
                    </div>
                </div>
            )
        }
        
    }
}

export default Chat;
