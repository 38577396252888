import React, {useState, useEffect} from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import ActivityBox from "./activity-box";

const CommentsViewer = (props)=>{

    const [activities, setActivities] = useState({})

    console.log(props)
    useEffect(()=>{

        firebase.firestore()
            .collection("students/"+props.uid+"/uploads")
            .where("status", "==", "teacher-comment")
            .orderBy("date", "desc")
            .onSnapshot(snap=>{

                let aux = {}
                snap.docs.forEach(doc=>{
                    aux[doc.id]=doc.data()
                })
                setActivities(aux)

            })

        

        

    }, [])

    return(
        <div className="activity-selector-view" >
            <div className="activity-list">
                {Object.keys(activities).map(activity_key=>{
                    let act = activities[activity_key]
                    return(
                        <ActivityBox
                            name={act.nombre}
                            uploadedActivity={act}
                            // addActivity={this.props.addActivity}
                            activity_key={activity_key}
                            activity={act}
                            backView="comments-viewer"
                            viewCurricularActivity={props.viewCurricularActivity}
                        />
                    )
                })}
            </div>
            
        </div>
    )
}

export default CommentsViewer