import React from "react";
import Button from "../layout/button/button";
import Field from "../layout/field/field";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import axios from 'axios'

import './address-form.css'

class AddressForm extends React.Component {
  constructor() {
    super();

    this.state = {
      calle: "",
      colonia: "",
      municipio: "",
      estado: "",
      zip: "",
      ciudad: "",
      estado: "",
      phone: "", 
      display: "true"
    };

  }




  handleChange = (event) =>{
    switch (event.target.placeholder) {
        case "Calle y número":
            this.setState({
            calle: event.target.value
            });
            break;
        
        case "Colonia":
            this.setState({
            colonia: event.target.value
            });
            break;
        case "Código Postal":
            this.setState({
            zip: event.target.value
            });
            break;
        case "Teléfono de Contacto":
            this.setState({
              phone: event.target.value
            });
            break;
        case "Municipio/Ciudad":
            this.setState({
            ciudad: event.target.value
            });
            break;
        case "Estado":
            this.setState({
              estado: event.target.value
            });
            break;
    }
  }

  handlePurchase=()=>{

    var item = this.props.item
    var amount = item.price

    console.log(this.props)

    firebase.database().ref("users/"+this.props.uid+"/balance").once("value", (snap)=>{
      
      var balance = snap.val() 

      console.log(amount)
      console.log(balance)

      if(amount<=balance){

        balance = balance - amount

        

        axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
          var timestamp = data.data.timestamp


          
          var order = {
            client: this.props.child.name,
            clientID: this.props.uid,
            date: timestamp,
            address: this.state,
            img: item.img,
            name: item.name,
            price: item.price,
            status: "ordered",
            url: item.url
          }

          firebase.database().ref("transactions/" + this.props.uid).push().set({
            desc: item.name,
            cant: amount,
            type: "sub",
            date: timestamp
          })

          alert(timestamp)

          firebase.database().ref("orders").push(order)
          alert("¡Gracias por comprar en Robin! Te llegará en menos de dos semanas.")
        
          firebase.database().ref("users/"+this.props.uid).update({
            balance: balance
          })
      })
      
      
      }

      else{
        alert("No tienes suficientes Robins")
      }
      
      
    })
  }

  render() {

    // alert("FORM")
    
    console.log(this.props)
    return (
    
    <div className="address-form-container">
        <div>
            <p className="address-form-name">{this.props.item.name}</p>
            <p className="address-form-price">R{this.props.item.price}</p>
            <img className="address-form-img" src={this.props.item.img}></img>
        </div>
        <div className="form">
            <Field
                placeholder="Calle y número"
                color="field-green"
                onChange={this.handleChange}
                text={this.state.calle}
                type="text"
            />
                
            <Field
                placeholder="Colonia"
                color="field-green"
                onChange={this.handleChange}
                text={this.state.colonia}
                type="text"
            />

            <Field
                placeholder="Código Postal"
                color="field-green"
                onChange={this.handleChange}
                text={this.state.password}
                type="text"
            />

            <Field
                placeholder="Teléfono de Contacto"
                color="field-green"
                onChange={this.handleChange}
                text={this.state.phone}
                type="text"
            />

            <Field
                placeholder="Municipio/Ciudad"
                color="field-green"
                onChange={this.handleChange}
                text={this.state.ciudad}
                type="text"
            />

            <Field
                placeholder="Estado"
                color="field-green"
                onChange={this.handleChange}
                text={this.state.estado}
                type="email"
            />

            <div style={{display: this.state.display}}>
              <Button
                  text="Realizar Pedido"
                  color="button-green"
                  handleClick={()=>{this.setState({display: "none"});this.handlePurchase()}}
              />
            </div>
        
        </div>
        
        
      </div>
    )
  }
}

export default AddressForm;
