import React from 'react'

class AuxRenderer extends React.Component {
    
    constructor(){
        super()
    }

    componentWillMount(){
        console.log("AUXXXXX")
        this.props.handleViewChange(this.props.course)
    }
    
    render(){
        return(
            <div>
                .
            </div>
        )
    }
}

export default AuxRenderer