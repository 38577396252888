import React from 'react';
import './chat-message.css'

const ChatSentImg = (props) => {

    var prevFrom = ''

    if(props.previous){
        prevFrom= props.previous.from
    }

    var timeString =""
    if(props.msg.timestamp){


        var date= new Date(props.msg.timestamp)
        // console.log(date.toLocaleDateString())
        // console.log(date.toLocaleTimeString())
        var dateString = date.toString()

        dateString = dateString.split(" ")

        var day = dateString[1]+" "+dateString[2]

        var time = date.toLocaleTimeString()
        var hours = time.split(" ")[0]
        var miday = time.split(" ")[1]

        console.log(hours,miday)

        hours = hours.slice(0,-3)

        timeString = day+" "+hours+" "+miday
    }
    
    return(
        <div>
            {prevFrom != props.from &&
                <div className={"message-first-"+props.kind}>
                    <p className={"message-name-"+props.kind}>{props.name}</p>

                    <p className={"message-timestamp"}>{timeString}</p>
                </div>
            }
            <div className={"chat-message-container " + props.kind}>
            <div className="message-img-container">

                {props.shouldBeHidden ? (
                    <p className="message-text">... ... ...</p>
                ) : (
                    <img src={props.value} className="message-sent-img"></img>
                )
                }
                
            </div>
            <div className="message-sender">
                <img className="sender-img"src={props.img}></img>
            </div>
        </div>
        </div>
        
    )
}

export default ChatSentImg