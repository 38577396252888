import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import StoreItem from './store-item'
// import Left from '../../assets/images/left.png';

import Gift from '../../assets/images/gift.png';
import {Mixpanel} from '../../Mixpanel'
import Robins from '../treehouse/robins';



class Store extends React.Component {

    constructor(){
        super()

        this.state = {
            items: ""
        }
        
    }

   

    componentWillMount(){
        document.body.style.backgroundColor = "#F0F0F4";

        document.body.style.backgroundImage = "none";
        window.scrollTo(0, 0);
        
        firebase.database().ref('store').on("value", (snap) =>{
            const items = snap.val()
            this.setState({
                items: items
            })
        })


        Mixpanel.track('Check Store');
    }

    render(){

      

        return(
            <div className="store">
                {/* <div className="nav-bar">

                    <div className="nav-left">
                        <div
                            className="nav-icon" 
                            onClick={()=>this.props.handleViewChange("app")}
                        >
                        <img className="back"src={Left}></img>
                        </div>
                        <p className="heading">Tienda</p>

                    </div>

                </div> */}
                <div className="referral-code">
                    <p className="referral-text">Invita a tus amigos a los cursos de Robin y cada uno recibirá 750 Robins de regalo.</p>
                
                    <p className="referral-text">Además tendrá un 25% de descuento usando tu código:</p>

                    <div className="code-container">
                        {this.props.child.referral}
                    </div>

                    <div className="referral-bottom">
                        <img className="referral-gift-img" src={Gift}></img>
                        <Robins
                            robins={750}
                        />
                    </div>
                
                </div>
                {
                
                    Object.keys(this.state.items).map((key)=>{
                        const item = this.state.items[key]
                        
                        return(
                            <div key={key}>
                                

                                <StoreItem
                                    name={item.name}
                                    price={item.price}
                                    img={item.img}
                                    item={item}
                                    handlePurchase={this.props.handlePurchase}
                                />

                            </div>
                        )
                    })
                }
                
            </div>
        )
    }
}

export default Store