import React from 'react'

import KidStats from "./kid-stats";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import Left from '../../assets/images/left.png'
import  './treehouse.css'
import Wood from "../../assets/images/wood-texture.png"
import Grass from "../../assets/images/grass.png"


class Treehouse extends React.Component {

    

    constructor(){
        super()

        this.state = {
            skillgrades: "", 
            user: ""
        }
    }

    componentWillMount(){
        document.body.style.backgroundImage = `url(${Wood})`

        firebase.database().ref("grades/"+this.props.user).once("value", (snap)=>{
            this.setState({
                skillgrades: snap.val()
            })
        })

        firebase.database().ref("users/"+this.props.user).once("value", (snap)=>{
            this.setState({
                user: snap.val()
            })
        })
    }

    componentWillUnmount(){
        document.body.style.backgroundImage = `url(${Grass})`
    }
        
    render(){

        

        if(this.state.skillgrades != "" && this.state.user != ""){
            return(
                <div className="treehouse">
                    <div className="nav-left">
                        <div
                            className="nav-icon" 
                            onClick={()=>this.props.handleViewChange("app")}
                        >
                            <img className="back"src={Left}></img>
                        </div>
                    </div>
    

                    <div>
                        <KidStats
                            skillgrades = {this.state.skillgrades}
                            user = {this.state.user}
                        />
                    </div>
                </div>
            )
        }

        else{
            return(
            <div className="nav-left">
                <div
                    className="nav-icon" 
                    onClick={()=>this.props.handleViewChange("app")}
                >
                    <img className="back"src={Left}></img>
                </div>
            </div>
        )
        }
        
    }
}

export default Treehouse;