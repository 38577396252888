import React from 'react'
import './language-areas.css'

const LanguageAreas = (props) =>{
    document.body.style.backgroundColor = '#F0F0F4'
    
    return(
        <div className="language-areas-view">
            <div onClick={()=>{props.viewLanguageActivities("writing")}} className="language-area-box">
                <p className="language-area-title">Writing</p>
            </div>
            <div onClick={()=>{props.viewLanguageActivities("listening")}} className="language-area-box">
                <p className="language-area-title">Listening</p>
            </div>
            <div onClick={()=>{props.viewLanguageActivities("reading")}} className="language-area-box">
                <p className="language-area-title">Reading</p>
            </div>
            {/* <div onClick={()=>{props.viewLanguageActivities("grammar")}} className="language-area-box">
                <p className="language-area-title">Grammar</p>
            </div> */}

            <div onClick={()=>{props.viewLanguageActivities("ellii")}} className="language-area-box">
                <p className="language-area-title">Ellii</p>
            </div>
            <div onClick={()=>{props.handleViewChange("recurrent-activities")}} className="language-area-box">
                <p className="language-area-title">Recurrent Activities</p>
            </div>
        </div>
    )
}

export default LanguageAreas