import React from 'react';
import './instructions.css'

const Instructions = (props) => {
    return(
        <div className="instructions-container">
            <p className="instructions-text">{props.scene.text}</p>
            <img className="instructions-img" src={props.scene.img}></img>
            <button className="instructions-btn" onClick={()=>{props.nextInstructions()}}>{props.scene.btn}</button>
        </div>
    )
}

export default Instructions