import React from 'react'

import Check from '../../assets/images/green-check.png'
import PurpleArrow from '../../assets/images/purple-right-arrow.png'
import TeacherCircle from '../../assets/images/teacher-circle-purple.png'
import CommentCircle from '../../assets/images/comment-circle-yellow.png'
// import PlusCircle from '../../assets/images/plus-circle-gray.png'
import Clock from '../../assets/images/clock-icon.png'
import BerryImg from '../../assets/images/berry-purple.png'


const ActivityBox = (props)=>{
    
    const PlusCircle = "https://polychatstorage.blob.core.windows.net/icons/add-task-dark.png"
    const Check = "https://polychatstorage.blob.core.windows.net/icons/green-check.png"
    const TeacherCircle = "https://polychatstorage.blob.core.windows.net/icons/purple-check.png"
    const CommentCircle = "https://polychatstorage.blob.core.windows.net/icons/comment.png"

    var img = PlusCircle

    const statusImages = {
        "approved": Check, 
        "waiting-approval": TeacherCircle, 
        "teacher-comment": CommentCircle, 
        "pending-upload":PlusCircle
    }

    if(props.uploadedActivity){

        if(props.uploadedActivity){
            if(props.uploadedActivity.status){
                var img = statusImages[props.uploadedActivity.status]
                

                var date= new Date(props.uploadedActivity.date)
                var dateString = date.toString()

                dateString = dateString.split(" ")

                var day = dateString[1]+" "+dateString[2]

                var time = date.toLocaleTimeString()
                var hours = time.split(" ")[0]
                var miday = time.split(" ")[1]

            }
        }

       

    }

    var circleAction = ()=>{props.viewCurricularActivity(props.activity, props.backView)}
    if(img == PlusCircle){
        circleAction = ()=>{props.addActivity(props.activity, props.activity_key)}
    }
    

    
    var points = 0

    var activity = props.activity

    points = activity?.properties?.tiempo?.number

    // if(activity.tiempo){
    

    //     if(activity.tiempo.includes("m")){
    //         const pointsString = activity.tiempo.split("m")

    //         points = parseInt(pointsString[0])
    //     }
    //     else if(activity.tiempo.includes("m")){
    //         const pointsString = activity.tiempo.split("m")

    //         points = parseInt(pointsString[0])*60

    //     }
    //     else{
    //         points = parseInt(activity.tiempo)
    //     }

    // }


    var statusText = {
        "waiting-approval": "Entregado",
        "approved": "Aprobado",
        "teacher-comment": "Comentario"
    }

    if(props.uploadedActivity){
        const status = props.uploadedActivity.status

    }

    var score

    if(props.uploadedActivity){
        score = props.uploadedActivity.score
    }

    

    return(
        <div className="activity-box">
            
            
            <div className="activity-box-left row" >


                <img onClick={circleAction} src={img} className="activity-box-circle-btn"></img>
                


                <p onClick={()=>{props.viewCurricularActivity(props.activity, props.backView, props.activity_key)}} className="activity-box-title">{props.name}</p>
            </div>
        

            {score &&
            
                <p className="activity-box-score">{score}%</p>
            
            }

            {points>0 &&
            <div className='berry-counter disabled-berry-input'>
                <img src={BerryImg} className='berry-counter-img disabled-berry-input'></img>
                <input  className='berry-reward-input' value={points}></input>
            </div>
            // <div className='activity-box-berry-counter'>
            //     <img src={BerryImg} className='berry-counter-img disabled-berry-input'></img>
            //     <input className='berry-reward-input' value={points}></input>
            // </div>
        
            }
            
            {(props.uploadedActivity && day !="Date undefined") &&
                <p className="activity-box-date">{day}</p>
            }

           
            
        </div>
     
    )
}

export default ActivityBox