import React, {useState, useEffect} from 'react'
import AgendaTask from './agenda-task'
import PlusCircleWhite from '../../assets/images/plus-circle-white.png'
import PlusCirclePurple from '../../assets/images/plus-circle-purple.png'
import DatePicker from 'react-datepicker'
import ActivityBuilder from './activity-builder'
import CourseTopButton from '../course/course-top-button'
// import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import CommentCircle from '../../assets/images/comment-circle-yellow.png'
import { FaStar, FaCalendarAlt } from 'react-icons/fa'

import './agenda.css'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;
import BerryImg from '../../assets/images/berry-purple.png'
import '../treehouse/berries'



import Bar from '../treehouse/bar'

class Agenda extends React.Component{


    constructor(){
        super()

        let timestamp = +new Date
        let today = new Date(timestamp)

        today.setHours(0, 0, 0, 0)

        timestamp = +new Date(today)

        this.state = {
            agenda:{},
            date:'', 
            edit: false,
            activityBuilder: false,
            startDate: timestamp,
            endDate: timestamp+(1000*60*60*24)-1, 
            days: [], 
            rating: 0
        }
    }

    componentDidMount(){
        const date = new Date()
        var day = date.getDate()
        var month = date.getMonth() + 1
        var year = date.getFullYear()


        var dateFormat = day+"-"+month+"-"+year
    
        if(this.props.agendaDate){
            dateFormat = this.props.agendaDate
        }

        this.setState({
            date: dateFormat, 
            calendar: +new Date()
        })
        
        this.readDates(this.state.startDate, this.state.endDate, dateFormat)

        firebase.firestore()
        .collection("students/"+this.props.uid+"/effort")
        .doc(dateFormat)
        .onSnapshot(snap => {
            console.log(snap.data())
            if (snap.exists && snap.data()) {
                this.setState({ rating: snap.data().effort || 0 })
            }
        })
        
    }



    

    changeDate = calendarDate =>{
        var date = new Date(calendarDate)
        console.log(date)
        console.log(calendarDate)
        

        var day = date.getDate()
        var month = date.getMonth() + 1
        var year = date.getFullYear()


        const dateFormat = day+"-"+month+"-"+year
    
        this.setState({
            date: dateFormat, 
            calendar: calendarDate
        })

        this.props.receiveAgendaDate(dateFormat)

        firebase.firestore().collection("students/"+this.props.uid+"/agenda").doc(dateFormat).onSnapshot(snap=>{
            
            if(snap.data()){
                this.setState({
                    agenda: snap.data()
                }) 
            }
            else{
                this.setState({
                    agenda: {}
                })
            }

        })
    }

    toggleEdit = () =>{
        this.setState({
            edit: !this.state.edit
        })
    }

    cancelActivityBuilder=()=>{
        this.setState({
            activityBuilder: false
        })
    }

    readDates = (startDate, endDate, dateFormate)=>{
        console.log(this.state)

        // firebase.firestore().collection("students/"+this.props.uid+"/agenda-v3")
        // .where("timestamp", ">=", startDate)
        // .where("timestamp", "<=", endDate)
        // .orderBy("timestamp", "desc").onSnapshot((snap)=>{
            
        //     let days = []
        
        //     snap.docs.forEach(doc=>{
        //         days.push(doc.data())
        //     })
        //     console.log(days)
        //     this.setState({days})

        // })


        const date = new Date(startDate)
        var day = date.getDate()
        var month = date.getMonth() + 1
        var year = date.getFullYear()


        var dateFormat = day+"-"+month+"-"+year

    firebase.firestore().collection("students/"+this.props.uid+"/agenda-v3")
    .doc(dateFormat)
    .get()
    .then((doc) => {
        console.log(dateFormat)
        if (doc.exists) {
            let day = doc.data();
            this.setState({
                days: [day]
            });
        } else {
            this.setState({
                days: []
            });
        }
    })
    .catch((error) => {
        console.log("Error getting document:", error);
    });
    }

    deleteTask = (act, day)=>{



        delete day.activities[act.activity_key]

        firebase.firestore().collection("students/"+this.props.uid+"/agenda-v3").doc(day.dayKey).set(day)


    }


    toggleStatus = (act, day) =>{
        console.log(act, day)
        var newStatus = "done"

        if(act.status === "done"){
            newStatus = "undone"
        }

        act.status = newStatus

        day.activities[act.activity_key] = act

        firebase.firestore().collection("students/"+this.props.uid+"/agenda-v3").doc(day.dayKey).set(day)

        this.readDates(this.state.startDate, this.state.endDate, this.state.date)

    }

    setStartDate = (startDate)=>{

        startDate.setHours(0, 0, 0, 0)

        let start = (new Date(startDate)).getTime()

        this.setState({
            startDate: start
        })

        this.readDates(start, this.state.endDate, this.state.date)
    }

    setEndDate = (endDate)=>{

        endDate.setHours(23, 59, 0, 0)

        let end = (new Date(endDate)).getTime()

        this.setState({
            endDate: end
        })

        this.readDates(this.state.startDate, end, this.state.date)
    }

    // Helper function to get the ordinal suffix
    getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return 'th'; // Covers 11th to 19th
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    }

    render(){
        const agenda = this.state.agenda
        let days = this.state.days
        console.log(this.props)

        var bgColor = "#F42F54"

        if(this.state.edit){
            bgColor = "#111E6B"
        }

        
        let timestamp = +new Date
        let date = new Date(timestamp)
        
        var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

        var totalPoints = 0
        var progressPoints = 0

        // Format the date
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(this.state.startDate));

        // Extract parts and add ordinal suffix to the day
        const [weekday, month, day] = formattedDate.split(' ');
        const dayNumber = parseInt(day, 10);
        const dayWithSuffix = `${dayNumber}${this.getOrdinalSuffix(dayNumber)}`;

            return(
                <div className="agenda-modal-view">

                    

                   {this.state.activityBuilder &&
                                <ActivityBuilder
                                    cancelActivityBuilder={this.cancelActivityBuilder}
                                    uid={this.props.uid}
                                    date={this.state.date}
                                    addTask={this.props.addTask}

                                    addActivity={this.props.addActivity}
                                />
                            }

                    {(this.props.mentor || this.props.homeschooler) &&
                        <div style={{backgroundColor: bgColor}} onClick={()=>{this.toggleEdit()}} className="edit-agenda-btn">
                            <p>Editar</p>
                        </div>
                    }

                    <div className="agenda-tasks-container" >

                    
                        

                        <div className="day">
                        
                            <div onClick={()=>{this.setState({activityBuilder:true})}} className="create-activity-btn">
                                <img className="plus-img" src="https://polychatstorage.blob.core.windows.net/icons/add-task.png"></img>
                                <p>Crear Actividad Nueva</p>
                            </div>

                            
                             
                        
                        {days.map(day=>{


                            let timestamp = +new Date
                            let date = new Date(timestamp)

                            console.log(date)
                            var activities = day.activities
                            if(!activities){
                                activities ={}
                            }
                            console.log(days)
                            console.log(date.toLocaleString('es-MX', options))
                            
                            
                            

                            

                            return(
                                <div style={{width:"100%"}}>

                                    

                        
                                    
                                        {Object.keys(activities).map(key=>{
                                            const task = activities[key]
                                            var points = 0
                                            points = task.activity?.properties?.tiempo?.number? task.activity.properties.tiempo.number : 0
                                        

                                            totalPoints += parseInt(points)

                                            if(task.status == "done"){
                                                progressPoints += parseInt(points)
                                            }
                                            console.log(totalPoints)
                                            


                                            return(
                                                    <AgendaTask
                                                        name={task.name}
                                                        status={task.status}
                                                        deleteTask={this.deleteTask}
                                                        timestamp={key}
                                                        activity={task}
                                                        activity_key={task.activity_key}
                                                        key={key}
                                                        toggleTask={this.toggleTask}
                                                        viewCurricularActivity={this.props.viewCurricularActivity}
                                                        edit={this.state.edit}
                                                        day={day}
                                                        toggleStatus={this.toggleStatus}
                                                    />
                                            )
                                        })}

                                        
                                </div>
                                    
                                    

                            )
                        })}
                    
                        </div>
                        
                    </div>
                    <div className="day-top-container">

                        <div className="top-date-row">
                            
                            

                        <div className="date-display">
                            <p className="formatted-date">{`${weekday} ${month} ${dayWithSuffix}`}</p>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={date => this.setStartDate(date)}
                                customInput={
                                    <FaCalendarAlt
                                        className="calendar-icon"
                                        size={24}
                                    />
                                }
                            />
                        </div>

                            {/* <DatePicker
                                selected={this.state.startDate}
                                onChange={date => this.setStartDate(date)}
                                selectsStart
                                startDate={this.state.startDate}
                                dateFormat="d MMM, yyyy"
                            />  */}

                            
                            <div className="stars-container">
                                    {[...Array(5)].map((star, index) => {
                                        const ratingValue = index + 1
                                        return (
                                            <FaStar
                                                key={index}
                                                className="star"
                                                color={ratingValue <= this.state.rating ? "#FFD100" : "rgb(0 0 0 / 30%)"}
                                                size={25}
                                            />
                                        )
                                    })}
                                </div>
                            
                            
                        </div>
                        
                            {/* <p className="day-title">{date.toLocaleString('es-MX', options)}</p> */}
                            <div className='progress-row' style={{width:"100%"}}>
                                <div className="agenda-progress-bar-bg " style={{backgroundColor: "#CFBE9E"}}>
                                    {progressPoints != 0 &&
                                        <div className="agenda-progress-bar" style={{backgroundColor: "#FCF8D7", width: (progressPoints*100)/totalPoints+"%"}}></div>
                                    }
                                </div>
                                <div className='berry-counter-container'>
                                    <img src={BerryImg} className='berry-counter-img disabled-berry-input'></img>
                                    <p className='berry-counter-text'>{totalPoints}</p>
                                </div>
                                {/* <p className="day-points">{totalPoints}</p> */}

                                

                                <div onClick={()=>{this.props.handleViewChange("comments-viewer")}} className="view-comment-btn">
                                    <img className="plus-img" src="https://polychatstorage.blob.core.windows.net/icons/comment.png"></img>
                                
                                </div> 
                            </div>
                                        
                                  
                        </div>
                </div>
                
            )
        

    }
}

export default Agenda