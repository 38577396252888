import React from 'react'
import AgendaTask from './agenda-task'
import PlusCircleWhite from '../../assets/images/plus-circle-white.png'
import PlusCirclePurple from '../../assets/images/plus-circle-purple.png'
import DatePicker from 'react-datepicker'
import ActivityBuilder from './activity-builder'
import CourseTopButton from '../course/course-top-button'
// import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import CommentCircle from '../../assets/images/comment-circle-yellow.png'

import './agenda.css'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import Bar from '../treehouse/bar'

class Agenda extends React.Component{


    constructor(){
        super()

        let timestamp = +new Date
        let today = new Date(timestamp)

        today.setHours(0, 0, 0, 0)

        timestamp = +new Date(today)

        this.state = {
            agenda:{},
            date:'', 
            edit: false,
            activityBuilder: false,
            startDate: timestamp,
            endDate: timestamp+(1000*60*60*24)-1, 
            days: []
        }
    }

    componentDidMount(){
        document.body.style.backgroundColor = '#F0F0F4'
        document.body.style.backgroundImage = 'none'
        const date = new Date()
        var day = date.getDate()
        var month = date.getMonth() + 1
        var year = date.getFullYear()


        var dateFormat = day+"-"+month+"-"+year
    
        if(this.props.agendaDate){
            dateFormat = this.props.agendaDate
        }

        this.setState({
            date: dateFormat, 
            calendar: +new Date()
        })
        
        this.readDates(this.state.startDate, this.state.endDate, dateFormat)
        
    }


    // toggleTask = (key)=>{
    //     var agenda = this.state.agenda

    //     var newStatus = "done"

    //     if(agenda[key].status === "done"){
    //         newStatus = "undone"
    //     }
        
    //     agenda[key].status = newStatus

    //     firebase.firestore().collection("students/"+this.props.uid+"/agenda").doc(this.state.date).set(agenda)

    // }

    

    changeDate = calendarDate =>{
        var date = new Date(calendarDate)
        console.log(date)
        console.log(calendarDate)
        

        var day = date.getDate()
        var month = date.getMonth() + 1
        var year = date.getFullYear()


        const dateFormat = day+"-"+month+"-"+year
    
        this.setState({
            date: dateFormat, 
            calendar: calendarDate
        })

        this.props.receiveAgendaDate(dateFormat)

        firebase.firestore().collection("students/"+this.props.uid+"/agenda").doc(dateFormat).onSnapshot(snap=>{
            
            if(snap.data()){
                this.setState({
                    agenda: snap.data()
                }) 
            }
            else{
                this.setState({
                    agenda: {}
                })
            }

        })
    }

    toggleEdit = () =>{
        this.setState({
            edit: !this.state.edit
        })
    }

    cancelActivityBuilder=()=>{
        this.setState({
            activityBuilder: false
        })
    }

    readDates = (startDate, endDate, dateFormat)=>{
        console.log(this.state)

        firebase.firestore().collection("students/"+this.props.uid+"/agenda-v3")
        .where("timestamp", ">=", startDate)
        .where("timestamp", "<=", endDate)
        .orderBy("timestamp", "desc").onSnapshot((snap)=>{
            
            let days = []
        
            snap.docs.forEach(doc=>{
                days.push(doc.data())
            })
            console.log(days)
            this.setState({days})

        })
    }

    deleteTask = (act, day)=>{

        // var agenda = this.state.agenda
        // delete agenda[key]
        // firebase.firestore().collection("students/"+this.props.uid+"/agenda").doc(this.state.date).set(agenda)

        

        // console.log(act, day)


        delete day.activities[act.activity_key]

        firebase.firestore().collection("students/"+this.props.uid+"/agenda-v3").doc(day.dayKey).set(day)


    }


    toggleStatus = (act, day) =>{
        console.log(act, day)
        var newStatus = "done"

        if(act.status === "done"){
            newStatus = "undone"
        }

        act.status = newStatus

        day.activities[act.activity_key] = act

        firebase.firestore().collection("students/"+this.props.uid+"/agenda-v3").doc(day.dayKey).set(day)

    }

    setStartDate = (startDate)=>{

        startDate.setHours(0, 0, 0, 0)

        let start = (new Date(startDate)).getTime()
        this.setState({
            startDate: start
        })

        this.readDates(start, this.state.endDate, this.state.date)
    }

    setEndDate = (endDate)=>{

        endDate.setHours(23, 59, 0, 0)

        let end = (new Date(endDate)).getTime()

        this.setState({
            endDate: end
        })

        this.readDates(this.state.startDate, end, this.state.date)
    }


    render(){
        const agenda = this.state.agenda
        const days = this.state.days
        console.log(agenda)

        var bgColor = "#F42F54"

        if(this.state.edit){
            bgColor = "#111E6B"
        }

        

        
            return(
                <div className="agenda-view">
                    {/* <p className="agenda-view-title">¿Qué Voy a Hacer Hoy?</p> */}
                    <div className="datepicker-container">
                        

                    <div className="datepicker">
                        <p className="datepiker-input-text">Desde: </p>
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={date => this.setStartDate(date)}
                            selectsStart
                            startDate={this.state.startDate}
                            dateFormat="d MMM, yyyy"
                        />
                    </div>

                    <div className="datepicker">
                        <p className="datepiker-input-text">Hasta: </p>
                        <DatePicker
                            selected={this.state.endDate}
                            onChange={date => this.setEndDate(date)}
                            selectsEnd
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            minDate={this.state.startDate}
                            dateFormat="d MMM, yyyy"
                        />
                    </div>
                    </div>

                    <div className="agenda-links">
                        <div onClick={()=>{this.setState({activityBuilder:true})}} className="create-activity-btn">
                            <img className="plus-img" src={PlusCirclePurple}></img>
                            <p>Crear Actividad Nueva</p>
                        </div>

                        <div onClick={()=>{this.props.handleViewChange("subject-selector")}} className="add-activity-btn">
                            <img className="plus-img" src={PlusCircleWhite}></img>
                            <p>Agregar del Curriculum</p>
                        </div>

                        <div onClick={()=>{this.props.handleViewChange("comments-viewer")}} className="view-comment-btn">
                            <img className="plus-img" src={CommentCircle}></img>
                            <p>Ver Comentarios</p>
                        </div>
                    </div>

                    {this.state.activityBuilder &&
                        <ActivityBuilder
                            cancelActivityBuilder={this.cancelActivityBuilder}
                            uid={this.props.uid}
                            date={this.state.date}
                            addTask={this.props.addCustomTask}
                        />
                    }
                    


                    <div className="agenda-tasks-container">
                        
                        {days.map(day=>{

                            var date = new Date(day.timestamp)
                            var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
                            var activities = day.activities
                            console.log(days)
                            console.log(date.toLocaleString('es-MX', options))
                            var totalPoints = 0
                            var progressPoints = 0
                            
                            



                            return(
                                <div className="day">
                                    
                                    {Object.keys(activities).map(key=>{
                                        const task = activities[key]
                                        var points = 0
                                        // if(task.tiempo.includes("m")){
                                        //     const pointsString = task.tiempo.split("m")
                                
                                        //     points = parseInt(pointsString[0])
                                        // }
                                        // else if(task.tiempo.includes("h")){
                                        //     const pointsString = task.tiempo.split("h")
                                
                                        //     points = parseInt(pointsString[0])*60
                                
                                        // }
                                        // else{
                                        //     points = parseInt(task.tiempo)
                                        // }

                                        totalPoints += points

                                        if(task.status == "done"){
                                            progressPoints += points
                                        }
                                        console.log(totalPoints)



                                        return(
                                                <AgendaTask
                                                    name={task.name}
                                                    status={task.status}
                                                    deleteTask={this.deleteTask}
                                                    timestamp={key}
                                                    activity={task}
                                                    activity_key={task.activity_key}
                                                    key={key}
                                                    toggleTask={this.toggleTask}
                                                    viewCurricularActivity={this.props.viewCurricularActivity}
                                                    edit={this.state.edit}
                                                    day={day}
                                                    toggleStatus={this.toggleStatus}
                                                />
                                        )
                                    })}
                                    <div className="day-top-container" style={{marginTop:'5px'}}>
                                        <Bar
                                            max={totalPoints}
                                            progress={progressPoints}
                                            color={"#3DD869"}
                                        />

                                        <p className="day-points">{Math.floor(progressPoints*100/totalPoints)}%</p>

                                        
                                    </div>
                                    
                                    <div className="day-top-container">
                                        <p className="day-title">{date.toLocaleString('es-MX', options)}</p>
                                        <p className="day-points">{progressPoints+" / "+totalPoints} pts</p>
                                    </div>
                                    
                                </div>
                            )
                        })}
                    

                    </div>
                </div>
            )
        

    }
}

export default Agenda