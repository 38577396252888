import React from 'react';
import Merchant from './merchant';


const GourmetMarket = (props) => {

    document.body.style.backgroundColor = "#3DD869";
    window.scrollTo(0, 0);
    
    return(
        <div className="market">
            {/* Merchant */}
            {Object.keys(props.market).map((item)=>{
                
                return(
                    <Merchant
                        item={item}
                        price={props.market[item]}
                        ingredientCatalogue = {props.ingredientCatalogue}
                        key={item}
                        buyItem = {props.buyItem}
                    />
                )
                
            })}
            {/* <Merchant
                price={20}
            />

            <Merchant
                price={20}
            />

            <Merchant
                price={20}
            />


            <Merchant
                price={20}
            />


            <Merchant
                price={20}
            /> */}

        </div>

    )
}

export default GourmetMarket;