import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';
import FileUploader from 'react-firebase-file-uploader'
import axios from 'axios';

import {Mixpanel} from '../../Mixpanel'


import './session-view.css'
import CourseTopButton from './course-top-button'
import EvidenceUpload from './evidence-upload';
import Toolbox from '../../assets/images/tools.png'
import Doubt from '../../assets/images/speech-bubble.png'
import UploadImg from '../../assets/images/camera.png'

import Discord from '../../assets/images/discord.png'

import DownArrow from '../../assets/images/down-arrow.png'
import Robins from '../treehouse/robins';
import Button from '../layout/button/button';
import Berries from '../treehouse/berries';
class BerrySession extends React.Component  {
    
    constructor(){
        super()

        this.state = {
            file: "",
            fileURL: "",
            progress: 0, 
            old: false
        }
    }

    componentWillMount() {

        console.log(this.props)
        document.body.style.backgroundColor = "white";

        Mixpanel.track('View Session', {
            session: "session"+this.props.sessionIndex, 
            course: "vg1"
        });
        firebase.database().ref("homework-"+this.props.course+"/"+this.props.userID+"/session"+this.props.sessionIndex).on("value", (snap)=>{
            this.setState({
                homework: snap.val()
            })

            console.log(snap.val())
        })

        console.log(this.props.child[this.props.course])


        var course = this.props.course.replace("ohana/", "")

        const group = this.props.child[course]

        const oldCourses = ["vg1-nov5", "vg1-dic1", "vg1-dic2", "vg1-dic3", "vg1-dic4", "vg1-ene1", "vg1-ene2", "vg1-ene3", "vg1-ene4", "vg1-ene5", "vg1-feb1", "vg1-feb2", "vg1-feb3", "vg1-feb4", "vg1-feb5", "vg1-mara", "vg1-mar1", "vg1-mar2", "vg1-mar3"]

        if(oldCourses.includes(group)){
            this.setState({
                old:true
            })
        }

        if(this.props.child.vg2){
            this.setState({
                old:true
            })
        }

        console.log(this.props.child)

        firebase.database().ref("academy-groups/"+group).once("value",(snap)=>{
            console.log(snap.val())

            this.setState({
                lastSession: snap.val()
            })
        })
    }

    handleUploadStart = () => {

        this.setState({
            progress: 1
        })
    }

    handleUploadSuccess = filename => {

        this.setState({
            file: filename, 
            progress: 100
        })

        firebase.storage().ref("ohana-evidence").child(this.state.file).getDownloadURL()
            .then(url=>{
                this.setState({
                    fileURL: url
                })
            })
        
    }

    handleProgress = progress => {

        this.setState({
            progress:progress
        })
    }

    handleCancelUpload = () => {
        this.setState({
            file: ""
        })
    }

    handlePushToFirebase = () =>{

        Mixpanel.track('Activity Upload', {
            session: "session"+this.props.sessionIndex, 
            course: this.props.course
        });

        Mixpanel.track('Activity '+this.props.sessionIndex);
        
        axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{

            var timestamp = data.data.timestamp

            const homework = {
                title: this.props.session.title,
                correct: false,
                img: this.state.fileURL, 
                name: this.props.child.name,
                email: this.props.child.email,
                user: this.props.userID,
                robins: this.props.session.robins,
                date: timestamp,
                session: "session"+this.props.sessionIndex
                
            }

            firebase.database().ref("homework-"+this.props.course+"/"+this.props.userID+"/session"+this.props.sessionIndex).set(homework)
            
            firebase.database().ref("aux-hw").push(homework)

            firebase.database().ref("users/"+this.props.userID+"/reactivations").set(null)
            // I set the extra timestamp because kids should not deliver homework and extra work on the same day, i guess so they hay more days
            firebase.database().ref("users/"+this.props.userID+"/extra").set(timestamp)

            if(this.props.sessionIndex == 7) {
                firebase.database().ref("users/"+this.props.userID+"/last_upload").set("finished")
            }

            else{
                firebase.database().ref("users/"+this.props.userID+"/last_upload").set(timestamp)
            }

        })
         

            
    }


    render(){
        console.log(this.state)

        const stepsArray = this.props.session.steps
        console.log(this.state.lastSession)
        console.log(this.props.sessionIndex+1)

        if(this.state.lastSession>=this.props.sessionIndex+1 || (this.props.sessionIndex == 0 && this.props.course != 'vg2')){
            var chat = this.props.session.chat

            if(this.props.child.adult){
                chat = "https://wa.me/528119041378"
            }

            // if(this.state.old){
            //     return(
            //         <div className="session-view">
            //             {/* <div>
            //                 <p className="session-locked-title">{this.props.session.title}</p>
            //             </div> */}
    
            //             {/* <div className="steps-container">
            //                 <div style={{backgroundColor:'red', width:'100px', margin:'10px'}}>
    
            //                 </div>
            //             </div> */}
    
    
            //         <div className="session-view-container">
            //             <div className="session-view-left">
    
            //                 {/* {this.props.session.steps.map((value, index) =>{
            //                     return(
            //                         <div>
            //                             <p id="texto" className="session-description">Paso {index+1}</p>
            //                             <iframe className="session-video" src={this.props.session.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            //                             <img style={{height:'50px'}} src={DownArrow}></img>
            //                         </div>
            //                     )
            //                 })} */}
    
            //                 <iframe className="session-video" src={this.props.session.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        
    
            //                 <p className="session-title">{this.props.session.title}</p>
            //                 <p id="texto" className="session-description">{this.props.session.text}</p>
                            
            //                 {/* {document.getElementById("texto").append(this.props.session.text)} */}
            //             </div>
            
            //             <div className="session-view-right">
            //                 <CourseTopButton
            //                     text="Recursos"
            //                     color="#F42F54"
            //                     fill="#F42F54"
            //                     img={Toolbox}
            //                     handleClick = {()=> window.open(this.props.session.files, "_blank")}
            //                 />


            //                 <CourseTopButton
            //                     text="Chat con Tutor"
            //                     color="#00CCFF"
            //                     fill="#00CCFF"
            //                     img={Doubt}
            //                     handleClick = {()=> {Mixpanel.track('Help Chat'); this.props.handleViewChange("chat")}}
            //                 />
                            
        
            //                 {!this.state.homework ?
            //                     <div>
            //                         {this.state.file ? 
                                        
            //                             <EvidenceUpload
            //                                 img={this.state.fileURL}
            //                                 handleCancelUpload={this.handleCancelUpload}
            //                                 handlePushToFirebase={this.handlePushToFirebase}
        
            //                             />
            //                             : <div>
    
            //                                 <label className="file-uploader">
    
            //                                     <CourseTopButton
            //                                         text="Compartir Foto"
            //                                         color="#3DD869"
            //                                         fill="#3DD869"
            //                                         img={UploadImg}
            //                                         className="hahaha"
                                                    
            //                                     />
    
            //                                     <FileUploader
            //                                         hidden
            //                                         accept="image/*"
            //                                         name="image"
            //                                         filename={this.props.userID+"-"+this.props.course+"-Activity"+this.props.sessionIndex}
            //                                         storageRef={firebase.storage().ref("course-evidence")}
            //                                         onUploadStart={this.handleUploadStart}
            //                                         onUploadSuccess={this.handleUploadSuccess}
            //                                         onProgress={this.handleProgress}
            //                                         style={{opacity:'0'}}
            //                                     />
    
                                                
    
            //                                 </label>
                                        
                                            
        
            //                                 {this.state.progress >0 &&
            //                                     <div>
            //                                         <label>Progreso: </label>
            //                                         <p>{this.state.progress}%</p>
            //                                     </div>
            //                                 }
        
                                        
            //                             </div>
            //                         }
            //                     </div>
        
            //                     : <img src={this.state.homework.img} className="homework-img"></img>
                            
            //                 }
        
            //                 <div className="robin-session-container">
            //                     <Robins
            //                         robins={this.props.session.robins}
            //                     />
            //                     <p className="robin-session-text">Recuerden que para ganar los Robins deberán subir una foto o video mostrándome su trabajo de esta clase.</p>
            //                 </div>
    

            //                 <CourseTopButton
            //                     text="Discord"
            //                     color="#984EFF"
            //                     fill="#984EFF"
            //                     img={Discord}
            //                     handleClick = {()=> {Mixpanel.track('Help Chat');window.open(chat, "_blank")}}
            //                 />
                            
                            
    
                            
        
                            
            
            //             </div>
                        
            //         </div>
            //         </div>
            //     )
            // }
            // else{
                return(
                    <div className="session-view">
                        {/* <div>
                            <p className="session-locked-title">{this.props.session.title}</p>
                        </div> */}
    
                        {/* <div className="steps-container">
                            <div style={{backgroundColor:'red', width:'100px', margin:'10px'}}>
    
                            </div>
                        </div> */}
    
    
                    <div className="session-view-container">
                        <div className="session-view-left">
    
                            {this.props.session.steps.map((value, index) =>{
                                return(
                                    <div>
                                        <p id="texto" className="session-title">Paso {index+1}</p>
                                        <iframe className="session-video" src={value} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        {console.log(index)}
                                        {console.log(stepsArray.length)}
                                        {index != (stepsArray.length -1) &&
                                            <img style={{height:'50px'}} src={DownArrow}></img>
                                        }
                                        
                                    </div>
                                )
                            })}
    
                            {/* <iframe className="session-video" src={this.props.session.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        
    
                            <p className="session-title">{this.props.session.title}</p>
                            {<p id="texto" className="session-description">{this.props.session.text}</p>}
                            
                            {document.getElementById("texto").append(this.props.session.text)} */}
                        </div>
            
                        <div className="session-view-right">
                            <CourseTopButton
                                text="Recursos"
                                color="#F42F54"
                                fill="#F42F54"
                                img={Toolbox}
                                handleClick = {()=> window.open(this.props.session.files, "_blank")}
                            />

            
                             {/* <CourseTopButton
                                text="Chat con Tutor"
                                color="#00CCFF"
                                fill="#00CCFF"
                                img={Doubt}
                                handleClick = {()=> {Mixpanel.track('Help Chat'); this.props.handleViewChange("chat")}}
                            /> */}
                            
        
                            {!this.state.homework ?
                                <div>
                                    {this.state.file ? 
                                        
                                        <EvidenceUpload
                                            img={this.state.fileURL}
                                            handleCancelUpload={this.handleCancelUpload}
                                            handlePushToFirebase={this.handlePushToFirebase}
        
                                        />
                                        : <div>
    
                                            <label className="file-uploader">
    
                                                <CourseTopButton
                                                    text="Compartir Foto"
                                                    color="#3DD869"
                                                    fill="#3DD869"
                                                    img={UploadImg}
                                                    className="hahaha"
                                                    
                                                />
    
                                                <FileUploader
                                                    hidden
                                                    accept="image/*"
                                                    name="image"
                                                    filename={this.props.userID+"-"+this.props.course.replace("/","-")+"-Activity"+this.props.sessionIndex}
                                                    storageRef={firebase.storage().ref("ohana-evidence")}
                                                    onUploadStart={this.handleUploadStart}
                                                    onUploadSuccess={this.handleUploadSuccess}
                                                    onProgress={this.handleProgress}
                                                    style={{opacity:'0'}}
                                                />
    
                                                
    
                                            </label>
                                        
                                            
        
                                            {this.state.progress >0 &&
                                                <div>
                                                    <label>Progreso: </label>
                                                    <p>{this.state.progress}%</p>
                                                </div>
                                            }
        
                                        
                                        </div>
                                    }
                                </div>
        
                                : <img src={this.state.homework.img} className="homework-img"></img>
                            
                            }
        
                            <div className="robin-session-container">
                                <Berries
                                    berries={this.props.session.robins}
                                    />
                                <p className="robin-session-text">Recuerden que para ganar Moritas deberán subir una foto o video mostrándome su trabajo de esta clase.</p>
                            </div>
    
                            <CourseTopButton
                                text="Chat Grupal"
                                color="#984EFF"
                                fill="#984EFF"
                                img={Doubt}c
                                handleClick = {()=> {Mixpanel.track('Help Chat');window.open("https://robinacademy.slack.com", "_blank")}}
                            />

    
                            
        
                            
            
                        </div>
                        
                    </div>
                    </div>
                )
            // }

            
        }

        else{
            console.log(this.props)
            console.log(this.props.course)

            var course = this.props.course.replace("ohana/", "")
            console.log(course)
            console.log(this.props.child[course])
            return(
                <div className="session-view-locked">
                    <p className="session-locked-title">{this.props.session.title}</p>
                    
                    {!this.props.child[course] ? 
                    
                    <div className="session-locked-promt">
                        
                        <p className="session-text-centered">Esta clase todavía no está disponible para tu grupo</p> 
                        {/* <Button color="green-btn" onClick={()=>{window.open("https://robinacademy.com", "_blank")}}>Regístrate</Button> */}
                        
                        
                    </div>:
                    <p className="session-locked-text">Esta clase todavía no está disponible para tu grupo.</p>

                    
                    }
                </div>
            )
        }
        
    }
}

export default BerrySession;