import React from 'react'
import AgendaImg from '../../assets/images/checklist.png'
import CallImg from '../../assets/images/video-conference.png'
import Discord from '../../assets/images/discord-purple.png'
import Portfolio from '../../assets/images/portfolio-icon.png'

import './school-options.css'

const SchoolOptions = (props)=>{

    document.body.style.backgroundColor = '#F0F0F4'
    document.body.style.backgroundImage = 'none'

    const openGather=(link)=>{
        if(!link){
            alert("No se te ha asignado grupo de gather aún. Habla con tu mentor para que te asignen uno.")
        }
        else{
            alert("Tu contraseña para la llamada es: fundadores")
            window.open(link, "_blank")
        }
    }

    return(
        <div className="school-options-view">
            
            <div className="school-options-box" onClick={()=>{openGather(props.child.gather)}}>
                <img src={CallImg} className="school-options-img"></img>
                <p>Videollamada</p>
            </div>

            <div className="school-options-box" onClick={()=>{props.handleViewChange("agenda")}}>
                <img src={AgendaImg} className="school-options-img"></img>
                <p>Agenda</p>
            </div>

            <div className="school-options-box" onClick={()=>{props.handleViewChange("student-uploads")}}>
                <img src={Portfolio} className="school-options-img"></img>
                <p>Portafolio</p>
            </div>

            <div className="school-options-box" onClick={()=>{window.open("https://discord.gg/DgrsGfy9Jc", "_blank") }}>
                <img src={Discord} className="school-options-img"></img>
                <p>Discord</p>
            </div>


        </div>
    )
}

export default SchoolOptions