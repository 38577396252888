import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import NavBar from '../navigation/nav-bar'
import ActivityBox from './activity-box'
import LevelSelector from './level-selector'

import './language-activities.css'


class LanguageActivities extends React.Component {
    
    constructor(){
        super()

        this.state={
            activities: {},
            uploadedActivities: {}, 
            level: "beginner"
        }
    }
    
    componentDidMount(){
        console.log(this.props)

        firebase.firestore().collection("curricular-progress").doc(this.props.uid).onSnapshot((snap)=>{

            const progress = snap.data()

            if(progress){
                var subjectProgress = progress[this.props.area]

                if(subjectProgress){

                    this.setState({
                        level: subjectProgress
                    })
                }
            }

        


            firebase.firestore().collection("lms/"+this.props.area+"/"+this.state.level).get()
            .then((querySnapshot) => {
                let activities = {}
                querySnapshot.forEach((doc) => {
                    activities[doc.id]=doc.data()
                });
                this.setState({activities})
                console.log(activities)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });


            firebase.firestore().collection("students/"+this.props.uid+"/uploads/").onSnapshot((snap)=>{

                let uploadedActivities = {}
                snap.docs.forEach(doc=>{
                    uploadedActivities[doc.id]=doc.data()
                })

                this.setState({uploadedActivities})


            })

        })
    }


    handleToggle=(grade)=>{
        const currentState = this.state.gradeToggle

        this.setState({
            gradeToggle: !currentState
        })

        if(grade){

            firebase.firestore().collection("curricular-progress").doc(this.props.uid).get().then(doc=>{

                var progress = doc.data()

                if(!progress){
                    progress = {}
                }

                progress[this.props.area] = grade

                firebase.firestore().collection("curricular-progress").doc(this.props.uid).set(progress)
            })

            
        }
    }

    render(){
        const activities = this.state.activities
        console.log(this.state.topic)
        const topic = this.state.topic
       
        return(
            <div>
                <NavBar
                    handleBack={this.props.handleBack}
                    backView="language"
                    title="Actividades"
                />

                <LevelSelector
                    level={this.state.level}
                    toggle={this.state.gradeToggle}
                    handleToggle={this.handleToggle}
                />


                {topic &&
                    <p className="curricular-activities-topic">{topic.name.upperCase()}</p>
                }
                
                <div className="curricular-activities-view">
                    
                    {Object.keys(activities).map(key=>{

                        const activity = activities[key]
                        return(
                            <div>
                                
                                <ActivityBox
                                    name={activity.nombre}
                                    uploadedActivity={this.state.uploadedActivities[key]}
                                    addActivity={this.props.addActivity}
                                    activity_key={key}
                                    activity={activity}
                                    backView="language-activities"
                                    viewCurricularActivity={this.props.viewCurricularActivity}
                                />
                            </div>
                        )
                    })}

                </div>
            </div>
        )
    }
}

export default LanguageActivities