import React from 'react'

const InventoryTabs = (props)=>{
    return(
        <div>
            {props.type == props.labelType ?
                <div onClick={()=>{props.selectType(props.labelType)}} className="inventory-label-selected">
                    <p> {props.label} </p>
                </div>
                :
                <div onClick={()=>{props.selectType(props.labelType)}} className="inventory-label-unselected">
                    <p> {props.label} </p>
                </div>
            }
        </div>
    )
}

export default InventoryTabs