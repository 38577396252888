import React from 'react'
import './store-item.css'


const StoreItem = (props) => {

    return(
        <div className="store-item">
            <p className="product-name">{props.name}</p>
            <img 
                className="product-img"
                src={props.img}
            ></img>
            <p className="product-price">R{props.price}</p>
            <button className="buy-btn" onClick={()=>props.handlePurchase(props.item)}>Comprar</button>
        </div>
    )

}

export default StoreItem;