import React from 'react';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import BerryStoreItem from './berry-store-item'
import Left from '../../assets/images/left.png';
import {Mixpanel} from '../../Mixpanel'
import NavBar from '../navigation/nav-bar';
import Berries from '../treehouse/berries';


class BerryStore extends React.Component {

    constructor(){
        super()

        this.state = {
            items: ""
        }
        
        this.handlePurchase = this.handlePurchase.bind(this)
    }

    componentWillMount(){
        document.body.style.backgroundColor = "#F0F0F4";
        window.scrollTo(0, 0);

        const that = this
        firebase.database().ref('item-store').on("value", function(snap){
            const items = snap.val()
            that.setState({
                items: items
            })
        })


        Mixpanel.track('Check Berry Store');
    }

    handlePurchase(berryCost, item){
        if(this.props.child.berries>=berryCost){
            firebase.database().ref("users/"+this.props.userID).update({
                berries: this.props.child.berries - berryCost,
            })

            firebase.database().ref("treehouse/"+this.props.userID).once("value", (snap)=>{
                const treehouse = snap.val()

                var inventory = []
                if(treehouse){
                    var inventory = treehouse.inventory

                    if(!inventory){
                        inventory = []
                    }


                }

            
                
                console.log(inventory)

                inventory.push(item)

                firebase.database().ref("treehouse/"+this.props.userID+"/"+"inventory").update(inventory)

                
            
            })
        }

        else{
            alert("Te faltan moritas!")
        }


    }

    render(){
        const that = this
        return(
            <div className="store">
                <NavBar
                    handleBack = {this.props.handleViewChange}
                    backView="app"
                />

                <div className="top-berries">
                    <Berries
                        berries={this.props.child.berries}
                    />
                </div>
                
                {
                
                    Object.keys(this.state.items).map(function(key){
                        const item = that.state.items[key]
                        return(
                            <div key={key}>

                                <BerryStoreItem
                                    item={item}
                                    // price={item.price}
                                    // img={item.img}
                                    handlePurchase={that.handlePurchase}
                                />

                            </div>
                        )
                    })
                }
                
            </div>
        )
    }
}

export default BerryStore;