import React from 'react'
import DatePicker from 'react-datepicker'
import './agenda.css'


class AddActivityModal extends React.Component{

    constructor(){
        super()
        const date = new Date()

        this.state = {
            calendar: date
        }
    }



    changeDate = calendarDate =>{

        var date = new Date(calendarDate)
        var day = date.getDate()
        var month = date.getMonth() + 1
        var year = date.getFullYear()

        const timestamp = +new Date(calendarDate)

        const dateFormat = day+"-"+month+"-"+year

        this.setState({
        selectDate: dateFormat
        })

        this.props.addTask(this.props.activity,dateFormat, timestamp)

    }

    render(){
        return(
            <div className="modal-background">
            <div className="modal-box">
                    <p className="modal-title">
                        Agendar Actividad
                    </p>
                

                {/* {imgArray.map(img=>{
                    return(
                        <img className="modal-img" src={img}></img>
                    )
                    
                })} */}
                <p className="modal-text">
                    Selecciona una fecha para realizar la actividad
                </p>

                <div className="modal-options">

                    <DatePicker
                      selected={this.state.calendar}
                      onChange={date => this.changeDate(date)}
                    />

                    
                    {/* {Object.keys(props.modal.options).map(function(key){
                        return(
                            <button 
                                onClick={props.modal.options[key]}
                                className="modal-option-btn"
                            >{key}</button>
                        )
                        
                    })} */}
                </div>
            </div>
            </div>
        )
    }

}

export default AddActivityModal