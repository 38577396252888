import React from 'react';

import './stock-offer.css'

const StockOffer = (props) => {

    var classname = ""
    if(props.flip){
        classname = "stock-offer swing-in-top-fwd"
    }
    else{
        classname = "stock-offer"
    }

    return(
        <div className={classname}>
            <div className="stock-details">
                <img className="stock-img" src={props.img}></img>
                <p className="stock-range">${props.min} - ${props.max}</p>
                <p className="stock-description"> Lo mínimo que puede valer esta acción es ${props.min} Robins y lo máximo es ${props.max} Robins. Su valor actual es de ${props.price} Robins </p>
                <p className="stock-value-label">Valor:</p>
                <p className="stock-price">${props.price}</p>
            </div>

            <button 
                className="stock-buy-btn"
                onClick={()=>props.handleStockPurchase(props.stock, props.price)}
            
            >Comprar</button>
            

        </div>
    )
}

export default StockOffer;