import React from 'react'
import Robins from '../treehouse/robins'

// import Delete from "../../assets/images/delete.png";
import './chore.css'

const Chore = (props) => {
    return(
        <div className="chore-container">
            <p className="chore-name">{props.name}</p>

            <img className="chore-icon" src={props.img}></img>

            <Robins
                robins={props.robins}
            />

            {/* <div className="chore-bottom-container">
                <img onClick={()=>{props.deleteChore(props.id)}} className="chore-delete" src={Delete}></img>
                <button onClick={()=>{props.payChore(props.id, props.robins)}} className="chore-pay-btn">Pagar</button>
            </div> */}
        </div>
    )
}

export default Chore