import React from 'react' 
import './evidence-upload.css'

const EvidenceUpload = (props) => {
    return(
        <div className="evidence-upload-container">
            <img src={props.img} className="evidence-img"></img>
            <button onClick={props.handleCancelUpload} className="evidence-cancel-btn">Cancelar</button>
            <button onClick={props.handlePushToFirebase} className="evidence-upload-btn">Compartir</button>
        </div>
    )
}

export default EvidenceUpload