import React from 'react';
import './course-session-box.css'

import Check from '../../assets/images/green-check.png'
import TeacherCircle from '../../assets/images/teacher-circle.png'

const GameModuleBox = (props) => {
    console.log(props)


    if(props.progress){
        var style ={
            borderStyle: "solid"
        }
        if(props.progress.correct){
            style.borderColor = "#3DD869"
            style.color = "#3DD869"

        }
        else{
            style.borderColor = "#984EFF"
            style.color = "#984EFF"
        }
        return(
            <div style={style} onClick={()=>{props.handleViewChange(props.module.view, props.game);}} className="course-session-box-container">
                {props.progress.correct ? 
                    (<img style={{position: "relative"}} src={Check} className="session-complete-img"></img>)
                    :(<img style={{position: "relative"}} src={TeacherCircle} className="session-complete-img"></img>) 
                }
                <img src={props.module.img} className="session-img"></img>
                <p className="crouse-session-box-title">{props.module.title}</p>
            </div>
    
    
        )
    }
    else{
        return(
            <div onClick={()=>{props.handleViewChange(props.module.view, props.game);}} className="course-session-box-container">
                
                <img src={props.module.img} className="session-img"></img>
                <p className="crouse-session-box-title">{props.module.title}</p>
            </div>
    
    
        )
    }
    
}

export default GameModuleBox