import React from 'react'

import Mex from '../../assets/images/mex.jpg'
import Nyc from '../../assets/images/nyc.jpg'
import Sha from '../../assets/images/sha.jpg'
import Ldn from '../../assets/images/ldn.jpg'

import './summary-city-box.css'

const SummaryCityBox = (props) =>{

    const market = props.market[props.city]
    var portfolioSummary = props.portfolioSummary
    const players = props.players
    const playerQueue = props.playerQueue
    

    if (!portfolioSummary){
        portfolioSummary = {}
    }

    const cityImg={
        mex: Mex,
        nyc: Nyc, 
        sha: Sha, 
        ldn: Ldn
    }

    return(
        <div className="summary-city-box">
            <p className="city-name">{props.name}</p>
            <div className="summary-city-market">
                <div style={{backgroundImage: "url('"+cityImg[props.city]+"')"}} className="city-box-top">

                {/* <img src={cityImg[props.city]}className="city-box-img"></img> */}
                {Object.keys(players).map(key=>{
                    if(players[key]===props.city){
                        
                        return(
                            <div className="visitor-tag">
                                <img className="visitor-img" src={playerQueue[key].img}></img>
                                <p className="visitor-name">{playerQueue[key].name}</p>
                            </div>
                        )
                    }
                    
                })}
                
                </div>
                <div className="summary-city-stocks">
                    <div className="summary-city-stock-row">
                        <p className="summary-city-stock-name">Empresa</p>
                        <p className="summary-city-stock-range">Rango</p>
                        <p className="summary-city-stock-owned">Tenemos</p>
                    </div>

                    {Object.keys(market).map(key=>{
                        return(
                            <div className="summary-city-stock-row">
                                <p className="summary-city-stock-name">{market[key].name}</p>
                                <p className="summary-city-stock-range">${market[key].min} - ${market[key].max}</p>
                                {portfolioSummary[key] ?
                                    <p className="summary-city-stock-owned">{portfolioSummary[key]} acciones</p>
                                :
                                    <p className="summary-city-stock-owned">0</p>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default SummaryCityBox