import React from 'react';
import ConversationScene from './conversation-scene';
import './dialogue-modal.css'

const DialogueModal = (props) => {
    
    return(
        <div className="dialogue-modal">
            <ConversationScene
                options = {props.options}
                text = {props.dialogue}
            />
        </div>
    )
}

export default DialogueModal;