
import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import ActivityBox from './activity-box'
import './projects-box.css'
import NavBar from '../../components/navigation/nav-bar'
import PlusWhite from '../../assets/images/plus-white.png'
import ProjectBox from './project-box'


class ProjectsLibrary extends React.Component {

    constructor(){
        super()

        this.state={
            activities: {},
            uploadedActivities: {}
        }
    }

    componentDidMount(){
        
        firebase.firestore().collection("projects-library/").onSnapshot((snap)=>{
            

            let activities = {}
            snap.docs.forEach(doc=>{
                activities[doc.id]=doc.data()
            })

            this.setState({activities})

        })


        firebase.firestore().collection("students/"+this.props.uid+"/uploads").onSnapshot((snap)=>{
            let uploadedActivities = {}
            snap.docs.forEach(doc=>{
                uploadedActivities[doc.id]=doc.data()
            })
            this.setState({uploadedActivities})
        })

    }


    render(){
        const activities = this.state.activities
        console.log(activities)
        const topic = this.state.topic
       
        return(
            <div>
                <NavBar
                    handleBack={this.props.handleBack}
                    backView="subject-selector"
                    title="Projectos Extracurriculares"
                />

                <div className="projects-library-view">
                    
                    {Object.keys(activities).map(key=>{

                        const activity = activities[key]
                        return(
                            // <div className="project-box">

                            //     <img src={activity.img} className="project-box-img">
                            //     </img>

                            //     <div className="project-box-bottom">
                            //         <p className="project-title">
                            //             {activity.title}
                            //         </p>
                            //         <img src={PlusWhite} className="activity-box-circle-btn"></img>
                            //     </div>

                                <ProjectBox
                                    name={activity.nombre}
                                    uploadedActivity={this.state.uploadedActivities[key]}
                                    addActivity={this.props.addActivity}
                                    activity_key={key}
                                    activity={activity}
                                    backView="projects-library"
                                    viewCurricularActivity={this.props.viewCurricularActivity} 
                                />

                                
                                
                                
                            // </div>
                        )
                    })}

                </div>
            </div>
        )
    }
}

export default ProjectsLibrary