import React from 'react';
import Skill from './skill'
import './skillgrades.css'

const SkillGrades = (props) => {

    let colors ={
      credito: "#3DD869",
      inversion: "#F42F54",
      ahorro: "#00CCFF", 
      presupuesto: "#984EFF", 
      valor: "#FFD100"
    }

    
    if(props.skillgrades != "" && props.skillgrades != undefined){
        return(
            <div className="skillgrades">
                
                {Object.keys(props.skillgrades).map(skill => {
                    console.log(props.skillgrades[skill])
                    return(
                        <Skill
                            name={skill}
                            key={skill}
                            grades={props.skillgrades[skill]}
                            color={colors[skill]}
                        />
                    )
                })}
            </div>
        )
    }

    else{
        return <div className="skills-placehorder"> No ha completado ninguna actividad para obtener sus resultados</div>
    }

    
}

export default SkillGrades;