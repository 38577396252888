import React from 'react';
import StreetView from './wallstreet-view';
import StreetNav from './wallstreet-nav';
import WallStreetTabs from './wallstreet-tabs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';
import 'firebase/compat/functions';

import DialogueModal from '../street/dialogue-modal';
import PrizeModal from '../prize/prize-modal';
import {Mixpanel} from '../../Mixpanel'
import axios from 'axios';
import Instructions from '../street/instructions';

import img1 from '../../assets/images/street-img1.png'
import img2 from '../../assets/images/street-img2.png'
import img3 from '../../assets/images/street-img3.png'
import CityPicker from './city-picker';
import TeamPicker from './team-picker';




class WallStreet extends React.Component {

    constructor(){
        super()

        this.state = {
            host: false,
            streetPlay: 0,
            tab: "market",
            balance: 100,
            market: "",
            news: "",
            portfolio: "", 
            timer: 60,
            cityTimer: 10,
            round: 1, 
            active: false,
            modal: false,
            options: "", 
            dialogue:"", 
            prize: -1, 
            finish: false, 
            stockArray: [], 
            scene: 0,
            instructions: [
                {
                    text: "Tienes $100 Robins para comprar tarjetas de negocio en el mercado. Lo mejor es que las compres al precio más barato.",
                    img: img1, 
                    btn: "Siguiente"  
                }, 
                {
                    text: "Cada ronda los precios de las tarjetas cambian. Cuando tus tarjetas suban de precio puedes entrar a tu portafolio para venderlas.",
                    img: img2, 
                    btn: "Siguiente"  
                }, 
                {
                    text: "En la sección de noticias encontrarás pistas para saber que pasará en las próximas rondas. Si a la empresa le va mal probablemente su tarjeta baje de precio.",
                    img: img3, 
                    btn: "Empezar"  
                }

            ]
        }

        this.handleTabChange = this.handleTabChange.bind(this)
        this.handleCashOut = this.handleCashOut.bind(this)
        this.activateNewGame = this.activateNewGame.bind(this)
        this.handleTimeOut = this.handleTimeOut.bind(this)
        // this.startNewGame = this.startNewGame.bind(this)
        this.cashOutModal = this.cashOutModal.bind(this)
        this.rules = this.rules.bind(this)

        this.handleTransaction = this.handleTransaction.bind(this)

        var intervalID = 0
    }

    componentWillMount(){

        document.body.style.backgroundColor = "#F0F0F4";
        document.body.style.backgroundImage = `none`



        firebase.database().ref("wallstreet-games/"+this.props.gameID).on("value", (snap)=>{


            const game = snap.val()

            console.log(game)

            if(this.state.game){
                const prevStatus = this.state.game.status
                const currentStatus = game.status
                
                if(prevStatus != currentStatus){
                    if(currentStatus === "round-start"){
                        
                        this.startRound()
                    }
                    if(currentStatus === "change-round"){
                        clearInterval(this.intervalID);
                        if(game.host == this.props.uid){
                            this.cityInterval = setInterval(this.startCityTimer, 1000)
                        }
                    }
                }
            }

            if(game){
                this.setState({
                    game
                })

                console.log(game)
                if(game.host == this.props.uid){
                    this.setState({
                        host: true
                    })
                }
            }
            
            


        })

        // firebase.database().ref("wallstreet-games/street1/status").on("value", (snap)=>{
            
        //     if(snap.val() === "round-start"){
        //         this.startRound()
        //     }
        //     if(snap.val() === "prepare-round"){
        //         clearInterval(this.intervalID);
        //     }
        // })

    }

    componentDidMount(){
        // this.intervalID = setInterval(this.handleTick, 1000)
        this.props.toggleLoading(false)
        
    }

    startCityTimer = () =>{
        var ending = Date.now()+(1000*15)

        var cityTimer = this.state.cityTimer
        
        if(cityTimer>0 && ending>Date.now()){
            
            cityTimer = cityTimer-1
            
            this.setState({
                cityTimer: cityTimer
            })
        }
        else{
            this.startRoundBackend()
            this.setState({cityTimer:15})
        }

    }

    startRound = () =>{
        this.intervalID = setInterval(this.handleTick, 1000)
    }

    componentWillUnmount(){
        clearInterval(this.intervalID);
    }

    activateNewGame(user){

        this.rules(user)
        
    }
    

    rules(user){
        this.setState({
            modal: true, 
            options: {
                ok: () => {this.startRound()}
            },
            dialogue:"En este juego tendrás que comprar tarjetas cuando esten baratas y venderlas caras. Cada Ronda el precio cambiará y habrá nuevas noticias."
        })
    }

    startRoundBackend = ()=>{
        clearInterval(this.cityInterval);
        var players = this.state.game.playerQueue

        var everyoneHasCity = true
        Object.keys(players).map(id=>{
            if (!players[id].currentCity){
                everyoneHasCity = false
            }
        })

        if (everyoneHasCity){
            const startRound = firebase.functions().httpsCallable("startWallstreetRound")
            startRound({gameID: this.props.gameID})
        }
        else{
            alert("No todos han elgido ciudad")
        }

        
    }
    
    // startNewGame(user){

    //     this.setState({
    //         active: true, 
    //         modal: false
    //     })

    //     firebase.database().ref("street-progress/"+user).set({
    //         balance: 100, 
    //         active: true
    //     })
        
    // }

    handleTick =()=>{
        
        var timer = this.state.timer
        const round = this.state.game.currentRound
        const ending = this.state.game.roundEnding
        console.log(timer)
        var numOfRounds = this.state.game.roundValues.length        
        
        if(timer>0 && ending>Date.now()){
            
            timer = Math.max(0,ending - Date.now())

            this.setState({
                timer: timer
            })
        }

        

        else if(this.state.host && ending){
            
            clearInterval(this.intervalID)
            var newRound = {
                currentRound: this.state.game.currentRound + 1, 
                status: "change-round"
            }

            if(this.state.game.currentRound === numOfRounds-1){
                newRound = {
                    currentRound: this.state.game.currentRound + 1, 
                    status: "ending"
                }
            }

            
            firebase.database().ref("wallstreet-games/"+this.props.gameID).update(newRound)
            
        }
    }

    handleTimeOut(){

        const options = {
            ok: () => {this.cashOutModal()}
        }
        this.setState({
            modal: true,
            options: options, 
            dialogue: "Ya pasaron 10 rondas y el mercado va a cerrar. Vuelve mañana para seguir invirtiendo."
        })
    }

    cashOutModal(){

        this.setState({
            modal: false
        })

        if(this.state.portfolio){
            alert("Tienes acciones en tu portafolio. Para poder salir y retirar tu dinero vende todas tus acciones primero.")
        }
        else{
            const options = {
                no: () => {this.handleCloseModal()},
                si: () => {this.handleCashOut()}
            }
    
            this.setState({
                modal: true, 
                options: options,
                dialogue: "¿Quieres salir del juego y retirar el balance que tienes hasta ahora?"
            })
        }
    
    }

    handleCloseModal(){
        this.setState({
            modal: false, 
            options: "", 
            dialogue: ""
        })
    }

    handleCashOut(){

        this.handleCloseModal()

        axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
            var timestamp = data.data.timestamp



            const balance = this.state.game.team1.balance
            var amount = 0
            var stars = 0


            switch(true){
                case (balance<101):
                    stars = 0
                    amount = 0
                    break;
                case (balance<200): 
                    stars = 1
                    amount = 10
                    break;
                case (balance<350): 
                    stars = 2
                    amount = 15
                    break;
                case (balance>=350):
                    stars = 3
                    amount = 20
                    break;
            }

        
            // Analytics
            firebase.database().ref("analytics/street/"+timestamp).set(this.props.userID)
            Mixpanel.track('Street Finish');

            //Calificación en skills
            firebase.database().ref("grades/"+this.props.userID+"/inversion").once("value", (snap) => {
                var skillgrades = []

                if(snap.val()){
                    
                    skillgrades = snap.val()
                }
        
                skillgrades.push(stars)
                
                firebase.database().ref("grades/"+this.props.userID+"/inversion").set(skillgrades)

            })



            firebase.database().ref("s-progress/"+this.props.userID+"/"+(this.props.level-1)).once("value",(snap)=>{
              
                const oldGrade = snap.val()

                if(stars>oldGrade){
                    // Register level progress
                    firebase.database().ref("s-progress/"+this.props.userID+"/"+(this.props.level-1)).set(stars)
                    
                    this.setState({
                        prize: (stars-oldGrade)*5, 
                        stars: stars
                    })
                }

                else{
                    this.setState({
                        prize: 0, 
                        stars: stars
                    })
                }
            })



            
            this.handleCloseModal

        })

    }

    handleTransaction(){
        // const amount = this.state.prize

        // var timestamp = +new Date();
        
        // //Actualizar Balance y generar transacción
        // firebase.database().ref("users/"+this.props.userID+"/balance").once("value", (snap) => {
        //     var oldBalance = snap.val()
            
            
        //     firebase.database().ref("transactions/" + this.props.userID).push().set({
        //         desc: "Street",
        //         cant: amount,
        //         type: "add",
        //         date: timestamp, 
        //         oldBalance: oldBalance,
        //     })

        //     if(oldBalance) {
        //         firebase.database().ref("users/"+ this.props.userID).update({
        //             balance: oldBalance + amount, 
        //             street: timestamp
        //         })
        //     }
        //     else{
        //         this.props.handleViewChange("app")
        //     }
        // })
    }


    handleTabChange(newTab){
        this.setState({
            tab: newTab
        })
    }

    handleStockPurchase = (stock, value)=>{
        const user = this.props.userID

        const team = this.state.game.playerQueue[this.props.uid].currentTeam


        const city = this.state.game[team].players[this.props.uid]
        
        const stockObj = {
            name: stock,
            purchase: value
        }


        if(this.state.game[team].balance>=value){
            firebase.database().ref("wallstreet-games/"+this.props.gameID+"/"+team+"/portfolio/"+city).push(stockObj)


            
            const newBalance = this.state.game[team].balance - value
            firebase.database().ref("wallstreet-games/"+this.props.gameID+"/"+team+"/balance").set(newBalance)

            var newAmount = 1

            if (this.state.game[team].portfolioSummary){
                if(this.state.game[team].portfolioSummary[stock]){
                    newAmount = this.state.game[team].portfolioSummary[stock] + 1
                }
            }
            
            firebase.database().ref("wallstreet-games/"+this.props.gameID+"/"+team+"/portfolioSummary/"+stock).set(newAmount)

        }

        
    }

    handleStockSell = (id, name)=>{
        const uid = this.props.uid

        const team = this.state.game.playerQueue[this.props.uid].currentTeam

        const city = this.state.game.playerQueue[this.props.uid].currentCity

        const value = this.state.game.roundValues[this.state.game.currentRound][name]

        firebase.database().ref("wallstreet-games/"+this.props.gameID+"/"+team+"/portfolio/"+city+"/"+id).remove()
        
        const newBalance = this.state.game[team].balance + value
        
        firebase.database().ref("wallstreet-games/"+this.props.gameID+"/"+team+"/balance").set(newBalance)
    
        var newAmount = 1

        if (this.state.game[team].portfolioSummary){
            if (this.state.game[team].portfolioSummary[name]){
                newAmount = this.state.game[team].portfolioSummary[name] - 1
            }
        }
            
        firebase.database().ref("wallstreet-games/"+this.props.gameID+"/"+team+"/portfolioSummary/"+name).set(newAmount)

    
    }

    nextInstructions = () => {

        const newScene = this.state.scene + 1

        if(newScene >= this.state.instructions.length){
            this.setState({
                streetPlay: 1
            })
        }

        else{
            this.setState({
                scene: newScene
            })
        }
        

        
    }

    pickCity=(city)=>{
        const team = this.state.game.playerQueue[this.props.uid].currentTeam
        const uid = this.props.uid

        firebase.database().ref("wallstreet-games/"+this.props.gameID+"/"+team+"/players/"+uid).set(city)
        
        firebase.database().ref("wallstreet-games/"+this.props.gameID+"/playerQueue/"+uid+"/currentCity").set(city)

    }

    pickTeam=(team)=>{
        if(this.state.game.status != "ending"){

            const player = {
                name: this.props.child.name,
                img: this.props.child.avatar,
                currentTeam: team
            }

            firebase.database().ref("wallstreet-games/"+this.props.gameID+"/playerQueue/"+this.props.uid).set(player)
        
        }
        
    }


    startGame = () =>{

        var players = this.state.game.playerQueue

        var everyoneHasTeam = true
        Object.keys(players).map(id=>{
            if (!players[id].currentTeam){
                everyoneHasTeam = false
            }
        })

        if (everyoneHasTeam){
            firebase.database().ref("wallstreet-games/"+this.props.gameID).update({
                status: "change-round", 
                currentRound: 0
            }) 
        }
        else{
            alert("No todos han elgido equipo")
        }

       
    }

   



    render(){

        

        // if(!this.state.streetPlay){
        //     return(
        //         <Instructions
        //             scene={this.state.instructions[this.state.scene]}
        //             nextInstructions={this.nextInstructions}
        //         />
        //     )
        // }




        if(this.state.game){




            switch(this.state.game.status){

                case "ending":
                    return(
                        <div className="in-queue-view">
                            <p className="teams-text">¡Se acabó el juego!</p>
                            
                            <TeamPicker
                                pickTeam={this.pickTeam}
                                players={this.state.game.playerQueue}
                                game={this.state.game}
                            />
                            {this.state.game.host === this.props.uid &&
                                <button className="start-game-btn" onClick={()=>{this.props.handleViewChange("app")}}>Salir del Juego</button>
                            }
                        </div>
                    )

                case "in-queue":
                    return(
                        <div className="in-queue-view">
                            <p className="teams-text">Invita a tus amigos con el código de juego:</p>
                            <p className="teams-gameID">{this.props.gameID}</p>
                            <p className="teams-text">Haz click en uno de los colores para elegir tu equipo</p>
                            <TeamPicker
                                pickTeam={this.pickTeam}
                                players={this.state.game.playerQueue}
                                game={this.state.game}
                            />
                            
                            {this.state.game.host === this.props.uid &&

                                <button className="start-game-btn" onClick={()=>{this.startGame()}}>Iniciar Juego</button>
                            }

                            <button className="start-game-btn" onClick={()=>{this.props.handleViewChange("app")}}>Salir del Juego</button>
                            
                        </div>
                    )

                case "change-round":
                    
                    return(
                        <div className="change-round-view">
                            
                            <CityPicker
                                pickCity={this.pickCity}
                                // players={this.state.game[team].players}
                                players={this.state.game.playerQueue}
                                uid={this.props.uid}
                                
                            />
                            <p>{this.state.cityTimer}</p>
                            <button className="start-round-btn" onClick={()=>{this.startRoundBackend()}}>Iniciar Ronda</button>

                        </div>
                    )

                case "finished":
                    console.log(this.state)
                    return(
                        <div>
                            <div>Terminó</div>
                            <button onClick={()=>{this.createGame()}}>Create</button>
                        </div>
                    )
    
                case "round-start":
                    const team = this.state.game.playerQueue[this.props.uid].currentTeam

                    return(
                        <div>
                            <StreetNav
                                balance={this.state.game[team].balance}
                                handleTabChange={this.handleTabChange}
                                handleViewChange = {this.props.handleViewChange}
                                timer = {this.state.timer}
                                round={this.state.round}
                                cashOutModal={this.cashOutModal}
                                currentRound={this.state.game.currentRound}

                                city={this.state.game.playerQueue[this.props.uid].currentCity}
                                roundValues={this.state.game.roundValues}
                            />
    
                            {this.state.prize==0 &&
                                <PrizeModal
                                    amount={this.state.prize}
                                    text="No ganaste 😰"
                                    handleClick={this.handleTransaction}
    
                                />
                            }
    
                            {this.state.prize>=1 &&
                                <PrizeModal
                                    amount={this.state.prize}
                                    text="¡Ganaste! 😱"
                                    handleClick={this.handleTransaction}
                                />
                            }
            
                            <StreetView
                                tab={this.state.tab}  
                                market={this.state.game.market}
                                portfolio={this.state.game[team].portfolio}
                                news={this.state.game.news}
                                handleStockPurchase={this.handleStockPurchase}
                                handleStockSell={this.handleStockSell}
                                roundValues={this.state.game.roundValues}
                                currentRound={this.state.game.currentRound}
                                timer={this.state.timer}
                                stockArray={this.state.game.portfolio}
                                city={this.state.game.playerQueue[this.props.uid].currentCity}
                                players={this.state.game[team].players}
                                pickCity={this.pickCity}
                                mexNews={this.state.game.mexNews}
                                nycNews={this.state.game.nycNews}
                                shaNews={this.state.game.shaNews}
                                ldnNews={this.state.game.ldnNews}
                                team={team}
                                portfolioSummary={this.state.game[team].portfolioSummary}
                                playerQueue={this.state.game.playerQueue}
                                news={this.state.game.roundNews}
                            />

                            <WallStreetTabs
                                handleTabChange={this.handleTabChange}
                            />

                            { this.state.modal &&
                                <DialogueModal
                                    options={this.state.options}
                                    dialogue={this.state.dialogue}
                                />
                            }
                        
            
                        </div>
                    )
            }
        }

        else{
            return <div>Cargando Datos...</div>
        }


        
    }
}

export default WallStreet;