import React from 'react';

import '.././gourmet/gourmet-inventory.css'
import './item-inventory.css'
import ItemContainer from './item-container';

import InventoryTabs from './inventory-tabs';

const AvatarInventory = (props) => {

    const dummyArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]

    
    console.log(props)

    return(
        <div className="inventory avatar-inventory">
            <div className="inventory-label-container">
               
                <InventoryTabs
                    selectType={props.selectType}
                    labelType="layer1"
                    type={props.type}
                    label='Piel'
                />

                <InventoryTabs
                    selectType={props.selectType}
                    labelType="layer2"
                    type={props.type}
                    label='Piernas'
                />

                <InventoryTabs
                    selectType={props.selectType}
                    labelType="layer3"
                    type={props.type}
                    label='Cuerpo'
                />

                <InventoryTabs
                    selectType={props.selectType}
                    labelType="layer4"
                    type={props.type}
                    label='Cabello'
                />

                <InventoryTabs
                    selectType={props.selectType}
                    labelType="layer5"
                    type={props.type}
                    label='Accesorios'
                />

            </div>
            

            <section className="item-collection">

                    {props.inventory.map((img)=>{
                        if(props.inventory){
                            return(
                                <ItemContainer
                                    name={img}
                                    img={img}
                                    key={img}
                                    index={img}
                                    type={props.type}
                                    selectItem = {props.selectItem}
                                />
                            )
                        }
                        else{
                            return(
                                <ItemContainer
                                    name={img}
                                    img={''}
                                    key={img}
                                    index={img}
                                    type={props.type}
                                    selectItem = {props.selectItem}
                                />
                            )
                        }
                    
                       
                    })}
                    
                    <ItemContainer
                                    name={'img'}
                                    img={''}
                                    key={'img'}
                                    index={'img'}
                                    type={props.type}
                                    selectItem = {props.selectItem}
                                />

                    

                </section>
            
            {/* Ingredient */}
        </div>

    )
}

export default AvatarInventory;