import React from "react";
import Button from "../layout/button/button";

const Welcome = props => {
  return (
    <div>
      <p className="welcome-text">
        Bienvenido a Robin, la aplicación para aprender a aprender a crear cosas increibles y ganar Robins.
      </p>

      <Button
        text="Crear Cuenta"
        color="button-green"
        handleClick={props.handleChangeWelcomeView}
        view="email"
      />
      <Button
        text="Iniciar Sesión"
        color="button-blue"
        handleClick={props.handleChangeWelcomeView}
        view="login"
      />
      {/* <Button
        text="Ingresa con QR"
        color="button-purple"
        handleClick={props.handleChangeWelcomeView}
        view="qr-login"
      /> */}
    </div>
  );
};

export default Welcome;
