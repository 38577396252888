import React from 'react'
import './grade-selector.css'

const LevelSelector = (props) =>{
    return(
        <div className="level-selector" onClick={()=>{props.handleToggle()}}>

            {props.toggle &&
                <div>
                    
                    <div className="level" onClick={()=>{props.handleToggle(props.level)}}>Level</div>
                    <div className="level" onClick={()=>{props.handleToggle("beginner")}}>Beginner</div>
                    <div className="level" onClick={()=>{props.handleToggle("intermediate")}}>Intermediate</div>
                    <div className="level" onClick={()=>{props.handleToggle("advanced")}}>Advanced</div>
                </div>
                
            }

            {!props.toggle &&
                <div className="selected-level" onClick={()=>{props.handleToggle()}}>
                    <p>{props.level}</p>
                </div>
                
            }
        </div>
    )
}

export default LevelSelector