import React from 'react'
import Bar from '../treehouse/bar'
import Arrow from '../../assets/images/right-purple-arrow.png'

const KhanActBox = (props)=>{

    console.log(props)

    return(
        <div className="khan-act-box">
            <div className="top-act-title">
                <p className="khan-act-title">{props.name}</p>
                <img src={Arrow} className="khan-act-arrow"></img>
            </div>

            <div className="khan-progress">
                <Bar
                    max={props.mastery}
                    progress={props.progress}
                    color={'#3DD869'}
                />
                <p>{Math.round(props.progress*100/props.mastery)}%</p>
            </div>
        </div>
    )
}

export default KhanActBox