import React from 'react';

import './gourmet-inventory.css'
import IngredientContainer from './ingredient-container';

const GourmetInventory = (props) => {

    const dummyArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]



    return(
        <div className="inventory">
            <div className="inventory-label">
                <p> Inventario </p>
            </div>

            <section className="ingredient-collection">

                    {dummyArray.map((index)=>{

                        return(
                            <IngredientContainer
                                name={props.inventory[index]}
                                img={props.ingredientCatalogue[props.inventory[index]]}
                                key={index}
                                index={index}
                                selectIngredient = {props.selectIngredient}
                            />
                        )
                       
                    })}

                    {/* {renderInventory()} */}

                    {/* <IngredientContainer
                        img="https://image.flaticon.com/icons/svg/1155/1155281.svg"
                    />
                    <IngredientContainer
                        img="https://image.flaticon.com/icons/svg/1155/1155281.svg"
                    />
                    <IngredientContainer
                        img="https://image.flaticon.com/icons/svg/135/135702.svg"
                    />
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/>
                    <IngredientContainer/> */}

                </section>
            
            {/* Ingredient */}
        </div>

    )
}

export default GourmetInventory;