import React from 'react';
import MarketIcon from '../../assets/images/market.png'
import PortfolioIcon from '../../assets/images/portfolio.png'
import NewsIcon from '../../assets/images/news.png'
import './street-tab-bar.css';

const StreetTabBar = (props) => {
    return(
        <div className="street-tabs">
                <div onClick={()=>props.handleTabChange("market")}>
                    <img src={MarketIcon} className="street-tab"></img>
                </div>
                <div onClick={()=>props.handleTabChange("portfolio")}>
                    <img src={PortfolioIcon} className="street-tab"></img>
                </div>
                <div onClick={()=>props.handleTabChange("news")}>
                    <img src={NewsIcon} className="street-tab"></img>
                </div>

        </div>
    )
}

export default StreetTabBar;