import React from 'react';
import Teacher from '../../assets/images/teacher.png'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;


import './quizz.css'
import PrizeModal from '../prize/prize-modal';
import Modal from '../gourmet/modal';

class Quiz extends React.Component {
    constructor(){
        super()

        this.state ={
            prize: -1,
            index:0,
            correct:0,
            questions: [
                {
                    question: "",
                    img: "nil",
                    options: {
                        answer: "",
                        op1: "",
                        op2: ""
                    }
                }, 
                {
                    question: "",
                    img: "",
                    options: {
                        answer: "",
                        op1: "",
                        op2: ""
                    }
                },
                {
                    question: "",
                    img: "",
                    options: {
                        answer: "",
                        op1: "",
                        op2: ""
                    }
                },

            ]
        }
    }

    componentWillMount(){

        firebase.database().ref("quizzes/"+this.props.game).once("value", (snap)=>{
            console.log(snap.val())

            const shuffledQuestions = this.shuffle(snap.val())

            
            this.setState({
                questions: shuffledQuestions
            })
        })

        
    }

    shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
          [array[i], array[j]] = [array[j], array[i]]; // swap elements
        }
        return array
        
    }


    nextQuestion(response){
        if(response == this.state.questions[this.state.index].options.answer){
            this.setState({
                correct: this.state.correct+1
            })

        }

        if(this.state.index < this.state.questions.length-1){
            
            this.setState({
                index: this.state.index+1
            })
        }
        else{
            // this.handleFinishQuiz()
            this.setState({
                finish: true
            })
        }

        
        
    }

    handleFinishQuiz =()=>{

        console.log(this.state.correct)
        console.log(this.state.questions.length)
        var grade = this.state.correct / this.state.questions.length *100

        grade = Math.round(grade)
        var stars=0

        switch(true){
            case (grade<=30):
                stars = 0
                break;
            case (grade<=60): 
                stars = 1
                break;
            case (grade<100): 
                stars = 2
                break;
            case (grade==100):
                stars = 3
                break;
        }
        var timestamp = +new Date();
        this.setState({
            finish:false
        })

        alert("Obtuviste "+grade+" de 100")


        const results = {
            name: this.props.child.name, 
            module: this.props.game, 
            grade: grade
        }

        // Register resultado quiz
        firebase.database().ref("quiz-resonses/"+this.props.userID+"/"+timestamp).set(results)
        

        firebase.database().ref(this.props.game+"-progress/"+this.props.userID+"/4").once("value",(snap)=>{
              
            const oldGrade = snap.val()
            
            console.log(oldGrade)
            if(stars>oldGrade){
                // Register level progress
                firebase.database().ref(this.props.game+"-progress/"+this.props.userID+"/4").set(stars)
                
                this.setState({
                    prize: (stars-oldGrade)*5, 
                    stars: stars
                })
            }
            else{
                this.setState({
                    prize: 0, 
                    stars: stars
                })
            }
        })


        


        
    }

    handleTransaction =()=>{
        console.log(this.state)
        const amount = this.state.prize
        
        var timestamp = +new Date();

        var skill=""
        var nextGame="fin-finish"

        switch(this.props.game){
            case ("gourmet"):
                skill = "presupuesto"
                nextGame = "pricetag"
                break;
            case ("pricetag"): 
                skill = "valor"
                nextGame = "street"
                break;
            case ("street"): 
                skill = "inversion"
                nextGame = "old-quest"
                break;
            case ("quest"):
                skill = "credito"
                nextGame = "fin-finish"
                break;
        }


        firebase.database().ref("users/"+this.props.userID+"/"+nextGame+"/").set(timestamp)


        //Calificación en skills
        firebase.database().ref("grades/"+this.props.userID+"/"+skill).once("value", (snap) =>{
            var skillgrades = []

            if(snap.val()){
                 
                skillgrades = snap.val()
            }
    
            skillgrades.push(this.state.stars)
            
            firebase.database().ref("grades/"+this.props.userID+"/"+skill).set(skillgrades)

        })

        
        //Actualizar Balance y generar transacción
        firebase.database().ref("users/"+this.props.userID+"/balance").once("value", (snap) => {
            var oldBalance = snap.val()

            firebase.database().ref("users/"+ this.props.userID).update({
                balance: oldBalance + amount, 
                // pricetag: timestamp
            })
            
            firebase.database().ref("transactions/" + this.props.userID).push().set({
                desc: "Pricetag",
                cant: amount,
                type: "add",
                date: timestamp
            }).then(()=>{
                this.props.handleViewChange("app")
            })
        })
    }

    render(){


        if(this.state.questions){
            const question = this.state.questions[this.state.index]

            var options = question.options;
            var optionArray = []

            Object.keys(options).map((option)=>{
                optionArray.push(options[option])
            })

            optionArray = this.shuffle(optionArray)
            

            return(
                <div className="quiz-view">

                    {this.state.finish &&
                        // <Modal
                            
                        //     text="Terminamos!"
                        //     handleClick={{op:()=>{this.handleFinishQuiz}}}
                        // />
                        
                        
                        <div onClick={this.handleFinishQuiz()}></div>
                        
                    }
                    {this.state.prize>=0 &&
                        <PrizeModal
                            amount={this.state.prize}
                            text="Estos son los Robins que obtuviste"
                            handleClick={this.handleTransaction}
                        />
                        
                    }
                    <p className="quiz-question">{question.question}</p>
                    <div className="quiz-container">
                        
                            
                            <img src={question.img} className="quiz-img"></img>
                        
                        
                        <br/>
                        <div className="quiz-answer-container">
                            {optionArray.map((option)=>{
                                return(
                                    <p className="quiz-answer" onClick={()=>{this.nextQuestion(option)}} >{option}</p>
                                )
                        
                            })}
                        </div>
                        
                    </div>
                </div>
                
            )
        }

        else{
            return(
                <div>
                    Cargando...
                </div>
            )
        }

        
        
    }
}

export default Quiz