import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import FileUploader from 'react-firebase-file-uploader'
import axios from 'axios';

import {Mixpanel} from '../../Mixpanel'


import './session-view.css'

import Chat from '../chat/firestorechat'

import GroupChat from '../chat/group-chat'
import CourseTopButton from './course-top-button'
import EvidenceUpload from './evidence-upload';
import Toolbox from '../../assets/images/tools.png'
import Doubt from '../../assets/images/doubt.png'

import UploadImg from '../../assets/images/camera.png'

import Discord from '../../assets/images/discord.png'

import DownArrow from '../../assets/images/down-arrow.png'
import Robins from '../treehouse/robins';
import Button from '../layout/button/button';
import School from '../../assets/images/school-flat.png';
import VideoPlayer from './video-player';
class SessionView extends React.Component  {
    
    constructor(){
        super()

        this.state = {
            file: "",
            fileURL: "",
            progress: 0, 
            old: false, 
            chat: "tutor",
            robins: 0
        }
    }

    componentWillMount() {

        console.log(this.props)

    

    
        

        document.body.style.backgroundColor = "white";

        Mixpanel.track('View Session', {
            session: "session"+this.props.sessionIndex, 
            course: "vg1"
        });
        firebase.database().ref("homework-"+this.props.course+"/"+this.props.userID+"/session"+this.props.sessionIndex).on("value", (snap)=>{
            this.setState({
                homework: snap.val()
            })

            console.log(snap.val())
        })

        console.log(this.props.child[this.props.course])

        const group = this.props.child[this.props.course]

        const oldCourses = ["vg1-nov5", "vg1-dic1", "vg1-dic2", "vg1-dic3", "vg1-dic4", "vg1-ene1", "vg1-ene2", "vg1-ene3", "vg1-ene4", "vg1-ene5", "vg1-feb1", "vg1-feb2", "vg1-feb3", "vg1-feb4", "vg1-feb5", "vg1-mara", "vg1-mar1", "vg1-mar2", "vg1-mar3"]

        if(oldCourses.includes(group)){
            this.setState({
                old:true
            })
        }

        if(this.props.child.vg2){
            this.setState({
                old:true
            })
        }

        console.log(this.props.child)

        firebase.database().ref("academy-groups/"+group).once("value",(snap)=>{
            console.log(snap.val())

            this.setState({
                lastSession: snap.val()
            })
        })

        this.setState({
            
            robins: this.props.session.robins

        })

        if(group){
            if(group.includes("ifa")){
                this.setState({
                    robins: this.props.session.robins/2
                })
            } 
            else{
                this.setState({
            
                    robins: this.props.session.robins

                })
            }
        }
        
    }

    handleUploadStart = () => {

        this.setState({
            progress: 1
        })
    }

    handleUploadSuccess = filename => {
        alert("Success")
        this.setState({
            file: filename, 
            progress: 100
        })

        firebase.storage().ref("course-evidence").child(this.state.file).getDownloadURL()
            .then(url=>{
                this.setState({
                    fileURL: url
                })
            })
        
    }

    handleProgress = progress => {

        this.setState({
            progress:progress
        })
    }

    handleCancelUpload = () => {
        this.setState({
            file: ""
        })
    }

    handlePushToFirebase = () =>{
        console.log("0")
        
        axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{

            console.log("00")
            var timestamp = data.data.timestamp

            const homework = {
                title: this.props.session.title,
                correct: false,
                img: this.state.fileURL, 
                name: this.props.child.name,
                email: this.props.child.email,
                user: this.props.userID,
                robins: this.state.robins,
                date: timestamp,
                session: "session"+this.props.sessionIndex
                
            }

            console.log(homework)

            firebase.database().ref("homework-"+this.props.course+"/"+this.props.userID+"/session"+this.props.sessionIndex).set(homework)
            console.log("a")
            firebase.database().ref("aux-hw").push(homework)
            console.log("b")
            firebase.database().ref("reactivations/"+this.props.userID).remove()
            console.log("c")
            // console.log("NARTURO", this.props.userID)
            firebase.database().ref("users/"+this.props.userID+"/reactivations").set(null)
            console.log("d")
            // I set the extra timestamp because kids should not deliver homework and extra work on the same day, i guess so they hay more days
            // firebase.database().ref("users/"+this.props.userID+"/extra").set(timestamp)

            if(this.props.sessionIndex == 7) {
                firebase.database().ref("users/"+this.props.userID+"/last_upload").set("finished")
                console.log("1")
            }

            else{
                firebase.database().ref("users/"+this.props.userID+"/last_upload").set(timestamp)
                console.log("2")
            }

        })
         

            
    }


    render(){
        console.log(this.state)

        const stepsArray = this.props.session.steps
        console.log(this.state.lastSession)
        console.log(this.props.sessionIndex+1)

        if(this.state.lastSession>=this.props.sessionIndex+1 ){
            var chat = this.props.session.chat

            if(this.props.child.adult){
                chat = "https://wa.me/528119041378"
            }
                return(
                    <div className="session-view">

                    
               
    
                    <div className="session-view-container">
                        <div className="session-view-left">
    
                            {this.props.session.steps.map((value, index) =>{

                                
                                if(value.substring(0,8)==="https://"){
                                    return(
                                        <div className="step-container">
                                            <p id="texto" className="session-title">Paso {index+1}</p>
                                            <iframe className="session-video" src={value} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            {console.log(index)}
                                            {console.log(stepsArray.length)}
                                            {index != (stepsArray.length -1) &&
                                                <img className="arrow-down-step" style={{height:'50px'}} src={DownArrow}></img>
                                            }
                                            
                                        </div>
                                    )
                                }

                                else{
                                    return(
                                        <div className="step-container">
                                            <p id="texto" className="session-title">Paso {index+1}</p>
                                            <VideoPlayer
                                                id={value}
                                            />
                                            {index != (stepsArray.length -1) &&
                                                <img className="arrow-down-step" style={{height:'50px'}} src={DownArrow}></img>
                                            }
                                            
                                        </div>
                                        
                                    )
                                }

                                
                            })}
                        </div>

                        
            
                        <div className="session-view-right">

                        <div className="robin-session-container">
                            <Robins
                                robins={this.state.robins}
                            />
                                
                            </div>
                            <p className="robin-session-text">Puedes ganar {this.state.robins} Robins enviando tu evidencia</p>
                            {!this.state.homework ?
                                <div>
                                    {this.state.file ? 
                                        
                                        <EvidenceUpload
                                            img={this.state.fileURL}
                                            handleCancelUpload={this.handleCancelUpload}
                                            handlePushToFirebase={this.handlePushToFirebase}
        
                                        />
                                        : <div>
    
                                            <label className="file-uploader">
    
                                                <CourseTopButton
                                                    text="Compartir Foto"
                                                    color="#3DD869"
                                                    fill="#3DD869"
                                                    img={UploadImg}
                                                    className="hahaha"
                                                    
                                                />
    
                                                <FileUploader
                                                    hidden
                                                    accept="image/*"
                                                    name="image"
                                                    filename={this.props.userID+"-"+this.props.course+"-Activity"+this.props.sessionIndex}
                                                    storageRef={firebase.storage().ref("course-evidence")}
                                                    onUploadStart={this.handleUploadStart}
                                                    onUploadSuccess={this.handleUploadSuccess}
                                                    onProgress={this.handleProgress}
                                                    style={{opacity:'0'}}
                                                />
    
                                                
    
                                            </label>
                                        
                                            
        
                                            {this.state.progress >0 &&
                                                <div>
                                                    <label>Progreso: </label>
                                                    <p>{this.state.progress}%</p>
                                                </div>
                                            }
        
                                        
                                        </div>
                                    }
                                </div>
        
                                : <img src={this.state.homework.img} className="homework-img"></img>
                            
                            }

                            <CourseTopButton
                                text="Recursos"
                                color="#F42F54"
                                fill="#F42F54"
                                img={Toolbox}
                                handleClick = {()=> window.open(this.props.session.files, "_blank")}
                            />


                            <div className="chat-big-buttons">
                                <CourseTopButton
                                    text="Chat con Tutor"
                                    color="#00CCFF"
                                    fill="#00CCFF"
                                    img={Doubt}
                                    handleClick = {()=> {Mixpanel.track('Help Chat'); this.props.handleViewChange("chat")}}
                                />

                                <CourseTopButton
                                    text="Chat Grupal"
                                    color="#984EFF"
                                    fill="#984EFF"
                                    img={School}
                                    handleClick = {()=> {Mixpanel.track('Help Chat'); this.props.handleViewChange("group-chat")}}
                                />
                            </div>
            
                            

                            <div className="side-chat-tabs">
                                <div onClick={()=>{this.setState({chat: "tutor"})}} className="side-tutor-chat-tab">
                                    <img className="side-chat-tab-img" src={Doubt}></img>
                                    <p>Chat con Tutor</p>
                                </div>

                                <div onClick={()=>{this.setState({chat: "group"})}} className="side-group-chat-tab">
                                    <img className="side-chat-tab-img" src={School}></img>
                                    <p>Chat Grupal</p>
                                </div>
                            </div>

                            
                            
                            {this.state.chat == "tutor" ?
                                <div className="side-tutor-chat">
                                    <Chat
                                        child={this.props.child}
                                        uid={this.props.userID}
                                        handleViewChange = {this.handleViewChange}
                                        chatID = {this.props.userID}
                                        type="chats/"
                                        
                                        session={this.props.session}
                                        sessionIndex={this.props.sessionIndex}
                                        course={this.props.course}
                                    />
                                </div>
                                
                            :
                                <div className="side-group-chat">

                                    <GroupChat
                                        child={this.props.child}
                                        uid={this.props.userID}
                                        handleViewChange = {this.handleViewChange}
                                        chatID = {this.props.userID}
                                        type="chats/"
                                        
                                        session={this.props.session}
                                        sessionIndex={this.props.sessionIndex}
                                        course={this.props.course}

                                        reference={"group-chats/"+this.props.course}
                                    />
                                </div>

                                

                            }
                           
        
                    

    
                            
        
                            
            
                        </div>
                        
                    </div>
                    </div>
                )

            
        }

        else if(this.props.child.school && this.props.sessionIndex == 0){
            var chat = this.props.session.chat

            if(this.props.child.adult){
                chat = "https://wa.me/528119041378"
            }
                return(
                    <div className="session-view">

                    
                    
                    
                    <div className="session-view-container">
                        <div className="session-view-left">
    
                            {this.props.session.steps.map((value, index) =>{

                                
                                if(value.substring(0,8)==="https://"){
                                    return(
                                        <div className="step-container">
                                            <p id="texto" className="session-title">Paso {index+1}</p>
                                            <iframe className="session-video" src={value} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            {console.log(index)}
                                            {console.log(stepsArray.length)}
                                            {index != (stepsArray.length -1) &&
                                                <img className="arrow-down-step" style={{height:'50px'}} src={DownArrow}></img>
                                            }
                                            
                                        </div>
                                    )
                                }

                                else{
                                    return(
                                        <div className="step-container">
                                            <p id="texto" className="session-title">Paso {index+1}</p>
                                            <VideoPlayer
                                                id={value}
                                            />
                                            {index != (stepsArray.length -1) &&
                                                <img className="arrow-down-step" style={{height:'50px'}} src={DownArrow}></img>
                                            }
                                            
                                        </div>
                                        
                                    )
                                }

                                
                            })}
                        </div>

                        
            
                        <div className="session-view-right">

                        <div className="robin-session-container">
                            <Robins
                                robins={this.state.robins}
                            />
                                
                            </div>
                            <p className="robin-session-text">Puedes ganar {this.state.robins} Robins enviando tu evidencia</p>
                            {!this.state.homework ?
                                <div>
                                    {this.state.file ? 
                                        
                                        <EvidenceUpload
                                            img={this.state.fileURL}
                                            handleCancelUpload={this.handleCancelUpload}
                                            handlePushToFirebase={this.handlePushToFirebase}
        
                                        />
                                        : <div>
    
                                            <label className="file-uploader">
    
                                                <CourseTopButton
                                                    text="Compartir Foto"
                                                    color="#3DD869"
                                                    fill="#3DD869"
                                                    img={UploadImg}
                                                    className="hahaha"
                                                    
                                                />
    
                                                <FileUploader
                                                    hidden
                                                    accept="image/*"
                                                    name="image"
                                                    filename={this.props.userID+"-"+this.props.course+"-Activity"+this.props.sessionIndex}
                                                    storageRef={firebase.storage().ref("course-evidence")}
                                                    onUploadStart={this.handleUploadStart}
                                                    onUploadSuccess={this.handleUploadSuccess}
                                                    onProgress={this.handleProgress}
                                                    style={{opacity:'0'}}
                                                />
    
                                                
    
                                            </label>
                                        
                                            
        
                                            {this.state.progress >0 &&
                                                <div>
                                                    <label>Progreso: </label>
                                                    <p>{this.state.progress}%</p>
                                                </div>
                                            }
        
                                        
                                        </div>
                                    }
                                </div>
        
                                : <img src={this.state.homework.img} className="homework-img"></img>
                            
                            }

                            <CourseTopButton
                                text="Recursos"
                                color="#F42F54"
                                fill="#F42F54"
                                img={Toolbox}
                                handleClick = {()=> window.open(this.props.session.files, "_blank")}
                            />


                            <div className="chat-big-buttons">
                                <CourseTopButton
                                    text="Chat con Tutor"
                                    color="#00CCFF"
                                    fill="#00CCFF"
                                    img={Doubt}
                                    handleClick = {()=> {Mixpanel.track('Help Chat'); this.props.handleViewChange("chat")}}
                                />

                                <CourseTopButton
                                    text="Chat Grupal"
                                    color="#984EFF"
                                    fill="#984EFF"
                                    img={School}
                                    handleClick = {()=> {Mixpanel.track('Help Chat'); this.props.handleViewChange("group-chat")}}
                                />
                            </div>
            
                            

                            <div className="side-chat-tabs">
                                <div onClick={()=>{this.setState({chat: "tutor"})}} className="side-tutor-chat-tab">
                                    <img className="side-chat-tab-img" src={Doubt}></img>
                                    <p>Chat con Tutor</p>
                                </div>

                                <div onClick={()=>{this.setState({chat: "group"})}} className="side-group-chat-tab">
                                    <img className="side-chat-tab-img" src={School}></img>
                                    <p>Chat Grupal</p>
                                </div>
                            </div>

                            
                            
                            {this.state.chat == "tutor" ?
                                <div className="side-tutor-chat">
                                    <Chat
                                        child={this.props.child}
                                        uid={this.props.userID}
                                        handleViewChange = {this.handleViewChange}
                                        chatID = {this.props.userID}
                                        type="chats/"
                                        
                                        session={this.props.session}
                                        sessionIndex={this.props.sessionIndex}
                                        course={this.props.course}
                                    />
                                </div>
                                
                            :
                                <div className="side-group-chat">

                                    <GroupChat
                                        child={this.props.child}
                                        uid={this.props.userID}
                                        handleViewChange = {this.handleViewChange}
                                        chatID = {this.props.userID}
                                        type="chats/"
                                        
                                        session={this.props.session}
                                        sessionIndex={this.props.sessionIndex}
                                        course={this.props.course}

                                        reference={"group-chats/"+this.props.course}
                                    />
                                </div>

                                

                            }
                           
        
                    

    
                            
        
                            
            
                        </div>
                        
                    </div>
                    </div>
                )
        }

        else{
            console.log(this.props)
            console.log(this.props.course)
            console.log(this.props.child[this.props.course])
            return(
                <div className="session-view-locked">
                    <p className="session-locked-title">{this.props.session.title}</p>
                    
                    {!this.props.child[this.props.course] ? 
                    
                    <div className="session-locked-promt">
                        
                        <p className="session-text-centered">Todavía no estás inscrito en esta clase, registrate en robinacademy.com</p> 
                        <button className="register-btn" onClick={()=>{window.open("https://robinacademy.com", "_blank")}}>Regístrate</button>
                    </div>:
                    <p className="session-locked-text">Esta clase todavía no está disponible para tu grupo. Recuerda que cada lunes y miércoles se desbloquea una nueva sesión.</p>

                    
                    }
                </div>
            )
        }
        
    }
    
}
export default SessionView;