import React from 'react'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;


import TextField from './textfield'
import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";

import RobinIcon from '../../assets/images/robin-icon.png'
import Tarjeta from '../../assets/images/tarjeta.png'

import TarjetaOxxo from '../../assets/images/tarjeta-oxxo.png'

import CheckoutForm from "./checkout-form.js";
import CuentaBanco from '../../assets/images/cuenta-banco.png'
import Efectivo from '../../assets/images/efectivo.png'
import Paypal from '../../assets/images/paypal.png'
import Spei from '../../assets/images/spei.png'
import Security from '../../assets/images/security.png'

import VG2 from '../../assets/images/vg2-icon.png'
import Robot from '../../assets/images/robot-icon.png'
import VG1 from '../../assets/images/vg1-icon.png'
import Finanzas from '../../assets/images/finanzas-icon.png'
import Cocina from '../../assets/images/cocina-icon.png'
import Sci from '../../assets/images/sci-icon.png'
import YT from '../../assets/images/yt-icon.png'
import Music from '../../assets/images/music-icon.png'


import DiscountImg from '../../assets/images/discount.png'

import './signup-form.css'

const promise = loadStripe("pk_live_4mxodS1urqM170r0EBSO5qG4");


class BuyCourse extends React.Component {


    constructor(){
        super()

        this.state = {
            step:"choose",
            originalPrice: 1000,
            finalPrice: 1000,
            discount: 0,
            currency: "MXN", 
            newStudent: true,
            courseImg: ""
        }
    }

    componentDidMount(){

        if (this.props.child.vg1 || this.props.child.vg2 || this.props.child.sci || this.props.child.cocina || this.props.child.finanzas || this.props.child.robot || this.props.child.yt || this.props.child.robot2){
            
            this.setState({
                newStudent: false, 
                finalPrice: 750,
                discount: 25
            })
        }

        switch(this.props.course){
            case "vg1":
                this.setState({
                    courseName: "Curso de Videojuegos I",
                    courseImg: VG1
                })
            break
            case "robot2":
                this.setState({
                    courseName: "Curso de Robótica 2",
                    courseImg: Robot
                })
            break
            case "robot":
                this.setState({
                    courseName: "Curso de Robótica",
                    courseImg: Robot
                })
            break

            case "vg2":
                this.setState({
                    courseName: "Curso de Videojuegos II",
                    courseImg: VG2
                })
            break

            case "sci":
                this.setState({
                    courseName: "Curso de Ciencias",
                    courseImg: Sci
                })
            break

            case "cocina":
                this.setState({
                    courseName: "Curso de Cocina",
                    courseImg: Cocina
                })
            break

            case "finanzas":
                this.setState({
                    courseName: "Curso de Finanzas",
                    courseImg: Finanzas
                })
            break

            case "robot":
                this.setState({
                    courseName: "Curso de Robótica",
                    courseImg: Robot
                })
            break

            case "yt":
                this.setState({
                    courseName: "Curso de Youtuber",
                    courseImg: YT
                })
            break

            case "music":
                this.setState({
                    courseName: "Curso de Producción Musical",
                    courseImg: Music
                })
            break
            case "web":
                this.setState({
                    courseName: "Curso de Desarrollo Web",
                    courseImg: "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2Fdesarrollo%20web.png?alt=media&token=edb767aa-192b-404c-a753-9b12c76be248"
                })
            break
        }

        
    
    }

    activateAccount=()=>{
        firebase.database().ref("next-week").once("value", (snap)=>{
            const group = snap.val()

            let timestamp = +new Date()

            firebase.database().ref("users/"+this.props.uid+"/"+this.props.course).set(group)

            firebase.database().ref("academy-global/"+timestamp).set({
                kid: this.props.child.name,
                course: this.props.course,
                parent: this.props.child.parent,
                method: "app",
                paid: true,
                uid: this.props.uid,
                timestamp: timestamp, 
                amount: this.state.finalPrice, 
                username: this.props.child.email
            })

            firebase.firestore().collection("signups").doc(timestamp.toString()).set({
                kid: this.props.child.name,
                course: this.props.course,
                parent: this.props.child.parent,
                method: "app",
                paid: true,
                uid: this.props.uid,
                timestamp: timestamp, 
                amount: this.state.finalPrice, 
                username: this.props.child.email
            })


            alert("Ya estas registrado al curso. Recuerda que cada lunes y miércoles se libera una nueva actividad.")
        })
    } 

    handleChange = (event) => {
        switch (event.target.placeholder) {
            case "Nombre en la Tarjeta":
                this.setState({
                    nombre: event.target.value, 
                    correo: this.state.email
                });
                break;

            case "Correo Electrónico":
                this.setState({
                    correo: event.target.value
                });
                break;
            case "Código de Descuento":
                this.setState({
                    code: event.target.value
                });
                break;
        }
    }

    applyCode = () => {

        if(this.state.code){
            firebase.database().ref("discount-codes/"+this.state.code).once("value", (snap)=>{
           
                const discount = snap.val()

                let timestamp = +new Date()
                
                if(discount){
                    alert("¡Tienes un descuento de "+snap.val()+"%!")
                    this.setState({
                        discount:discount,
                        step:"choose", 
                        finalPrice: this.state.originalPrice-(this.state.originalPrice*(discount/100))
                    })
    
                    firebase.database().ref("applied-discounts/"+timestamp).set({
                        // parent: this.props.child.email,
                        email: this.props.child.parent,
                        code: this.state.code,
                        discount: discount, 
                        uid: this.props.uid
                    })
    
                    
                }
    
                else{
                    alert("El código que ingresaste no es válido 😕")
                }
                
            })
        }
        

    }

    render(){
        console.log(this.props)
        
        switch(this.state.step){
            case "choose":
                return(
                    <div className="hoc-container">
                        <div className="signup-container">

                        
                            
                            <div className="bottom-signup-container">
                                <p className="signup-title">{this.state.courseName}</p>
                                <img src={this.state.courseImg} className="course-icon"></img>
                                <div className="price-total">
                                    {this.state.discount > 0 && 
                                        <p className="old-price">${this.state.originalPrice} {this.state.currency}</p>   
                                    }
                                    <p className="final-price">${this.state.finalPrice} {this.state.currency}</p>
                                </div>
                                
                                {this.state.newStudent &&
                                    <button onClick={()=>{this.setState({step:"discount"})}} className="signup button">Código de Descuento</button>
                        
                                }
                                
                        
                                <img onClick={()=>{this.setState({step:"stripe"})}} className="payment-type" src={Tarjeta}></img>
                                {/* <img onClick={()=>{window.location.href="https://paypal.me/robinbootcamp"}}className="payment-type" src={Paypal}></img> */}
                                {this.state.currency == "MXN" &&
                                    <img onClick={()=>{this.setState({step:"cash"})}} className="payment-type" src={Efectivo}></img>
                                    
                                }

                                {this.state.currency == "MXN" &&
                                    <img onClick={()=>{this.setState({step:"spei"})}}className="payment-type" src={Spei}></img>
                                    
                                }
                            </div>
                            
                            
                                
                            
                        </div>
                    </div>  
                )
                break;

            case "cash":
                return(
                    <div className="hoc-container">

                        <div className="signup-container">
                            <p className="signup-title">En cualquier Oxxo pide hacer una deposito a la siguiente tarjeta</p>

                            <img className="tarjeta-oxxo" src={TarjetaOxxo}></img>
                            
                            <p className="whatsapp">Envía tu comprobante de pago al WhatsApp: 81 1904 1378</p>
                            
                            <button onClick={()=>{window.open("https://api.whatsapp.com/send?phone=528119041378&text=&source=&data=", "_blank")}} className="whatsapp-send button">Enviar Comprobante</button>
                        
                            <button onClick={()=>{this.setState({step:"choose"})}} className="regresar button">Regresar</button>
                        
                        </div>

                    </div>
                )
                break;

            case "discount":
                return(
                    <div className="hoc-container">

                        <div className="signup-container">
                            <p className="signup-title">Ingresa el código de descuento</p>

                            <img className="discount-img" src={DiscountImg}></img>
                            
                            <TextField
                                placeholder="Código de Descuento"
                                color="#3DD869"
                                onChange={this.handleChange}
                                text={this.state.code}
                                type="text"
                            />

                            <button onClick={()=>{this.applyCode()}} className="whatsapp-send button">Agregar Código</button>
                            <button onClick={()=>{this.setState({step:"choose"})}} className="regresar button">Regresar</button>
                            
                        </div>

                    </div>
                )
                break;
            
            case "spei":
                return(

                    <div>
                        <div className="hoc-container">

                            <div className="signup-container">
                                <p className="signup-title">Puedes hacer una transferencia con los siguientes datos</p>

                                <img className="tarjeta-oxxo" src={CuentaBanco}></img>
                                
                                <p className="whatsapp">Envía tu comprobante de pago al WhatsApp: 81 1904 1378</p>
                                
                                <button onClick={()=>{window.open("https://api.whatsapp.com/send?phone=528119041378&text=&source=&data=", "_blank")}} className="whatsapp-send button">Enviar Comprobante</button>
                            
                                <button onClick={()=>{this.setState({step:"choose"})}} className="regresar button">Regresar</button>
                            
                            </div>

                        </div>
                    </div>

                    
                    
                )
                break;
            
            case "stripe":
                return(
                    <div>

                        <div className="stripe-container">
                        <div className="payform-container">

                            <img src={RobinIcon} style={{width:'75px', height:'75px', borderRadius:'10px'}}className="robin-icon-img"></img>
                            
                            <img src={Security} className="security-img"></img>

                            {/* <p className="old-price">${this.props.amount} {this.props.currency}</p> */}
                            <p className="checkout-price-display">${this.state.finalPrice} {this.props.currency}</p>


                            
                            {/* <div className="extra-card-fields">
                                <div>
                                    <p class="extra-card-field-text">Nombre en la Tarjeta</p>
                                    <input
                                        onChange={e => this.handleChange(e)}
                                        className="card-field"
                                        value={this.state.nombre}
                                        placeholder="Nombre en la Tarjeta"
                                        type="text"
                                    />
                                </div>
                                
                                <div>
                                    <p class="extra-card-field-text">Correo Electrónico</p>
                                    <input
                                        onChange={e => this.handleChange(e)}
                                        className="card-field"
                                        value={this.state.correo}
                                        placeholder="Correo Electrónico"
                                        type="text"
                                    />
                                </div>


                                
                            </div> */}
                            

                            <div className="elements-container">
                                <Elements stripe={promise}>
                                    {/* <InjectedCheckoutForm 
                                        currency={this.state.currency}
                                        amount={Math.trunc(this.state.finalPrice)}
                                        email={this.state.correo}
                                        name={this.state.nombre}
                                    /> */}
                                    <CheckoutForm 
                                        amount={Math.trunc(this.state.finalPrice)}
                                        currency={this.state.currency}
                                        activateAccount={this.activateAccount}
                                    />
                                </Elements>
                            </div>

                            <button onClick={()=>{this.setState({step:"choose"})}} className="regresar button">Regresar</button>
                        
                            
                        </div>
                    </div> 
                    </div>
                    
                    
                )
            
        }   
    }
} 

export default BuyCourse