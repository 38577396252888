import React from 'react';

const GameButton = (props) => {

    const style = {
        backgroundColor: 'lightgray'
    }
    
    
    if (props.side == props.status){
        style.backgroundColor = '#00E676'
        
    }
    return(
        <div 
            style={style}
            onClick={()=>props.handleGameClick(props.side)}
            className="game-btn"
        >
            
        </div>
    )
}

export default GameButton;