import React from 'react';
import BerryImg from '../../assets/images/berry-purple.png'
import './berries.css'

const Berries = (props) => {

    return(
        <div className="berry-container">
            <img src={BerryImg} className="berry-img"></img>
            <p className="berry-balance">{props.berries}</p>
        </div>
    )

}

export default Berries;