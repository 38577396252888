import React from 'react'
// import './level.css'

const OldLevel = (props) => {
    

    // var renderGame = props.renderGame

    
    return(
        <div onClick={()=>props.renderExtra(props.index)} className="quest-container">
            <div className="quest-level">{props.index+1}</div>
        </div>
    )
    
    
}

export default OldLevel;