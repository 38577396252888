import React from 'react';
import DialogueBox from './dialogue-box';
import './conversation-scene.css'

const ConversationScene = (props) => {
    return(
        <div>
            <DialogueBox
                text={props.text}
            />

            <div className="bottom-dialogue">
                <img className="character" src="https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/boy%20(6).png?alt=media&token=0dddb8ae-33fe-4bd1-bd0b-19a5fe35b4ed"></img>
                <div className="options">
                    {Object.keys(props.options).map(function(key){
                        return(
                            <button 
                                onClick={props.options[key]}
                                className="option-btn"
                            >{key}</button>
                        )
                        
                    })}
                </div>
            </div>
            
        </div>
    )
}

export default ConversationScene;