import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;


import './course-view.css'
import CourseTopButton from './course-top-button';
import CourseSessionBox from './course-session-box';

import SchoolFlat from '../../assets/images/speech-bubble.png'
import Teacher from '../../assets/images/teacher.png'
import Storefront from '../../assets/images/storefront.png'
import MoneyGrow from '../../assets/images/money-grow.png'
import Groceries from '../../assets/images/groceries.png'
import Shopping from '../../assets/images/shopping.png'

import Multimedia from '../../assets/images/multimedia.png'
import GameModuleBox from './game-module-box';
import KidStats from '../treehouse/kid-stats';
import SkillGrades from '../treehouse/skillgrades';

class FinanceCourseView extends React.Component {

    constructor(){
        super()
        this.state = {
            progress: {
                session0:""
            }
        }
    }
    
    componentWillMount(){
        document.body.style.backgroundColor = "#F0F0F4";
        document.body.style.backgroundImage = 'none'
        window.scrollTo(0, 0);
        
        firebase.database().ref("grades/"+this.props.userID).once("value", (snap)=>{
            console.log(snap.val())
            this.setState({
                skillgrades: snap.val()
            })
        })

        // firebase.database().ref(this.props.course).once("value", (snap)=> {
        //     this.setState({
        //         course: snap.val()
        //     })
        // })

        // firebase.database().ref("homework/"+this.props.userID).on("value", (snap)=>{
        //     if(snap.val()) {
        //         this.setState({
        //             progress: snap.val()
        //         })
        //     }
            
        // })
    }

    render(){

        if(true){

        var gameModules = {
            "gourmet": {
                title: "Planeación y Presupuesto",
                img: Groceries, 
                view: "gourmet-levels", 
                game: "gourmet"
            },
            "pricetag": {
                title: "El Valor de Las Cosas",
                img: Shopping, 
                view: "pricetag-levels", 
                game: "pricetag"
            }, 
            
            "street": {
                title: "Inversión en Bolsa",
                img: MoneyGrow, 
                view: "street-levels",
            },
            "old-quest": {
                title: "Estrategia de Negocios",
                img: Storefront,
                view: "quest-levels"
            },
        }




            return(
                <div className="course-view-container">
                    {/* <p className="session-locked-title">{this.state.course.name}</p> */}
                    <div className="course-top-buttons">
                        {/* <CourseTopButton
                            text="Chat Grupal"
                            // onClick={()=>{alert("www.apple.com")}}
                            color="#00CCFF"
                            fill="#00CCFF"
                            img={SchoolFlat}
                            handleClick = {()=> window.open("https://discord.gg/RXx7NYk", "_blank")}
                        /> */}

                         <CourseTopButton
                                text="Chat con Tutor"
                                color="#00CCFF"
                                fill="#00CCFF"
                                img={SchoolFlat}
                                handleClick = {()=> {this.props.handleViewChange("chat")}}
                            />

                        <CourseTopButton
                            text="Glosario"
                            color="#00CCFF"
                            fill="#00CCFF"
                            img={Multimedia}
                            handleClick = {()=> {this.props.handleViewChange("fin-glossary")}}
                        />
                        {/* <CourseTopButton
                            text="Tutor Virtual"
                            // onClick={()=>{alert("www.apple.com")}}
                            color="#984EFF"
                            fill="#984EFF"
                            img={Teacher}
                            handleClick = {()=> window.open("https://discord.gg/RXx7NYk", "_blank")}
                        /> */}
                    </div>
                    <div className="course-sessions">
                        {Object.keys(gameModules).map((game)=>{
                            
                            const gameModule = gameModules[game]

                            var complete = false

                            

                            return(
                                <GameModuleBox
                                    complete={complete}
                                    // progress={this.state.progress[sessionNum]}
                                    game={game}
                                    // text={session.title}
                                    module={gameModule}
                                    handleViewChange={this.props.handleViewChange}
                                    
                                    
                                />

                                
                            )

                        })}
                        <div className="skill-container">
                            <p className="skills-header">Habilidades</p>
                            <SkillGrades
                                skillgrades = {this.state.skillgrades}
                            />
                        </div>
                    </div>

                    

                    
                    

                </div>
            )
        }
        else{
            return(
                <div></div>
            )
        }
        
    }
    
}

export default FinanceCourseView