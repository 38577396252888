import React from 'react';
import RobinCoin from '../../assets/images/robin-coin.png'
import './robins.css'

const Robins = (props) => {

    return(
        <div className="robins-container">
            <img src={RobinCoin} className="robin-coin"></img>
            <p className="robin-balance">{props.robins}</p>
        </div>
    )

}

export default Robins;