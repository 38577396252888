import React from 'react'
import './ingredient-container.css'

const IngredientContainer = (props) => {

    return(
        <div className="ingredient-container" onClick={()=>props.selectIngredient(props.index)}>
            {props.img &&
                <img className="ingredient-img" src={props.img}></img>
            }
            
        </div>
    )

}

export default IngredientContainer

