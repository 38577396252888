import React from 'react';
import './dialogue.css'
import Arrow from '../../assets/images/triangle.png'

const Dialogue = (props) => {
    return(
        <div>
            <div
                className="dialogue"
            >
                <p>
                    {props.text}
                </p>
            </div>
            <img className="arrow"src={Arrow}></img>
        </div>
    )
}

export default Dialogue;