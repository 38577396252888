import React from 'react';

import '../street/stock-offer.css'
import WallstreetMarket from './wallstreet-market';

const WallstreetMarketCard = (props) => {

    var classname = ""
    if(props.flip){
        classname = "stock-offer swing-in-top-fwd"
    }
    else{
        classname = "stock-offer"
    }

    return(
        <div className={classname}>
            <div className="stock-details">
                <img className="stock-img" src={props.img}></img>
                <p className="stock-range">${props.min} - ${props.max}</p>
                <p className="stock-description"> {props.text}</p>
                <p className="stock-value-label">Valor:</p>
                <p className="stock-price">${props.price}</p>
            </div>

            <button 
                className="stock-buy-btn"
                onClick={()=>props.handleStockPurchase(props.stock, props.price)}
            
            >Comprar</button>
            

        </div>
    )
}

export default WallstreetMarketCard;