import React from "react";
import Button from "../layout/button/button";
import Field from "../layout/field/field";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import axios from 'axios'
import Voucher from 'voucher-code-generator'

class CreateEmail extends React.Component {
  constructor() {
    super();

    this.state = {
      name: "",
      email: "",
      password: "",
      parent: "",
      // array: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  // componentWillMount(){
  //   console.log("CACACAAAAA")
  //   const sayHello = firebase.functions().httpsCallable('sayHello')

  //   sayHello({name: "RogeR"}).then(result => {
  //     console.log(result.data)
  //   })
  // }

  componentWillMount() {
  
    // firebase.database().ref("users").once("value", (snap)=>{
    //   const users = snap.val()

    //   console.log("Listo")

    //   Object.keys(users).map((key, value)=>{
    //     var code = Voucher.generate({
    //       length: 5,
    //       count: 1,
    //       charset: "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    //     });

        
    //     firebase.database().ref("referral-codes/"+code).once("value", (snap)=>{
    //       if(snap.val()){
    //         console.log("Se Repitio:")
    //         console.log(key)

    //       }
    //       else{
    //         firebase.database().ref("discount-codes/"+code).set(25)
    //         firebase.database().ref("referral-codes/"+code).set(key)
    //         firebase.database().ref("users/"+key+"/referral").set(code[0])
    //       }
    //     })

    //   })

    // })
  }



  handleCreate() {
    if (this.state.password) {
      if (this.state.password.length > 5) {
        // that = this
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.state.email, this.state.password)
          .then(() => {
            const user = firebase.auth().currentUser;
            console.log(this.state.email)
            user
              .updateProfile({
                displayName: this.state.name,
                photoURL: "https://example.com/jane-q-user/profile.jpg"
              })
              .then(
                function() {},
                function(error) {
                  // An error happened.
                }
              );

            this.writeUserData(user);
          })
          .catch(function(error) {
            // var errorCode = error.code;
            var errorMessage = error.message;
            alert(errorMessage);
          });
      } else {
        alert("El password debe tener por lo menos 6 caracteres.");
      }
    } else {
      alert("Los passwords no son iguales.");
    }
  }

  writeUserData(user) {
    console.log(user)
    console.log(user.uid)
    firebase
      .database()
      .ref("users/" + user.uid)
      .set({
        name: this.state.name,
        email: this.state.email,
        parent: this.state.parent,
        balance: 0, 
        // vg1: "vg1-jun3", 
        // cocina: "cocina-may3"
        // adult: true
        // finanzas: +new Date()
      });

    console.log(firebase.database().ref("users"))

    // firebase
    //   .database(
    //   .ref("leads/" + user.uid)
    //   .set({
    //     name: this.state.name,
    //     email: this.state.email,
    //     balance: 0, 
    //     parent: this.state.parent,
    //     // vg1: "vg1-may1",
    //     // cocina: "trial"
    //     // vg1: "vg1-abr5"
    //     // course: "finanzas"
    //   });

    firebase
      .database()
      .ref("clicks/" + user.uid)
      .set({
        clicks: 0
      });

    firebase
      .database()
      .ref("quest-progress/" + user.uid)
      .set({
        level: 1,
        playedToday:false
      });

    firebase
      .database()
      .ref("street-progress/" + user.uid)
      .set({
        balance:0, 
        active: false,
        playedToday: false
    });

    // (user) => this.props.setUser(user)
    this.props.setUser(user, 0, this.state.name)

    //this.props.setUser(user);

    this.props.handleStartApp();
  }

  handleChange(event) {
    switch (event.target.placeholder) {
      case "Nombre":
        this.setState({
          name: event.target.value
        });
        break;
      case "Email":
        this.setState({
          email: event.target.value
        });
        break;
      case "Contraseña":
        this.setState({
          password: event.target.value
        });
        break;
      case "Correo del papá o mamá":
        this.setState({
          parent: event.target.value
        });
        break;
    }
  }

  render() {

    // if(this.state.array){

    //   const array = this.state.array
    //   return(
    //     <div>
    //       {array.map((element, index) => {
    //         return(
    //           <div>
    //             <p>{index}</p>
    //             <img style={{width:'50px', height:'50px'}} src={element.img}></img>
    //             {/* <p>{element.img}</p> */}
    //             <br/>
    //           </div>
            
    //         )
    //       })}
    //     </div>
    //   )
    // }
    // else{

    

    return (
      <div className="form">
        <div>
          <Field
            placeholder="Nombre"
            color="field-green"
            onChange={this.handleChange}
            text={this.state.name}
            type="text"
          />

          <Field
            placeholder="Email"
            color="field-green"
            onChange={this.handleChange}
            text={this.state.email}
            type="text"
          />

          <Field
            placeholder="Contraseña"
            color="field-green"
            onChange={this.handleChange}
            text={this.state.password}
            type="password"
          />

          <Field
            placeholder="Correo del papá o mamá"
            color="field-green"
            onChange={this.handleChange}
            text={this.state.passwordConfirm}
            type="email"
          />
        </div>

        <Button
          text="Crear Cuenta"
          color="button-green"
          handleClick={this.handleCreate}
          view="logged"
        />

        
      </div>
    )
  }
}

export default CreateEmail;
