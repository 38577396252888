import React from 'react';
import './course-session-box.css'

import Check from '../../assets/images/green-check.png'
import TeacherCircle from '../../assets/images/teacher-circle.png'

const CourseSessionBox = (props) => {
    console.log(props.session)

    if(props.progress){
        var style ={
            borderStyle: "solid"
        }
        if(props.progress.correct){
            style.borderColor = "#3DD869"
            style.color = "#3DD869"
            style.borderStyle = 'solid'

        }
        else{
            style.borderColor = "#984EFF"
            style.color = "#984EFF"
            style.borderStyle = 'solid'
        }
        return(
            <div style={style} onClick={()=>{props.handleSessionView(props.session, props.sessionIndex);}} className="course-session-box-container">
                {props.progress.correct ? 
                    (
                        <div style={{position:"relative", width: "50px", height: "50px",display: "flex", justifyContent:"center",alignItems:"center"}}>
                            <img style={{position: "relative"}} src={Check} className="session-complete-img"></img>
                    
                        </div>
                    )
                    :(
                        <div style={{position:"relative", width: "50px", height: "50px",display: "flex", justifyContent:"center",alignItems:"center"}}>
                            <img style={{position: "relative"}} src={TeacherCircle} className="session-complete-img"></img>
                        </div>
                    )
                }
                <img src={props.session.img} className="session-img"></img>
                <p className="crouse-session-box-title">{props.session.title}</p>
            </div>
    
    
        )
    }
    else{
        return(
            <div onClick={()=>{props.handleSessionView(props.session, props.sessionIndex);}} className="course-session-box-container">
                
                <img src={props.session.img} className="session-img"></img>
                <p className="crouse-session-box-title">{props.session.title}</p>
            </div>
    
    
        )
    }
    
}

export default CourseSessionBox