import React from 'react'
import Article from '../street/article'
import '../street/news.css'
import MarketRanges from '../../assets/images/ranges.png'

const News = (props) => {

    var flip = false

    if(props.timer==40){
        flip = true
    }
    else{
        flip = false
    }
    
    var news = props.news[props.currentRound]
    console.log(news)

    if(news){
        
        return(
            <div className="news-view">

                    {
                        Object.keys(news).map(article=>{
                            console.log(news)
                            console.log(article)
                            if(news[article].city === props.city){
                                console.log(news[article])
                                return(
                                    <Article
                                    title={news[article].title}
                                    img={news[article].img}
                                    text={news[article].text}
                                    flip={flip}
                                />
                                )
                            }
                            
                        })
                    }
                            {/* <Article
                                title={news[1].title}
                                img={news[1].img}
                                text={news[1].text}
                                flip={flip}
                            />

                            <Article
                                title={news[2].title}
                                img={news[2].img}
                                text={news[2].text}
                                flip={flip}
                            /> */}

                            {/* <Article
                                title={news[3].title}
                                img={news[3].img}
                                text={news[3].text}
                            /> */}
            </div>
         )
    }
    else{
        return(<div></div>)
    }
}

export default News