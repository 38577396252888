import React from 'react';
import Stock from './wallstreet-stock';

const StockPortfolio = (props) => {
    var flip = false

    if(props.timer==40){
        flip = true
    }
    else{
        flip = false
    }

    var cityPortfolio 
    
    if (props.portfolio){
        cityPortfolio = props.portfolio[props.city]
    }

    
    if(cityPortfolio){
        return(
            <div className="stock-portfolio">
    
                {Object.keys(cityPortfolio).map(function(key){
                    
                    const stock = cityPortfolio[key]
                    return(
                        <Stock
                            img={props.market[props.city][stock.name].img}
                            purchase={stock.purchase}
                            price={props.roundValues[props.currentRound][stock.name]}
                            key={key}
                            stock={key}
                            handleStockSell={props.handleStockSell}
                            flip={flip}
                            name={stock.name}
                        />
                    )
                })}

                {/* {stockArray.forEach(key => {
                    console.log(props.market[key].value[props.round])
                    return(
                        <Stock
                            img={props.portfolio[key].img}
                            price={props.portfolio[key].value[props.round]}
                            key={key}
                            stock={key}
                            handleStockSell={props.handleStockSell}
                            flip={flip}
                        />
                    )
                }) */}

                
    
            </div>
        )
    }

    else{
        return(
                <p className="portfolio-placeholder">
                    Puedes comprar acciones en el mercado. 
                    Recuerda que cada ronda cambian los precios de las acciones. 
                    <br/><br/>
                    Intenta comprar acciones baratas para después venderlas caras.
                </p>   
        )
    }
}

export default StockPortfolio;