import React from 'react'
import ActivityBox from './activity-box'


import './agenda.css'
import './student-uploads.css'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;


class StudentUploads extends React.Component{


    constructor(){
        super()
        this.state = {
            uploads:{}, 
            status:"teacher-comment"
        }
    }

    componentDidMount(){
        console.log(this.props)
        document.body.style.backgroundColor = '#F0F0F4'
        document.body.style.backgroundImage = 'none'
       
        firebase.firestore()
        .collection("students/"+this.props.uid+"/uploads")
        .where("status", "==", this.state.status)
        .onSnapshot(snap=>{

            let uploads = {}
            snap.docs.forEach(doc=>{
                uploads[doc.id]=doc.data()
            })
     
            this.setState({uploads})
     
            console.log(uploads)



        })
    }

    setStatus = (status) =>{
        this.setState({
            status
        })

        firebase.firestore()
        .collection("students/"+this.props.uid+"/uploads")
        .where("status", "==", status)
        .onSnapshot(snap=>{

            let uploads = {}
            snap.docs.forEach(doc=>{
                uploads[doc.id]=doc.data()
            })
     
            this.setState({uploads})
     
            console.log(uploads)



        })
    }


    
    render(){
        const uploads = this.state.uploads
        var approved = {}
        var comment = {}
        var waiting = {}


        
        switch (this.state.status){
            case "approved":
                approved = {
                    backgroundColor:'#3DD869',
                    color: 'white'
                }
                comment = {}
                waiting= {}
                break;
            case "teacher-comment":
                approved = {}
                comment = {
                    backgroundColor:'#FFD100',
                    color: '#111E6B'
                }
                waiting= {}
                break;
            case "waiting-approval":
                approved = {}
                comment = {}
                waiting = {
                    backgroundColor:'#984EFF',
                    color: 'white'
                }
                break;
        }
        
        return(
            <div className="agenda-view">
                {/* <p className="agenda-view-title">¿Qué Voy a Hacer Hoy?</p> */}
                <div className="student-uploads-row">
                    <button style={comment} className="toggle-upload-status" onClick={()=>{this.setStatus("teacher-comment")}} >Comentarios</button>
                    <button style={waiting} className="toggle-upload-status" onClick={()=>{this.setStatus("waiting-approval")}} >Esperando Revisión</button>
                    <button style={approved} className="toggle-upload-status" onClick={()=>{this.setStatus("approved")}} >Aprobados</button>
                </div>
                
                <div className="agenda-tasks-container">
                    
                    {Object.keys(uploads).map(key=>{
                        const task = uploads[key]

                        var title = task.title
                        if(!title){
                            title = task.name
                        }
                        if(!title){
                            title = task.nombre
                        }
                        return(

                            <ActivityBox
                                name={title}
                                uploadedActivity={task}
                                // addActivity={this.props.addActivity}
                                activity_key={key}
                                activity={task}
                                backView="student-uploads"
                                viewCurricularActivity={this.props.viewCurricularActivity}
                                    
                            />
                                // <AgendaTask
                                //     name={task.name}
                                //     status={task.status}
                                //     timestamp={key}
                                //     activity={task}
                                //     key={key}
                                //     viewCurricularActivity={this.props.viewCurricularActivity}
                                // />
                        )
                    })}

                

                </div>
            </div>
        )
    }
}

export default StudentUploads