import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import KhanActBox from './khan-act-box'
import './khan-activities.css'


class KhanActivites extends React.Component{
    constructor(){
        super()

        this.state={

        }
    }


    componentDidMount(){

        document.body.style.backgroundImage = "none"
        document.body.style.backgroundColor = "#F0F0F4";

        firebase.database().ref("khan").once("value", (snap)=>{
            this.setState({
                khan: snap.val()
            })
            console.log(snap.val())

            firebase.firestore().collection("khan-progress").doc(this.props.uid).onSnapshot((snap)=>{
            
                // console.log(snap.data())
    
                this.setState({
                    progress: snap.data()
                })
    
            })
        })

        
    }


    render(){

        console.log(this.state)
        return(
            <div>
        
            {this.state.progress && 
            <div className="khan-activities-container">
                
                        {Object.keys(this.state.khan["4to"]).map(activity=>{
                            var act = this.state.khan["4to"][activity]
                            act.progress = this.state.progress[activity]
                            if(!act.progress){
                                act.progress=0
                            }
                            return(
                                <div onClick={()=>{this.props.handleActivityView(act)}}>
                                    <KhanActBox
                                        name={act.name}
                                        mastery={act.mastery}
                                        progress={act.progress}
                                    />
                                </div>
                            )
                        })}
                
            </div>
            }
            </div>
        )
    }

}

export default KhanActivites