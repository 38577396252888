import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import QuestLevel from './quest-level';
import './quest-levels.css'
import Quit from '../../assets/images/left.png'
import ManagerImg from '../../assets/images/manager.png'

class QuestLevels extends React.Component {

    constructor(){
        super()
        this.state = {
            levels: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], 
            grades: ""
        }
    }

    componentWillMount(){
        document.body.style.backgroundImage = "none"
        document.body.style.backgroundColor = "white"
        const that = this
        firebase.database().ref("q-progress/"+this.props.userID).on("value", function(snap){
            
            var grades = snap.val()

            console.log(grades)
            if(grades){
                const length = grades.length
                grades[length] = 0

                that.setState({
                    grades: grades
                })
            }
            else{
                that.setState({
                    grades: [0]
                })
            }
        })
    }

    render(){
        const grades = this.state.grades
        const levels = this.state.levels
        if (grades) {
            return(
                
                <div>
                    <div className="nav-bar">
                        <div
                            className="nav-icon" 
                            onClick={()=>this.props.handleViewChange("app")}
                        >
                            <img className="back"src={Quit}></img>
                        </div>
                        
                        <p className="view-title">Manager</p>
                    </div>

                    <div className="game-intro">
                        <img src={ManagerImg} className="game-img"></img>
                        <p className="game-description">Robin Manager es un juego donde recibarás un reto nuevo cada día. Tomando las decisiones correctas tendrás que hacer crecer un negocio o incrementar las ventas. Pon atención porque cada decisión que tomes afecta el resultado y los Robins que puedes ganar por tus consejos.</p>
                    </div>

                    <div className="levels">
                        {levels.map((element, index) => {
                            console.log(grades[index])

                            if(grades[index] == undefined){
                                return(<div className="locked-level-container"><div className="locked-level"> <img className="lock-img" src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUyIDUyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MiA1MjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+CjxwYXRoIHN0eWxlPSJmaWxsOiNDN0NBQzc7IiBkPSJNNDAuNzcxLDUySDExLjIyOUM3Ljc4OSw1Miw1LDQ5LjIxMSw1LDQ1Ljc3MVYyNi4yMjlDNSwyMi43ODksNy43ODksMjAsMTEuMjI5LDIwaDI5LjU0MiAgQzQ0LjIxMSwyMCw0NywyMi43ODksNDcsMjYuMjI5djE5LjU0MkM0Nyw0OS4yMTEsNDQuMjExLDUyLDQwLjc3MSw1MnoiLz4KPHBhdGggc3R5bGU9ImZpbGw6I0U3RUNFRDsiIGQ9Ik00MC43NzEsMjBIMjZ2MzJoMTQuNzcxQzQ0LjIxMSw1Miw0Nyw0OS4yMTEsNDcsNDUuNzcxVjI2LjIyOUM0NywyMi43ODksNDQuMjExLDIwLDQwLjc3MSwyMHoiLz4KPHBhdGggc3R5bGU9ImZpbGw6IzRDNEM0RjsiIGQ9Ik0yNiw0MEwyNiw0MGMtMS42NSwwLTMtMS4zNS0zLTN2LTZjMC0xLjY1LDEuMzUtMywzLTNoMGMxLjY1LDAsMywxLjM1LDMsM3Y2ICBDMjksMzguNjUsMjcuNjUsNDAsMjYsNDB6Ii8+CjxwYXRoIHN0eWxlPSJmaWxsOiM1NDZBNzk7IiBkPSJNMTEuMjI5LDIwSDE1di02LjVDMTUsOC4yNjIsMTkuOTM1LDQsMjYsNHMxMSw0LjI2MiwxMSw5LjVWMjBoMy43NzEgIGMwLjA3NywwLDAuMTUyLDAuMDA5LDAuMjI5LDAuMDExVjEzLjVDNDEsNi4wNzUsMzQuMjUsMCwyNiwwUzExLDYuMDc1LDExLDEzLjV2Ni41MTFDMTEuMDc3LDIwLjAwOSwxMS4xNTIsMjAsMTEuMjI5LDIweiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K" /></div></div>)
                            }
                            else{
                                
                                return(<QuestLevel
                                    renderQuest={this.props.renderQuest}
                                    index={index}
                                    grades={grades[index]}
                                />)

                            }
                        })}
                    </div>
                </div>
            )
        }
        
        
    }
}

export default QuestLevels;