import React from 'react'
import { NotionRenderer } from "react-notion";
import axios from 'axios'
import "react-notion/src/styles.css";
import './projects.css'


class ProjectView extends React.Component {
    constructor(){
        super()

        this.state={

        }
    }

    componentDidMount(){

        document.body.style.backgroundImage = "none"
        document.body.style.backgroundColor = "white";

        axios.get("https://notion-api.splitbee.io/v1/page/"+this.props.id).then(res=>{
        this.setState({
            items:res.data
        })
        })
    }

    render(){
        const blockMap = this.state.items

        console.log(blockMap)


        return(
            <div className="project-view-container" style={{ maxWidth: 768 }}>
                {blockMap &&
                    <NotionRenderer blockMap={blockMap} />
                }
                
            </div>
        )
    }
}

export default ProjectView