
import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';

import NavBar from '../../components/navigation/nav-bar'
import CourseTopButton from '../course/course-top-button'
import FileUploader from 'react-firebase-file-uploader'
import EvidenceUpload from '../course/evidence-upload'
import UploadImg from '../../assets/images/camera.png'
import parse from 'html-react-parser'
import Check from '../../assets/images/check-circle-gray.png'
import FileIcon from '../../assets/images/file.png'
import Finger from '../../assets/images/finger.png'
import NotionPage from './notion-page'


import './curricular-activity.css'
import ChatInput from '../chat/chat-input'
import ActivityCommentSection from './activity-comment-section'
import Quiz from './quiz';

class CurricularActivity extends React.Component {
    constructor(){
        super()

        this.state={
            // activities: {}
            uploads:{},
            comments: {}, 
            activityData: {}
        }
    }

    componentDidMount(){
        this.setState({
            status: this.props.activity.status
        })
       //Get activity uploads from DB. 

    //    this.setState({
    //         uploads: []
    //    })

        console.log(this.props)
        firebase.firestore().collection("students/"+this.props.uid+"/uploads").doc(this.props.activity.activity_key).onSnapshot((snap)=>{



            const activity = snap.data()

            console.log(activity)

            if(activity){
                if(activity.files){
                    this.setState({uploads: activity.files})
                }
                if(activity.comments){
                    this.setState({comments: activity.comments})
                }

                this.setState({
                    activityData: activity,
                    status: activity.status
                })
            }

            
     
            // let uploads = {}
            // snap.docs.forEach(doc=>{
            //     uploads[doc.id]=doc.data()
            // })

            // this.setState({uploads})

            // console.log(uploads)
        })
    }

    handleUploadStart = () => {

        this.setState({
            progress: 1
        })
    }

    handleUploadSuccess = filename => {

        this.setState({
            file: filename, 
            progress: 100
        })
        console.log(filename)

        firebase.storage().ref("activities/"+this.props.uid+"/"+this.props.activity.activity_key).child(filename).getDownloadURL()
            .then(url=>{

                this.setState({
                    fileURL: url
                })
                console.log(url)
            })
        
    }

    handleProgress = progress => {

        this.setState({
            progress:progress
        })
    }

    handleCancelUpload = () => {
        this.setState({
            file: ""
        })
    }

    handlePushToFirebase = () =>{


        //Just upload activity data to database.

        const timestamp = +new Date()

        var files = this.state.uploads

        files[timestamp] = this.state.fileURL

        var activity = this.props.activity

        var activity_key = this.props.activity.activity_key



        if(!activity_key.includes(activity.timestamp) && activity.area === "recurrent-activities" && (activity_key.length < 30)){
            
            activity_key=activity_key+"_"+timestamp
        }


        
        activity.files = files
        activity.timestamp = timestamp
        activity.status = "waiting-approval"
        activity.date = timestamp  
        activity.activity_key = activity_key

        if(this.state.activityData.comments){
            activity.comments = this.state.activityData.comments

        }
        
        
        if(this.props.child.currentObjective){
            activity.obj_key = this.props.child.currentObjective

        }



        
        console.log(this.props)
        console.log(this.state)
        console.log(activity)
        console.log(activity_key)
        
        firebase.firestore().collection("students/"+this.props.uid+"/uploads").doc(activity_key)
        .set(activity)
        .then(i=>{
            console.log(i)
            this.setState({file: ""})
        })



        
    }


    approveActivity = ()=>{
       

        firebase.firestore().collection("students/"+this.props.uid+"/uploads").doc(this.props.activity.activity_key)
        .update({status: "approved"})
        .then(i=>{
            console.log(i)
            this.setState({status: "approved"})
        })

        firebase.firestore().collection("student-points").doc(this.props.uid).get().then((snap)=>{
            console.log(this.state.activityData)
            
            var progress = snap.data()
            var newProgress = progress

            if(!newProgress){
                newProgress = {}
            }

            newProgress[this.state.activityData.topic_key] = parseInt(this.state.activityData.tiempo)

            if(progress){
                if(progress[this.state.activityData.topic_key]){
                    newProgress[this.state.activityData.topic_key] = parseInt(progress[this.state.activityData.topic_key]) + parseInt(this.state.activityData.tiempo)
                }
            }

            
            
            firebase.firestore().collection("student-points").doc(this.props.uid).set(newProgress)

        })

        


    }


    renderActivity(){
        
        const activity = this.props.activity?.activity ?  this.props.activity.activity : this.props.activity

        console.log(activity)
        if((activity.acceso?.startsWith("https://rovaga.notion.site/") || activity.acceso?.startsWith("https://www.notion.so/"))){

            var pageID = activity.acceso.split("-")
            pageID = pageID[pageID.length-1]
            return <NotionPage notionLink={pageID} activityID={this.props.activityID} />
        }

        if (activity?.activity_key?.startsWith("custom") || activity?.acceso){
            return(
                <div className="activity-box-right-top">
                        <p className="activity-view-box-title">{activity.nombre}</p>

                        {activity.html ?
                            <div className="activity-view-description">
                                {parse(activity.html)}
                            </div>
                        :
                        <p className="activity-view-description">{activity.instrucciones}</p>
                        }

                        {activity.acceso &&
                            <div onClick={()=>{window.open(activity.acceso, "_blank")}}>
                                <CourseTopButton
                                    text="Empezar"
                                    color="#984EFF"
                                    fill="#984EFF"
                                    img={Finger}
                                    className="hahaha"
                                />
                            </div>  
                        }
                        
                    </div>
            )
        }
        else{
            return <NotionPage activityID={this.props.activityID} />
        }
        
    }

    


    render(){

        console.log(this.props)
        console.log(this.state)
        let link = this.props.activity.acceso
        if(!link){
            link=""
        }
        const status = this.state.status
        const activity = this.props.activity
        const timestamp = +new Date()
        const uploads = this.state.uploads
        const english = {writing:"language-activities", speaking:"language-activities", grammar:"language-activities", listening:"language-activities", reading:"language-activities", "recurrent-activities": "recurrent-activities"}
        var back = this.props.backView

        if(english[this.props.activity.topic_key]){
            back=english[this.props.activity.topic_key]
        }

        var canApprove = false

        if(status!="approved" && uploads){
            canApprove = true
        }
        

        return(
            <div>
                <NavBar
                    handleBack={this.props.handleBack}
                    backView={back}
                    title={"Actividad"}
                />

                <div className="curricular-activity-view">
                    <div className="activity-description-box">
                        
                        {this.renderActivity()}

                        
                        
                    </div>

                    <div className="activity-view-right">

                        <div className="activity-delivery-box">
                            <p className="activity-view-box-title activity-uploads-title">Tu Evidencia</p>

                            
                                <div>
                                    {this.state.file ? 

                                        <div>
                                            <EvidenceUpload
                                                img={this.state.fileURL}
                                                handleCancelUpload={this.handleCancelUpload}
                                                handlePushToFirebase={this.handlePushToFirebase}
            
                                            />

                                            {this.state.progress >0 &&
                                                <div>
                                                    <label>Progreso: </label>
                                                    <p>{this.state.progress}%</p>
                                                </div>
                                            }
                                            </div>
                                        
                                            : 
                                            <div>
    
                                            <label className="file-uploader">
    
                                                <CourseTopButton
                                                    text="Compartir Archivo"
                                                    textColor="navy"
                                                    color="#FFCD29"
                                                    fill="#FFCD29"
                                                    img={FileIcon}
                                                    className="hahaha"
                                                    
                                                />
    
                                                <FileUploader
                                                    hidden
                                                    accept="image/*"
                                                    name="image"
                                                    // filename={this.props.userID+"-"+this.props.course+"-Activity"+this.props.sessionIndex}
                                                    filename={this.props.activity.activity_key+"_"+timestamp}
                                                    storageRef={firebase.storage().ref("activities/"+this.props.uid+"/"+this.props.activity.activity_key)}
                                                    onUploadStart={this.handleUploadStart}
                                                    onUploadSuccess={this.handleUploadSuccess}
                                                    onProgress={this.handleProgress}
                                                    style={{opacity:'0'}}
                                                />
    
                                                
    
                                            </label>
                                        
                                            
        
                                            
        
                                        
                                        </div>
                                    }

                                    {/* {canApprove &&
                                        <div onClick={()=>{this.approveActivity()}}>
                                            <CourseTopButton
                                                text="Aprobar"
                                                color="#3DD869"
                                                fill="#3DD869"
                                                img={Check}
                                                className="hahaha"             
                                            />
                                        </div>
                                        
                                    } */}
                                    
                                    
                                </div>
                                
                                <div className = "activity-uploads-container">
                                    {Object.keys(uploads).map(timestamp=>{
                                        const fileURL = uploads[timestamp]
                                        
                                        return(
                                            <div onClick={()=>{window.open(fileURL)}}>
                                                <img src={fileURL} className="uploaded-img"></img>
                                            </div>
                                        )
                                    })}                                    
                                </div>
                                
                        
                            

                            {/* <label className="file-uploader">
    
                                <CourseTopButton
                                    text="Compartir Foto"
                                    color="#3DD869"
                                    fill="#3DD869"
                                    img={'UploadImg'}
                                    className="hahaha"
                                                    
                                />
    
                                <FileUploader
                                    hidden
                                    accept="image/*"
                                    name="image"
                                    // filename={this.props.userID+"-"+this.props.course+"-Activity"+this.props.sessionIndex}
                                    filename={"prueba-de-actividad-2021"}
                                    storageRef={firebase.storage().ref("course-evidence")}
                                    onUploadStart={this.handleUploadStart}
                                    onUploadSuccess={this.handleUploadSuccess}
                                    onProgress={this.handleProgress}
                                    style={{opacity:'0'}}
                                />                       
    
                            </label> */}


                            {/* Puedo agregar texto explicativo. También un botón para agregar links */}
                            {/* <button className="upload-activity-btn">Agregar Evidencia</button> */}
                        </div>

                        <div className="activity-comment-box">
                            
                            <ActivityCommentSection
                                comments={this.state.comments}
                                uid={this.props.uid}
                                upload={this.state.activityData}
                                user={this.props.user}
                                child={this.props.child}
                                activity={this.props.activity}
                                avatar={this.props.avatar}
                            />
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default CurricularActivity