import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import React from 'react'
import Voucher from 'voucher-code-generator'
import './join-wallstreet.css'


class JoinWallstreet extends React.Component {

    constructor(){
        super()
        this.state = {
            view:"choose"
        }
    }

    componentDidMount(){
        document.body.style.backgroundColor = "#F0F0F4";
        document.body.style.backgroundImage = `none`
    }


    joinGame = (event) =>{
        event.preventDefault()

        let gameID = event.target.gameID.value

        gameID = gameID.toUpperCase()

        this.props.joinWallstreetGame(gameID)
    }


    callCreateGameServer = () =>{
        var createWallstreet = firebase.functions().httpsCallable("createWallstreet")

        const user = {
            uid: this.props.uid, 
            name: this.props.child.name, 
            img: this.props.child.avatar
        }

        createWallstreet(user).then((result)=>{
            firebase.database().ref("wallstreet-games/"+result.data).on("value", snap=>{
                if(snap.val()){
                    if(snap.val().status){
                        console.log(snap.val().status)
                    this.props.joinWallstreetGame(result.data)
                    }
                    else{
                        console.log("SHIII")
                    }
                    
                }
                else{
                    console.log("NACA")
                }
            })
        })
    }

    weightedChange = ()=>{
        const values = {
            1: 5, 
            2: 10, 
            3: 10, 
            4: 10, 
            5: 20, 
            6: 20, 
            7: 20, 
            8: 30, 
            9: 30
        }

        const random = Math.ceil(Math.random() * (9 - 0) + 0)

        return(values[random])

    }

    randomStock = (obj) =>{
        var keys = Object.keys(obj);
        return keys[ keys.length * Math.random() << 0];
    }

    applyRandomChange = (market, roundv) => {
            var stock = this.randomStock(market)
            
            var stockChange = this.weightedChange()


            if(Math.ceil(Math.random() * (2 - 0) + 0) === 1){
                stockChange= stockChange*-1    
            }

            
            var stockInMarket = market[stock]

            const min = stockInMarket.min
            const max = stockInMarket.max

            var currentRound = {...roundv}

            const newValue = roundv[stock]+stockChange

            
            
            // console.log(currentRound)
            if (newValue >= min && newValue <= max){

                console.log("changed stock: "+stock+" from: "+currentRound[stock])
                console.log("to new value: "+ newValue)

                currentRound[stock]= newValue
                
                console.log(currentRound[stock])

            }

            
            return currentRound
    }


    createGameID = () =>{
        console.log("CA")
        var id = Voucher.generate({
            length: 4,
              count: 1,
              charset: "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
        });

        const player= {
            [this.props.uid]: {
                name: this.props.child.name,
                img: this.props.child.avatar
            }
        }

        firebase.database().ref("wallstreet-games/"+id).set({host: this.props.uid, playerQueue:player})

        this.createGame(id)
    }

    shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          const temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
        return array
    }

    getPairs = array =>{
        var newArray = []
        var auxRound = 0
        
        for (let round = 0; round<10; round++){
            newArray[round] = []
        
            newArray[round][0] = array[auxRound]
            newArray[round][1] = array[auxRound+1]

            auxRound = auxRound+2
        }

        return newArray
    }

    getRoundValues =(markets)=>{
        // const markets = this.state.game.market

        const firstRound = {}

        Object.keys(markets).map(city=>{
            Object.keys(markets[city]).map(stock=>{

                const stockData = markets[city][stock]
    
                const min = stockData.min
                const max = stockData.max
    
                var value = Math.floor(Math.random() * (max/10 - min/10 + 1)) + min/10
    
                value = value*10
                
                firstRound[stock] = value

            })
        })

        
        return(firstRound)
    }

    // applyRandomEffect = (roundValues, market) => {

    //     const rounds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
       

    //     let roundv = {...roundValues}
    //     console.log(roundv)

    //     rounds.map(round=>{
    //         console.log(round)
    //         var currentRound = {...roundv[round]}


    //         console.log(currentRound)
    //         currentRound = {...this.applyRandomChange(market.mex, currentRound)}
    //         currentRound = {...this.applyRandomChange(market.nyc, currentRound)}
    //         currentRound = {...this.applyRandomChange(market.ldn, currentRound)}
    //         currentRound = {...this.applyRandomChange(market.sha, currentRound)}
    //         console.log(currentRound)

    //         roundv[round+1]= {...currentRound}
    //     })

    //     console.log(roundv)

    //     return(roundv)


    // }


    // applyNewsEffect = async(newsData, roundValues, market) =>{
       
    //     console.log(roundValues)
    //     const rounds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
       

    //     let roundv = {...roundValues}
    //     console.log(roundv)

    //     rounds.map(round=>{
    //         console.log(newsData)
    //         const currentNews = newsData[round]
    //         console.log(roundv)
    //         var currentRound = {...roundv[round]}


    //         // console.log(currentRound)
    //         // currentRound = {...this.applyRandomChange(market.mex, currentRound)}
    //         // currentRound = {...this.applyRandomChange(market.nyc, currentRound)}
    //         // currentRound = {...this.applyRandomChange(market.ldn, currentRound)}
    //         // currentRound = {...this.applyRandomChange(market.sha, currentRound)}
    //         // console.log(currentRound)

    //         Object.keys(currentNews).map(n=>{
    //             console.log(currentNews[n])
    //             Object.keys(currentNews[n].stocks).map(stock=>{

            

               

    //             var stockInMarket = market.mex[stock]

    //             if(!stockInMarket){
    //                 stockInMarket = market.nyc[stock]
    //             }
    //             if(!stockInMarket){
    //                 stockInMarket = market.sha[stock]
    //             }
    //             if(!stockInMarket){
    //                 stockInMarket = market.ldn[stock]
    //             }

    //             const min = stockInMarket.min
    //             const max = stockInMarket.max

    //             var change = 10
    //             // if(changes[stock] === "down"){
    //             //     change= change*-1
    //             // }

                

    //             if(currentNews[n].stocks[stock] === "down"){
    //                 change= change*-1
                    
            
    //             }

    //             const newValue = currentRound[stock]+change

    //             // console.log("changed stock:"+stock+"from: ")
    //             // console.log(currentRound[stock])
    //             // console.log("to new value: "+ newValue)
                
    //             if (newValue >= min && newValue <= max){
                    

    //                 currentRound[stock]= newValue
    //             }

    //             })
    //         })

           
    //         console.log(currentRound)
    //         console.log(roundv)
    //         roundv[round+1]= {...currentRound}
    //         console.log(roundv)
    //     })
    //     console.log(roundv)
    //     return(roundv)

       

    // }

    applyEffects = (values, news, market, id)=>{
       
        const rounds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
       

        let roundv = {...values}


        // let roundv = {...this.applyRandomEffect(values, market)}
        
        rounds.map(round=>{
            const currentNews = news[round]
            var currentRound = {...roundv[round]}

            console.log(currentRound)
            currentRound = {...this.applyRandomChange(market.mex, currentRound)}
            currentRound = {...this.applyRandomChange(market.nyc, currentRound)}
            currentRound = {...this.applyRandomChange(market.ldn, currentRound)}
            currentRound = {...this.applyRandomChange(market.sha, currentRound)}
            console.log(currentRound)

            Object.keys(currentNews).map(n=>{
                Object.keys(currentNews[n].stocks).map(stock=>{

                console.log(currentNews[n])
                var stockInMarket = market.mex[stock]

                if(!stockInMarket){
                    stockInMarket = market.nyc[stock]
                }
                if(!stockInMarket){
                    stockInMarket = market.sha[stock]
                }
                if(!stockInMarket){
                    stockInMarket = market.ldn[stock]
                }

                const min = stockInMarket.min
                const max = stockInMarket.max

                var change = 10
                

                if(currentNews[n].stocks[stock] === "down"){
                    change= change*-1
                    
            
                }

                const newValue = currentRound[stock]+change

                
                if (newValue >= min && newValue <= max){
                    
                    console.log("Stock: "+stock+" from: "+currentRound[stock])
                    currentRound[stock]= newValue
                    console.log("Stock: "+stock+" to: "+currentRound[stock])
                }

                })
            })

            roundv[round+1]= {...currentRound}
        })
        console.log(roundv)
        return(roundv)


    }

    getRoundNews=(mexNews, nycNews, shaNews, ldnNews) =>{
        var newArray = []
        var auxRound = 0

        console.log(mexNews)
        
        for (let round = 0; round<10; round++){
            newArray[round] = []
        
            newArray[round][0] = mexNews[auxRound]
            newArray[round][1] = mexNews[auxRound+1]

            newArray[round][2] = nycNews[auxRound]
            newArray[round][3] = nycNews[auxRound+1]

            newArray[round][4] = shaNews[auxRound]
            newArray[round][5] = shaNews[auxRound+1]

            newArray[round][6] = ldnNews[auxRound]
            newArray[round][7] = ldnNews[auxRound+1]

            auxRound = auxRound+2
        }

        console.log(newArray)

        return newArray
    }


    createGame=async(id)=>{

        console.log("Cs")
        
        firebase.database().ref("wallstreet-news").on("value", (snap) =>{
            var newsData = snap.val()
            console.log(newsData)
        
            var shuffledMexNews = this.shuffleArray(newsData["mex-news"])
            var shuffledNycNews = this.shuffleArray(newsData["nyc-news"])
            var shuffledShaNews = this.shuffleArray(newsData["sha-news"])
            var shuffledLdnNews = this.shuffleArray(newsData["ldn-news"])
    
            // var cityNews = getPairs(newsData)
    
            // var mexNewsPairs = this.getPairs(shuffledMexNews)
            // var nycNewsPairs = this.getPairs(shuffledNycNews)
            // var shaNewsPairs = this.getPairs(shuffledShaNews)
            // var ldnNewsPairs = this.getPairs(shuffledLdnNews)
    
            var roundValues = []
    
            roundValues.push(this.getRoundValues(snap.val().market))
    
    
    
            const market = snap.val().market

            var auxR = {...roundValues}
    
            
            const roundNews = this.getRoundNews(shuffledMexNews, shuffledNycNews, shuffledShaNews, shuffledLdnNews)
            const prices = this.applyEffects(auxR, roundNews, market, id)
            console.log(prices)
            // console.log(roundValues)
            // const pricesFive = await this.applyRandomEffect({...auxR}, market)
            // console.log(pricesFive)
            // const prices = await this.applyNewsEffect(mexNewsPairs, {...pricesFive}, market)
            // console.log(prices)
            // const pricesTwo = await this.applyNewsEffect(nycNewsPairs, {...prices}, market)
            // console.log(prices)
            // console.log(pricesTwo)
            // const pricesThree = await this.applyNewsEffect(shaNewsPairs, pricesTwo, market)
            // console.log(pricesThree)
            // const pricesFour = await this.applyNewsEffect(ldnNewsPairs, pricesThree, market)
            // console.log(pricesFour)
            // console.log(pricesFive)

            // this.applyEffects(auxR, mexNewsPairs, nycNewsPairs, ldnNewsPairs, shaNewsPairs, market, roundNews, id)
            
            var gameData = {
                roundValues: prices,
                prices, 
                roundNews,
                market: market,
                status: "in-queue", 
                host: this.props.uid, 
                azul:{
                    balance: 200
                },
                verde:{
                    balance: 200
                },
                rojo:{
                    balance: 200
                },
                morado:{
                    balance: 200
                }
            
            }
    

    
        // var trice = this.applyNewsEffect(newsData, twice)
        console.log(gameData)
        
        // console.log(trice)
        firebase.database().ref("wallstreet-games/"+id).update(gameData)
        this.props.joinWallstreetGame(id)

        })
        
    }

   

    render(){

        switch(this.state.view){
            case "choose":
                return(
                    <div className="join-wallstreet-view">
                        <p className="join-text">Puedes crear un nuevo juego e invitar a tus amigos o unirte a un juego que ya esté creado.</p>
                        <button onClick={()=>{this.createGameID()}} className="create-game-btn">Crear Nuevo Juego</button>
                        <button onClick={()=>{this.setState({view: "join"})}} className="join-game-btn">Unirse a Partida</button>
                    </div>
                )
                break;
            case "join":
                return(
                    <form onSubmit={this.joinGame} className="join-wallstreet-view">
                        <p className="join-text">Introduce el código de juego para unirte a la partida.</p>
                        <input id="gameID" name="gameID" className="join-field"></input>
                        <button type="submit" className="join-game-btn">Unirse a Partida</button>
                    </form>
                )
        }
        
    }
}

export default JoinWallstreet