import React from "react";
import './avatar.css'

const Avatar = props => {
  return (
    <div className="avatar">
      <img src={props.image} alt="Imagen"/>
    </div>
  );
};

export default Avatar;
