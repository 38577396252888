import React from "react";
import QrReader from "react-qr-scanner";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;


const delay = 100;

const previewStyle = {
    height: '100%',
    width: '100%'
};

function handleScan(data, props) {
    if (data != null) {
        const words = data.split(" ");
        const username = words[0];
        const password = words[1];
        console.log(username)
        console.log(password)
        firebase
            .auth()
            .signInWithEmailAndPassword(username, password)
            .then(() => {
                const user = firebase.auth().currentUser;
                console.log(user.uid);

                readUserData(props, user);
            })
            .catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                alert(errorMessage);
            });
    }
}

function readUserData(props, user) {
    firebase
      .database()
      .ref("users/" + user.uid)
      .on("value", function(snapshot) {
        const child = snapshot.val();

        props.setUser(user, child);

        props.handleStartApp();
      });
  }

function handleError(err) {
    console.error(err);
}

const QRLogin = props => (
    <div className={props.className}>
        <p>
            Escanea el código QR de tu tarjeta Robin para ingresar a tu cuenta.
        </p>
        <QrReader
            delay={delay}
            style={previewStyle}
            onError={handleError}
            onScan={data => handleScan(data, props)}
        />
    </div>
);

export default QRLogin;
