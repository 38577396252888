import React from 'react'
import Bar from '../treehouse/bar'
import PurpleArrow from '../../assets/images/purple-right-arrow.png'

import './topic-box.css'


const TopicBox = (props)=>{
    return(
        <div className="topic-box topic-book-background" onClick={()=>{props.viewTopic(props.topic_id)}}>
            <div className='book-top'></div>
            <div className="book-cover">
                <p className="topic-title">{props.name}</p>
                
            </div>
        </div>
    )
}

export default TopicBox