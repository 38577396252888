import React from 'react';
import './dialogue-box.css'
import Arrow from '../../assets/images/triangle.png'

const DialogueBox = (props) => {
    return(
        <div>
            <div
                className="dialogue-box"
            >
                <p>
                    {props.text}
                </p>
            </div>
            <img className="arrow"src={Arrow}></img>
        </div>
    )
}

export default DialogueBox;