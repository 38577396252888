import React from "react";
import Welcome from "./welcome";
import EmailPrompt from "../auth/email-prompt";
import CreateEmail from "../auth/create-email";
import CreateUsername from "../auth/create-username";
import Login from "../auth/login-new";
import QRLogin from '../auth/qr-login'

import "./welcome-box.css";

const WelcomeBox = props => {
  console.log(props);

  switch (props.view) {
    case "welcome":
      return (
        <Welcome
          className="box-view"
          handleChangeWelcomeView={props.handleChangeWelcomeView}
        />
      );
    case "login":
      return (
        <Login
          className="box-view"
          handleChangeWelcomeView={props.handleChangeWelcomeView}
          handleStartApp={props.handleStartApp}
          setUser={props.setUser}
          handleBack={props.handleBack}
        />
      );
    case "email":
      return (
        <EmailPrompt
          className="box-view"
          handleChangeWelcomeView={props.handleChangeWelcomeView}
          handleBack={props.handleBack}
        />
      );
    case "create-email":
      return (
        <CreateEmail
          className="box-view"
          handleChangeWelcomeView={props.handleChangeWelcomeView}
          handleStartApp={props.handleStartApp}
          setUser={props.setUser}
          handleBack={props.handleBack}
        />
      );
    case "create-username":
      return (
        <CreateUsername
          className="box-view"
          handleChangeWelcomeView={props.handleChangeWelcomeView}
          handleStartApp={props.handleStartApp}
          setUser={props.setUser}
          handleBack={props.handleBack}
        />
      );
    case "qr-login":
      return (
        <QRLogin
          className="box-view"
          handleChangeWelcomeView={props.handleChangeWelcomeView}
          handleStartApp={props.handleStartApp}
          setUser={props.setUser}
          handleBack={props.handleBack}
        />
      );
  }
};
export default WelcomeBox;
