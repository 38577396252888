import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;



class FinGlossary extends React.Component {
    constructor(){
        super()
        this.state ={
            videos: {}
        }
    }

    componentDidMount(){

        // firebase.database().ref("users/"+user.uid).set(kid)
        firebase.database().ref("fin-glossary").once("value", (snap)=>{
            const videos = snap.val()
            this.setState({
                videos
            })
            console.log(videos)
        })
    }

    render(){
        const videos = this.state.videos
        
        return(
            <div className="fin-glossary">
                {
                Object.keys(videos).map(key=>{
                    const video = videos[key]
                    return(
                        <iframe className="fin-glossary-video" src={video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>          
                    )

                })
                }
            </div>
        )
    }
}

export default FinGlossary