import React from 'react'

const TeamBox = (props) =>{

    var players = props.players

    return(
        <div className="team-box" onClick={()=>{props.pickTeam(props.team)}}>
            <p className="team-name">{props.name} ${props.balance}</p>

            <div className="team-players" style={{backgroundColor: props.color}}>
                
                {Object.keys(players).map(id=>{
                    if(players[id].currentTeam===props.team){
                        return(
                            <div className="player-tag">
                                <img className="player-img" src={players[id].img}></img>
                                <p className="player-name">{players[id].name}</p>
                            </div>
                        )
                    }
                    
                })}

            </div>
        </div>
    )

}

export default TeamBox