import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import axios from 'axios';

import './subscription.css';
import RobinMascot from '../../assets/images/subscription.png';
import Checkout from './checkout'
import Left from '../../assets/images/left.png'
import Arrow from '../../assets/images/green-arrow.png'
import Peso from '../../assets/images/peso.png'
import Coin from '../../assets/images/robin-coin.png'
import OxxoBrand from '../../assets/images/oxxopay_brand.png'
import {Mixpanel} from '../../Mixpanel'



class Subscription extends React.Component{

    constructor(){
        super()

        this.state = {
            subscription: "", 
            active: false, 
            amount: "", 
            paymentData: "",
            oxxoPay: false
        }

        this.setSuscription = this.setSuscription.bind(this)
        this.cancelSuscription = this.cancelSuscription.bind(this)
        this.handleChangeAmount = this.handleChangeAmount.bind(this)
        this.oxxoPay = this.oxxoPay.bind(this)
    }

    componentWillMount(){
        document.body.style.backgroundColor = "#F0F0F4";
        document.body.style.backgroundImage = "none"
        firebase.database().ref("users/"+this.props.userID+"/subscription").once("value", (snap)=>{
            
            if(snap.val()){
                this.setState({
                    subscription: snap.val()
                })
                console.log(this.state)

                axios.post("https://workshop-payments.herokuapp.com/validate",
                {
                    subscription: snap.val()
                })
                .then((data)=>{

                    let status = data.data.subscription.status
                    if (status == "active" || status == "trialing"){
                        this.setState({
                            active: true
                        })
                    }
                })
            }
            
        })

        Mixpanel.track('Check Subscription');

       
    }

    handleChangeAmount(event) {
        this.setState({
            amount: event.target.value
        })
    }

    setSuscription(data){
        firebase.database().ref("users/"+this.props.userID+"/subscription").set(data.subscription.id)
    }

    cancelSuscription(){
        const that = this
        
        axios.post("https://workshop-payments.herokuapp.com/cancel",
                {
                    subscription: that.state.subscription
                })
                .then((data)=>{
                    
                    this.setState({
                        active: false
                    })
                })
    }

    oxxoPay(){

        const sendingData = {
            amount: this.state.amount*100, 
            name: this.props.child.name, 
            email: this.props.child.email
        }
        axios.post("https://workshop-payments.herokuapp.com/oxxopay", sendingData).then((data)=>{        
            console.log(data.data)
            this.setState({
                paymentData: data.data, 
                oxxoPay: true
            })
        })
    }

    render(){

        if(this.state.oxxoPay){

            const orderID = this.state.paymentData.otso.id

            firebase.database().ref("oxxo-pay/"+orderID).set({
                status: "ordered",
                user: this.props.userID,
                amount: this.state.amount*5
            })

            return(
                <div>
                    <div className="nav-bar">

                        <div className="nav-left">
                            <div
                                className="nav-icon" 
                                onClick={()=>this.props.handleViewChange("app")}
                            >
                            <img className="back"src={Left}></img>
                            </div>
                            <p className="heading">Suscripción</p>

                        </div>

                    </div>
                    <div>
                        <div className="opps">
                            <div className="opps-header">
                                <div className="opps-reminder">Ficha digital. No es necesario imprimir.</div>
                                <div className="opps-info">
                                    <div className="opps-brand"><img src={OxxoBrand} alt="OXXOPay"/></div>
                                    <div className="opps-ammount">
                                        <h3>Monto a pagar</h3>
                                        <h2>$ {this.state.amount} <sup>MXN</sup></h2>
                                        <p>OXXO cobra una comisión adicional de $12 pesos al momento de realizar el pago.</p>
                                    </div>
                                </div>
                                <div className="opps-reference">
                                    <h3>Referencia</h3>
                                    <h1>{this.state.paymentData.data.payment_method.reference}</h1>
                                </div>
                            </div>
                            <div className="opps-instructions">
                                <h3>Instrucciones</h3>
                                <ol>
                                    <li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank">Encuéntrala aquí</a>.</li>
                                    <li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
                                    <li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>
                                    <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                                    <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
                                </ol>
                                <div className="opps-footnote">Al completar estos pasos recibirás tus <strong>Robins</strong>.</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        
        if(!this.state.active){
            return(
                <div>
                    <div className="nav-bar">

                        <div className="nav-left">
                            <div
                                className="nav-icon" 
                                onClick={()=>this.props.handleViewChange("app")}
                            >
                            <img className="back"src={Left}></img>
                            </div>
                            <p className="heading">Suscripción</p>

                        </div>

                    </div>

                    <div className="settings-view">
                        <div className="oxxo-view">
                            <p className="oxxo-text">Puedes acudir a cualquier tienda Oxxo a depositar tus ahorros en pesos para cambiarlos a Robins.</p>
                            <div className="conversion">
                                <div className="peso-container">
                                    <img className="peso-img" src={Peso}></img>
                                    <input type="number" onChange={e => this.handleChangeAmount(e)} value={this.state.amount} className="peso-input"></input>
                                </div>
                                <img className="arrow-img" src={Arrow}></img>
                                <div className="robin-container">
                                    <img className="robin-img" src={Coin}></img>
                                    <p className="robin-amount">{this.state.amount*5}</p>
                                </div>
                            </div>
                            <button onClick={()=>this.oxxoPay()} className="oxxo-deposit">Depositar</button>
                        </div>

                        <div className="subscription-view">
                            <img className="mascot" src={RobinMascot}></img>
                            <p className="sus-text">Para jugar todos los niveles, ganar más Robins y aprender a manejar tu dinero suscríbete por $50 MXN al mes. </p>

                            <Checkout
                                name="Robin"
                                description="Educación Financiera para Niños"
                                amount={150}
                                isHidden={false}
                                className="stripe-button"
                                user={this.props.userID}
                                setSuscription={this.setSuscription}
                            />
                        </div>
                    </div>
                </div>
                
            )
        }

        else{
            return(
                <div>
                    <div className="nav-bar">

                        <div className="nav-left">
                            <div
                                className="nav-icon" 
                                onClick={()=>this.props.handleViewChange("app")}
                            >
                            <img className="back"src={Left}></img>
                            </div>
                            <p className="heading">Suscripción</p>

                        </div>

                    </div>

                    <div className="settings-view">
                        <div className="oxxo-view">
                            <p className="oxxo-text">Puedes acudir a cualquier tienda Oxxo a depositar tus ahorros en pesos para cambiarlos a Robins.</p>
                            <div className="conversion">
                                <div className="peso-container">
                                    <img className="peso-img" src={Peso}></img>
                                    <input type="number" onChange={e => this.handleChangeAmount(e)} value={this.state.amount} className="peso-input"></input>
                                </div>
                                <img className="arrow-img" src={Arrow}></img>
                                <div className="robin-container">
                                    <img className="robin-img" src={Coin}></img>
                                    <p className="robin-amount">{this.state.amount*5}</p>
                                </div>
                            </div>
                            <button onClick={()=>this.oxxoPay()} className="oxxo-deposit">Depositar</button>
                        </div>
                        <div className="subscription-view">
                            <img className="mascot" src={RobinMascot}></img>
                            <p className="sus-text">Con tu suscripción de Robin puedes jugar todos los niveles y ganar Robins cada día. </p>

                            <button onClick={this.cancelSuscription} className="cancel-btn">Cancelar</button>
                        </div>
                    </div>

                    
                </div>
            )
        }
        
    }
}

export default Subscription