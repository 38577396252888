import React from 'react';
import './textfield.css'

const TextField = (props) => {

    const style = {
        borderColor: props.color
    }

    return(
        <div>
            <input
                onChange={e => props.onChange(e)}
                className="textfield"
                value={props.view || props.text}
                placeholder={props.placeholder}
                type={props.type}
                style={style}
            />
        </div>
    )

}

export default TextField;