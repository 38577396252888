import React from 'react'
import Teacher from '../../assets/images/blond-teacher.png'
import './tutoring.css'

const Tutoring = (props)=>{
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundImage = "none";
    window.scrollTo(0, 0);

    return (
        <div className="tutoring-view">
            <p className="tutoring-title">¿Necesitas ayuda con las clases de la escuela?</p>

            <img src={Teacher} className="tutoring-img"></img>
            <p className="tutoring-text">Puedes agendar una sesión de asesoría personal por videollamada con el equipo de Robin 😉</p>

            <br/>
            <br/>
            <p className="tutoring-text">Podemos ayudarte a estudiar para un exámen y entender algún tema o tarea de cualquier materia. El costo por hora es de $320 MXN y se pueden registrar aquí 👇</p>
        
            <button onClick={()=>{window.open("https://airtable.com/shrTTLNphNCnRzQhM", "_blank")}} className="tutoring-registro">Registro</button>
        </div>
    )
}

export default Tutoring