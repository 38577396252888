import React from "react";
import "./field.css";

const Field = props => {
  return (
    <input
      onChange={e => props.onChange(e)}
      className={props.color}
      value={props.view || props.text}
      placeholder={props.placeholder}
      type={props.type}
    />
  );
};

export default Field;
