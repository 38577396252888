import React from 'react';
import './bar.css'

const Bar = (props) => {

    var width = (props.progress*100)/props.max

    const style = {
        width: width+"%",
        backgroundColor: props.color,
    }

    return(
        <div>
            <div className="background-bar">
                {props.progress != 0 &&
                    <div className="progress-bar" style={style}></div>
                }
                
            </div>
            
        </div>
    )
}

export default Bar;