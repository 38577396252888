// import React from 'react'
// import English from '../../assets/images/english-bg.jpg'
// import Spanish from '../../assets/images/spanish-bg.jpg'
// import Math from '../../assets/images/math-bg.jpg'
// import Science from '../../assets/images/science-bg.jpg'
// import Ethics from '../../assets/images/ethics-bg.jpg'
// import './subject-selector.css'

// const SubjectSelector = (props)=>{
//     return(
//         <div className="subject-selector-view">
//             <div className="subject-box" onClick={()=>{props.viewLMS("matematicas")}}>
//                 <img src={Math} className="subject-img"></img>
//                 <div>
//                     <p className="subject-box-title">Matemáticas</p>
//                 </div>
//             </div>

//             <div className="subject-box" onClick={()=>{props.viewLMS("ciencias")}}>
//                 <img src={Science} className="subject-img"></img>
//                 <div>
//                     <p className="subject-box-title">Ciencias</p>
//                 </div>
//             </div>
//             <div className="subject-box" onClick={()=>{props.viewLMS("espanol")}}>
//                 <img src={Spanish} className="subject-img"></img>
//                 <div>
//                     <p className="subject-box-title">Español</p>
//                 </div>
//             </div>

//             {props.child.highschool &&
//             <div className="subject-box" onClick={()=>{props.viewLMS("biologia")}}>
//                 <img src={English} className="subject-img"></img>
//                 <div>
//                     <p className="subject-box-title">Biología</p>
//                 </div>
//             </div>
//             }

//             <div className="subject-box" onClick={()=>{props.handleViewChange("language")}}>
//                 <img src={English} className="subject-img"></img>
//                 <div>
//                     <p className="subject-box-title">Inglés</p>
//                 </div>
//             </div>

//             {props.child.highschool &&
//             <div className="subject-box" onClick={()=>{props.viewLMS("met")}}>
//                 <img src={'https://images.unsplash.com/photo-1474631245212-32dc3c8310c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1981&q=80'} className="subject-img"></img>
//                 <div>
//                     <p className="subject-box-title">Metodologías de Investigación</p>
//                 </div>
//             </div>
//             }

//             {props.child.highschool &&
//             <div className="subject-box" onClick={()=>{props.viewLMS("ter")}}>
//                 <img src={'https://images.unsplash.com/photo-1550592704-6c76defa9985?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80'} className="subject-img"></img>
//                 <div>
//                     <p className="subject-box-title">Taller de Escritura y Redacción</p>
//                 </div>
//             </div>
//             }
            
//             <div className="subject-box" onClick={()=>{props.viewLMS("geografia")}}>
//                 <img src={'https://images.unsplash.com/photo-1476514525535-07fb3b4ae5f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'} className="subject-img"></img>
//                 <div>
//                     <p className="subject-box-title">Geografía</p>
//                 </div>
//             </div>

//             <div className="subject-box" onClick={()=>{props.viewLMS("historia")}}>
//                 <img src={'https://images.unsplash.com/photo-1628014958838-7d1d0a52e9f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80'} className="subject-img"></img>
//                 <div>
//                     <p className="subject-box-title">Historia</p>
//                 </div>
//             </div>

            

//             {!props.child.kinder &&
//             <div className="subject-box" onClick={()=>{props.handleViewChange("projects-library","projects-library")}}>
//                 <img src={"https://images.unsplash.com/photo-1452860606245-08befc0ff44b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"} className="subject-img"></img>
//                 <div>
//                     <p className="subject-box-title">Proyectos Extracurriculares</p>
//                 </div>
//             </div>
//             }

            
            

            
//             {/* <div className="subject-box" onClick={()=>{props.viewLMS("sociales")}}>
//                 <img src={Ethics} className="subject-img"></img>
//                 <div>
//                     <p className="subject-box-title">Ciencias Sociales</p>
//                 </div>
//             </div> */}

//         </div>
//     )
// }

// export default SubjectSelector

import React, {useState, useEffect} from 'react'

import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

import './subject-selector.css'

const SubjectSelector = (props)=>{

    const [notionData, setNotionData] = useState([]);

    const [subjects, setSubjects] = useState([])

    let curriculum = "extracurricular"

    if(props.child.school){
        curriculum = "school"
    }

    if(props.child.highschool){
        curriculum = "highschool"
    }

    console.log(props)
    
    let size = ""
    if(props.size){
        size = props.size
    }

    if(props.child.plan){
        curriculum = props.child.plan

    }

    

    useEffect(()=>{

        const query = {
            database:"f3bd8c7771604938bb7238ceb5b8d197",
            filter: {
                and: [
                    {
                        property: 'Tags',
                        multi_select: {
                            contains: curriculum
                        }
                    }
                ]
            },
            sorts:[
                {
                    property: 'Sequence',
                    direction: 'ascending'
                }
            ]
        }

        const notionQuery = firebase.functions().httpsCallable("notionQuery")
        notionQuery(query)
            .then(response=>{
                setNotionData(response.data);
                console.log(response.data)
                setSubjects(response.data.results)
            })
        
    }, [])


    return(
        <div className={"subject-selector-view"+size}>

            {subjects.map(subject =>{
                const subjectKey = subject.properties.Subject.select.name
                let cover = ""
                if (subject.cover.external){
                    cover = subject.cover.external.url
                }
                else{
                    cover = subject.cover.file.url
                }
                return(
                    <div className={"building"+size} onClick={()=>props.viewSubject(subjectKey)}>
                        <img className={"building-img"+size} src={cover}></img>
                    </div>
                    // <div className="subject-box" onClick={()=>{props.viewSubject(subjectKey)}}>
                    //     <img src={cover} className="subject-img"></img>
                    //     <div>
                    //         <p className="subject-box-title">{subject.properties.Name.title[0].plain_text}</p>
                    //     </div>
                    // </div>
                )
            })}
            
           
        </div>
    )
}

export default SubjectSelector