import React from 'react';
import Left from '../../assets/images/left.png'
import MainImage from '../../assets/images/boy1.png'
import ManagerDialogue from './manager-dialogue'
import QuestOptions from './quest-options'
import './quest.css'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;


import Pantalla from  '../../assets/images/screen.png'
import Character from  '../../assets/images/businessman.png'
import Store from '../../assets/images/store-bg.png'
import Cross from '../../assets/images/cross.png'

class Manager extends React.Component {

    constructor(){

        super()

        this.state = {
            quest: 0, 
            scene: 0, 
            pause: false,
            prev_scene: 0,
            history: ["scene1"]
        }

        this.handleSceneChange = this.handleSceneChange.bind(this);
        this.handleBackScene = this.handleBackScene.bind(this);

        this.handlePause = this.handlePause.bind(this);

        // this.handleAddRobins = this.handleAddRobins.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
    }

    componentDidMount(){
        this.props.toggleLoading(false)
    }

    componentWillMount(){
        alert("CAACA")
        const that = this
        firebase.database().ref("quests/quest"+this.props.questLevel).on("value", function(snap){
            const quest = snap.val()

            that.setState({
                quest: quest,
                scene: "scene1"
            })
        })
        
    }

    handlePause(){
        this.setState({
            pause: !this.state.pause
        })
    }

    handleFinish(grade, skill){

        firebase.database().ref("old-quest-progress/"+this.props.userID+"/"+(this.props.questLevel-1)).set(grade)
        

        const that = this

        firebase.database().ref("grades/"+this.props.userID+"/"+skill).once("value", function(snap){
            var skillgrades = []

            if(snap.val()){
                
                skillgrades = snap.val()
            }

            skillgrades.push(grade)
          
            firebase.database().ref("grades/"+that.props.userID+"/"+skill).set(skillgrades)
            
        })

        firebase.database().ref("users/"+this.props.userID+"/balance").once("value", function(snap){
            var amount = 0

            var timestamp = +new Date();

            switch(grade){
                case 0:
                    amount = 0
                    break;
                case 1: 
                    amount = 5
                    break;
                case 2: 
                    amount = 10
                    break;
                case 3:
                    amount = 20
                    break;
            }

            const newBal = amount + snap.val()

            firebase.database().ref("users/"+that.props.userID).update({
                balance: newBal
            })

            firebase.database().ref("transactions/" + that.props.userID).push().set({
                desc: "Reto del día",
                cant: amount,
                type: "add",
                date: timestamp
            })

            

            firebase.database().ref("users/"+that.props.userID+"/quest").set(timestamp)

            
            firebase.database().ref("analytics/quests/"+timestamp).push(that.props.userID)
            
    
        })

        
    }


    handleSceneChange(scene){

        var history = this.state.history

        history.push(scene)

        // this.setState({
        //     prev_scene: this.state.scene
        // })

        this.setState({
            scene: scene, 
            history: history
        })
    }

    handleBackScene(){
        
        var history = this.state.history
        history.pop()
        this.setState({
            scene: history[history.length - 1]
        })
    }

    render(){
        const currentScene = this.state.scene

        console.log(currentScene)
        console.log(this.state)
        console.log(this.state.quest[currentScene])

        if(this.state.quest == 0){
            return(
                <div></div>
            )
        }
        else{
            
            return(

                <div>
                    
        
                    <div className="manager-scene">
                        <img className="store-img"src={Store}></img>
                    </div>
                    <div className="manager-office">
    
                        <div
                            className="manager-quit" 
                            onClick={()=>this.props.handleViewChange("app")}
                            // onClick={this.handleBackScene}
                            >
                
                
                            <img className="back"src={Cross}></img>
                        </div>
                    
                    
                        <div className="manager-img-container">
                            {/* <img className="quest-img"src={this.state.scene.img}></img>
                            <img className="character-img"src={this.state.scene.character}></img> */}

                            
    
                            <img className="manager-img" src={this.state.quest[currentScene].img}></img>
                            <img className="manager-character" src={Character}></img>
    
                        </div>
                        <div className="right-container">
                            <ManagerDialogue
                                text={this.state.quest[currentScene].text}
                            />
                            <div className="bottom-collection">

                                {this.state.quest[currentScene].back &&
                                    <div
                                        className="manager-back" 
                                        // onClick={()=>this.props.handleViewChange("app")}
                                        onClick={this.handleBackScene}
                                    >
                    
                    
                                        <img className="back"src={Left}></img>
                                    </div>
                                }
                
                                <QuestOptions
                                    scene={this.state.quest[currentScene]}
                                    handleSceneChange={this.handleSceneChange}
    
                                    handleAddRobins={this.handleAddRobins}
                                    handleFinish={this.handleFinish}
                                />
                                {console.log(this.state.scene)}
                            </div>
                            
                            
                        </div>
                    </div>
    
                    
                    {/* <PauseModal
                        handlePause = {this.handlePause}
                        handleViewChange = {this.props.handleViewChange}
                    /> */}
                </div>
            
            )
        }
    }
    
}

export default Manager;
