import React from 'react'

import './video-modal.css'
import Stepper from '../stepper/stepper'

const VideoModal = (props) => {

    const tutorial = [
        {
            text: "Bienvenido a la ciudad de Robin, cada edificio es una experiencia de aprendizaje.", 
            img: "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2FScreen%20Shot%202020-06-20%20at%204.51.29%20PM.png?alt=media&token=74a9b665-8bde-41c4-b636-265e3e3b5c70"
        }, 
        {
            text:"Entra al edificio de Arcade para ir al Curso de Programación de Videojuegos, por ejemplo.", 
            img: "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2FScreen%20Shot%202020-06-20%20at%204.50.43%20PM.png?alt=media&token=64ded100-e989-4df5-b456-d65e2285ecb1"
        }, 
        {
            text:"Haz click en la Clase 1 para empezar el curso y ve los videos de la actividad.", 
            img:"https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2FScreen%20Shot%202020-06-20%20at%205.34.18%20PM.png?alt=media&token=4bbac8bb-4ebf-4c9d-9c96-4144def90003"
        }, 
        {
            text:"Si tienes alguna duda pregúntale al profesor o tus compañeros en el Chat Grupo.", 
            img:"https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2FScreen%20Shot%202020-06-20%20at%205.35.04%20PM.png?alt=media&token=bf06f1d9-25a7-4ca7-a3b0-6194add8daaf"
        }, 
        {
            text:"Cuando termines sube una foto de tu trabajo para ganar Robins.", 
            img:"https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2FScreen%20Shot%202020-06-20%20at%205.36.46%20PM.png?alt=media&token=65956a9c-f444-441a-88f2-940e13e1b0f2"
        }, 
        {
            text:"Podrás cambiar tus Robins por premios reales en la tienda para que te lleguen a tu casa.", 
            img:"https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2FScreen%20Shot%202020-06-20%20at%205.44.51%20PM.png?alt=media&token=7f704fcf-6f63-4584-97f7-ed8c5b839b52"
        }
    ]

    return(
        <div className="video-modal-bg">
    
            {/* <iframe className="video-modal" src={props.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                        
            <img onClick={()=>{props.handleFinishTutorial()}} className="modal-quit" src={"https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/quit-cross.png?alt=media&token=04ad50b8-1b1e-43c2-94df-396aca65202c"}></img>
            <button onClick={()=>{props.handleFinishTutorial()}} className="close-video-modal">Empezar</button> */}

            <Stepper
                stepArray={tutorial}
                finish={()=>{props.handleFinishTutorial()}}
            />
        </div>
    )
}

export default VideoModal