import React from 'react';
import Stock from './stock';

const StockPortfolio = (props) => {
    document.body.style.backgroundColor = "#00CCFF";

    var flip = false

    if(props.timer==40){
        flip = true
    }
    else{
        flip = false
    }

    
    if(props.portfolio && props.stockArray){
        const stockArray = props.stockArray
        // console.log(stockArray)
        return(
            <div className="stock-portfolio">
    
                {Object.keys(props.portfolio).map(function(key){

                    return(
                        <Stock
                            img={props.portfolio[key].img}
                            price={props.portfolio[key].value[props.round]}
                            key={key}
                            stock={key}
                            handleStockSell={props.handleStockSell}
                            flip={flip}
                        />
                    )
                })}

                {/* {stockArray.forEach(key => {
                    console.log(props.market[key].value[props.round])
                    return(
                        <Stock
                            img={props.portfolio[key].img}
                            price={props.portfolio[key].value[props.round]}
                            key={key}
                            stock={key}
                            handleStockSell={props.handleStockSell}
                            flip={flip}
                        />
                    )
                }) */}

                
    
            </div>
        )
    }

    else{
        return(
                <p className="portfolio-placeholder">
                    Puedes comprar acciones en el mercado. 
                    Recuerda que cada 6 horas cambian los precios de las acciones. 
                    <br/><br/>
                    Intenta comprar acciones baratas para después venderlas caras.
                </p>   
        )
    }
}

export default StockPortfolio;