import React from 'react';
import StockOffer from './stock-offer';
import './stock-market.css'

const StockMarket = (props) => {

    document.body.style.backgroundColor = "#984EFF";
    var flip = false

    if(props.timer==40){
        flip = true
    }
    else{
        flip = false
    }

    return(
        <div className="stock-market">

            
            {Object.keys(props.market).map(function(key){
                return(
                    <StockOffer
                        img={props.market[key].img}
                        name={props.market[key].name}
                        min={props.market[key].min}
                        max={props.market[key].max}
                        price={props.market[key].value[props.round]}
                        key={key}
                        stock={key}
                        handleStockPurchase = {props.handleStockPurchase}
                        flip={flip}
                    />
                )
            })}
        </div>
    )

}

export default StockMarket;