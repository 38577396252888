import React from 'react';
import './wallstreet-stock.css'

const Stock = (props) => {

    var classname = ""
    if(props.flip){
        classname = "stock swing-in-top-fwd"
    }
    else{
        classname = "stock"
    }


    return(
        <div className={classname}>
            <img className="wallstret-stock-logo" src={props.img}></img>
            <div>
                <div className="stock-row">
                    <p className="stock-data">Precio de Compra: </p>
                    <p className="stock-value">${props.purchase}</p>
                </div>

                <div className="stock-row">
                    <p className="stock-data">Precio de Actual: </p>
                    <p className="stock-value">${props.price}</p>
                </div>

                <div className="stock-row">
                    <p className="stock-data">Industria: </p>
                    <p className="stock-value">Tecología</p>
                </div>
                
            </div>
            
            
            <button 
                className="stock-sell-btn"
                onClick={()=>props.handleStockSell(props.stock, props.name)}
                
            >Vender</button>
        </div>
    )
}

export default Stock;