import React from 'react'

import Check from '../../assets/images/green-check.png'
import PurpleArrow from '../../assets/images/purple-right-arrow.png'
import TeacherCircle from '../../assets/images/teacher-circle-purple.png'
import CommentCircle from '../../assets/images/comment-circle-yellow.png'
import PlusCircle from '../../assets/images/plus-circle-gray.png'
import Clock from '../../assets/images/clock-icon.png'
// import PlusWhite from '../../assets/images/plus-white-blue-bg.png'


const ProjectBox = (props)=>{

    var img = PlusCircle

    const statusImages = {
        "approved": Check, 
        "waiting-approval": TeacherCircle, 
        "teacher-comment": CommentCircle, 
    }

    if(props.uploadedActivity){
        if(props.uploadedActivity.status){
            var img = statusImages[props.uploadedActivity.status]
        }
    }

    var circleAction = ()=>{props.viewCurricularActivity(props.activity, props.backView)}
    if(img == PlusCircle){
        circleAction = ()=>{props.addActivity(props.activity, props.activity_key)}
    }
    

    const activity = props.activity

    return(
        
        <div className="project-box">
            
            <div className="project-box-top-overlay">
                <p className="project-age">
                    {activity.age}
                </p>
                <p className="project-time">
                    {activity.time}
                </p>
            
            </div>

            <img onClick={()=>{props.viewCurricularActivity(props.activity, props.backView)}} src={activity.img} className="project-box-img">
            </img>

            <div className="project-box-bottom">
                <p onClick={()=>{props.viewCurricularActivity(props.activity, props.backView)}} className="project-title">
                    {activity.title}
                </p>
                <img onClick={circleAction} src={img} className="project-box-circle-btn"></img>

            </div>
        

        </div>
    )
}

export default ProjectBox