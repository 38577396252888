import React from 'react'
import './agenda-task.css'

import Delete from '../../assets/images/delete-circle.png'
import Check from '../../assets/images/check-circle-gray.png'
import Minus from '../../assets/images/minus-circle-gray.png'
import Clock from '../../assets/images/clock-icon.png'
import BerryImg from '../../assets/images/berry-purple.png'
import RobinCoin from '../../assets/images/robin-coin.png'  // Add this import

class AgendaTask extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            points: 0,
            isRobins: false
        }
    }

    componentDidMount(){
        this.updatePointsState()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activity !== this.props.activity) {
            this.updatePointsState()
        }
    }

    updatePointsState = () => {
        const activity = this.props.activity?.activity || this.props.activity

        if (activity?.properties?.robins?.number) {
            this.setState({
                points: activity.properties.robins.number,
                isRobins: true
            })
        } else if (activity?.properties?.tiempo?.number) {
            this.setState({
                points: activity.properties.tiempo.number,
                isRobins: false
            })
        } else {
            this.setState({
                points: 0,
                isRobins: false
            })
        }
    }

    setReward = (e) => {
        this.setState({
            points: e.target.value
        })
    }

    saveAndReturn = () => {
        this.props.updateReward(this.props.activity.activity_key, this.props.dayIndex, this.props.day, this.state.points)
    }

    render(){
        const statusImg = {
            undone: "https://polychatstorage.blob.core.windows.net/icons/undone-task.png", 
            done: "https://polychatstorage.blob.core.windows.net/icons/purple-check.png", 
            approved: Check,
            rejected: Check
        }

        return(
            <div className="agenda-task">
                <div className="row">
                    {this.props.edit ?
                        <img onClick={()=>{this.props.deleteTask(this.props.activity, this.props.day)}} className="agenda-task-circle-btn" src={Delete}></img>
                        :
                        <img onClick={()=>{this.props.toggleStatus(this.props.activity, this.props.day)}} className="agenda-task-circle-btn" src={statusImg[this.props.status]}></img>
                    }
                    <p onClick={()=>{this.props.viewCurricularActivity(this.props.activity, "app", this.props.activity_key)}} className="agenda-task-name">{this.props.name}</p>
                </div>
                    
                <div className="row">
                    {this.props.edit ?
                        <div className='berry-counter'>
                            <img onClick={()=>{this.saveAndReturn()}} src={this.state.isRobins ? RobinCoin : BerryImg} className='berry-counter-img'></img>
                            <input onChange={(e)=>{this.setReward(e)}} className={`berry-reward-input ${this.state.isRobins ? 'robin-reward-input' : ''}`} value={this.state.points}></input>
                        </div>
                    :
                        <div className='berry-counter disabled-berry-input'>
                            <img src={this.state.isRobins ? RobinCoin : BerryImg} className='berry-counter-img disabled-berry-input'></img>
                            <input className={`berry-reward-input ${this.state.isRobins ? 'robin-reward-input' : ''}`} value={this.state.points} readOnly></input>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default AgendaTask
