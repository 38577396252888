import React from 'react';
import './gourmet-tab.css'

const GourmetTab = (props) => {
    return(
        <div className="gourmet-tab" style={{backgroundColor: props.color}}>
            <img className="tab-img" src={props.img}></img>
            <p className="tab-name">{props.name}</p>
        </div>
    )
}

export default GourmetTab;