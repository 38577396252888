import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import Chore from '../chores/chore'
import axios from 'axios';

import './chore-view.css'

import AskChore from '../../assets/images/pedir-chores.png'

import FinishChore from '../../assets/images/finish-chores.png'


class ChoreView extends React.Component{
    constructor(){
        super()

        this.state = {
            chores: {}
        }
        
    }

    componentWillMount(){


        document.body.style.backgroundColor = "#F0F0F4";
        document.body.style.backgroundImage = "none";

        firebase.database().ref("chores/"+this.props.uid).once("value", (snap)=>{
            this.setState({
                chores: snap.val()
            })
        })
    }

    choreRequest = () => {

        firebase.database().ref("users/"+this.props.uid+"/chore-request").once("value", (snap)=>{

            const sent = snap.val()


            axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
                var timestamp = data.data.timestamp

                const auxtimestamp = timestamp-72000000

                if(auxtimestamp>=(sent)) {

                    firebase.database().ref("users/"+this.props.uid+"/chore-request").set(timestamp)


                    console.log(this.props)
                    var name = this.props.child.name

                    console.log(name)
                    name = name.split(" ")

                    console.log(name)

                    if(this.props.child.parent){
                        const request = {
                            child: name[0], 
                            parent: this.props.child.parent,
                            fullname: this.props.child.name
    
                        }
    
                        // firebase.database().ref("chore-requests/"+timestamp).set(request)

                        const sendWelcome = firebase.functions().httpsCallable("sayHello")
 
                        const user = {
                            //   text: "",
                            subject: name[0] + " está pidiendo trabajos para la casa",
                                //   template: "",
                            html:"<div>Hola, <br/><br/>"+name[0]+" te envió una solicitud para que pongas trabajos en casa a través de la página de Robin. <br/><br/> Como sabes en nuestros cursos los alumnos ganan Robins cuando realizan correctamente sus ejercicios, ellos los van ahorrando para comprar algún premio que les llega a su casa. Los padres de familia también pueden comprar Robins y ponerle tareas en el hogar a los niños para ayudarlos a cumplir su meta de ahorro.<br/><br/>En la página de padres también puedes ver su progreso. En este video les cuento como funciona la dinámica: <a href='https://youtu.be/rqTl_2iP8ws'>https://youtu.be/rqTl_2iP8ws</a><br/><br/> Pueden crear su cuenta de padres en: <a href='robinacademy.com/parents'>robinacademy.com/parents </a> <br/>Saludos,<br/>Roger</div>",
                            to:this.props.child.parent,
                        }
            
                        sendWelcome(user).then((result)=>{
                            console.log(result)
                            alert("Hemos enviado un correo a tus padres para que te pongan una actividad")
                        
                            // alert("Te enviamos un correo con el acceso a "+this.state.correo)
                            // window.location = "https://robinacademy.com/app"
                        })
    
                        // alert("Hemos enviado un correo a tus padres para que te pongan una actividad")
                        
                    }
                    else{
                        alert("Tus papás deben crear cuenta en Robin primero")
                    }

                    
                }
                else{
                    alert("Ya enviamos un mensaje a tus papás el día de hoy. Solo podemos enviar un mensaje al día.")
                }

                

            })
        })

    }

    choreFinish = () => {

        firebase.database().ref("users/"+this.props.uid+"/chore-request").once("value", (snap)=>{

            const sent = snap.val()

            axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
                var timestamp = data.data.timestamp

                const auxtimestamp = timestamp-72000000

                if(auxtimestamp>=(sent)) {

                    firebase.database().ref("users/"+this.props.uid+"/chore-request").set(timestamp)


                    console.log(this.props)
                    var name = this.props.child.name

                    console.log(name)
                    name = name.split(" ")

                    console.log(name)

                    if(this.props.child.parent){
                        const request = {
                            child: name[0], 
                            parent: this.props.child.parent,
                            fullname: this.props.child.name
    
                        }
    
                        firebase.database().ref("chore-finish/"+timestamp).set(request)

                        const sendWelcome = firebase.functions().httpsCallable("sayHello")
 
                        const user = {
                            //   text: "",
                            subject: name[0] + " terminó trabajos para la casa",
                                //   template: "",
                            html:"<div>Hola, <br/><br/>"+name[0]+" indicó en su apliación de Robin que ya terminó sus actividades. Puedes aprobar lo que hizo en la plataforma de padres entrando a <a href='robinacademy.com/parents'>robinacademy.com/parents </a> <br/>Saludos,<br/>Roger</div>",
                            to:this.props.child.parent,
                        }
            
                        sendWelcome(user).then((result)=>{
                            console.log(result)
                            alert("Hemos enviado un correo a tus padres para que revisen su las tareas, ellos deben entrar a su aplicación de papás en Robin y aprobar las tareas para que ganes los Robins.")
                        
                            // alert("Te enviamos un correo con el acceso a "+this.state.correo)
                            // window.location = "https://robinacademy.com/app"
                        })
    
                        // alert("Hemos enviado un correo a tus padres para que revisen la actividad")
                        
                    }
                    else{
                        alert("Tus papás deben crear cuenta en Robin primero")
                    }

                    
                }
                else{
                    alert("Ya enviamos un mensaje a tus papás el día de hoy. Solo podemos enviar un mensaje al día.")
                }

                

            })
        })

    }


    render(){
        console.log(this.state)

        if(this.state.chores){
            return(
                <div className="chore-view">
                        
                    {Object.keys(this.state.chores).map((id)=>{
    
                        const chore = this.state.chores[id]
                        return(
                            <Chore
                                name={chore.name} 
                                img={chore.img}
                                robins={chore.robins}  
                                id={id}
                                deleteChore={this.deleteChore}
                                payChore={this.payChore}
                            />
                        )
                    })}
                    
                        
    
                    <img onClick={()=>{this.choreFinish()}} className="ask-chore-btn" src={FinishChore}></img>
                    
                </div>
            )
        }
        else{
            return(
                <div className="chore-view">
                        
                    <p className="chore-explanation">Puedes ganar Robins haciendo trabajos en casa como lavar el carro o limpiar el jardín.</p>
                    <br/><br/>
                    <p className="chore-explanation">Tus papás de pueden asignar estos trabajos creando cuenta de papás en <a href="https://robinacademy.com/parents">robinacademy.com/parents </a></p>
                    <img onClick={()=>{this.choreRequest()}} className="ask-chore-btn" src={AskChore}></img>
                    
                </div>
            )
        }
        
    }
}

export default ChoreView