import React from 'react';
import WallstreetMarketCard from './wallstreet-market-card';
import '../street/stock-market.css'

const WallstreetMarket = (props) => {

    var flip = false

    if(props.timer==40){
        flip = true
    }
    else{
        flip = false
    }

    
    const market = props.market[props.city]
    const currentRound = props.currentRound
    return(
        <div className="stock-market">

            
            {Object.keys(market).map(function(key){
                    

                const stock = market[key]
                return(
                    <WallstreetMarketCard
                        img={stock.img}
                        name={stock.name}
                        min={stock.min}
                        max={stock.max}
                        price={props.roundValues[currentRound][key]}
                        key={key}
                        stock={key}
                        handleStockPurchase = {props.handleStockPurchase}
                        flip={flip}
                        text={stock.text}
                    />
                )
            })}
        </div>
    )

}

export default WallstreetMarket;