import React from 'react';
import StockMarket from './wallstreet-market';
import StockPortfolio from './wallstreet-portfolio';
import News from './wallstreet-news'
import CityPicker from './city-picker';
import Summary from './summary';



const StreetView = (props) => {

    switch(props.tab){
        case "portfolio":
            return <StockPortfolio
                portfolio = {props.portfolio}
                handleStockSell={props.handleStockSell}
                timer={props.timer}
                stockArray={props.stockArray}
                market={props.market}
                city={props.city}
                roundValues={props.roundValues}
                currentRound={props.currentRound}
            />
        case "news":
            return <News
                news={props.news}
                currentRound={props.currentRound}
                timer={props.timer}
                mexNews={props.mexNews}
                nycNews={props.nycNews}
                shaNews={props.shaNews}
                ldnNews={props.ldnNews}
                city={props.city}
                news={props.news}
            />
        case "market":
            return <StockMarket
                market = {props.market}
                handleStockPurchase = {props.handleStockPurchase}
                roundValues={props.roundValues}
                currentRound={props.currentRound}
                timer={props.timer}
                city={props.city}
            />
        
        case "city-picker":
            return <CityPicker
                players={props.players}
                pickCity={props.pickCity}
        />

        case "summary":
            return <Summary
                market={props.market}
                portfolioSummary = {props.portfolioSummary}
                players = {props.players}
                playerQueue={props.playerQueue}
                team = {props.team}
            />
    }
}

export default StreetView;