import React from 'react'
import Field from '../layout/field/field'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;

import Button from "../layout/button/button";

class Login extends React.Component {
    constructor(){
        super()

        this.state={
            user: "",
            password: ""
        }
    }

    handleLogin = () =>{
        var username = this.state.user
        var n = username.indexOf("@")

        if(n<0){
            username += "@robin.com"
        }

        firebase.auth().signInWithEmailAndPassword(username, this.state.password).then(()=>{

            const user = firebase.auth().currentUser

            this.readUserData(user)
        }).catch((error)=>{
            var errorCode = error.code;
            var errorMessage = error.message
            alert(errorMessage)
            
        })



    }

    readUserData = (user) =>{
        firebase.database().ref("users/"+user.uid).on("value", (snap) => {

            const child = snap.val()
            this.props.setUser(user, child)

            this.props.handleStartApp()
        })
    }

    handleChange = (event) => {
        switch(event.target.placeholder){
            case "Usuario":
                this.setState({
                    user:event.target.value
                })
                break
            case "Contraseña":
                this.setState({
                    password: event.target.value
                })
                break
        }
    }

    render(){
        return(
            <div className="form">
                <div>
                    <Field
                        placeholder="Usuario"
                        color="field-green"
                        onChange={this.handleChange}
                        text={this.state.user}
                        type="text"
                    />
                    <Field
                        placeholder="Contraseña"
                        color="field-green"
                        onChange={this.handleChange}
                        text={this.state.password}
                        type="password"
                    />

                    <Button
                        text="Iniciar Sesión"
                        color="button-green"
                        handleClick={this.handleLogin}
                        view="logged"
                    />
                </div>
            </div>
        )
    }



}

export default Login