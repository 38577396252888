import React, {useState, useEffect} from 'react'

import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import TopicBox from './topic-box';
import ActivityBox from './activity-box';
import NavBar from '../navigation/nav-bar';
import Bar from '../treehouse/bar';
const Topic = (props)=>{

    const [notionData, setNotionData] = useState([]);

    const [topics, setTopics] = useState([])

    const [uploadedActivities, setUploadedActivities] = useState({})
    
    const [totalPoints, setTotalPoints] = useState(0)
    const [gainedPoints, setGainedPoints] = useState(0)
    
    const [averageScore, setAverageScore] = useState(0);

    console.log(props)

    useEffect(() => {
        let total = 0;
        let gained = 0;
        let totalScore = 0;
        let scoredActivities = 0;
        
        topics.forEach(topic => {
            const points = topic.properties?.tiempo?.number || 0;
            total += points;
            
            // Check if activity is approved and has score
            const uploadedActivity = uploadedActivities[topic.id];
            if (uploadedActivity) {
                if (uploadedActivity.status === "approved") {
                    // Calculate gained points based on score percentage
                    const score = uploadedActivity.score || 0;
                    gained += (points * score / 100);
                }
                if (uploadedActivity.score) {
                    totalScore += uploadedActivity.score;
                    scoredActivities++;
                }
            }
        });
        
        setTotalPoints(total);
        setGainedPoints(Math.round(gained)); // Round to avoid decimal points
        setAverageScore(scoredActivities > 0 ? Math.round(totalScore / scoredActivities) : 0);
    }, [topics, uploadedActivities]);

    useEffect(()=>{

        const query = {
            database:"396c609207ef4df3a3ade19f60f37646",
            filter: {
                and: [
                    {
                        property: 'Topic',
                        relation: {
                            contains: props.topic
                        }
                    }
                ]
            },
            sorts: [
                {
                    property: 'secuencia',
                    direction: 'ascending'
                }
            ]
        }

        const notionQuery = firebase.functions().httpsCallable("notionQuery")
        notionQuery(query)
            .then(response=>{
                setNotionData(response.data);
                console.log(response.data)
                setTopics(response.data.results)
            })

        

        firebase.firestore().collection("students/"+props.uid+"/uploads").onSnapshot((snap)=>{

            let uploadedActivities = {}
            snap.docs.forEach(doc=>{
                uploadedActivities[doc.id]=doc.data()
            })

            setUploadedActivities(uploadedActivities)


        })
        
    }, [])


    return(
        <div className="activity-selector-view">
            <img className="lms-avatar" src={props.avatar}></img>

            <NavBar
                    handleBack={props.handleBack}
                    backView="subject"
                    title="Actividades del Tema"
            />

            {/* <div className="topic-progress-container">
                <div className="topic-points">
                    <p>Puntos {gainedPoints}/{totalPoints}</p>
                    <p>Promedio: {averageScore}%</p>
                </div>
                <Bar
                    max={totalPoints}
                    progress={gainedPoints}
                    color={'#3DD869'}
                />
            </div> */}

            <div className="global-progress-container">
                <div className="global-progress-info">
                    <h2>Progreso del Tema</h2>
                    <p>Dominio: {Math.round(gainedPoints*100/totalPoints)}%</p>
                    {/* <p>Promedio: {averageScore}%</p> */}
                </div>
                

                <div className="subject-progress-bar-background">
                    
                    {gainedPoints != 0 ?
                        <div className="topic-progress-bar" style={{width: ((gainedPoints*100)/totalPoints)+"%"}}>
                            <p>{gainedPoints}/{totalPoints}</p>
                        </div>
                        :
                        <div className="topic-progress-bar" style={{backgroundColor:"transparent"}}>
                            <p>{gainedPoints}/{totalPoints}</p>
                        </div>
                    }
                                
                </div>
            </div>

            <div className='activity-list'>
                {topics.map(topic =>{
                    console.log(topic)
                    topic.activity_key=topic.id
                    topic.name = topic.properties.nombre.title[0].plain_text
                    return(
                        
                        <ActivityBox
                            name={topic.name}
                            uploadedActivity={uploadedActivities[topic.id]}
                            addActivity={props.addActivity}
                            activity_key={topic.id}
                            activity={topic}
                            backView="topic"
                            viewCurricularActivity={props.viewCurricularActivity}
                        />
                    )
                })}
            </div>
            

        </div>
    )
}

export default Topic